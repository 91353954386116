import { Chip, Grid, Typography } from "@mui/material";
import {
  FormCheckBox,
  FormNumeric2,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import {
  IEnergyStudyAirHydronicsDTO,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import { Validator } from "src/ts/types";
import ElectricBank from "../../components/ElectricBank";
import { Dispatch, SetStateAction } from "react";
import FormTooltip from "src/components/formControls/FormTooltip";

const hzOptions = [
  { key: 60, value: "60Hz" },
  { key: 50, value: "50Hz" },
];

const motorPhaseOptions = [
  { key: "3 Phase", value: "3 Phase" },
  { key: "2 Wire Single Phase", value: "2 Wire Single Phase" },
  { key: "1 Wire Single Phase", value: "1 Wire Single Phase" },
];

export const energyElectricConfig = {
  title: "Energy Study Air Electric",
  baseUrlName: "EnergyStudyAirElectric",
  targetOptions: [
    { key: "Air", value: "I know the Electric Heat, find for Air" },
    { key: "Water", value: "I know the Air, find for Electric Heat" },
  ],
  labels: {
    heatingCoilTitle: "Electric Resistance Heating Coil Design Data",
    heatingCoilHasMultipleCircuits: "Electric Resistance Circuits/Banks",
    numberOfCircuitsInHeatingCoil: "Number of Circuits/Banks",
    sensibleBTUHHeating: (systemOfMeasurement: ISystemOfMeasurement) =>
      `Sensible ${systemOfMeasurement.get("btuhw")} Electric`,
    latentBTUHHeating: (systemOfMeasurement: ISystemOfMeasurement) =>
      `Latent ${systemOfMeasurement.get("btuhw")} Electric`,
    shrCoilHeating: "Sensible Heat Ratio (SHR) Electric:",
    val1TheMechanicalCoilBeingTested:
      "Validate the Electric Resistance Heating Coil can be fully isolated from allowing any heat transfer from occuring.",
    val2IfItsDeterminedFluidFlow:
      "If it's determined the Electric Resistance Heating Coil can't be fully isolated from allowing any heat transfer from occuring, have the system issue be repaired.",
    pretestSystemLoadEnsured: (testMode: string) =>
      `When testing is performed, ensure you have a thermal load imposed on this system - If no load is present, ${
        testMode === "Cooling" ? "heat" : "cool"
      } the space ${
        testMode === "Cooling" ? "up" : "down"
      } while no electric resistance heat is flowing.`,
    testSystemLoadEnsured: (testMode: string) =>
      `At time of system test, ensure you have a thermal load imposed on this system - If no load is present, ${
        testMode === "Cooling" ? "heat" : "cool"
      } the space ${
        testMode === "Cooling" ? "up" : "down"
      } while no electric resistance heat is flowing.`,
    haveHydronicControlValve:
      "Turn or command Electric Resistance Heat setting to off.",
    valveOffCoilFlowInlet:
      "Observe and ensure the MAT doesn't exceed the OAT or RAT.",
    haveHydronicControlValveOpen:
      "Energize/Activate the Electric Resistance Heating Coil.",
    openAnyCoilValvePreviouslyClosed:
      "Validate the MAT exceeds the OAT and/or the RAT.",
    heatingElectricResistanceTitle: () => (
      <Grid item xs={12}>
        <h2>Electric Heating Resistance Actual Data</h2>
      </Grid>
    ),
  },
  controlStatus: {
    numberOfCircuitsInHeatingCoil: { disabled: true },
    numberOfCircuitsInHeatingCoilNotListed: { disabled: true },
  },
  tooltips: {
    numberOfCircuitsInHeatingCoil: <></>,
    actualTestedCoilSP: (
      <FormTooltip
        text={
          <Typography>
            <span style={{ fontWeight: "bold" }}>Note:</span> Electric
            Resistance Heating Coil SP Drop may be null due to no mass or
            minimal restriction in the airstream.
          </Typography>
        }
      />
    ),
    spThroughCoil: (
      <FormTooltip
        text={
          <Typography>
            <span style={{ fontWeight: "bold" }}>Note:</span> Electric
            Resistance Heating Coil SP Drop may be null due to no mass or
            minimal restriction in the airstream
          </Typography>
        }
      />
    ),
  },
  inputs: {
    numberOfPumps: () => <></>,
    numberOfLeadPumpsDesign: () => <></>,
    maAirPressureDeltaHeating: () => <></>,
    designWaterDeltaPressureHeating: () => <></>,
    heatingCoilGPM: () => <></>,
    enteringHydronicHeatingCoilTemperature: () => <></>,
    leavingHydronicHeatingCoilTemperature: () => <></>,
    hydronicFluidFlowDeltaTemperatureHeating: () => <></>,
    wpdFeetHeadHeating: () => <></>,
    finsPerInchHeating: () => <></>,
    heatingCoilRows: () => <></>,
    heatingElectricResistanceManufacturer: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceManufacturerNotListed"
            value={values?.heatingElectricResistanceManufacturerNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormText
            disabled={
              values?.heatingElectricResistanceManufacturerNotListed ||
              disableStudy()
            }
            label="Manufacturer"
            name="heatingElectricResistanceManufacturer"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceManufacturer}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceManufacturer}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceModel: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceModelNotListed"
            value={values?.heatingElectricResistanceModelNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormText
            disabled={
              values?.heatingElectricResistanceModelNotListed || disableStudy()
            }
            label="Model Number"
            name="heatingElectricResistanceModel"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceModel}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceModel}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceHz: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceHzNotListed"
            value={values?.heatingElectricResistanceHzNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormRadioGroup
            name="heatingElectricResistanceHz"
            label="Rated Hz:"
            items={hzOptions}
            value={values?.heatingElectricResistanceHz}
            onChange={handleInputChange}
            row
            showSkeleton={isLoading}
            disabled={
              values?.heatingElectricResistanceHzNotListed || disableStudy()
            }
            error={errors.heatingElectricResistanceHz}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceRatedKws: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceRatedKwsNotListed"
            value={values?.heatingElectricResistanceRatedKwsNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.heatingElectricResistanceRatedKwsNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label="Rated kW"
            name="heatingElectricResistanceRatedKws"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceRatedKws}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceRatedKws}
          />
        </Grid>
      </>
    ),
    heatingElectricResistancePhase: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistancePhaseNotListed"
            value={values?.heatingElectricResistancePhaseNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormRadioGroup
            name="heatingElectricResistancePhase"
            label="Phase:"
            items={motorPhaseOptions}
            value={values?.heatingElectricResistancePhase}
            onChange={handleInputChange}
            row
            showSkeleton={isLoading}
            disabled={
              values?.heatingElectricResistancePhaseNotListed || disableStudy()
            }
            error={errors.heatingElectricResistancePhase}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceRatedVoltage: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceRatedVoltageNotListed"
            value={values?.heatingElectricResistanceRatedVoltageNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.heatingElectricResistanceRatedVoltageNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label="Rated Voltage"
            name="heatingElectricResistanceRatedVoltage"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceRatedVoltage}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceRatedVoltage}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceRatedAmperage: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceRatedAmperageNotListed"
            value={values?.heatingElectricResistanceRatedAmperageNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.heatingElectricResistanceRatedAmperageNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label="Rated Amperage"
            name="heatingElectricResistanceRatedAmperage"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceRatedAmperage}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceRatedAmperage}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceSteps: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceStepsNotListed"
            value={values?.heatingElectricResistanceStepsNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.heatingElectricResistanceStepsNotListed || disableStudy()
            }
            placeholder="0.00"
            decimalScale={0}
            thousandSeparator
            fixedDecimalScale
            label="Number of Steps"
            name="heatingElectricResistanceSteps"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceSteps}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceSteps}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceStages: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingElectricResistanceStagesNotListed"
            value={values?.heatingElectricResistanceStagesNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.heatingElectricResistanceStagesNotListed || disableStudy()
            }
            placeholder="0.00"
            decimalScale={0}
            thousandSeparator
            fixedDecimalScale
            label="Number of Stages"
            name="heatingElectricResistanceStages"
            onChange={handleInputChange}
            value={values?.heatingElectricResistanceStages}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceStages}
          />
        </Grid>
      </>
    ),
    banks: (
      values: IEnergyStudyAirHydronicsDTO,
      setValues: Dispatch<
        SetStateAction<IEnergyStudyAirHydronicsDTO | undefined>
      >,
      saveValues: (...params: any[]) => Promise<void>,
      handleSaveParent: () => void,
      refreshCircuits: boolean,
      disableStudy: () => boolean,
      isLoading: boolean,
      setLoadingTable: Dispatch<SetStateAction<boolean>>
    ) => (
      <>
        <ElectricBank
          data={values}
          setData={setValues}
          saveData={saveValues}
          setLoadingTable={setLoadingTable}
          saveParent={handleSaveParent}
          refreshCircuits={refreshCircuits}
          disableStudy={disableStudy()}
          showSkeleton={isLoading}
          isCoilBank={false}
        ></ElectricBank>
      </>
    ),
    cfms: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        {values?.target === "Electric Heat" && (
          <Grid item xs={12} md={4}>
            <FormNumeric2
              disabled={disableStudy()}
              placeholder="0.00"
              decimalScale={2}
              thousandSeparator
              fixedDecimalScale
              label={`(Actual) Electric Resistance Heating ${systemOfMeasurement.get(
                "cfm"
              )}`}
              name="cfms"
              onChange={handleInputChange}
              value={values?.cfms}
              showSkeleton={isLoading}
              error={errors.cfms}
            />
          </Grid>
        )}
      </>
    ),
    heatingElectricResistanceActualWatts: (
      values: IEnergyStudyAirHydronicsDTO,
      isLoading: boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={values?.target === "Electric Heat" ? 4 : 6}>
          <FormNumeric2
            disabled={true}
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label="Running Total Actual Watts (W):"
            name="heatingElectricResistanceActualWatts"
            value={values?.heatingElectricResistanceActualWatts}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceActualWatts}
            readOnly
            mode="read"
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceActualKWatts: (
      values: IEnergyStudyAirHydronicsDTO,
      isLoading: boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={values?.target === "Electric Heat" ? 4 : 6}>
          <FormNumeric2
            disabled={true}
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label="Running Total Actual Kilowatts (kW):"
            name="heatingElectricResistanceActualKWatts"
            value={values?.heatingElectricResistanceActualKWatts}
            showSkeleton={isLoading}
            error={errors.heatingElectricResistanceActualKWatts}
            readOnly
            mode="read"
          />
        </Grid>
      </>
    ),
  },
  validators: {
    step1: (fieldValues: IEnergyStudyAirHydronicsDTO) => {
      let temp: Record<string, string> = {};

      temp.numberOfCircuitsInHeatingCoil = new Validator(
        fieldValues,
        "numberOfCircuitsInHeatingCoil"
      )
        .validateIf(!fieldValues.numberOfCircuitsInHeatingCoilNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 1")
        .lessThan(16, "This field must be lower than 16")
        .toString();
      temp.heatingElectricResistanceManufacturer = new Validator(
        fieldValues,
        "heatingElectricResistanceManufacturer"
      )
        .validateIf(!fieldValues.heatingElectricResistanceManufacturerNotListed)
        .isRequired("This field is required")
        .toString();
      temp.heatingElectricResistanceModel = new Validator(
        fieldValues,
        "heatingElectricResistanceModel"
      )
        .validateIf(!fieldValues.heatingElectricResistanceModelNotListed)
        .isRequired("This field is required")
        .toString();
      temp.heatingElectricResistanceHz = new Validator(
        fieldValues,
        "heatingElectricResistanceHz"
      )
        .validateIf(!fieldValues.heatingElectricResistanceHzNotListed)
        .isRequired("This field is required")
        .toString();
      temp.heatingElectricResistanceRatedKws = new Validator(
        fieldValues,
        "heatingElectricResistanceRatedKws"
      )
        .validateIf(!fieldValues.heatingElectricResistanceRatedKwsNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.heatingElectricResistancePhase = new Validator(
        fieldValues,
        "heatingElectricResistancePhase"
      )
        .validateIf(!fieldValues.heatingElectricResistancePhaseNotListed)
        .isRequired("This field is required")
        .toString();
      temp.heatingElectricResistanceRatedVoltage = new Validator(
        fieldValues,
        "heatingElectricResistanceRatedVoltage"
      )
        .validateIf(!fieldValues.heatingElectricResistanceRatedVoltageNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.heatingElectricResistanceRatedAmperage = new Validator(
        fieldValues,
        "heatingElectricResistanceRatedAmperage"
      )
        .validateIf(
          !fieldValues.heatingElectricResistanceRatedAmperageNotListed
        )
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.heatingElectricResistanceSteps = new Validator(
        fieldValues,
        "heatingElectricResistanceSteps"
      )
        .validateIf(!fieldValues.heatingElectricResistanceStepsNotListed)
        .isRequired("This field is required")
        .toString();
      temp.heatingElectricResistanceStages = new Validator(
        fieldValues,
        "heatingElectricResistanceStages"
      )
        .validateIf(!fieldValues.heatingElectricResistanceStagesNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      return temp;
    },
  },
  actualsSummary: {
    columnLeft: (
      systemOfMeasurement: ISystemOfMeasurement,
      values: IEnergyStudyAirHydronicsDTO
    ) => (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>
              ELECTRIC RESISTANCE HEATING ACTUALS DATA
            </h2>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip label={systemOfMeasurement.get("btuh")} color="info" />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <p>
              <span
                style={{ fontWeight: "bold" }}
              >{`(Actual) ${systemOfMeasurement.get("btuh")}:`}</span>{" "}
              {(
                values?.heatingElectricResistanceActualBTUh || 0
              ).toLocaleString("en-US", {
                useGrouping: true,
                minimumFractionDigits: 3,
              })}
            </p>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip label="Watts (W)" color="info" />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span style={{ fontWeight: "bold" }}>(Actual) Watts(W):</span>{" "}
            {(values?.heatingElectricResistanceActualWatts || 0).toLocaleString(
              "en-US",
              {
                useGrouping: true,
                minimumFractionDigits: 3,
              }
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip label="Kilowatts (kW)" color="info" />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span style={{ fontWeight: "bold" }}>(Actual) Kilowatts(kW):</span>{" "}
            {(
              values?.heatingElectricResistanceActualKWatts || 0
            ).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
        </Grid>
      </>
    ),
  },
  energySummarySections: {
    gpm: () => <></>,
    chilledWater: () => <></>,
  },
  allAHUDesignDataNA: () => true,
  allHeatingCoilDataNA: (values: IEnergyStudyAirHydronicsDTO) =>
    values?.heatingElectricResistanceManufacturerNotListed &&
    values?.heatingElectricResistanceModelNotListed &&
    values?.heatingElectricResistanceHzNotListed &&
    values?.heatingElectricResistanceRatedKwsNotListed &&
    values?.heatingElectricResistancePhaseNotListed &&
    values?.heatingElectricResistanceRatedVoltageNotListed &&
    values?.heatingElectricResistanceRatedAmperageNotListed &&
    values?.heatingElectricResistanceStepsNotListed &&
    values?.heatingElectricResistanceStagesNotListed,
  requriedApprovalSections: [
    "cfm",
    "oa",
    "ra",
    "building",
    "coilStatic",
    "coilDelta",
    "btu",
  ],
};
