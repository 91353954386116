import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Zoom,
  Typography,
} from "@mui/material";
import { Form, useAuth, useLog } from "src/hooks";
import {
  FormSelect,
  FormText,
  FormTextArea,
  FormDatePicker,
  FormTimePicker,
  FormRadioGroup,
  FormButton,
  FormCheckBox,
  FormNumeric2,
} from "src/components/formControls";
import { IBuilding, ICompany, ICountry } from "src/ts/interfaces";
import { Validator } from "src/ts/types/Validator";
import {
  MeasurementSystemEnum,
  ProjectTimeDurationUnit,
} from "src/ts/enums/project";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import FormNumeric from "src/components/formControls/FormNumeric";
import { InfoIcon } from "src/components/icons";
import useFormTyped from "src/hooks/useFormTyped";
import { CountryService, buildingService } from "src/services";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import moment from "moment";
import { ROLES } from "src/constants";

const initialValues: ProjectWithBogus = {
  bogusStartDate: null,
  bogusStartDateTime: "8:00 AM",
  bogusActualStartDate: null,
  bogusActualStartDateTime: "8:00 AM",
  name: "",
  estimatedTimeDurationUnit: 0,
  startDate: null,
  actualStartDate: null,
  estimatedTimeDuration: 0,
  buildingId: -1,
  measurementSystem: 1,
  suppliedPowerGridFrequency: null,
  auditAreaSqFt: null,
  auditAreaCuFt: null,
  isParkingAreaPartOfThisTest: false,
  requiredComprehensiveEnergyTest: false,
  printDrawingsAvailableOnSite: false,
  printAddendumDateUsedForProposal: null,
  specificationsProvided: false,
  addendumBidForThisProject: 0,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  roofAccessHatchAvailable: 0,
  laddersRequired: false,
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: false,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  currentProject: false,
  activeProject: false,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  projectDescription: "",
  projectEstimatedTime: [],
  bogusAuditors: [],
};

const measurementSystem = [
  { key: MeasurementSystemEnum.Imperial, value: "Imperial" },
  {
    key: MeasurementSystemEnum.MetricSystem,
    value: "Metric System",
  },
];

interface Props {
  updateControlStep: (step: number, value: boolean) => void;
  value: boolean;
  selectedBuildingId: number;
  setProjectInformationHandler: (project: ProjectWithBogus | null) => void;
  company: ICompany;
  projectInformation: ProjectWithBogus | null;
}

const step = 4;

const ProjectInformation = ({
  selectedBuildingId,
  value,
  updateControlStep,
  setProjectInformationHandler,
  company,
  projectInformation,
}: Props) => {
  //hooks
  const { log } = useLog();

  //state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pressurization, setPressurization] = useState("");
  const { user } = useAuth();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Project Name is required")
      .toString();
    if (user?.role !== ROLES.Architect) {
      temp.buildingOwnerName = new Validator(fieldValues, "buildingOwnerName")
        .isRequired("Building owner name is required")
        .toString();

      temp.buildingOwnerLastName = new Validator(
        fieldValues,
        "buildingOwnerLastName"
      )
        .isRequired("Building owner last name is required")
        .toString();

      temp.buildingOwnerEmail = new Validator(fieldValues, "buildingOwnerEmail")
        .isRequired("Building owner email is required")
        .toString();
    }

    temp.bogusStartDate = new Validator(fieldValues, "bogusStartDate")
      .isRequired("The project's projected start date is required")
      .toString();

    temp.bogusStartDateTime = new Validator(fieldValues, "bogusStartDateTime")
      .isRequired("")
      .toString();
    if (user?.role !== ROLES.Architect) {
      temp.estimatedTimeDuration = new Validator(
        fieldValues,
        "estimatedTimeDuration"
      )
        .isRequired("The time duration is required.")
        .greatThan(0, "The value can't be less than 0.")
        .toString();
    }
    temp.buildingId = new Validator(fieldValues, "buildingId")
      .selectedOption(-1, "The project's building is required.")
      .toString();

    temp.measurementSystem = new Validator(fieldValues, "measurementSystem")
      .selectedOption("", "Measurement system is required.")
      .toString();

    temp.suppliedPowerGridFrequency = new Validator(
      fieldValues,
      "suppliedPowerGridFrequency"
    )
      .selectedOption(null, "Supplied power grid frequency is required.")
      .toString();
    if (user?.role === ROLES.Architect) {
      temp.auditAreaSqFt = new Validator(fieldValues, "auditAreaSqFt")
        .validateIf(fieldValues.buildingId > 0 && !fieldValues.auditAreaUnknown)
        .isRequired("This field is required.")
        .toString();

      temp.auditAreaCuFt = new Validator(fieldValues, "auditAreaCuFt")
        .validateIf(
          fieldValues.buildingId > 0 && !fieldValues.auditAreaUnknownCuFt
        )
        .isRequired("This field is required.")
        .toString();
    } else {
      temp.auditAreaSqFt = new Validator(fieldValues, "auditAreaSqFt")
        .validateIf(fieldValues.buildingId > 0)
        .isRequired("This field is required.")
        .toString();

      temp.auditAreaCuFt = new Validator(fieldValues, "auditAreaCuFt")
        .validateIf(fieldValues.buildingId > 0)
        .isRequired("This field is required.")
        .toString();
    }

    temp.bogusStartDate = new Validator(fieldValues, "bogusStartDate")
      .isRequired("This field is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //query
  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ProjectWithBogus>(
      projectInformation ?? initialValues,
      true,
      validate
    );

  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [country, setCountry] = useState<ICountry | null>(null);

  const getDateId = () => {
    var dateId = new Date();

    return (
      dateId.getHours().toString() +
      dateId.getMinutes().toString() +
      dateId.getSeconds().toString() +
      dateId.getMilliseconds().toString()
    );
  };

  const getCode = (company: ICompany) => {
    return `INFCT-${company.code}${moment(new Date()).format(
      "yyyyMMDD"
    )}-${getDateId()}`;
  };

  const getSuppliedPowerGridFrequency = async (building: IBuilding) => {
    let suppliedPowerGridFrequency = 60;
    if (building?.countryId !== null) {
      const contryResponse = await CountryService.getById(building?.countryId!);
      setCountry(contryResponse.data);

      suppliedPowerGridFrequency = contryResponse.data.frequency as number;
    }
    return suppliedPowerGridFrequency;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const buildingResponse = await buildingService.getById(
          selectedBuildingId
        );
        const building = buildingResponse.data;
        setBuilding(building);

        if (building?.pressurizationId)
          setPressurizationBybyId(building?.pressurizationId);

        const suppliedPowerGridFrequency = await getSuppliedPowerGridFrequency(
          building
        );

        var bogusStartTime = new Date();
        bogusStartTime.setHours(8, 0, 0, 0);
        setValues({
          ...values,
          buildingId: selectedBuildingId,
          projectTypeId: 2,
          isInfectiousControlProject: true,
          name: `Infectious Control Compliancy - ${building.name}`,
          code: getCode(company),
          startDate: null,
          bogusStartDate: null,
          bogusStartDateTime: "8:00 AM",
          laddersRequired: false,
          ladderOther: "",
          bogusLadderAFrames: [],
          bogusLadderExtensions: [],
          ladderAFrames: [],
          projectDescription: "",
          requiredComprehensiveEnergyTest: false,
          estimatedTimeDuration: 0,
          estimatedTimeDurationUnit: 2,
          completionPaymentByOwner: false,
          suppliedPowerGridFrequency: suppliedPowerGridFrequency,
          buildingOwnerName: "",
          buildingOwnerLastName: "",
          buildingOwnerEmail: "",
        });
      } catch (error: any) {
        const message = error.message || "Something went wrong";
        setErrors({ submit: message });
        setIsSubmitting(false);
      }
    };

    if (projectInformation == null) getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      values?.auditAreaSqFt !== undefined &&
      values?.auditAreaSqFt !== null &&
      values?.auditAreaSqFt >= 0
    ) {
      const multiplier =
        values.measurementSystem === MeasurementSystemEnum.Imperial ? 10 : 3;

      setValues({
        ...values,
        auditAreaCuFt: values.auditAreaSqFt * multiplier,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.auditAreaSqFt]);

  //handlers
  const saveHandler = async (validTech: boolean = false) => {
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      setProjectInformationHandler(null);
      updateControlStep(step, false);
      return;
    }
    setProjectInformationHandler(values);
    updateControlStep(step, true);
    return;
  };

  const setPressurizationBybyId = (id: number) => {
    if (id === 1) setPressurization("Positive +");
    else if (id === 2) setPressurization("Negative -");
    else setPressurization("Combination of Both +/-");
  };

  const isLoading = false;

  const row = 12;

  return (
    <>
      <Typography variant="h4" gutterBottom pt={10} pb={4} align="center">
        Infectious Control Compliancy Project Information
      </Typography>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="Project Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
            />
          </Grid>

          {user?.role !== ROLES.Architect && (
            <>
              <Grid item xs={6}>
                <FormText
                  name="buildingOwnerName"
                  label="Building Owners' Name"
                  value={values.buildingOwnerName}
                  onChange={handleInputChange}
                  error={errors.buildingOwnerName}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="buildingOwnerLastName"
                  label="Last Name"
                  value={values.buildingOwnerLastName}
                  onChange={handleInputChange}
                  error={errors.buildingOwnerLastName}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="buildingOwnerEmail"
                  label="Building Owners' Email"
                  value={values.buildingOwnerEmail}
                  onChange={handleInputChange}
                  error={errors.buildingOwnerEmail}
                  showSkeleton={isLoading}
                />
              </Grid>{" "}
            </>
          )}

          <Grid item xs={row}>
            <Stack direction="row" spacing={2}>
              <FormDatePicker
                name="bogusStartDate"
                label="Proposed Start Date"
                value={values.bogusStartDate}
                onChange={handleInputChange}
                error={errors.bogusStartDate}
                disablePast={true}
              />
              <FormTimePicker
                label="Proposed Start Time"
                value={values.bogusStartDateTime}
                onChange={handleInputChange}
                name={"bogusStartDateTime"}
                error={errors.bogusStartDateTime}
              />
            </Stack>
          </Grid>
          <>
            {user?.role !== ROLES.Architect && (
              <>
                <Grid item xs={6}>
                  <FormNumeric
                    name="estimatedTimeDuration"
                    label="Proposal Project Time Duration / Bid"
                    value={values.estimatedTimeDuration?.toString() ?? ""}
                    onChange={handleInputChange}
                    maxLength={6}
                    error={errors.estimatedTimeDuration}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormRadioGroup
                    name="estimatedTimeDurationUnit"
                    label={""}
                    items={[
                      { key: ProjectTimeDurationUnit.Hours, value: "Hours" },
                      { key: ProjectTimeDurationUnit.Days, value: "Days" },
                    ]}
                    onChange={handleInputChange}
                    value={values.estimatedTimeDurationUnit}
                    row={true}
                  />
                </Grid>{" "}
              </>
            )}
          </>
          <Grid item xs={12}>
            {building && country && (
              <>
                <FormReadOnlyField label="Location" value={building?.name} />

                <FormReadOnlyField
                  label="Address"
                  value={`${building?.address}, ${building?.address2 ?? ""} ${
                    building?.address2 ? ", " : ""
                  } ${country?.name}, ${
                    building?.state?.name ?? building.stateName
                  }, ${building?.city}, Zip: ${building?.zip}`}
                />

                <FormReadOnlyField
                  label="Building Serial #"
                  value={`${building?.serial}`}
                />
              </>
            )}
          </Grid>

          <Grid item xs={row}>
            <FormSelect
              name="measurementSystem"
              label="Measurement System"
              value={values.measurementSystem.toString()}
              onChange={handleInputChange}
              options={measurementSystem}
              error={errors.measurementSystem}
              disable={isSubmitting}
              showSkeleton={isLoading}
            />
          </Grid>

          <Grid item xs={row}>
            <FormRadioGroup
              name="suppliedPowerGridFrequency"
              label={"Supplied Power Grid Frequency"}
              items={[
                { key: 60, value: "60 Hz" },
                { key: 50, value: "50 Hz" },
              ]}
              onChange={handleInputChange}
              value={values?.suppliedPowerGridFrequency}
              row={true}
              error={errors.suppliedPowerGridFrequency}
            />
          </Grid>

          {values?.buildingId > -1 && (
            <>
              <Grid item xs={row}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormNumeric2
                      name="auditAreaSqFt"
                      suffix={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? " ft\u00b2"
                          : " mt\u00b2"
                      }
                      label={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Total square feet (Sq/Ft) of audit area being tested"
                          : "Total square meters (Sq/Mt) of audit area being tested"
                      }
                      value={values.auditAreaSqFt?.toString() ?? ""}
                      onChange={handleInputChange}
                      error={errors.auditAreaSqFt}
                      disabled={values.auditAreaUnknown}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3} direction={"row"}>
                    {user?.role === ROLES.Architect && (
                      <FormCheckBox
                        name="auditAreaUnknown"
                        value={values.auditAreaUnknown}
                        label="Unknown at this time"
                        onChange={handleInputChange}
                      />
                    )}
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Building Total Cubic Feet defaults using a cellining height value of 10'"
                          : "Building Total Cubic Meters defaults using a cellining height value of 3m"
                      }
                    >
                      <span>
                        <IconButton aria-label="delete" size="large">
                          <InfoIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                <Stack direction={"row"} spacing={2}></Stack>
              </Grid>
              <Grid item xs={row}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormNumeric2
                      name="auditAreaCuFt"
                      suffix={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? " ft\u00b3"
                          : " mt\u00b3"
                      }
                      label={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Total cubic feet (Cu/Ft) of audit area being tested"
                          : "Total cubic meters (Cu/Mt) of audit area being tested"
                      }
                      value={values.auditAreaCuFt?.toString() ?? ""}
                      onChange={handleInputChange}
                      error={errors.auditAreaCuFt}
                      disabled={values.auditAreaUnknownCuFt}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {user?.role === ROLES.Architect && (
                      <FormCheckBox
                        name="auditAreaUnknownCuFt"
                        value={values.auditAreaUnknownCuFt}
                        label="Unknown at this time"
                        onChange={handleInputChange}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
            </>
          )}
          {values?.buildingId && (
            <Grid item xs={row}>
              <FormCheckBox
                label="Parking area is part of this test"
                name="isParkingAreaPartOfThisTest"
                value={values.isParkingAreaPartOfThisTest}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <FormTextArea
              name="projectDescription"
              label="Project Description"
              value={values.projectDescription ?? ""}
              onChange={handleInputChange}
              error={errors.projectDescription}
              placeholder=""
            />
          </Grid>
          <Grid item xs={row}>
            <Stack direction={"row"}>
              <FormReadOnlyField
                label="Building Pressurization"
                value={pressurization}
              />
              <Tooltip
                TransitionComponent={Zoom}
                title={
                  'Go to "Building" then "Specifics" to change the required building pressurization status.'
                }
              >
                <span>
                  <IconButton aria-label="delete" size="large">
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormButton
                onClick={() => saveHandler(true)}
                isSubmitting={isSubmitting}
                text="Validate form"
                size="small"
              />
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default ProjectInformation;
