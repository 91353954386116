import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import { FormText, FormButton } from "src/components/formControls";
import { useAsyncQuery, useLoading } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { ArrowBackIcon, ShieldIcon } from "src/components/icons";

import { INFECTIOUSPROJECTSTABS } from "src/constants";

import { useNavigate, useParams } from "react-router-dom";

import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import projectInfectiousComplianceService from "src/services/project/projectInfectiousComplianceService ";
import NewProjectInfectious from "./infectiousControlProjectArchitect/NewProjectInfectious";
import OutForBidInfectious from "./infectiousControlProjectArchitect/OutForBidInfectious";
import AwarededInfectious from "./infectiousControlProjectArchitect/AwarededInfectious";
import ApprovedByAMPInfectious from "./infectiousControlProjectArchitect/ApprovedByAMPInfectious";
import ClosedInfectious from "./infectiousControlProjectArchitect/ClosedInfectious";
import ArchivedInfectious from "./infectiousControlProjectArchitect/ArchivedInfectious";
import CompletedInfectious from "./infectiousControlProjectArchitect/CompletedInfectious";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  search: "",
};

export type ProjectsParams = {
  companyId: string;
};

const ProjectInfectiousControlArchitect = () => {
  let params = useParams<ProjectsParams>();
  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );

  const { startRequest, endRequest } = useLoading();
  const [value, setValue] = useState(INFECTIOUSPROJECTSTABS.NEW);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataFilter, setDataFilter] = useState<IGetProjectInfectious[]>([]);

  let navigate = useNavigate();
  const { values, handleInputChange } = useForm(initialValues, false, []);

  const {
    execute: executeProjects,
    data: dataTable,
    setData: setDataTable,
    isLoading: isLoadingData,
  } = useAsyncQuery<IGetProjectInfectious[]>(
    projectInfectiousComplianceService.getProjectsInfecteous,
    {
      onSuccess: (response) => {
        setDataFilter(response);
      },
    }
  );

  useEffect(() => {
    startRequest("projectsInfect");
    executeProjects();
    endRequest("projectsInfect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  useEffect(() => {
    setDataFilter(filterSearch(dataFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.search]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const projectCount = (id: number) => {
    let total =
      dataTable &&
      dataTable.filter((item) => {
        if (id === INFECTIOUSPROJECTSTABS.AWARDED) {
          return (
            item.status === id || item.status === INFECTIOUSPROJECTSTABS.OTHER
          );
        } else {
          return item.status === id;
        }
      }).length;

    return total ?? 0;
  };

  const dataTableFilter = (id: number, isAwarded?: boolean) => {
    if (!isAwarded) {
      const data =
        dataTable &&
        dataTable.filter((item) => {
          if (id === INFECTIOUSPROJECTSTABS.AWARDED) {
            return (
              item.status === id || item.status === INFECTIOUSPROJECTSTABS.OTHER
            );
          } else {
            return item.status === id;
          }
        });
      if (values.search !== "") {
        return filterSearch(data);
      } else {
        return data ?? [];
      }
    } else {
      const data =
        dataTable &&
        dataTable.filter((item) => {
          return (
            item.status === id && item.status === INFECTIOUSPROJECTSTABS.OTHER
          );
        });
      if (values.search !== "") {
        return filterSearch(data);
      } else {
        return data ?? [];
      }
    }
  };

  const filterSearch = (dataTableToSearch: IGetProjectInfectious[]) => {
    if (values.search === "") {
      return dataTableToSearch;
    }
    const searchLower = values.search.toLowerCase();

    const filteredArray = dataTableToSearch.filter(
      (item: IGetProjectInfectious) => {
        return (
          item.code.toLowerCase().includes(searchLower) ||
          item.name.toLowerCase().includes(searchLower) ||
          item.buildingAddress.toLowerCase().includes(searchLower)
        );
      }
    );
    return filteredArray;
  };

  return (
    <>
      <HeaderPage
        title={`Projects`}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <>
            <Stack direction={"row"} spacing={2}>
              <FormButton
                text={"Plaque & Decals Order(s)"}
                onClick={() => {
                  navigate(`/app/plaqueCompanyOrders`);
                }}
                startIcon={<ShieldIcon />}
                size="medium"
                color="primary"
              />
              <FormButton
                onClick={() => {
                  navigate(`/app/ProjectArchitect/${companyId}/all`);
                }}
                variant="outlined"
                color="primary"
                text={"Return to Regular Project"}
                startIcon={<ArrowBackIcon />}
                size="medium"
              />
            </Stack>
          </>
        }
        pageYoutube=""
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`New (${projectCount(INFECTIOUSPROJECTSTABS.NEW)})`}
                  id={"0"}
                  value={INFECTIOUSPROJECTSTABS.NEW}
                />
                <Tab
                  label={`Out For Bid (${projectCount(
                    INFECTIOUSPROJECTSTABS.OUTFORBID
                  )})`}
                  id={"1"}
                  value={INFECTIOUSPROJECTSTABS.OUTFORBID}
                />
                <Tab
                  label={`Awarded (${projectCount(
                    INFECTIOUSPROJECTSTABS.AWARDED
                  )})`}
                  id={"2"}
                  value={INFECTIOUSPROJECTSTABS.AWARDED}
                />
                <Tab
                  label={`Completed (${projectCount(
                    INFECTIOUSPROJECTSTABS.COMPLETED
                  )})`}
                  id={"3"}
                  value={INFECTIOUSPROJECTSTABS.COMPLETED}
                />
                <Tab
                  label={`Approved by AMP (${projectCount(
                    INFECTIOUSPROJECTSTABS.APPROVEDBYAMP
                  )})`}
                  id={"4"}
                  value={INFECTIOUSPROJECTSTABS.APPROVEDBYAMP}
                />
                <Tab
                  label={`Closed (${projectCount(
                    INFECTIOUSPROJECTSTABS.CLOSED
                  )})`}
                  id={"5"}
                  value={INFECTIOUSPROJECTSTABS.CLOSED}
                />
                <Tab
                  label={`Archived (${projectCount(
                    INFECTIOUSPROJECTSTABS.ARCHIVED
                  )})`}
                  id={"6"}
                  value={INFECTIOUSPROJECTSTABS.ARCHIVED}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <FormText
                      name="search"
                      value={values.search}
                      label="Search"
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {value === INFECTIOUSPROJECTSTABS.NEW && (
                      <NewProjectInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(INFECTIOUSPROJECTSTABS.NEW)}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.OUTFORBID && (
                      <OutForBidInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.OUTFORBID
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.AWARDED && (
                      <AwarededInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.AWARDED
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.COMPLETED && (
                      <CompletedInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.COMPLETED
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.APPROVEDBYAMP && (
                      <ApprovedByAMPInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.APPROVEDBYAMP
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.CLOSED && (
                      <ClosedInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.CLOSED
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === INFECTIOUSPROJECTSTABS.ARCHIVED && (
                      <ArchivedInfectious
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTableFilter(
                          INFECTIOUSPROJECTSTABS.ARCHIVED
                        )}
                        isLoading={isLoadingData}
                        setDataTable={setDataTable}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectInfectiousControlArchitect;
