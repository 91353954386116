import { useState } from "react";
import useLog from "src/hooks/useLog";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Popup from "src/components/Popup";
import { ICoupon, ISystemCostsCatalog } from "src/ts/interfaces";
import { currencyformatter } from "src/utils/format";
import { FormButton, FormCancelButton } from "src/components/formControls";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Save } from "@mui/icons-material";
import certificationRenewalsService from "src/services/certificationRenewalsService";

interface CertificationRenewalsPopupProps {
  itemCostCatalog: ISystemCostsCatalog;
  showBuyModal: boolean;
  onChangeShowBuyModal: () => void;
  technicianId: number | undefined;
  techName: string;
  techType: string;
}

const CertificationRenewalsPopup = (props: CertificationRenewalsPopupProps) => {
  const {
    itemCostCatalog,
    showBuyModal,
    onChangeShowBuyModal,
    technicianId,
    techName,
    techType,
  } = props;
  const { log } = useLog();

  const [coupon, setCoupon] = useState<ICoupon | null>(null);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.code = new Validator(fieldValues, "code")
      .isRequired("Please enter a value for the coupon code.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors } = useForm({ code: "" }, false, validate);

  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeHandler = () => {
    values.code = "";
    setCoupon(null);
    onChangeShowBuyModal();
  };

  const confirmPayHandler = async () => {
    setIsSubmitting(true);

    try {
      const result = await certificationRenewalsService.payRenewal(
        itemCostCatalog.id,
        technicianId ?? 0
      );
      if (result.data.statusCode !== 0) {
        log.error(result.data.resultMessage);
      } else {
        log.success(
          `The ${
            techType === "Auditor"
              ? "Testing & Balancing Technician"
              : "Energy Auditor / Infectious Building Control"
          } Certification was successfully renewed for ${techName}`
        );
        onChangeShowBuyModal();
      }

      setIsSubmitting(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to renew ${
          techType === "Auditor"
            ? "Testing & Balancing Technician"
            : "Energy Auditor / Infectious Building Control"
        } for ${techName}? This action will charge ${currencyformatter.format(
          (itemCostCatalog?.pricing ?? 0) -
            ((itemCostCatalog?.pricing ?? 0) / 100) * (coupon?.value ?? 0)
        )} to your AMP Wallet.`}
        showPopup={showConfirm}
        setShowPopup={setShowConfirm}
        onSave={confirmPayHandler}
        isSubmitting={isSubmitting}
      />
      <Popup
        openPopup={showBuyModal}
        setOpenPopup={() => {}}
        title="Confirmation & Payment"
        onClose={closeHandler}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              To renew the{" "}
              {techType === "Auditor"
                ? "Testing & Balancing Technician"
                : "Energy Auditor / Infectious Building Control"}{" "}
              certification for {techName}, the following charge will be applied
              to your wallet:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1}>1</TableCell>
                    <TableCell>{itemCostCatalog.name}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(itemCostCatalog?.pricing ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Subtotal:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(itemCostCatalog?.pricing ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Taxes:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Discount:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Typography color="red"></Typography>
                      {currencyformatter.format(
                        ((itemCostCatalog?.pricing ?? 0) / 100) *
                          (coupon?.value ?? 0)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>TOTAL:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(
                        (itemCostCatalog?.pricing ?? 0) -
                          ((itemCostCatalog?.pricing ?? 0) / 100) *
                            (coupon?.value ?? 0)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <>
          <FormCancelButton
            onClick={closeHandler}
            isSubmitting={isSubmitting}
          />
          <Grid pl={2}>
            <FormButton
              text={"Pay"}
              onClick={() => {
                setShowConfirm(true);
              }}
              startIcon={<Save />}
              size="small"
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Grid>
        </>
      </Popup>
    </>
  );
};

export default CertificationRenewalsPopup;
