import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

import { useForm, useLog } from "src/hooks";
import { ISchools } from "src/ts/interfaces/catalogs/schools";
import schoolsService from "src/services/catalogs/schoolsService";
import SchoolForm from "./SchoolForm";
import ActiveSchools from "./ActiveSchools";
import { FormText } from "src/components/formControls";
import InactiveSchools from "./InactiveSchools";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ISchools = {
  id: 0,
  name: "",
  address: "",
  address2: "",
  countryId: 235,
  countryCode: "",
  city: "",
  stateId: 0,
  stateName: "",
  zip: "",
  getFullAddress: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  contactExtension: "",
  statusId: 0,
};

const Schools = () => {
  const { log } = useLog();
  const [data, setData] = useState<ISchools[]>([]);

  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [initialRow, setInitialRow] = useState<ISchools>();
  const [selectedTab, setSelectedTab] = useState(1);
  const { values: filterValues, handleInputChange } = useForm(
    initialValues,
    true,
    []
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const response = await schoolsService.getAll();
        setData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const newHandler = () => {
    setInitialRow(initialValues);
    setIsDialogOpen(true);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  return (
    <>
      <Helmet title="Schools" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Schools
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Schools</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button onClick={newHandler} variant="contained" color="primary">
              <AddIcon />
              {`New School`}
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} mb={5}>
              <FormText
                onChange={handleInputChange}
                label="Search"
                name="filter"
                value={filterValues.filter}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={10}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 5,
            }}
          >
            <Grid item xs={12}>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Active" id="1" value={1} />
                <Tab label="Inactive" id="2" value={2} />
              </Tabs>
            </Grid>

            {selectedTab === 1 && (
              <ActiveSchools
                data={data.filter((item) => item.statusId === 1)}
                isSubmitting={isSubmitting}
                filterValues={filterValues.filter}
                onUpdate={refreshGridHandler}
              />
            )}
            {selectedTab === 2 && (
              <InactiveSchools
                data={data.filter((item) => item.statusId === 2)}
                isSubmitting={isSubmitting}
                filterValues={filterValues.filter}
                onUpdate={refreshGridHandler}
              />
            )}
          </Grid>
        </CardContent>
      </Card>

      {isDialogOpen && (
        <SchoolForm
          selectedValue={initialRow}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          onUpdate={refreshGridHandler}
        />
      )}
    </>
  );
};

export default Schools;
