import axios from "src/utils/axios";

const getEnergyHistory = (reportId: number) => {
  return axios.get(`EnergyHistory?reportId=${reportId}`);
};

const EnergyHistoryService = {
  getEnergyHistory,
};

export default EnergyHistoryService;
