/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  StepConnector,
  stepConnectorClasses,
  Alert,
  ButtonGroup,
  Button,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { Stack, spacing, useTheme } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
  useFormulas,
} from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormDatePicker,
  FormLabel,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IDefaultComboBoxCatalog,
  IEETReportServiceModelDTO,
  IFanMotorActualDTO,
  IFanMotorDTO,
  IFormulaSystemService,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetActualSpacePressurizationDTO,
  IHeadSheetGeneralDataDTO,
  IHeadsheetActualRpmPdAlertsDTO,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectService from "src/services/project/projectService";
import projectReportService from "src/services/study/projectReportService";
import headSheetService from "src/services/study/headSheetService";
import headSheetGeneralDataService from "src/services/study/headSheetGeneralDataService";
import headSheetActualSpacePressurizationService from "src/services/study/headSheetActualSpacePressurizationService";
import { headSheetType, steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import SteadyStateDialog from "../../components/SteadyState";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { East as EastIcon } from "@mui/icons-material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FormTooltip from "src/components/formControls/FormTooltip";
import Popup from "src/components/Popup";
import { ISystemOutsideAirReadByDTO } from "src/ts/interfaces/catalogs";
import { Validator } from "src/ts/types/Validator";
import systemOAReadByService from "src/services/study/systemOAReadByService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { SpacePressurization } from "./components/SpacePressurization";
import StudyWizard from "../../components/StudyWizard";
import moment from "moment";
import headSheetReportService from "src/services/study/headSheetReportService";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <ChecklistIcon />,
    2: <SafetyDividerIcon />,
    3: <ElectricBoltIcon />,
    4: <ChangeCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const preTrestSteps = ["Start test", "Safety", "Shock Hazard", "Fan rotation"];

const HeadSheetStep5 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [route, setRoute] = useState<string>();
  const [currentPretestStep, setCurrentPretestStep] = useState(0);
  const [selectedFanMotor, setSelectedFanMotor] = useState(0);
  const [selectedFanMotorIndex, setSelectedFanMotorIndex] = useState(0);
  const [openVoltsAmpsInvalidDialog, setOpenVoltsAmpsInvalidDialog] =
    useState(false);
  const [headSheetValues, setHeadSheetValues] =
    useState<IEETReportServiceModelDTO>();
  const [spacePressurizationValues, setSpacePressurizationValues] =
    useState<IHeadSheetActualSpacePressurizationDTO[]>();
  const [fanMotorValues, setFanMotorValues] = useState<IFanMotorDTO>();
  const [fanValues, setFanValues] = useState<IFanMotorActualDTO>();
  const [projectValues, setProjectValues] = useState<ProjectDTO>();
  const [rpmPdAlerts, setRpmPdAlerts] =
    useState<IHeadsheetActualRpmPdAlertsDTO[]>();
  const [systemOAReadBys, setSystemOAReadBys] =
    useState<IDefaultComboBoxCatalog[]>();
  const isDarkTheme = useTheme().palette.mode === "dark";
  const [sheaveDimensionBetween, setSheaveDimensionBetween] = useState(0);
  const [sheaveDimensionBetweenFirst, setSheaveDimensionBetweenFirst] =
    useState(0);
  const [pneumaticPressureBottom, setPneumaticPressureBottom] = useState(0);
  const [pneumaticPressureTop, setPneumaticPressureTop] = useState(0);
  const [pneumaticDiscrepancy, setPneumaticDiscrepancy] = useState(0);
  const [openPneumaticDiscrepancyDialog, setOpenPneumaticDiscrepancyDialog] =
    useState(false);
  const [pneumaticDiscrepancyAck, setPneumaticDiscrepancyAck] = useState(false);
  const [openRpmPdAlertDialog, setOpenRpmPdAlertDialog] = useState(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [disableEditData, setDisableEditData] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues: IHeadSheetActualDTO = values) => {
    let temp: Record<string, string> = { ...errors };

    if (disableStudy()) return true;

    temp.motorActualRPM = new Validator(fieldValues, "motorActualRPM")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.motorSheaveOperatingPitchDiameter = new Validator(
      fieldValues,
      "motorSheaveOperatingPitchDiameter"
    )
      .validateIf(!fanValues?.isDirectDrive)
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.fanActualRPM = new Validator(fieldValues, "fanActualRPM")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase1 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase1"
    )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase2 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase2"
    )
      .validateIf(
        fanMotorValues?.motorPhase === "Three phase" ||
          fanMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase3 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase3"
    )
      .validateIf(
        fanMotorValues?.motorPhase === "Three phase" ||
          fanMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase1 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase1"
    )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase2 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase2"
    )
      .validateIf(
        fanMotorValues?.motorPhase === "Three phase" ||
          fanMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase3 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase3"
    )
      .validateIf(
        fanMotorValues?.motorPhase === "Three phase" ||
          fanMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.systemOutsideAirReadById = new Validator(
      fieldValues,
      "systemOutsideAirReadById"
    )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field is required.`)
      .toString();
    temp.systemOutsideAirReadByOther = new Validator(
      fieldValues,
      "systemOutsideAirReadByOther"
    )
      .validateIf(
        catalogValueOtherEval(
          values.systemOutsideAirReadById as number,
          systemOAReadBys as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("This field is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IHeadSheetActualDTO>(initialValues, false, validate);

  const activeStep = 4;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executeGeneralData, data: generalDataValues } =
    useAsyncQuery<IHeadSheetGeneralDataDTO>(
      headSheetGeneralDataService.getById
    );

  const { execute: executeSystemOAReadBy, isLoading: isLoadingSystemOAReadBy } =
    useAsyncQuery<ISystemOutsideAirReadByDTO[]>(
      systemOAReadByService.getSystemOAReadBy,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const types = (dataResult as ISystemOutsideAirReadByDTO[]).map(
              (type) => ({
                key: type.id as number,
                value: type.name as any,
              })
            );
            setSystemOAReadBys([
              { key: 0, value: "Select System OA Read By" },
              ...types,
            ]);
          };
          getData();
        },
      }
    );

  const { execute: executeProject } = useAsyncQuery<ProjectDTO>(
    projectService.getProjectTolerancesByReportId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProjectValues(dataResult);
        };
        getData();
      },
    }
  );

  const { execute, isLoading } = useAsyncQuery<IEETReportServiceModelDTO>(
    headSheetService.getStepFour,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setHeadSheetValues({ ...dataResult });
          const reportTypeCodeTemp =
            dataResult.fanSystemType === "FanArray" ? "HSFA" : "HSFS";
          await executeProject(id, reportTypeCodeTemp);
          await systemOfMeasurement.getByProject(dataResult.projectId);
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
        };
        getData();
      },
    }
  );

  const { execute: executeFanMotor, isLoading: isLoadingFanMotor } =
    useAsyncQuery<IHeadSheetActualFormulaDTO>(headSheetService.getActuals, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const { actual, fanMotor } = dataResult;
          const newActual = {
            ...actual,
            model:
              actual.model ??
              (headSheetValues?.fans as IFanMotorActualDTO[])[
                selectedFanMotorIndex
              ].fanTagIdentifier,
            voltReadFromVFDScreen:
              !actual.voltReadFromVFDScreen && !actual.voltMeterUsed
                ? true
                : actual.voltReadFromVFDScreen,
          };
          setValues(newActual);
          setFanMotorValues(fanMotor);
          executeSpacePressurization(actual.id);
          window.scrollTo(0, 0);
        };
        getData();
      },
    });

  const {
    execute: executeSpacePressurization,
    isLoading: isLoadingSpacePressurization,
  } = useAsyncQuery<IHeadSheetActualSpacePressurizationDTO[]>(
    headSheetActualSpacePressurizationService.getByHeadSheetActualId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setSpacePressurizationValues(dataResult);
        };
        getData();
      },
    }
  );

  const { execute: executeRpmPdAlerts } = useAsyncQuery<
    IHeadsheetActualRpmPdAlertsDTO[]
  >(headSheetService.getActualRpmPdAlerts, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setRpmPdAlerts(dataResult);
        if (dataResult.length > 0) {
          const alerts = dataResult.filter(
            (alert: IHeadsheetActualRpmPdAlertsDTO) =>
              alert.fanRPMAlertPresent ||
              alert.fanPDAlertPresent ||
              alert.motorRPMAlertPresent ||
              alert.motorPDAlertPresent
          );
          if (alerts.length > 0) {
            setOpenRpmPdAlertDialog(true);
            return false;
          }
        }
        navigate(`/studies/${route}/step6/${id}`);
      };
      getData();
    },
  });

  const disableStudy = () => {
    return (
      headSheetValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData
    );
  };

  useEffect(() => {
    if (headSheetValues?.id) {
      setErrors({});
      setSelectedFanMotor(
        (headSheetValues?.fans as IFanMotorActualDTO[])[selectedFanMotorIndex]
          .key
      );
      setFanValues(
        (headSheetValues?.fans as IFanMotorActualDTO[])[selectedFanMotorIndex]
      );
      executeFanMotor(
        id,
        (headSheetValues?.fans as IFanMotorActualDTO[])[selectedFanMotorIndex]
          .key
      );
    }
  }, [headSheetValues, selectedFanMotorIndex]);

  useEffect(() => {
    if (headSheetValues?.reportTypeId) {
      const [{ title, code, route }] = headSheetType.filter(
        (x) => x.key === headSheetValues?.reportTypeId
      );
      setTitle(title);
      setCode(code);
      setRoute(route);
    }
  }, [headSheetValues?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeSystemOAReadBy();
        await execute(id);
        await executeGeneralData(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (formulas) {
      setSheaveDimensionBetween(formulas?.getSheaveDimensionBetween());
      setSheaveDimensionBetweenFirst(
        formulas?.getSheaveDimensionBetweenFirst()
      );
      setPneumaticPressureBottom(formulas?.getPneumaticPressureBottom());
      setPneumaticPressureTop(formulas?.getPneumaticPressureTop());
    }
  }, [formulas]);

  useEffect(() => {
    if (
      values.actualDateTestPerformed === null ||
      values.actualDateTestPerformed === undefined
    ) {
      let newvalues = { ...values };
      newvalues.actualDateTestPerformed = moment().format("MM/DD/YYYY");
      setValues(newvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const saveHandler = async () => {
    if (!validate()) return null;
    await saveSpacePressurizationHandle();
    await saveFanMotorHandler();
    await saveHeadSheetActualsHandler();
  };

  const saveHeadSheetActualsHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        const valuesBeforeSend = {
          ...values,
          fanRPMAlertPresent: showFanRPMWarning(),
          motorRPMAlertPresent: showMotorRPMWarning(),
          fanPDAlertPresent: showWarningFan(),
          motorPDAlertPresent: showWarningMotor(),
          calculatedFanRPM: headSheetService.calculateFanRPM(
            values?.motorActualRPM || 0,
            values?.motorSheaveOperatingPitchDiameter || 0,
            values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0
          ),
          calculatedMotorRPM: headSheetService.calculateMotorRPM(
            values?.fanActualRPM || 0,
            values?.motorSheaveOperatingPitchDiameter || 0,
            values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0
          ),
          calculatedFanPD: headSheetService.calculateFanPD(
            values?.fanActualRPM || 0,
            values?.motorActualRPM || 0,
            values?.motorSheaveOperatingPitchDiameter || 0
          ),
          calculatedMotorPD: headSheetService.calculateMotorPD(
            values?.fanActualRPM || 0,
            values?.motorActualRPM || 0,
            values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0
          ),
          fanTipSpeed: headSheetService.calculateFanTip(
            values?.fanActualRPM || 0,
            fanValues?.fanOuterEdgeToShaftRadius || 0,
            fanValues?.fanShaftDiameter || 0,
            systemOfMeasurement.measurementSystem || "imperialSystem"
          ),
        };
        await headSheetService.updateActuals(
          id,
          valuesBeforeSend,
          fanMotorValues?.id || 0,
          headSheetValues?.fanSystemType === "FanArray",
          selectedFanMotorIndex
        );
        await headSheetReportService.updateFormula(id, valuesBeforeSend);
        log.success("HeadSheet actuals data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveFanMotorHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await headSheetService.updateFanMotor(
          id,
          fanMotorValues as IFanMotorDTO,
          headSheetValues?.fanSystemType === "FanArray",
          selectedFanMotorIndex
        );
        log.success("Fan motor was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveSpacePressurizationHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        spacePressurizationValues?.forEach(async (spacePressurization) => {
          await headSheetActualSpacePressurizationService.update(
            spacePressurization.id,
            spacePressurization
          );
        });
        log.success("Space pressurization data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) setOpenIncompleteDataDialog(true);
    else await movingForwardHandler();
  };

  const movingForwardHandler = async () => {
    setOpenIncompleteDataDialog(false);
    await saveSpacePressurizationHandle();
    await saveFanMotorHandler();
    await saveHeadSheetActualsHandler();
    if (
      selectedFanMotorIndex <
      (headSheetValues?.fans as IFanMotorActualDTO[]).length - 1
    ) {
      setSelectedFanMotorIndex(selectedFanMotorIndex + 1);
      window.scrollTo(0, 0);
    } else {
      if (!disableStudy()) await executeRpmPdAlerts(id);
      else navigate(`/studies/${route}/step6/${id}`);
    }
  };

  const previousStepHandler = async () => {
    if (currentPretestStep > 3) {
      await saveSpacePressurizationHandle();
      await saveFanMotorHandler();
      await saveHeadSheetActualsHandler();
    }
    if (selectedFanMotorIndex > 0) {
      setSelectedFanMotorIndex(selectedFanMotorIndex - 1);
    } else {
      navigate(`/studies/${route}/step4/${id}`);
    }
  };

  const loadFanMotor = (index: number) => {
    setSelectedFanMotorIndex(index);
  };

  const handleSelectedVoltsReferenceChange = (index: number) => {
    setFanMotorValues({
      ...(fanMotorValues as IFanMotorDTO),
      selectedVoltAmpsReference: index,
      voltageNotListed: false,
    });
  };

  const handleFanMotorValuesChange = (e: any) => {
    setFanMotorValues({
      ...(fanMotorValues as IFanMotorDTO),
      selectedVoltAmpsReference: null,
      [e.target.name]: e.target.value,
    });
  };

  const handleVoltsReadChange = (
    voltReadFromVFDScreen: boolean,
    voltMeterUsed: boolean
  ) => {
    setValues({
      ...values,
      voltReadFromVFDScreen,
      voltMeterUsed,
    });
  };

  const handleVfdOnlyDisplaysSingleVoltageChange = (e: any) => {
    const voltsPhase2 = e.target.value
      ? values?.actualOperatingVoltsPhase1
      : values?.actualOperatingVoltsPhase2;
    const voltsPhase3 = e.target.value
      ? values?.actualOperatingVoltsPhase1
      : values?.actualOperatingVoltsPhase3;
    const ampsPhase2 = e.target.value
      ? values?.actualOperatingAmpsPhase1
      : values?.actualOperatingAmpsPhase2;
    const ampsPhase3 = e.target.value
      ? values?.actualOperatingAmpsPhase1
      : values?.actualOperatingAmpsPhase3;
    setValues({
      ...values,
      vfdOnlyDisplaysSingleVolAmp: e.target.value,
      actualOperatingVoltsPhase2: voltsPhase2,
      actualOperatingVoltsPhase3: voltsPhase3,
      actualOperatingAmpsPhase2: ampsPhase2,
      actualOperatingAmpsPhase3: ampsPhase3,
    });
  };

  const handleCloseVoltsAmpsInvalidDialog = () => {
    setOpenVoltsAmpsInvalidDialog(false);
  };

  const handleValidateVoltsAmps = (e: any) => {
    if (
      !fanMotorValues?.selectedVoltAmpsReference &&
      !fanMotorValues?.voltageNotListed
    ) {
      setOpenVoltsAmpsInvalidDialog(true);
      return null;
    }
  };

  const ampsValid = (line: number) => {
    if (values && fanMotorValues) {
      if (fanMotorValues.selectedVoltAmpsReference) {
        const ampsReferenceName =
          `nameplateAmpsPhase${fanMotorValues?.selectedVoltAmpsReference}` as
            | "nameplateAmpsPhase1"
            | "nameplateAmpsPhase2"
            | "nameplateAmpsPhase3"
            | "nameplateAmpsPhase4"
            | "nameplateAmpsPhase5"
            | "nameplateAmpsPhase6";
        const ampsTargetName = `actualOperatingAmpsPhase${line}` as
          | "actualOperatingAmpsPhase1"
          | "actualOperatingAmpsPhase2"
          | "actualOperatingAmpsPhase3"
          | "actualOperatingAmpsPhase4"
          | "actualOperatingAmpsPhase5";
        const actualAmps = values[ampsTargetName] ?? 0;
        const referenceAmps = fanMotorValues[ampsReferenceName] ?? 0;
        return actualAmps <= referenceAmps || fanMotorValues?.voltageNotListed;
      }
    }
    return true;
  };

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const handleSystemSPWorstCaseFlowingChange = (e: any, worstCase: string) => {
    if (e.target.value) {
      setValues({
        ...values,
        systemSPWorstCaseFlowing: worstCase,
      });
    } else {
      setValues({
        ...values,
        systemSPWorstCaseFlowing: null,
      });
    }
  };

  const showMotorRPMWarning = () => {
    const calculatedMotorRPM = headSheetService.calculateFanRPM(
      values?.fanActualRPM || 0,
      values?.motorSheaveOperatingPitchDiameter || 0,
      values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0
    );
    const deltaRPM = Math.abs(
      (values?.motorActualRPM || 0) - (calculatedMotorRPM || 0)
    );
    return deltaRPM > 25 && calculatedMotorRPM !== null;
  };

  const showFanRPMWarning = () => {
    const calculatedFanRPM = headSheetService.calculateFanRPM(
      values?.motorActualRPM || 0,
      values?.motorSheaveOperatingPitchDiameter || 0,
      values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0
    );
    const deltaRPM = Math.abs(
      (values?.fanActualRPM || 0) - (calculatedFanRPM || 0)
    );
    return deltaRPM > 25 && calculatedFanRPM !== null;
  };

  const showWarningMotor = () =>
    (values?.motorSheaveOperatingPitchDiameter || 0) >
      (fanMotorValues?.motorSheaveDimension || 0) + sheaveDimensionBetween ||
    (values?.motorSheaveOperatingPitchDiameter || 0) <
      (fanMotorValues?.motorSheaveDimension || 0) - sheaveDimensionBetweenFirst;

  const showWarningFan = () =>
    (values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0) >
      (fanValues?.fanSheaveDimensionMeasured || 0) + sheaveDimensionBetween ||
    (values?.technicianCalculatedFanSheaveOperatingPitchDiameter || 0) <
      (fanValues?.fanSheaveDimensionMeasured || 0) - sheaveDimensionBetween;

  const handleVfdOperatingHertzChange = (e: any) => {
    const speed = projectValues?.suppliedPowerGridFrequency
      ? ((e.target.value || 0) / projectValues?.suppliedPowerGridFrequency) *
        100
      : null;
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      vfdOperatingVelocity: speed,
    });
  };

  const handleVfdOperatingVelocityChange = (e: any) => {
    const hz =
      ((e.target.value || 0) / 100) *
      (projectValues?.suppliedPowerGridFrequency || 0);

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      vfdOperatingHertz: hz,
    });
  };

  const handleFurthestPneumaticPressureChange = (e: any) => {
    setPneumaticDiscrepancyAck(false);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      furthestPneumaticSignature: null,
    });
    setPneumaticDiscrepancy(pneumaticPressureBottom - (e.target.value || 0));
    if ((e.target.value || 0) < pneumaticPressureBottom && e.target.value) {
      setOpenPneumaticDiscrepancyDialog(true);
    }
  };

  const handlePneumaticDiscrepancyAckChange = (e: any) =>
    setPneumaticDiscrepancyAck(e.target.value);

  const handlePneumaticDiscrepancyClose = () => {
    const now = new Date(Date.now());
    setValues({
      ...values,
      furthestPneumaticSignature: `${user?.userName} - ${now.toLocaleString()}`,
    });
    setOpenPneumaticDiscrepancyDialog(false);
  };

  const handleRpmPdAlertDialogClose = () => setOpenRpmPdAlertDialog(false);

  const handleRpmPdAlertOkButtonClick = () => {
    setOpenRpmPdAlertDialog(false);
    navigate(`/studies/${route}/step6/${id}`);
  };

  const handleIncompleteDataDialogClose = () =>
    setOpenIncompleteDataDialog(false);

  const handleVoltsPhase1Blur = (e: any) => {
    if (values?.vfdOnlyDisplaysSingleVolAmp) {
      setValues({
        ...values,
        actualOperatingVoltsPhase2: e.target.value,
        actualOperatingVoltsPhase3: e.target.value,
      });
    }
  };

  const handleAmpsPhase1Blur = (e: any) => {
    if (values?.vfdOnlyDisplaysSingleVolAmp) {
      setValues({
        ...values,
        actualOperatingAmpsPhase2: e.target.value,
        actualOperatingAmpsPhase3: e.target.value,
      });
    }
  };

  const showActualReadingsDate = useMemo(
    () => dataReport?.parentReportId === null && selectedFanMotorIndex === 0,
    [dataReport?.parentReportId, selectedFanMotorIndex]
  );

  return (
    <>
      <SteadyStateDialog
        reportId={headSheetValues?.id as number}
        reportTypeId={headSheetValues?.reportTypeId as number}
        projectId={headSheetValues?.projectId as number}
        reportCompleted={headSheetValues?.isComplete || false}
        setDisableEditData={setDisableEditData}
      />
      <Popup
        openPopup={openVoltsAmpsInvalidDialog}
        setOpenPopup={setOpenVoltsAmpsInvalidDialog}
        onClose={handleCloseVoltsAmpsInvalidDialog}
        title="No Volts/Amps reference set selected"
        titleStyle={{ color: "red" }}
      >
        <p>
          You must select a Voltage that best represents Actual Voltage being
          read.
        </p>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseVoltsAmpsInvalidDialog}
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openPneumaticDiscrepancyDialog}
        setOpenPopup={setOpenPneumaticDiscrepancyDialog}
        onClose={handlePneumaticDiscrepancyClose}
        title="System Pressure Discrepancy"
        titleStyle={{ color: "red" }}
        disableClickOutside
        hideClose
        disableEscKeyDown
      >
        <>
          <p>
            Your pressure is {pneumaticDiscrepancy.toFixed(2)}{" "}
            {systemOfMeasurement.get("psi")} below recommended minimum.
          </p>
          <p>
            Before moving forward and balancing any Terminal Boxes go to the
            pressure regulator and adjust until{" "}
            {pneumaticPressureBottom.toFixed(2)}{" "}
            {systemOfMeasurement.get("psi")} to{" "}
            {pneumaticPressureTop.toFixed(2)} {systemOfMeasurement.get("psi")}{" "}
            is observed at the furthest VAV, CAV, or FPB.
          </p>
          <FormCheckBox
            name=""
            label="I have already adjusted the pressure regulator to the correct value."
            value={pneumaticDiscrepancyAck}
            onChange={handlePneumaticDiscrepancyAckChange}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePneumaticDiscrepancyClose}
            disabled={!pneumaticDiscrepancyAck}
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openRpmPdAlertDialog}
        setOpenPopup={setOpenRpmPdAlertDialog}
        onClose={handleRpmPdAlertDialogClose}
        title="System actual values discrepancy"
        titleStyle={{ color: "red" }}
        disableClickOutside
        hideClose
        disableEscKeyDown
      >
        <>
          <b>{`The following ${systemOfMeasurement.get(
            "rpm"
          )} and Pitch Diameter dimensions appear incorrect.`}</b>
          <br />
          <br />
          <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
            <Table stickyHeader aria-label="static components">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Fan tag identifier
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {`Fan actual ${systemOfMeasurement.get("rpm")}`}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {`Calc. Fan PD ${systemOfMeasurement.get("in")}`}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {`Motor actual ${systemOfMeasurement.get("rpm")}`}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {`Calc. Motor PD ${systemOfMeasurement.get("in")}`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rpmPdAlerts &&
                  rpmPdAlerts.map(
                    (row: IHeadsheetActualRpmPdAlertsDTO, index: number) => (
                      <>
                        <TableRow
                          key={`rpm-pd-alert-${row.headSheetId}-${index}`}
                          style={{
                            backgroundColor:
                              index % 2 > 0
                                ? isDarkTheme
                                  ? "#243549"
                                  : "#f3f3f3"
                                : "inherit",
                          }}
                        >
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {row.model}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {(row.fanActualRPM || 0).toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {(
                              row.technicianCalculatedFanSheaveOperatingPitchDiameter ||
                              0
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {(row.motorActualRPM || 0).toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {(
                              row.motorSheaveOperatingPitchDiameter || 0
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <b>Are you certain you want to continue?</b>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleRpmPdAlertDialogClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRpmPdAlertOkButtonClick}
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openIncompleteDataDialog}
        setOpenPopup={setOpenIncompleteDataDialog}
        onClose={handleIncompleteDataDialogClose}
        title="Form is incomplete"
        titleStyle={{ color: "red" }}
      >
        <>
          <p>There are some required fields that need to be reviewed.</p>
          <p>
            It's understood that continuing forward without filling in the
            required data will result in an incomplete final Study.
          </p>
          <p>Do you wish to still continue forward?</p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleIncompleteDataDialogClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={movingForwardHandler}
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{
          code: code,
          system: headSheetValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${headSheetValues?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              {currentPretestStep <= 3 && !disableStudy() && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stepper
                        alternativeLabel
                        activeStep={currentPretestStep}
                        connector={<ColorlibConnector />}
                      >
                        {preTrestSteps.map((label) => (
                          <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                    {currentPretestStep === 0 && (
                      <Grid item xs={12} textAlign="center">
                        <FormButton
                          text="Start Test"
                          variant="contained"
                          color="success"
                          endIcon={<EastIcon />}
                          onClick={() => setCurrentPretestStep(1)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 1 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="warning">
                          Ensure everybody is clear of any potential system
                          moving parts, ie motors, fans, pulleys, belts, etc.
                        </Alert>
                        <br />
                        <FormButton
                          text="Accept"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(2)}
                        />{" "}
                        <FormButton
                          text="Cancel"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 2 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="error" style={{ textAlign: "left" }}>
                          <p style={{ fontWeight: "bold", marginTop: 0 }}>
                            Arc Flash And Shock Hazard - Appropriate PPE
                            Required
                          </p>
                          <ul>
                            <li>
                              Do not operate controls or open covers without
                              appropriate Personal Protection Equipment (PPE).
                              Failure to comply may result in Injury or Death.
                            </li>
                            <li>
                              Refer to NFPA 70E for minimum PPE Requirements.
                            </li>
                            <li>
                              Warning NFPA code requirements may change, always
                              check for current or updated code requirements.
                            </li>
                            <li>
                              Request a qualified and licensed electrician to
                              collect voltage and Current/Amperage data if not
                              ARC Flash Trained.
                            </li>
                          </ul>
                          <p style={{ fontWeight: "bold" }}>
                            Warning: Above is understood and will be adhered to.
                          </p>
                        </Alert>
                        <br />
                        <FormButton
                          text="Accept"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(3)}
                        />{" "}
                        <FormButton
                          text="Cancel"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 3 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="info" style={{ textAlign: "left" }}>
                          Is the fan turning in the correct rotation/direction?
                        </Alert>
                        <br />
                        <FormButton
                          text="Yes"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(4)}
                        />{" "}
                        <FormButton
                          text="No"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {(currentPretestStep > 3 || disableStudy()) && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h2>Actuals</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="Loading button group"
                      >
                        {headSheetValues?.fans?.map((fanMotor, index) => (
                          <Button
                            key={fanMotor.key}
                            disabled={selectedFanMotor === fanMotor.key}
                            onClick={() => loadFanMotor(index)}
                          >
                            <AcUnitIcon />
                            {headSheetValues.fanSystemType === "FanArray"
                              ? fanMotor.serial
                                ? `R:${fanMotor.row} > #${fanMotor.index}`
                                : `S#${(fanMotor.serial || "").padStart(
                                    6,
                                    "..."
                                  )}`
                              : `FnMt > #${fanMotor.index}`}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    {!fanValues?.isDirectDrive && (
                      <>
                        {showActualReadingsDate && (
                          <Grid item xs={12} md={6}>
                            <FormDatePicker
                              name="actualDateTestPerformed"
                              label={`Actual Date Of When The Test Was Performed`}
                              value={values.actualDateTestPerformed}
                              onChange={handleInputChange}
                              error={errors.actualDateTestPerformed}
                              disable={disableStudy()}
                              showSkeleton={isLoadingFanMotor || isLoading}
                              disableFuture={true}
                              fullWidth
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={showActualReadingsDate ? 6 : 12}>
                          <FormText
                            name="model"
                            error={errors.model}
                            label={
                              headSheetValues?.fanSystemType === "FanSystem"
                                ? "Fan tag identifier"
                                : "Individual Fan Array Tag Identifier"
                            }
                            onChange={handleInputChange}
                            placeholder="As labeled by building or blueprint"
                            value={values?.model || ""}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormNumeric2
                            label={`Motor Actual ${systemOfMeasurement.get(
                              "rpm"
                            )}`}
                            placeholder="0.00"
                            name="motorActualRPM"
                            value={values?.motorActualRPM}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={errors?.motorActualRPM}
                            disabled={disableStudy()}
                          />
                          {showMotorRPMWarning() &&
                            values?.motorActualRPM &&
                            !fanValues?.isDirectDrive && (
                              <Alert
                                severity="warning"
                                style={{ marginTop: 10 }}
                              >
                                Please review RPMs and Pitch Diameters entered.
                                <br />
                                {`Data is inconsistent regarding actual Fan/Motor Pitch Diameter and Fan ${systemOfMeasurement.get(
                                  "rpm"
                                )}. Range Warning is +-25.0 ${systemOfMeasurement.get(
                                  "rpm"
                                )}.`}
                              </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Motor Sheave: ${fanValues?.motorSheave}`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Motor Sheave dimension measured: ${fanValues?.motorSheaveDimension.toFixed(
                              2
                            )} ${systemOfMeasurement.get("in")}`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormNumeric2
                            label={`Technicians calculated motor sheave operating pitch diameter ${systemOfMeasurement.get(
                              "in"
                            )}`}
                            placeholder="0.00"
                            name="motorSheaveOperatingPitchDiameter"
                            value={values?.motorSheaveOperatingPitchDiameter}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            fullWidth={false}
                            style={{ width: "90%" }}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={errors?.motorSheaveOperatingPitchDiameter}
                            disabled={disableStudy()}
                          />
                          <FormTooltip tooltipId={19} />
                          <p
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              color: isDarkTheme ? "darkgoldenrod" : "darkred",
                            }}
                          >
                            WARNING: Rotating equipment, injury or death may
                            occur. Only certified field technicians should
                            perform RPM/Speed testing
                          </p>
                          {showWarningMotor() &&
                            values?.motorSheaveOperatingPitchDiameter && (
                              <Alert
                                severity="warning"
                                style={{ marginTop: 5 }}
                              >
                                Please review dimension entered.
                                <br />
                                Difference in dimensions exceeds built-in
                                tolerance.
                                <br />
                                Motor Sheave Operating Pitch Diameter value
                                should be between{" "}
                                {(
                                  (fanValues?.motorSheaveDimension || 0) -
                                  sheaveDimensionBetweenFirst
                                ).toFixed(2)}{" "}
                                and{" "}
                                {(
                                  (fanValues?.motorSheaveDimension || 0) +
                                  sheaveDimensionBetween
                                ).toFixed(2)}{" "}
                                {systemOfMeasurement.get("in")} regarding the
                                actual regarding the actual Motor Sheave
                                Dimension Measured value.
                              </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormNumeric2
                            label={`Fan Actual ${systemOfMeasurement.get(
                              "rpm"
                            )}`}
                            placeholder="0.00"
                            name="fanActualRPM"
                            value={values?.fanActualRPM}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={errors?.fanActualRPM}
                            disabled={disableStudy()}
                          />
                          {showFanRPMWarning() && values?.fanActualRPM && (
                            <Alert severity="warning" style={{ marginTop: 10 }}>
                              Please review RPMs and Pitch Diameters entered.
                              <br />
                              {`Data is inconsistent regarding actual Fan/Motor Pitch Diameter and Motor ${systemOfMeasurement.get(
                                "rpm"
                              )}. Range Warning is +-25.0 ${systemOfMeasurement.get(
                                "rpm"
                              )}.`}
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Fan Sheave dimension measured: ${fanValues?.fanSheaveDimensionMeasured?.toFixed(
                              2
                            )} ${systemOfMeasurement.get("in")}`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormNumeric2
                            label={`Technicians calculated fan sheave operating pitch diameter ${systemOfMeasurement.get(
                              "in"
                            )}`}
                            placeholder="0.00"
                            name="technicianCalculatedFanSheaveOperatingPitchDiameter"
                            value={
                              values?.technicianCalculatedFanSheaveOperatingPitchDiameter
                            }
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            fullWidth={false}
                            style={{ width: "96%" }}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={
                              errors?.technicianCalculatedFanSheaveOperatingPitchDiameter
                            }
                            disabled={disableStudy()}
                          />
                          <FormTooltip tooltipId={17} />
                          <p
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              color: isDarkTheme ? "darkgoldenrod" : "darkred",
                            }}
                          >
                            WARNING: Rotating equipment, injury or death may
                            occur. Only certified field technicians should
                            perform RPM/Speed testing
                          </p>
                          {showWarningFan() &&
                            values?.technicianCalculatedFanSheaveOperatingPitchDiameter && (
                              <Alert
                                severity="warning"
                                style={{ marginTop: 5 }}
                              >
                                Please review dimension entered.
                                <br />
                                Difference in dimensions exceeds built-in
                                tolerance.
                                <br />
                                Fan Sheave Operating Pitch Diameter value should
                                be between{" "}
                                {(
                                  (fanValues?.fanSheaveDimensionMeasured || 0) -
                                  sheaveDimensionBetween
                                ).toFixed(2)}{" "}
                                and{" "}
                                {(
                                  (fanValues?.fanSheaveDimensionMeasured || 0) +
                                  sheaveDimensionBetween
                                ).toFixed(2)}{" "}
                                {systemOfMeasurement.get("in")} regarding the
                                actual regarding the actual Fan Sheave Dimension
                                Measured value.
                              </Alert>
                            )}
                        </Grid>
                      </>
                    )}
                    {((generalDataValues?.fedBySingleVFD &&
                      selectedFanMotorIndex === 0) ||
                      (fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice &&
                        !generalDataValues?.fedBySingleVFD) ||
                      (fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice &&
                        generalDataValues?.fedBySingleVFD &&
                        selectedFanMotorIndex === 0)) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="VFD Operating Hertz"
                            placeholder="0.00"
                            name="vfdOperatingHertz"
                            value={values?.vfdOperatingHertz}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleVfdOperatingHertzChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="VFD Operating % Speed"
                            placeholder="0.00"
                            name="vfdOperatingVelocity"
                            value={values?.vfdOperatingVelocity}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleVfdOperatingVelocityChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            If VFD has a display screen - Collect Operating
                            Voltage and Current/Amperage directly from this
                            display if available.
                            <br />
                            If the screen only displays a single averaged
                            Voltage and Current/Amperage, enter its value in
                            each data input box.
                            <br />
                            If using a standard Volt/Amp meter not rated to read
                            VFD or PWM voltage output, the meter won't work when
                            recording VFD / PWM "Output Voltage". This meter
                            will work when collecting Amperage(s). Collecting
                            actual VFD /PWM "Output Voltage" can be done with
                            the meters designed and available to do this task.
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            <strong>RECOMMENDATION:</strong>
                            <br />
                            <b>
                              Use VFD/PWM display data to collect actual Volts
                              and Amps
                            </b>
                            <br />
                          </Alert>
                        </Grid>
                      </>
                    )}
                    {((generalDataValues?.fedBySingleVFD &&
                      selectedFanMotorIndex === 0) ||
                      !generalDataValues?.fedBySingleVFD) && (
                      <Grid item xs={12}>
                        <FormLabel text="Select Voltage that best represents Actual read:" />
                        <FormCheckBox
                          name=""
                          label={`${
                            fanMotorValues?.nameplateVoltsPhase1 || "NA"
                          }V/${fanMotorValues?.nameplateAmpsPhase1 || "NA"}A`}
                          value={
                            fanMotorValues?.selectedVoltAmpsReference === 1
                          }
                          onChange={(e: any) =>
                            handleSelectedVoltsReferenceChange(1)
                          }
                          showSkeleton={isLoadingFanMotor || isLoading}
                          disabled={disableStudy()}
                        />
                        {(fanMotorValues?.motorPhase === "Three phase" ||
                          (fanMotorValues?.motorPhase ===
                            "2 wire single phase" &&
                            fanMotorValues?.additionalVolAmpValue >= 1)) && (
                          <FormCheckBox
                            name=""
                            label={`${
                              fanMotorValues?.nameplateVoltsPhase2 || "NA"
                            }V/${fanMotorValues?.nameplateAmpsPhase2 || "NA"}A`}
                            value={
                              fanMotorValues?.selectedVoltAmpsReference === 2
                            }
                            onChange={(e: any) =>
                              handleSelectedVoltsReferenceChange(2)
                            }
                            disabled={disableStudy()}
                          />
                        )}
                        {(fanMotorValues?.motorPhase === "Three phase" ||
                          (fanMotorValues?.motorPhase ===
                            "2 wire single phase" &&
                            fanMotorValues?.additionalVolAmpValue === 2)) && (
                          <FormCheckBox
                            name=""
                            label={`${
                              fanMotorValues?.nameplateVoltsPhase3 || "NA"
                            }V/${fanMotorValues?.nameplateAmpsPhase3 || "NA"}A`}
                            value={
                              fanMotorValues?.selectedVoltAmpsReference === 3
                            }
                            onChange={(e: any) =>
                              handleSelectedVoltsReferenceChange(3)
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        )}
                        {fanMotorValues?.motorPhase === "Three phase" &&
                          fanMotorValues?.additionalVolAmpValue >= 1 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                fanMotorValues?.nameplateVoltsPhase4 || "NA"
                              }V/${
                                fanMotorValues?.nameplateAmpsPhase4 || "NA"
                              }A`}
                              value={
                                fanMotorValues?.selectedVoltAmpsReference === 4
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(4)
                              }
                              disabled={disableStudy()}
                            />
                          )}
                        {fanMotorValues?.motorPhase === "Three phase" &&
                          fanMotorValues?.additionalVolAmpValue >= 2 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                fanMotorValues?.nameplateVoltsPhase5 || "NA"
                              }V/${
                                fanMotorValues?.nameplateAmpsPhase5 || "NA"
                              }A`}
                              value={
                                fanMotorValues?.selectedVoltAmpsReference === 5
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(5)
                              }
                              showSkeleton={isLoadingFanMotor || isLoading}
                              disabled={disableStudy()}
                            />
                          )}
                        {fanMotorValues?.motorPhase === "Three phase" &&
                          fanMotorValues?.additionalVolAmpValue === 3 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                fanMotorValues?.nameplateVoltsPhase6 || "NA"
                              }V/${
                                fanMotorValues?.nameplateAmpsPhase6 || "NA"
                              }A`}
                              value={
                                fanMotorValues?.selectedVoltAmpsReference === 6
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(6)
                              }
                              showSkeleton={isLoadingFanMotor || isLoading}
                              disabled={disableStudy()}
                            />
                          )}
                        <FormCheckBox
                          name="voltageNotListed"
                          label="Voltage not listed"
                          value={fanMotorValues?.voltageNotListed}
                          onChange={handleFanMotorValuesChange}
                          showSkeleton={isLoadingFanMotor || isLoading}
                          disabled={disableStudy()}
                        />
                        <Alert severity="warning">
                          1. Single Phase and 3 Phase Voltages are recorded from
                          Phase to Phase, not Phase to Ground.
                          {(fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice ||
                            generalDataValues?.fedBySingleVFD) && (
                            <>
                              <br />
                              2. Reading Actual Volts requires a handheld
                              voltmeter rated for VFDs. A typical RMS Voltmeter
                              will not provide accurate readings.
                              <br />
                              3. Actual Voltage and Amperage associated with the
                              VFD must be recorded on the leaving side of the
                              VFD to the Motor.
                            </>
                          )}
                        </Alert>
                      </Grid>
                    )}
                    {(fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice ||
                      generalDataValues?.fedBySingleVFD) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormCheckBox
                            name="voltReadFromVFDScreen"
                            label="Volts & Amperages read from VFD Screen"
                            value={values?.voltReadFromVFDScreen}
                            onChange={(e: any) =>
                              handleVoltsReadChange(
                                e.target.value,
                                !e.target.value
                              )
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormCheckBox
                            name="voltMeterUsed"
                            label="Volts & Amperages read with a handheld voltmeter"
                            value={values?.voltMeterUsed}
                            onChange={(e: any) =>
                              handleVoltsReadChange(
                                !e.target.value,
                                e.target.value
                              )
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {values?.voltMeterUsed && (
                          <Grid item xs={12}>
                            <Alert severity="info">
                              <b>DECLARATION</b>
                              <br />I hereby state that I'm using a voltmeter
                              rated for VFD.
                            </Alert>
                          </Grid>
                        )}
                      </>
                    )}
                    {values?.voltReadFromVFDScreen && (
                      <Grid item xs={12}>
                        <FormCheckBox
                          name="vfdOnlyDisplaysSingleVolAmp"
                          label="VFD/PWM Only displays 1 Voltage and 1 Amperage"
                          value={values?.vfdOnlyDisplaysSingleVolAmp}
                          onChange={handleVfdOnlyDisplaysSingleVoltageChange}
                          showSkeleton={isLoadingFanMotor || isLoading}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    {((generalDataValues?.fedBySingleVFD &&
                      selectedFanMotorIndex === 0) ||
                      !generalDataValues?.fedBySingleVFD) && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Volts L1-L2"
                            placeholder="0.00"
                            name="actualOperatingVoltsPhase1"
                            value={values?.actualOperatingVoltsPhase1}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={errors?.actualOperatingVoltsPhase1}
                            disabled={disableStudy()}
                            onBlur={handleVoltsPhase1Blur}
                          />
                        </Grid>
                        {fanMotorValues?.motorPhase === "Three phase" && (
                          <>
                            <Grid item xs={12} md={4}>
                              <FormNumeric2
                                label="Actual Volts L1-L3"
                                placeholder="0.00"
                                name="actualOperatingVoltsPhase2"
                                value={values?.actualOperatingVoltsPhase2}
                                decimalScale={2}
                                fixedDecimalScale
                                onKeyDown={handleValidateVoltsAmps}
                                onPaste={handleValidateVoltsAmps}
                                onChange={handleInputChange}
                                disabled={
                                  values?.vfdOnlyDisplaysSingleVolAmp ||
                                  disableStudy()
                                }
                                showSkeleton={isLoadingFanMotor || isLoading}
                                error={errors?.actualOperatingVoltsPhase2}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormNumeric2
                                label="Actual Volts L2-L3"
                                placeholder="0.00"
                                name="actualOperatingVoltsPhase3"
                                value={values?.actualOperatingVoltsPhase3}
                                decimalScale={2}
                                fixedDecimalScale
                                onKeyDown={handleValidateVoltsAmps}
                                onPaste={handleValidateVoltsAmps}
                                onChange={handleInputChange}
                                disabled={
                                  values?.vfdOnlyDisplaysSingleVolAmp ||
                                  disableStudy()
                                }
                                showSkeleton={isLoadingFanMotor || isLoading}
                                error={errors?.actualOperatingVoltsPhase3}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {((generalDataValues?.fedBySingleVFD &&
                      selectedFanMotorIndex === 0) ||
                      !generalDataValues?.fedBySingleVFD) && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Amps L1-L2"
                            placeholder="0.00"
                            name="actualOperatingAmpsPhase1"
                            value={values?.actualOperatingAmpsPhase1}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            error={errors?.actualOperatingAmpsPhase1}
                            disabled={disableStudy()}
                            onBlur={handleAmpsPhase1Blur}
                          />
                        </Grid>
                        {fanMotorValues?.motorPhase === "Three phase" && (
                          <>
                            <Grid item xs={12} md={4}>
                              <FormNumeric2
                                label="Actual Amps L1-L3"
                                placeholder="0.00"
                                name="actualOperatingAmpsPhase2"
                                value={values?.actualOperatingAmpsPhase2}
                                decimalScale={2}
                                fixedDecimalScale
                                onKeyDown={handleValidateVoltsAmps}
                                onPaste={handleValidateVoltsAmps}
                                onChange={handleInputChange}
                                disabled={
                                  values?.vfdOnlyDisplaysSingleVolAmp ||
                                  disableStudy()
                                }
                                showSkeleton={isLoadingFanMotor || isLoading}
                                error={errors?.actualOperatingAmpsPhase2}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormNumeric2
                                label="Actual Amps L2-L3"
                                placeholder="0.00"
                                name="actualOperatingAmpsPhase3"
                                value={values?.actualOperatingAmpsPhase3}
                                decimalScale={2}
                                fixedDecimalScale
                                onKeyDown={handleValidateVoltsAmps}
                                onPaste={handleValidateVoltsAmps}
                                onChange={handleInputChange}
                                disabled={
                                  values?.vfdOnlyDisplaysSingleVolAmp ||
                                  disableStudy()
                                }
                                showSkeleton={isLoadingFanMotor || isLoading}
                                error={errors?.actualOperatingAmpsPhase3}
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {(!ampsValid(1) || !ampsValid(2) || !ampsValid(3)) && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          {`WARNING: Actual Amps exceed referenced nameplate Amp rating in lines${
                            !ampsValid(1) ? " | 1" : ""
                          }${!ampsValid(2) ? " | 2" : ""}${
                            !ampsValid(3) ? " | 3" : ""
                          }`}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormSelect
                        name="systemOutsideAirReadById"
                        label="System Outside Air Read By"
                        error={errors.systemOutsideAirReadById}
                        onChange={handleInputChange}
                        options={systemOAReadBys}
                        value={values?.systemOutsideAirReadById || 0}
                        showSkeleton={isLoadingFanMotor || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {catalogValueOtherEval(
                      values?.systemOutsideAirReadById || 0,
                      systemOAReadBys as IDefaultComboBoxCatalog[]
                    ) && (
                      <Grid item xs={12}>
                        <FormText
                          name="systemOutsideAirReadByOther"
                          label="Other System Outside Air Read By"
                          onChange={handleInputChange}
                          placeholder="Type here"
                          value={values?.systemOutsideAirReadByOther || ""}
                          showSkeleton={
                            isLoadingFanMotor ||
                            isLoading ||
                            isLoadingSystemOAReadBy
                          }
                          error={errors?.systemOutsideAirReadByOther}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormCheckBox
                        name="aegisRing"
                        label="Aegis Bearing or Cool Blue Ring(s) installed"
                        value={values?.aegisRing}
                        onChange={handleInputChange}
                        showSkeleton={isLoadingFanMotor || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {(fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice ||
                      generalDataValues?.fedBySingleVFD) && (
                      <>
                        <Grid item xs={12}>
                          <FormLabel text="System SP set-point based on worst case flowing Terminal Box, which is a:" />
                          <FormCheckBox
                            name=""
                            label="VAV"
                            value={values?.systemSPWorstCaseFlowing === "VAV"}
                            onChange={(e: any) =>
                              handleSystemSPWorstCaseFlowingChange(e, "VAV")
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                          <FormCheckBox
                            name=""
                            label="FPB"
                            value={values?.systemSPWorstCaseFlowing === "FPB"}
                            onChange={(e: any) =>
                              handleSystemSPWorstCaseFlowingChange(e, "FPB")
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                          <FormCheckBox
                            name=""
                            label="CAV"
                            value={values?.systemSPWorstCaseFlowing === "CAV"}
                            onChange={(e: any) =>
                              handleSystemSPWorstCaseFlowingChange(e, "CAV")
                            }
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="Pneumatic pressure at furthest pneumatic (VAV, CAV, or FPB)"
                            placeholder="0.00"
                            name="furthestPneumaticPressure"
                            value={values?.furthestPneumaticPressure}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            onBlur={handleFurthestPneumaticPressureChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormText
                            name="boxIdentifier"
                            label="Worst case Terminal Box Identifier / BAS Address"
                            onChange={handleInputChange}
                            placeholder="Type here"
                            value={values?.boxIdentifier || ""}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="Terminal Box damper position open (BAS / Visual pneumatic) when operating at design SP set-point"
                            placeholder="0.00"
                            name="boxDamperPositionAtDesignSPSetPoint"
                            value={values?.boxDamperPositionAtDesignSPSetPoint}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            fullWidth={false}
                            style={{ width: "90%" }}
                            disabled={disableStudy()}
                          />
                          <FormTooltip tooltipId={36} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="Final System SP set-point"
                            placeholder="0.00"
                            name="systemFinalSPSetPoint"
                            value={values?.systemFinalSPSetPoint}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={isLoadingFanMotor || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </>
                    )}
                    {selectedFanMotorIndex === 0 && (
                      <Grid item xs={12}>
                        <h2>System Space Pressurization Data</h2>
                        <SpacePressurization
                          headSheetActualId={values?.id}
                          disableStudy={disableStudy()}
                          isLoading={isLoading}
                          isLoadingSpacePressurization={
                            isLoadingSpacePressurization
                          }
                          systemOfMeasurement={systemOfMeasurement}
                          spacePressurizationValues={spacePressurizationValues}
                          setSpacePressurizationValues={
                            setSpacePressurizationValues
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={headSheetValues?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={isUpdating || isLoading || disableStudy()}
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                hideSaveButton={currentPretestStep <= 3 || disableStudy()}
                disableNext={currentPretestStep <= 3 && !disableStudy()}
                isComplete={headSheetValues?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadSheetStep5;
