import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import { useForm, useLog, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { THEMES } from "src/constants";
import unionAffiliatedService from "src/services/unionAffiliatedService";
import { IUnionAffiliatedAnswer } from "src/ts/interfaces/unionAffiliated";
import {
  FormButton,
  FormCheckBox,
  FormTextArea,
} from "src/components/formControls";
import { Validator } from "src/ts/types";
import { convertUTCDateToLocalDate } from "src/utils/utils";

const Spacer = styled.div(spacing);

const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function UnionAffiliated() {
  const { theme } = useTheme();
  const { log } = useLog();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  let { isAffiliated } = useParams<{ isAffiliated: string }>();
  const [data, setData] = useState<IUnionAffiliatedAnswer>();
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [proceed, setProceed] = useState(false);
  const [isSubmmiting, setIsSubmmiting] = useState(false);

  interface IResponse {
    deactivationReason: string;
  }

  const initialValues: IResponse = {
    deactivationReason: "",
  };

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.deactivationReason = new Validator(fieldValues, "deactivationReason")
      .isRequired("This field is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmmiting(true);
        const response = await unionAffiliatedService.GetByCode(code ?? "");
        setData(response.data);

        if (response.data === null) setErrorMessage("Invalid Link");

        if (response.data?.userPersonId !== null) {
          setName("Journeyman/Apprentice");
          setUserName(
            `${response.data?.userPerson.name} ${response.data?.userPerson.lastName}`
          );
        }

        if (response.data?.companyId !== null) {
          setName("company");
          setUserName(response.data?.company.name ?? "");
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmmiting(false);
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      setIsSubmmiting(true);

      const response = await unionAffiliatedService.ModifyUnionAffiliated(
        code ?? "",
        isAffiliated === "true" ? true : false,
        values.deactivationReason
      );
      log.success(response.data);
      navigate("/auth/sign-in");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmmiting(false);
    }
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "35%",
                    marginRight: "35%",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {data ? (
                <>
                  {data.isAffiliated === null &&
                    errorMessage !== "Invalid Link" && (
                      <>
                        <Typography variant="h6">
                          {`Are you certain you want to set the ${userName} as ${
                            isAffiliated === "true" ? "" : "Non"
                          } Union Affiliated?`}
                        </Typography>
                        <Spacer mb={6} />
                        <FormCheckBox
                          value={proceed}
                          onChange={() => {
                            setProceed(!proceed);
                          }}
                          name={"proceed"}
                          label={"Yes, proceed"}
                        />
                        <Spacer mb={4} />
                        <FormTextArea
                          name="deactivationReason"
                          label={`Reason for ${
                            isAffiliated === "true" ? "" : "do not "
                          } authorize the Union affiliation`}
                          value={values.deactivationReason}
                          onChange={handleInputChange}
                          placeholder="Comments"
                          disabled={!proceed}
                          error={errors.deactivationReason}
                        />
                        <Spacer mb={4} />
                        <Grid item mb={12} textAlign={"right"}>
                          <FormButton
                            text={"Accept"}
                            size="medium"
                            onClick={handleSave}
                            disabled={!values.deactivationReason}
                            color={"primary"}
                            isSubmitting={isSubmmiting}
                          />
                        </Grid>
                      </>
                    )}

                  {data.isAffiliated !== null && (
                    <Grid container>
                      <Grid item xs={12} pb={4}>
                        <Typography variant="h6" pb={2} textAlign={"center"}>
                          {`The decision regarding ${name} ${userName} has been made, and the provided link is no longer valid.`}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} textAlign={"right"}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Union administrator:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography>
                          {` ${data.updatedAcceptRejectBy.name} ${data.updatedAcceptRejectBy.lastName}`}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} textAlign={"right"}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Decision:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography>
                          {` ${
                            data.isAffiliated ? "Affiliated" : "Not Affiliated"
                          }`}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} textAlign={"right"}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography>
                          {` ${convertUTCDateToLocalDate(
                            data.updatedAcceptRejectDate?.toString() || ""
                          )?.format("MM/DD/YYYY hh:mm A")}`}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} textAlign={"right"}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Comments:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography>{data.affiliatedComments}</Typography>
                      </Grid>

                      <Grid item xs={12} textAlign={"right"}>
                        <FormButton
                          text={"Accept"}
                          size="medium"
                          onClick={() => {
                            navigate("/auth/sign-in");
                          }}
                          color={"primary"}
                          isSubmitting={isSubmmiting}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12} pb={4}>
                    <Spacer mb={6} />
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      This request code is not longer valid
                    </Typography>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
}
export default UnionAffiliated;
