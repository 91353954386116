import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  useAsyncMutation,
  useAuth,
  useForm,
  useLog,
  useTheme,
} from "src/hooks";
import { useLocation, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import {
  FormCheckBox,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import { THEMES } from "src/constants";
import accountService from "src/services/accountService";
import ReCAPTCHA from "react-google-recaptcha";
import { IStudyStep } from "src/ts/interfaces";
import IdentityConfirmation from "src/pages/contactInformation/steps/IdentityConfirmation";
import ConfirmFree from "src/pages/contactInformation/steps/ConfirmFree";
import { INewUserAccount } from "src/ts/interfaces/account";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const RootCaptcha = styled.div`
  div {
    div {
      display: flex;
      place-content: center;
    }
  }
`;

export const steps: IStudyStep[] = [
  { label: "Step 1", key: 0, description: "Password Configuration" },
  { label: "Step 2", key: 1, description: "Identity Verification" },
  { label: "Step 3", key: 2, description: "Confirm" },
];
export const stepsAdmin: IStudyStep[] = [
  { label: "Step 1", key: 0, description: "Password Configuration" },
  { label: "Step 2", key: 2, description: "Confirm" },
];
function NewAccountPassword() {
  const { theme } = useTheme();
  const { pathname } = useLocation();
  const { signIn, user, signOut } = useAuth();
  const Divider = styled(MuiDivider)(spacing);
  const { log } = useLog();
  let { code } = useParams<{ code: string }>();
  let { email } = useParams<{ email: string }>();
  const [step, setStep] = useState(0);
  const [captcha, setCaptcha] = useState("");
  const isAdmin = pathname.indexOf("ResetPasswordNewUserCompany") > 0;
  const initialValues: INewUserAccount = {
    codePass: code ?? "",
    confirmPassword: "",
    email: email ?? "",
    newPassword: "",
    isTermsAndConditionsAccepted: false,
    recaptchaResponse: "",
  };
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.newPassword.length === 0) temp.newPassword = "Is required";
    if (fieldValues.confirmPassword.length === 0)
      temp.confirmPassword = "Is required";

    if (fieldValues.newPassword.length > 0) {
      if (fieldValues.newPassword !== fieldValues.confirmPassword) {
        temp.newPassword = "Password not match";
      } else {
        temp.newPassword = "";
      }
    }
    if (fieldValues.confirmPassword.length > 0) {
      if (fieldValues.newPassword !== fieldValues.confirmPassword) {
        temp.confirmPassword = "Password not match";
      } else {
        temp.confirmPassword = "";
      }
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );
  const { execute, isSubmitting } = useAsyncMutation(
    accountService.newAccountPassword,
    {
      hideErrorMessage: true,
      successfulMessage: "Your password has been updated succesfully",
      onSuccess: async () => {
        await signIn(values.email, values.newPassword);
      },
      onError: (result) => {
        log.error(result.message);
      },
    }
  );
  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (user !== null) setStep(step + (isAdmin ? 2 : 1));
    if (user === null) setStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleResetPassword = async () => {
    if (!validate()) return;
    await execute(values);
  };

  const handleChange = (recaptcha: any) => {
    setCaptcha(recaptcha);
  };

  const handleNext = async () => {
    setStep(step + 1);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={3} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              {!isAdmin && (
                <Stepper alternativeLabel activeStep={step}>
                  {steps.map((step) => (
                    <Step key={step.key}>
                      <StepLabel>
                        {step.label}
                        <br /> {step.description}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
              {isAdmin && (
                <Stepper alternativeLabel activeStep={step}>
                  {stepsAdmin.map((step) => (
                    <Step key={step.key}>
                      <StepLabel>
                        {step.label}
                        <br /> {step.description}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "30%",
                    marginRight: "30%",
                    width: "40%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {step === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography>
                      Enter below a new password for your email account
                      registered as: {email}. Your password must be at least six
                      characters long and contain at least one upper case and
                      one lower case letter and one special character.{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormText
                      type="password"
                      name="newPassword"
                      label="Password"
                      autoComplete="new-password"
                      placeholder="Password - Your password must have at least one non letter or digit character"
                      value={values?.newPassword}
                      error={errors.newPassword}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormText
                      type="password"
                      name="confirmPassword"
                      label="Confirm password"
                      value={values.confirmPassword}
                      error={errors.confirmPassword}
                      fullWidth
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} margin={0} textAlign={"center"}>
                    <RootCaptcha>
                      <ReCAPTCHA
                        sitekey="6Ld4ht0UAAAAAIurUS8AVwmhNlluE1wReG6_sGgf"
                        onChange={handleChange}
                        badge={"inline"}
                      />
                    </RootCaptcha>
                  </Grid>
                  <Grid item xs={12}>
                    <FormCheckBox
                      name="isTermsAndConditionsAccepted"
                      label={
                        <Typography>
                          <Typography display={"inline"}>
                            I understand and agree to{" "}
                          </Typography>
                          <Link
                            href="https://auditmaster.pro/legal?id=privacy_statement"
                            target="_blank"
                          >
                            AMP's Privacy Policy and Terms of Service
                          </Link>
                        </Typography>
                      }
                      value={values.isTermsAndConditionsAccepted}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"}>
                    <Divider mb={4} mt={3} />
                    <FormSaveButton
                      onClick={handleResetPassword}
                      isSubmitting={isSubmitting}
                      text="Reset Password"
                      disabled={
                        !captcha || !values.isTermsAndConditionsAccepted
                      }
                    ></FormSaveButton>
                  </Grid>
                </Grid>
              )}
              {step === 1 && <IdentityConfirmation handleNext={handleNext} />}
              {step === 2 && <ConfirmFree wizardId={0} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
}
export default NewAccountPassword;
