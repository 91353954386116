import axios from "../utils/axios";
import {
  IBlockWalletRequest,
  ICreditCard,
  ILastWalletMovement,
  IPartnerHistory,
  ISettle,
  ISettleDetail,
  ISettlementBalanceFilter,
  ISettlementBalancePage,
  IWalletList,
  IWalletMetrics,
  SystemWalletSummaryFilterType,
  WalletHistoryPage,
} from "../ts/interfaces";
import { ElavonPaymentFilterQueryString } from "../pages/Wallet/types";
import { getFilter } from "../utils/utils";

const blockWallet = async ({ id, reason }: IBlockWalletRequest) => {
  const walletMovement = {
    companyId: id,
    creditCardId: null, //is this required
    transactionType: "ccblock", //move to const ??
    transactionStatus: "BLOCKED", //move to const ??
    movementDescription: reason,
    amount: 0.0, //is this required
    transactionResponse: null, //is this required
    oldCrud: null, //is this required
    visible: 1,
    summarizable: 0,
    itemCode: null,
  };

  return await axios.post("Wallet/blockWallet", walletMovement);
};

const unlockWallet = async ({ id, reason }: IBlockWalletRequest) => {
  const walletMovement = {
    companyId: id,
    creditCardId: null,
    transactionType: "ccunlock",
    transactionStatus: "UNLOCKED",
    movementDescription: reason,
    amount: 0.0,
    transactionResponse: null,
    oldCrud: null,
    visible: 1,
    summarizable: 0,
    itemCode: null,
  };

  return await axios.post("Wallet/unlockWallet", walletMovement);
};

const getElavonPaymentLog = async (filters: ElavonPaymentFilterQueryString) => {
  const queryString = getFilter(filters);
  return await axios.get<WalletHistoryPage>(`Wallet?${queryString}`);
};

const getPurchaseHistory = async (filters: ElavonPaymentFilterQueryString) => {
  const queryString = getFilter(filters);
  return await axios.get<WalletHistoryPage>(
    `Wallet/purchaseHistory?${queryString}`
  );
};

const getWalletHistory = async (
  filters: ElavonPaymentFilterQueryString,
  companyId: string
) => {
  const queryString = getFilter(filters);
  return await axios.get<WalletHistoryPage>(
    `Wallet/${companyId}?${queryString}`
  );
};

const returnTxn = async (id: number) => {
  //
  return await axios.put(`Wallet/returnTxn/${id}`);
};

const getMetrics = async (filters: SystemWalletSummaryFilterType) => {
  return await axios.get<IWalletMetrics>(
    `Wallet/metrics?dateFrom=${filters.startDate}&dateTo=${filters.endDate}`
  );
};

const getPartnerMetrics = async (year: string) => {
  return await axios.get<IPartnerHistory[]>(
    `Wallet/partnerMetrics?year=${year}`
  );
};

const getPartnerSettleHistoryData = async (
  filter: ISettlementBalanceFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<ISettlementBalancePage>(
    `Wallet/partnerSettleHistory?${queryString}`
  );
};

const getSettleDetail = async (id: number) => {
  return await axios.get<ISettleDetail[]>(`Wallet/settleDetail/${id}`);
};

const settlePartners = async (year: string, month: string) => {
  return await axios.get<ISettle>(
    `Wallet/settlePartners?month=${month}&year=${year}`
  );
};

const generatePdfSettle = async (id: number) => {
  return await axios.post(`PdfExport/GenerateSettlePdfReport?settleId=${id}`);
};
const getPdfSettle = async (id: number) => {
  return await axios.get(`ReportFile/GetSettleFile?settleId=${id}`);
};

const getBalance = async (id: number) => {
  return await axios.get<number>(`Wallet/balance/${id}`);
};

const defaultCard = async (id: number) => {
  return await axios.get<number>(`Wallet/defaultCard/${id}`);
};

const getCardById = async (id: number) => {
  return await axios.get<ICreditCard>(`Wallet/card/${id}`);
};

const autoReload = async (creditCard: ICreditCard) => {
  return await axios.post<ICreditCard>(`wallet/autoReload`, creditCard);
};

const getWalletBalance = async (companyId: string) => {
  return axios.get(`Wallet/balance/${companyId}`);
};

const getWalletList = async (companyId: string) => {
  return axios.get<IWalletList[]>(`Wallet/CompanySelected/${companyId}`);
};

const addFunds = async (card: ICreditCard) => {
  return axios.post(`wallet/addFunds`, card);
};

const updateCard = async (card: ICreditCard, cardId: number) => {
  return axios.put(`Wallet/updateCard/${cardId}`, card);
};

const createCard = async (card: ICreditCard) => {
  return axios.post(`wallet/addRecurring`, card);
};

const changeStatusCard = async (cardId: number, isActive: boolean) => {
  return axios.put(`Wallet/changeCardStatus?id=${cardId}&isActive=${isActive}`);
};

const changeDefaultCard = async (cardId: number) => {
  return axios.put(`Wallet/makeDefaultCard?id=${cardId}`);
};

const deleteCard = async (cardId: number) => {
  return axios.delete(`Wallet/${cardId}`);
};

const getLastCardMovement = async (id: number) => {
  return axios.get<ILastWalletMovement>(`Wallet/LastMovement/${id}`);
};

const WalletService = {
  deleteCard,
  changeDefaultCard,
  changeStatusCard,
  createCard,
  getPdfSettle,
  generatePdfSettle,
  settlePartners,
  blockWallet,
  unlockWallet,
  getElavonPaymentLog,
  getPurchaseHistory,
  returnTxn,
  getMetrics,
  getPartnerMetrics,
  getPartnerSettleHistoryData,
  getSettleDetail,
  getBalance,
  defaultCard,
  getCardById,
  autoReload,
  getWalletBalance,
  getWalletList,
  addFunds,
  updateCard,
  getWalletHistory,
  getLastCardMovement,
};

export default WalletService;
