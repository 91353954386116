/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import projectReportService from "src/services/study/projectReportService";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEETReportServiceModelDTO,
  IEnergyStudyAirHydronicsDTO,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetActualSpacePressurizationDTO,
  IProjectPurchase,
  IProjectReportInfectiousComplianceControl,
  IReportType,
  IStaticIdentDTO,
  IStaticReportDTO,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import projectService from "src/services/project/projectService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import staticReportService from "src/services/study/staticReportService";
import Popup from "src/components/Popup";
import { FormTextArea } from "src/components/formControls";
import headSheetService from "src/services/study/headSheetService";
import headSheetActualSpacePressurizationService from "src/services/study/headSheetActualSpacePressurizationService";
import { SpacePressurization } from "../../headsheet/step5/components/SpacePressurization";
import StudyWizard from "../../components/StudyWizard";
import { useTheme } from "@mui/system";
import { blue, orange, green } from "@mui/material/colors";
import RuleIcon from "@mui/icons-material/Rule";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import RetestReport from "../components/RetestReport";
import { IEnergyApprovalComments } from "src/ts/interfaces/catalogs";
import energyApprovalCommentsService from "src/services/study/energyApprovalCommentsService";
import {
  IGeneralApprovalProcess,
  IStudyApprovalProcessStatusComments,
} from "src/ts/interfaces/study/studyApprovalProcess";
import SvgAmp from "../assets/SvgAmp";
import SvgBtu from "../assets/SvgBtu";
import SendToAmp from "../components/SendToAmp";
import studyApprovalProcessStatusService from "src/services/study/studyApprovalProcessStatusService";
import GavelIcon from "@mui/icons-material/Gavel";
//Section Icons
import InputIcon from "@mui/icons-material/Input";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AirIcon from "@mui/icons-material/Air";
import WaterIcon from "@mui/icons-material/Water";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import SaveEvaluation from "../components/SaveEvaluation";
import SvgCoil from "../assets/SvgCoil";
import BusinessIcon from "@mui/icons-material/Business";
import { TextAlign } from "chart.js";
import { useConfig } from "src/contexts/EnergyConfigProvider";
import SummaryCard from "../components/SummaryCard";
import FormTooltip from "src/components/formControls/FormTooltip";

interface SectionsStatus {
  section: string;
  decision: string;
}

const _STUDY_STATUS_APPROVED = 7;
const _STUDY_STATUS_NOT_COMPLIANT = 8;
const _STUDY_STATUS_REJECTED = 9;
const _STUDY_STATUS_APPEALED = 10;
const _STUDY_STATUS_FINAL_REJECTED = 11;

const _STUDY_STATUS_ENUM = {
  7: "Approved",
  8: "Approved but Not Compliant",
  9: "Rejected",
  10: "Appeal",
  11: "Final Rejected",
};
const _AUDITOR_ROLES = ["EnergyAuditor", "TemporalEnergyAuditor"];

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const EnergyStep7 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [openAppealWarning, setOpenAppealWarning] = useState(false);
  const [openFinalRejectWarning, setOpenFinalRejectdWarning] = useState(false);
  const [appealMessage, setAppealMessage] = useState("");
  const [currentCommentId, setCurrentCommentId] = useState("");
  const [staticValues, setStaticValues] = useState<IStaticReportDTO>();
  const [filters, setFilters] = useState<IStaticIdentDTO[]>();
  const [headSheetActualValues, setHeadSheetActualValues] =
    useState<IHeadSheetActualDTO>();
  const [spacePressurizationValues, setSpacePressurizationValues] =
    useState<IHeadSheetActualSpacePressurizationDTO[]>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [projectReportPurchase, setProjectReportPurchase] =
    useState<IProjectPurchase | null>(null);
  const isDarkTheme = useTheme().palette.mode === "dark";
  const [energyApprovalComments, setEnergyApprovalComments] =
    useState<IEnergyApprovalComments[]>();
  const [studyApprovalStatus, setStudyApprovalStatus] =
    useState<IGeneralApprovalProcess>();
  const [sectionApprovalComments, setSectionApprovalComments] =
    useState<IStudyApprovalProcessStatusComments[]>();
  const [mainApprovalComment, setMainApprovalComment] = useState("");
  const config = useConfig();

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 6;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({ ...dataResult });
          executeProject(dataResult.projectId);
          executeStatic(dataResult.finalStaticReportId || 0);
          if (dataResult.headSheetId)
            await executeHeadSheet(dataResult.headSheetId);
        };
        getData();
      },
    }
  );

  const { execute: executeStatic, isLoading: isLoadingStatic } =
    useAsyncQuery<IStaticReportDTO>(staticReportService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setStaticValues({ ...dataResult });
          if (dataResult?.staticIdent?.length > 0) {
            const filtersTemp = dataResult?.staticIdent?.filter(
              (x: IStaticIdentDTO) => x.upStaticRow?.category === "Filters"
            );
            setFilters(filtersTemp);
          }
        };
        getData();
      },
    });

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectSingle, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
        };
        getData();
      },
    });

  const {
    execute: executeEnergyApprovalComments,
    isLoading: isLoadingEnergyApprovalComments,
  } = useAsyncQuery<IEnergyApprovalComments[]>(
    energyApprovalCommentsService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setEnergyApprovalComments(dataResult);
        };
        getData();
      },
    }
  );

  const {
    execute: executeStudyApprovalStatus,
    isLoading: isLoadingStudypprovalStatus,
  } = useAsyncQuery<IGeneralApprovalProcess>(
    studyApprovalProcessStatusService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setStudyApprovalStatus(dataResult);
          if (dataResult.studyApprovalProcessStatus) {
            setSectionApprovalComments(
              JSON.parse(
                dataResult.studyApprovalProcessStatus[
                  dataResult.studyApprovalProcessStatus.length - 1
                ].comment
              )
            );
          }
        };
        getData();
      },
    }
  );

  const { execute: executeParent, isLoading: isLoadingParentReport } =
    useAsyncQuery<IProjectReport>(projectReportService.GetParentReport, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setParentStudy(dataResult);
        };
        getData();
      },
    });

  const { execute: executeEndFinalTest } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endFinalTest,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              fInalTestTimeAir: dataResult,
            });
            if (staticValues) {
              await staticReportService.updateIdent(
                staticValues.id,
                staticValues.staticIdent as IStaticIdentDTO[]
              );
            }
          };
          getData();
        },
      }
    );

  const { execute: executeHeadSheet, isLoading: isLoadingHeadSheet } =
    useAsyncQuery<IEETReportServiceModelDTO>(headSheetService.getStepFour, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (dataResult.fans)
            executeFanMotor(dataResult.id, dataResult.fans[0].key);
        };
        getData();
      },
    });

  const { execute: executeFanMotor, isLoading: isLoadingFanMotor } =
    useAsyncQuery<IHeadSheetActualFormulaDTO>(headSheetService.getActuals, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const { actual } = dataResult;
          setHeadSheetActualValues(actual);
          executeSpacePressurization(actual.id);
          window.scrollTo(0, 0);
        };
        getData();
      },
    });

  const {
    execute: executeSpacePressurization,
    isLoading: isLoadingSpacePressurization,
  } = useAsyncQuery<IHeadSheetActualSpacePressurizationDTO[]>(
    headSheetActualSpacePressurizationService.getByHeadSheetActualId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setSpacePressurizationValues(dataResult);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) {
      executeReportType(values?.reportTypeId);
      executeParent(id, values?.reportTypeId);
    }
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const getProjectReportData = async () => {
    await getInfectiousControlData(dataReport.id);
    await executeStudyApprovalStatus(dataReport.id);
    await getProjectReportPurchaseData(dataReport.id);
  };

  useEffect(() => {
    if (dataReport?.id) getProjectReportData();
  }, [dataReport?.id, values?.isComplete]);

  useEffect(() => {
    const getData = async () => await executeEnergyApprovalComments();
    if ([3, 6, 10].includes(infectiousComplianceControl?.studyStatusId || 0))
      getData();
  }, [infectiousComplianceControl?.studyStatusId]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (!isDialogOpen) getData();
  }, [isDialogOpen]);

  const saveHandler = async () => null;

  const nextStepHandler = async () => null;

  const previousStepHandler = async () => {
    try {
      navigate(`/studies/EnergyStudyAirHydronics/step5/${id}`);
    } catch (error) {}
  };

  const showFirstPart = () => !!values?.finalTestTimeH2O;
  const showSecondPart = () => showFirstPart() && !!values?.fInalTestTimeAir;

  const handleAppealWarningClose = () => setOpenAppealWarning(false);

  const handleAppealWarningOk = async () => {
    setOpenAppealWarning(false);
    if (sectionApprovalComments) {
      setSectionApprovalComments(
        sectionApprovalComments.map((item) => {
          if (item.id === currentCommentId) {
            const currentDate = new Date();
            return {
              ...item,
              appealComment: appealMessage || "",
              appealDate: currentDate.toString(),
              appealedBy: `${user?.userName}`,
            };
          }
          return item;
        }) as IStudyApprovalProcessStatusComments[]
      );
      setAppealMessage("");
    }
  };

  const handleFinalRejectWarningClose = () => setOpenFinalRejectdWarning(false);

  const handleFinalRejectWarningOk = async () => {
    setOpenFinalRejectdWarning(false);
    setAllSectionApprovalStatus("finalreject");
  };

  const handleAppealCommentChange = (e: any) => {
    setAppealMessage(e.target.value);
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReportPurchaseData = async (reportId: number) => {
    try {
      const res = await projectReportService.getProjectReportPurchase(reportId);
      setProjectReportPurchase(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#0288d1", // Change to your desired color
    color: "white",
    borderRadius: 5,
  };

  const leftStyle = {
    fontWeight: "bold",
    fontSize: "18px",
  };

  const rightStyle = {
    fontSize: "16px",
  };

  const showSummary = useMemo(
    () =>
      //true,
      values?.isComplete &&
      (infectiousComplianceControl?.projectPurchase?.paymentStatus === "Paid" ||
        (!project?.isInfectiousControlProject &&
          projectReportPurchase?.paymentStatus === "Paid")),
    [
      project?.isInfectiousControlProject,
      infectiousComplianceControl?.projectPurchase,
      infectiousComplianceControl?.projectPurchase?.paymentStatus,
      projectReportPurchase?.paymentStatus,
      values?.isComplete,
    ]
  );

  const showRetest = useMemo(
    () =>
      //true,
      values?.isComplete &&
      _AUDITOR_ROLES.includes(user?.role) &&
      ((infectiousComplianceControl?.projectPurchase?.paymentStatus ===
        "Paid" &&
        [3, 9].includes(infectiousComplianceControl?.studyStatusId)) ||
        (!project?.isInfectiousControlProject &&
          projectReportPurchase?.paymentStatus === "Paid")),
    [
      project?.isInfectiousControlProject,
      infectiousComplianceControl?.projectPurchase,
      infectiousComplianceControl?.projectPurchase?.paymentStatus,
      infectiousComplianceControl?.studyStatusId,
      projectReportPurchase?.paymentStatus,
      values?.isComplete,
      user,
    ]
  );

  const showSendToAMP = useMemo(
    () =>
      //true,
      values?.isComplete &&
      infectiousComplianceControl?.projectPurchase?.paymentStatus === "Paid" &&
      infectiousComplianceControl?.studyStatusId === 3,
    [
      infectiousComplianceControl?.projectPurchase,
      infectiousComplianceControl?.projectPurchase?.paymentStatus,
      infectiousComplianceControl?.studyStatusId,
      values?.isComplete,
    ]
  );

  const airSummaryText = useMemo(() => {
    if (values?.systemIsMA)
      return `System is at ${(values?.oaPercentResult || 0).toLocaleString(
        "en-US",
        {
          useGrouping: true,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}% O/A and ${(values?.raPercentResult || 0).toLocaleString("en-US", {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}% RA`;
    else if (values?.systemIs100OA) return "System is 100% OA";
    return "System is 100% RA";
  }, [values?.systemIs100OA, values?.systemIs100RA, values?.systemIsMA]);

  const isInfectiousControl = useMemo(
    () => project?.isInfectiousControlProject || false,
    [project?.isInfectiousControlProject]
  );

  const labelColor = (
    value: number,
    lowerRange = 97.5,
    higherRange = 102.5
  ) => {
    if (value < lowerRange || value > higherRange) return "red";
    if (value > 0) return "green";
    return "inherit";
  };

  const summaryDataBlock = (
    title: string | ReactNode,
    attribute: keyof IEnergyStudyAirHydronicsDTO | undefined,
    validateShowSummary?: boolean,
    colorAttr?: keyof IEnergyStudyAirHydronicsDTO | undefined,
    helperText?: string,
    factor = 1,
    colorValidatorLower = 97.5,
    colorValidatorHigher = 102.5,
    customContent?: string,
    customColor?: string,
    customContentAlignment?: TextAlign | undefined
  ) => {
    if (values) {
      if (showSummary || !validateShowSummary) {
        const value = !!attribute
          ? ((values[attribute] as number) || 0) * factor
          : 0;
        return (
          <>
            <Grid item xs={6} style={{ paddingLeft: 40 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                {title}
              </Typography>
            </Grid>
            {!!customContent ? (
              <Grid
                item
                xs={6}
                style={{ textAlign: customContentAlignment || "right" }}
              >
                <Typography
                  style={{
                    color: !!customColor
                      ? customColor
                      : !!colorAttr
                      ? labelColor(
                          (values[colorAttr] as unknown as number) || 0,
                          colorValidatorLower,
                          colorValidatorHigher
                        )
                      : "inherit",
                  }}
                >
                  {customContent}
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                style={{
                  textAlign: customContentAlignment || "right",
                  paddingRight: 200,
                }}
              >
                <Typography
                  style={{
                    color: !!customColor
                      ? customColor
                      : !!colorAttr
                      ? labelColor(
                          (values[colorAttr] as unknown as number) || 0,
                          colorValidatorLower,
                          colorValidatorHigher
                        )
                      : "inherit",
                  }}
                >
                  {value.toLocaleString("en-US", {
                    useGrouping: true,
                    minimumFractionDigits: 3,
                  })}
                </Typography>
              </Grid>
            )}
            {!!helperText && (
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: 40,
                  paddingRight: 200,
                }}
              >
                <Typography
                  style={{
                    color: "red",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  {helperText}
                </Typography>
              </Grid>
            )}
          </>
        );
      }
      return (
        <>
          <Grid item xs={6} style={{ paddingLeft: 40 }}>
            <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Chip
              color="default"
              label="Data will be available when payment is received"
            />
          </Grid>
        </>
      );
    }
  };

  const designGPM = useMemo(() => {
    if (values?.testMode === "Cooling") return values?.coolingCoilGPM;
    return values?.heatingCoilGPM;
  }, [values?.testMode]);

  const insertApprovalComment = (section: string, decision: string) => {
    if (decision === "finalreject") {
      setOpenFinalRejectdWarning(true);
      return;
    }
    if (energyApprovalComments) {
      const [comment] = energyApprovalComments?.filter(
        (x) => x.name === section && x.type === decision
      );
      if (comment) {
        const currentDate = new Date();
        if (sectionApprovalComments) {
          const deactivatedPrevious = sectionApprovalComments.map((item) => {
            if (item.section === section) return { ...item, active: false };
            return item;
          });
          setSectionApprovalComments([
            ...deactivatedPrevious,
            {
              section,
              comment: comment.description || "",
              decision,
              date: currentDate.toString(),
              id: `${section}${currentDate.valueOf()}`,
              userId: `${user?.userId}`,
              userName: `${user?.userName}`,
              active: true,
              new: true,
            },
          ]);
        } else {
          setSectionApprovalComments([
            {
              section,
              comment: comment.description || "",
              decision,
              date: currentDate.toString(),
              id: `${section}${currentDate.valueOf()}`,
              userId: `${user?.userId}`,
              userName: `${user?.userName}`,
              active: true,
              new: true,
            },
          ]);
        }
      }
    }
  };

  const removeApprovalComment = (id: string) => {
    if (sectionApprovalComments) {
      let newSectionApprovalComments = sectionApprovalComments;
      const [itemToRemove] = sectionApprovalComments.filter(
        (item) => item.id === id
      );
      const remainingSectionComments = sectionApprovalComments.filter(
        (item) =>
          item.section === itemToRemove.section && item.id !== itemToRemove.id
      );
      if (remainingSectionComments.length > 0) {
        newSectionApprovalComments = sectionApprovalComments.map((item) => {
          if (
            item.id ===
            remainingSectionComments[remainingSectionComments.length - 1].id
          ) {
            return { ...item, active: true };
          }
          return item;
        });
      }
      setSectionApprovalComments(
        newSectionApprovalComments.filter((comment) => comment.id !== id)
      );
    }
  };

  const handleOpenAppealDecisionModal = (commentId: string) => {
    setCurrentCommentId(commentId);
    setOpenAppealWarning(true);
  };

  const renderApprovalComment = (section: string) => {
    if (sectionApprovalComments) {
      const currentSectionApprovalComments = sectionApprovalComments.filter(
        (x) => x.section === section
      );
      if (currentSectionApprovalComments.length > 0) {
        return currentSectionApprovalComments.map((comment) => (
          <>
            <Grid item xs={12} md={1}>
              {comment.decision === "approve" ? (
                <ThumbUpAltIcon color="success" />
              ) : comment.decision === "reject" ? (
                <ThumbDownAltIcon color="error" />
              ) : comment.decision === "finalreject" ? (
                <CancelIcon color="error" />
              ) : (
                <RuleIcon color="warning" />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography>{comment.comment}</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography>{comment.date}</Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              {user?.role === "SuperAdministrator" && comment.new && (
                <IconButton
                  aria-label="Remove this comment"
                  title="Remove"
                  onClick={() => removeApprovalComment(comment.id)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {user?.role !== "SuperAdministrator" &&
                comment.decision === "reject" &&
                !comment.appealDate && (
                  <IconButton
                    aria-label="Appeal this comment"
                    title="Appeal"
                    onClick={() => handleOpenAppealDecisionModal(comment.id)}
                  >
                    <GavelIcon />
                  </IconButton>
                )}
            </Grid>
            {!!comment.appealDate && (
              <>
                <Grid item xs={12} md={1}>
                  <GavelIcon />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography>{comment.appealComment}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>{comment.appealDate}</Typography>
                </Grid>
              </>
            )}
          </>
        ));
      }
    }
    return (
      <Grid item xs={12}>
        <Typography textAlign="center">
          This section doesn't have any evaluation/approval comments.
        </Typography>
      </Grid>
    );
  };

  const approvalStatus = useMemo(() => {
    if (sectionApprovalComments) {
      const activeApprovalComments = sectionApprovalComments.filter(
        (item) => item.active
      );
      if (
        activeApprovalComments.length <
        (config?.requriedApprovalSections.length || 0)
      )
        return null;
      if (
        activeApprovalComments.filter(
          (item) => item.decision === "finalreject" && !item.appealDate
        ).length > 0
      )
        return _STUDY_STATUS_FINAL_REJECTED;
      if (
        activeApprovalComments.filter(
          (item) => item.decision === "reject" && !item.appealDate
        ).length > 0
      )
        return _STUDY_STATUS_REJECTED;
      if (activeApprovalComments.filter((item) => !!item.appealDate).length > 0)
        return _STUDY_STATUS_APPEALED;
      if (
        activeApprovalComments.filter((item) => item.decision === "compliant")
          .length > 0
      )
        return _STUDY_STATUS_NOT_COMPLIANT;
      return _STUDY_STATUS_APPROVED;
    }
  }, [sectionApprovalComments]);

  const actionsDisabled = useMemo(() => {
    if (sectionApprovalComments && config) {
      return config.requriedApprovalSections.map((section) => {
        const [comment] = sectionApprovalComments.filter(
          (item) => item.section === section && !item.appealDate
        );
        if (comment) return comment.section;
        return null;
      });
    }
    return [];
  }, [sectionApprovalComments]);

  const sectionsStatus = useMemo<(SectionsStatus | null)[]>(() => {
    if (sectionApprovalComments && config) {
      return config.requriedApprovalSections.map((section) => {
        const [comment] = sectionApprovalComments.filter(
          (item) => item.section === section && item.active
        );
        if (comment)
          return {
            section,
            decision: !comment.appealDate ? comment.decision : "appeal",
          } as SectionsStatus;
        return null;
      });
    }
    return [];
  }, [sectionApprovalComments]);

  const getSectionStatus = (section: string) =>
    sectionsStatus.filter((x) => x?.section === section).at(0)?.decision;

  const setAllSectionApprovalStatus = (decision: string) => {
    if (energyApprovalComments) {
      const currentDate = new Date();
      let remainingSections = config?.requriedApprovalSections || [];
      if (sectionApprovalComments) {
        const sectionApprovalCommentsUpdated = sectionApprovalComments.map(
          (item) => {
            const [comment] = energyApprovalComments?.filter(
              (x) => x.name === item.section && x.type === decision
            );
            if (comment && item.active && !item.appealDate) {
              remainingSections = remainingSections.filter(
                (x) => x !== item.section
              );
              if (item.new)
                return {
                  ...item,
                  comment: comment.description || "",
                  decision,
                  date: currentDate.toString(),
                  userId: `${user?.userId}`,
                  userName: `${user?.userName}`,
                };
              else return item;
            }
            return { ...item, active: false };
          }
        ) as IStudyApprovalProcessStatusComments[];
        const sectionApprovalCommentsRemaining = remainingSections.map(
          (section) => {
            const [comment] = energyApprovalComments?.filter(
              (x) => x.name === section && x.type === decision
            );
            if (comment)
              return {
                section,
                id: `${section}${currentDate.valueOf()}`,
                active: true,
                new: true,
                comment: comment.description || "",
                decision,
                date: currentDate.toString(),
                userId: `${user?.userId}`,
                userName: `${user?.userName}`,
              } as IStudyApprovalProcessStatusComments;
            return null;
          }
        ) as IStudyApprovalProcessStatusComments[];

        const sectionApprovalCommentsNew = [
          ...sectionApprovalCommentsUpdated,
          ...sectionApprovalCommentsRemaining,
        ];
        if (sectionApprovalCommentsNew)
          setSectionApprovalComments(sectionApprovalCommentsNew);
      } else {
        const sectionApprovalCommentsNew = remainingSections.map((section) => {
          const [comment] = energyApprovalComments?.filter(
            (x) => x.name === section && x.type === decision
          );
          if (comment)
            return {
              section,
              id: `${section}${currentDate.valueOf()}`,
              active: true,
              comment: comment.description || "",
              decision,
              date: currentDate.toString(),
              userId: `${user?.userId}`,
              userName: `${user?.userName}`,
              new: true,
            } as IStudyApprovalProcessStatusComments;
          return null;
        }) as IStudyApprovalProcessStatusComments[];

        if (sectionApprovalCommentsNew)
          setSectionApprovalComments(sectionApprovalCommentsNew);
      }
    }
  };

  const handleMainApprovalCommentChange = (e: any) =>
    setMainApprovalComment(e.target.value);

  const showMainApprovalComments = useMemo(() => {
    if (sectionApprovalComments) {
      const mainApprovalComments = sectionApprovalComments.filter(
        (comment) => comment.section === "main"
      );
      return mainApprovalComments.length > 0;
    }
    return false;
  }, [sectionApprovalComments]);

  return (
    <>
      <Popup
        title="Appeal a Decision"
        openPopup={openAppealWarning}
        setOpenPopup={setOpenAppealWarning}
        onClose={handleAppealWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            <strong>Instructions:</strong>
          </p>
          <ol>
            <li>
              <strong>Provide a brief comment:</strong> Please enter a short,
              clear explanation of why you are appealing this decision. Your
              comment should highlight key points such as:
              <ul>
                <li>
                  Any <strong>new information</strong> that was not previously
                  considered.
                </li>
                <li>
                  <strong>Clarification</strong> of details that may have been
                  misunderstood.
                </li>
                <li>
                  <strong>Supporting evidence</strong> or context that
                  strengthens your case.
                </li>
              </ul>
            </li>

            <li>
              <strong>Be concise:</strong> Keep your comment to{" "}
              <strong>10-50 words</strong>, focusing on the most important
              details to support your appeal.
            </li>

            <li>
              <strong>Next Steps:</strong> After submitting your comment, our
              team will review your appeal and follow up with a decision within
              the next few days.
            </li>
          </ol>
          <FormTextArea
            name="appealMessage"
            label="Appeal comments"
            onChange={handleAppealCommentChange}
            value={appealMessage}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleAppealWarningClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleAppealWarningOk}
            autoFocus
            disabled={!appealMessage}
          >
            Submit
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={openFinalRejectWarning}
        setOpenPopup={setOpenFinalRejectdWarning}
        onClose={handleFinalRejectWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            This action will set the general status of this study/report and all
            pending sections awaiting approval to{" "}
            <b style={{ color: "red" }}>Final Rejected</b>. This change is
            irreversible. As a result, the user will need to either create a new
            study/report or make a copy of the original to re-test the unit.
          </p>
          <p>
            Additionally, this action will require a new payment from the user
            upon completion of the new or copied study/report.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleFinalRejectWarningOk}
            autoFocus
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleFinalRejectWarningClose}
            autoFocus
          >
            Cancel
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <h2 style={{ textAlign: "center" }}>SUMMARY OF RESULTS</h2>
              {values?.soaNeeded && showSummary && (
                <Alert severity="error">
                  During the "Energy Off" testing phase, the Mixed Air (M/A)
                  temperature was calculated and found to be lower than the
                  combined temperatures of the Outside Air (O/A) and Return Air
                  (R/A). This is typically an indication of bleed-through in the
                  chilled water coil. AMP establishes its baseline temperature
                  using the lower of the recorded O/A or R/A temperature.
                </Alert>
              )}
            </CardContent>
          </Card>
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            alignContent="flex-start"
          >
            {/**OA/RA */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="oa"
                avatarIcon={<InputIcon />}
                avatarColor={orange[400]}
                title="System OA"
                subHeader={airSummaryText}
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("oa", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() => insertApprovalComment("oa", "approve")}
                rejectAction={() => insertApprovalComment("oa", "reject")}
                compliantAction={() => insertApprovalComment("oa", "compliant")}
                disabledActions={actionsDisabled.includes("oa")}
                currentDecision={getSectionStatus("oa")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {summaryDataBlock(
                        `(Required) O/A ${systemOfMeasurement.get("cfm")}:`,
                        "outsideAirflow"
                      )}
                      {summaryDataBlock(
                        `(Actual) O/A ${systemOfMeasurement.get("cfm")}:`,
                        "oaResultantCFM",
                        true,
                        "oacfmPercentageOfDesign"
                      )}
                      {summaryDataBlock(
                        `(Percentage of design) O/A ${systemOfMeasurement.get(
                          "cfm"
                        )} %:`,
                        "oacfmPercentageOfDesign",
                        true,
                        "oacfmPercentageOfDesign"
                      )}
                      {summaryDataBlock("(%) O/A:", "oaPercentResult", true)}
                    </Grid>
                    {(values?.oacfmPercentageOfDesign || 0) < 100 &&
                      values?.recordOA &&
                      showSummary && (
                        <Alert severity="warning" style={{ marginTop: 10 }}>
                          Adjust {systemOfMeasurement.get("cfm")} Outside
                          Airiflow Up to{" "}
                          {(values?.outsideAirflow || 0).toLocaleString(
                            "en-US",
                            {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                            }
                          )}{" "}
                          to Maximize Energy Efficiency and Heat Transfer
                        </Alert>
                      )}
                    {(values?.oacfmPercentageOfDesign || 0) > 100 &&
                      values?.recordOA &&
                      showSummary && (
                        <Alert severity="warning" style={{ marginTop: 10 }}>
                          Adjust {systemOfMeasurement.get("cfm")} Outside
                          Airiflow Down to{" "}
                          {(values?.outsideAirflow || 0).toLocaleString(
                            "en-US",
                            {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                            }
                          )}{" "}
                          to Maximize Energy Efficiency and Heat Transfer
                        </Alert>
                      )}
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("oa")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="ra"
                avatarIcon={<RotateLeftIcon />}
                avatarColor={orange[400]}
                title="System RA"
                isInfectiousControl={isInfectiousControl}
                approveAction={() => insertApprovalComment("ra", "approve")}
                rejectAction={() => insertApprovalComment("ra", "reject")}
                compliantAction={() => insertApprovalComment("ra", "compliant")}
                disabledActions={actionsDisabled.includes("ra")}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("ra", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                currentDecision={getSectionStatus("ra")}
                cardContent={
                  <Grid container spacing={0}>
                    {summaryDataBlock(
                      `(Required) R/A ${systemOfMeasurement.get("cfm")}:`,
                      "returnAirflow"
                    )}
                    {summaryDataBlock(
                      `(Actual) R/A ${systemOfMeasurement.get("cfm")}:`,
                      "raResultantCFM",
                      true,
                      "racfmPercentageOfDesign"
                    )}
                    {summaryDataBlock(
                      `(Percentage of design) R/A ${systemOfMeasurement.get(
                        "cfm"
                      )} %:`,
                      "racfmPercentageOfDesign",
                      true,
                      "racfmPercentageOfDesign"
                    )}
                    {summaryDataBlock("(%) R/A:", "raPercentResult", true)}
                  </Grid>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("ra")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            {/**System CFM */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="cfm"
                avatarIcon={<AirIcon />}
                avatarColor={orange[400]}
                title={`System ${systemOfMeasurement.get("cfm")}`}
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("cfm", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() => insertApprovalComment("cfm", "approve")}
                rejectAction={() => insertApprovalComment("cfm", "reject")}
                compliantAction={() =>
                  insertApprovalComment("cfm", "compliant")
                }
                disabledActions={actionsDisabled.includes("cfm")}
                currentDecision={getSectionStatus("cfm")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {summaryDataBlock(
                        `(Required) System ${systemOfMeasurement.get("cfm")}:`,
                        "systemCFM"
                      )}
                      {summaryDataBlock(
                        `(Actual) System ${systemOfMeasurement.get("cfm")}:`,
                        "calculatedCFM",
                        true,
                        "cfmPercentageOfDesign"
                      )}
                      {summaryDataBlock(
                        `(Percentage of design) System ${systemOfMeasurement.get(
                          "cfm"
                        )} %:`,
                        "cfmPercentageOfDesign",
                        true,
                        "cfmPercentageOfDesign"
                      )}
                      {!!values?.coilFoulingFactorAir &&
                        summaryDataBlock(
                          "(Air) Coil Fouling Factor %:",
                          "coilFoulingFactorAir",
                          true,
                          undefined,
                          "Coil Fouling: Energy Heat Transfer is below design"
                        )}
                      {!!values?.energyWasteFactorAir &&
                        summaryDataBlock(
                          "(Air) Energy Waste Factor %:",
                          "energyWasteFactorAir",
                          true,
                          "energyWasteFactorAir",
                          "Energy Waste: Fluid Flow exceeds design",
                          100,
                          0,
                          0.035
                        )}
                      {!!values?.bhpReductionFactorAir &&
                        summaryDataBlock(
                          `(Air) Operating ${systemOfMeasurement.get(
                            "bhp"
                          )} can drop %:`,
                          "bhpReductionFactorAir",
                          true,
                          undefined,
                          "",
                          100
                        )}
                      {!!values?.spReductionFactorAir &&
                        summaryDataBlock(
                          "(Air) Pressure can drop %",
                          "spReductionFactorAir",
                          true,
                          undefined,
                          "",
                          100
                        )}
                    </Grid>
                    {(values?.cfmPercentageOfDesign || 0) < 100 && showSummary && (
                      <Alert severity="warning" style={{ marginTop: 10 }}>
                        Adjust {systemOfMeasurement.get("cfm")} Airiflow Up to{" "}
                        {(values?.systemCFM || 0).toLocaleString("en-US", {
                          useGrouping: true,
                          minimumFractionDigits: 2,
                        })}{" "}
                        to Maximize Energy Efficiency and Heat Transfer
                      </Alert>
                    )}
                    {(values?.cfmPercentageOfDesign || 0) > 100 && showSummary && (
                      <Alert severity="warning" style={{ marginTop: 10 }}>
                        Adjust {systemOfMeasurement.get("cfm")} Airiflow Down to{" "}
                        {(values?.systemCFM || 0).toLocaleString("en-US", {
                          useGrouping: true,
                          minimumFractionDigits: 2,
                        })}{" "}
                        to Maximize Energy Efficiency and Heat Transfer
                      </Alert>
                    )}
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("cfm")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {values?.testMode === "Cooling" && (
                <SummaryCard
                  section="cfm-rated"
                  avatarIcon={<AirIcon />}
                  avatarColor={orange[400]}
                  title={`Coil rated for a ${systemOfMeasurement.get(
                    "cfm"
                  )} range of`}
                  isInfectiousControl={false}
                  userRole={user?.role}
                  isInReview={studyApprovalStatus?.isInReview || false}
                  initExpanded={false}
                  disabledActions={actionsDisabled.includes("cfm")}
                  cardContent={
                    <>
                      <Grid container spacing={0}>
                        {summaryDataBlock(
                          `(Standard airflow @ 500 ${systemOfMeasurement.get(
                            "fpm"
                          )}) ${systemOfMeasurement.get("cfm")}:`,
                          "cfmRangeLower"
                        )}
                        {summaryDataBlock(
                          `(Maximum airflow @ 550 ${systemOfMeasurement.get(
                            "fpm"
                          )}) ${systemOfMeasurement.get("cfm")}:`,
                          "cfmRangeHigher",
                          true
                        )}
                      </Grid>
                      {(values?.calculatedCFM || 0) >
                        (values?.cfmRangeHigher || 0) && (
                        <Alert severity="error" style={{ marginTop: 10 }}>
                          Warning: Water Coil carry over and water damage
                          potential may occur beyond this{" "}
                          {systemOfMeasurement.get("cfm")}
                        </Alert>
                      )}
                    </>
                  }
                  collapsibleContent={<></>}
                />
              )}
            </Grid>
            {/**System GPM */}
            {config?.energySummarySections.gpm(
              values,
              systemOfMeasurement,
              isInfectiousControl,
              user?.role,
              studyApprovalStatus?.isInReview,
              infectiousComplianceControl?.studyStatusId,
              insertApprovalComment,
              actionsDisabled,
              getSectionStatus,
              summaryDataBlock,
              renderApprovalComment,
              showSummary,
              designGPM
            )}
            {/**Chilled Water T */}
            {config?.energySummarySections.chilledWater(
              values,
              systemOfMeasurement,
              isInfectiousControl,
              user?.role,
              studyApprovalStatus?.isInReview,
              infectiousComplianceControl?.studyStatusId,
              insertApprovalComment,
              actionsDisabled,
              getSectionStatus,
              summaryDataBlock,
              renderApprovalComment,
              showSummary,
              designGPM
            )}
            {/**System BTUH */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="btu"
                avatarIcon={<SvgBtu color="#FFFFFF" />}
                avatarColor={orange[900]}
                title={`System ${systemOfMeasurement.get("btuh")}`}
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("btu", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() => insertApprovalComment("btu", "approve")}
                rejectAction={() => insertApprovalComment("btu", "reject")}
                compliantAction={() =>
                  insertApprovalComment("btu", "compliant")
                }
                disabledActions={actionsDisabled.includes("btu")}
                currentDecision={getSectionStatus("btu")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {values?.testMode === "Cooling" &&
                        values?.referenceTotalBTUH !== "AHU" &&
                        summaryDataBlock(
                          `(Required) System Total ${systemOfMeasurement.get(
                            "btuh"
                          )}:`,
                          "totalBTUHCooling"
                        )}
                      {values?.testMode === "Cooling" &&
                        values?.referenceTotalBTUH === "AHU" &&
                        summaryDataBlock(
                          `(Required) System Total ${systemOfMeasurement.get(
                            "btuh"
                          )}:`,
                          "sytemTotalBTUH"
                        )}
                      {values?.testMode === "Heating" &&
                        values?.referenceTotalBTUH !== "AHU" &&
                        summaryDataBlock(
                          `(Required) System Total ${systemOfMeasurement.get(
                            "btuh"
                          )}:`,
                          "totalBTUHHeating"
                        )}
                      {values?.testMode === "Heating" &&
                        values?.referenceTotalBTUH === "AHU" &&
                        summaryDataBlock(
                          `(Required) System Total ${systemOfMeasurement.get(
                            "btuh"
                          )}:`,
                          "heatingSytemTotalBTUH"
                        )}
                      {summaryDataBlock(
                        `(Actual) System Total ${systemOfMeasurement.get(
                          "btuh"
                        )}:`,
                        "systemBTUHActual",
                        true,
                        "systemBTUHPercentageOfDesign"
                      )}
                      {summaryDataBlock(
                        `(Percentage of design) ${systemOfMeasurement.get(
                          "btuh"
                        )} %:`,
                        "systemBTUHPercentageOfDesign",
                        true,
                        "systemBTUHPercentageOfDesign"
                      )}
                      {values?.motorAirstream &&
                        summaryDataBlock(
                          `(Actual) Motor Heat Added to Air ${systemOfMeasurement.get(
                            "btuh"
                          )}:`,
                          "motorHeatAddedToAir",
                          true
                        )}
                      {values?.motorAirstream &&
                        values?.testMode === "Cooling" &&
                        summaryDataBlock(
                          `(Actual) ${systemOfMeasurement.get(
                            "btuh"
                          )} Cooling Summer Deficit Tons:`,
                          "toonsCoolingSummerDeficit",
                          true
                        )}
                    </Grid>
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("btu")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            {/**Coil Delta T Air DB Temperature */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="coilDelta"
                avatarIcon={<SvgCoil color="#FFFFFF" />}
                avatarColor={orange[800]}
                title={`Coil Delta T Air DB Temperature ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                subHeader={`NOTE: ${
                  values?.motorAirstream ? "HOC and Motor Heat" : "HOC"
                } considered for calculation`}
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("coilDelta", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() =>
                  insertApprovalComment("coilDelta", "approve")
                }
                rejectAction={() =>
                  insertApprovalComment("coilDelta", "reject")
                }
                compliantAction={() =>
                  insertApprovalComment("coilDelta", "compliant")
                }
                disabledActions={actionsDisabled.includes("coilDelta")}
                currentDecision={getSectionStatus("coilDelta")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {values?.testMode === "Cooling" &&
                        summaryDataBlock(
                          `(Required) ΔT Air DB ${systemOfMeasurement.get(
                            "temperature"
                          )}:`,
                          "deltaAirTemperatureDBCooling"
                        )}
                      {values?.testMode === "Heating" &&
                        summaryDataBlock(
                          `(Required) ΔT Air DB ${systemOfMeasurement.get(
                            "temperature"
                          )}:`,
                          "deltaAirTemperatureDBHeating"
                        )}
                      {summaryDataBlock(
                        `(Actual) System ${systemOfMeasurement.get("cfm")}:`,
                        "atfdb",
                        true,
                        "atfdbPercentageOfDesign"
                      )}
                      {summaryDataBlock(
                        `(Percentage of design) ΔT Air DB ${systemOfMeasurement.get(
                          "temperature"
                        )} %:`,
                        "atfdbPercentageOfDesign",
                        true,
                        "atfdbPercentageOfDesign"
                      )}
                    </Grid>
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("coilDelta")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="coilDelta-warnings"
                avatarIcon={<SvgCoil color="#FFFFFF" />}
                avatarColor={orange[800]}
                title={`Air Upstream/Downstream DB Temperature ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                isInfectiousControl={false}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                initExpanded={false}
                disabledActions={actionsDisabled.includes("coilDelta")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {summaryDataBlock(
                        "Air Upstream Coil DB :",
                        "upStreamCoilAirTemperatureDryBulb",
                        true
                      )}
                      {summaryDataBlock(
                        "Air Downstream Coil DB",
                        "downStreamCoilAirTemperatureDryBulb",
                        true
                      )}
                    </Grid>
                    {(values?.calculatedCFM || 0) >
                      (values?.cfmRangeHigher || 0) &&
                      values?.testMode === "Cooling" && (
                        <Alert severity="error" style={{ marginTop: 10 }}>
                          Warning: Water Coil Carryover May Occur
                        </Alert>
                      )}
                  </>
                }
                collapsibleContent={<></>}
              />
            </Grid>
            {/**Upstream Coil Entering Enthalpy */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="entalphy"
                avatarIcon={
                  <Typography fontWeight="bold" fontSize="large">
                    h
                  </Typography>
                }
                avatarColor={orange["A100"]}
                title="Upstream Coil Entering Enthalpy"
                isInfectiousControl={false}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                initExpanded={false}
                disabledActions={actionsDisabled.includes("entalphy")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {summaryDataBlock(
                        "(Required) Enthalpy / h:",
                        "oaDesignEnthlpy"
                      )}
                      {summaryDataBlock(
                        "(Actual) Upstream Entering Coil Air Enthalpy / h:",
                        "maEnthalpyEnergyOff",
                        true,
                        "enthalpyPercentageOfDesign"
                      )}
                      {summaryDataBlock(
                        "(Percentage of design) Enthalpy / h %:",
                        "enthalpyPercentageOfDesign",
                        true,
                        "enthalpyPercentageOfDesign"
                      )}
                    </Grid>
                    {(values?.enthalpyPercentageOfDesign || 0) < 100 &&
                      showSummary && (
                        <Alert severity="warning" style={{ marginTop: 10 }}>
                          <span style={{ fontWeight: "bolder", color: "blue" }}>
                            Note:
                          </span>{" "}
                          At time of current testing the actual upstream caloric
                          Enthalpy value was at{" "}
                          <span style={{ color: "red" }}>
                            {(
                              values?.enthalpyPercentageOfDesign || 0
                            ).toLocaleString("en-US", {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>{" "}
                          of the systems designed capacity of Heat Transfer.
                          During the test the total caloric Enthalpy value was{" "}
                          <span style={{ color: "red" }}>
                            {(
                              100 - (values?.enthalpyPercentageOfDesign || 0)
                            ).toLocaleString("en-US", {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>{" "}
                          <span style={{ fontWeight: "bold", color: "blue" }}>
                            below
                          </span>{" "}
                          the full design Heat Transfer Challenge for this coil.
                          Expect the other test results to be not greater than{" "}
                          {(
                            values?.enthalpyPercentageOfDesign || 0
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          % of design heat transfer values.
                        </Alert>
                      )}
                    {(values?.enthalpyPercentageOfDesign || 0) > 100 &&
                      showSummary && (
                        <Alert severity="warning" style={{ marginTop: 10 }}>
                          <span style={{ fontWeight: "bolder", color: "blue" }}>
                            Note:
                          </span>{" "}
                          At time of current testing the actual upstream caloric
                          Enthalpy value was at{" "}
                          <span style={{ color: "red" }}>
                            {(
                              values?.enthalpyPercentageOfDesign || 0
                            ).toLocaleString("en-US", {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>{" "}
                          of the systems designed capacity of Heat Transfer.
                          During the test the total caloric Enthalpy value was{" "}
                          <span style={{ color: "red" }}>
                            {(
                              (100 -
                                (values?.enthalpyPercentageOfDesign || 0)) *
                              -1
                            ).toLocaleString("en-US", {
                              useGrouping: true,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>{" "}
                          <span style={{ fontWeight: "bold", color: "blue" }}>
                            above
                          </span>{" "}
                          the full design Heat Transfer Challenge for this coil.
                          Expect the other test results to be approximately @
                          100% or greater than the design heat transfer values.
                        </Alert>
                      )}
                  </>
                }
                collapsibleContent={<></>}
              />
            </Grid>
            {/**System Coil Static Pressure */}
            <Grid item xs={12} md={6}>
              <SummaryCard
                section="coilStatic"
                avatarIcon={<OpenWithIcon fontSize="large" />}
                avatarColor={green[500]}
                title="System Coil Static Pressure"
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("coilStatic", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() =>
                  insertApprovalComment("coilStatic", "approve")
                }
                rejectAction={() =>
                  insertApprovalComment("coilStatic", "reject")
                }
                compliantAction={() =>
                  insertApprovalComment("coilStatic", "compliant")
                }
                disabledActions={actionsDisabled.includes("coilStatic")}
                currentDecision={getSectionStatus("coilStatic")}
                cardContent={
                  <>
                    <Grid container spacing={0}>
                      {summaryDataBlock(
                        "(Required) Coil Static Pressure Factor:",
                        "acutalTestedCoilStaticPressureFactor",
                        true
                      )}
                      {summaryDataBlock(
                        <>
                          {config?.tooltips.actualTestedCoilSP}
                          <span>(Actual) Coil Static Pressure:</span>
                        </>,
                        "actualTestedCoilSP",
                        true,
                        "actualTestedCoilSP",
                        "",
                        1,
                        0,
                        values?.acutalTestedCoilStaticPressureFactor || 0
                      )}
                      {summaryDataBlock(
                        "Fluid flow (air) loss % based on Actual Coil Pressure Drop:",
                        "spAcrossCoil",
                        true,
                        "spAcrossCoil",
                        "",
                        100,
                        0.05,
                        0.125
                      )}
                      {summaryDataBlock(
                        <>
                          {config?.tooltips.spThroughCoil}
                          <span>
                            Fluid flow (water) loss % based on Actual Pressure
                            Drop:
                          </span>
                        </>,
                        "spThroughCoil",
                        true,
                        "spThroughCoil",
                        "",
                        100,
                        0.05,
                        0.125
                      )}
                    </Grid>
                    {(values?.actualTestedCoilSP || 0) >=
                      (values?.acutalTestedCoilStaticPressureFactor || 0) &&
                      values?.recordOA &&
                      showSummary && (
                        <Alert severity="warning" style={{ marginTop: 10 }}>
                          WARNING: This coil has potential surface blockage, we
                          recommend you to identifying if this coil needs
                          cleaning.
                        </Alert>
                      )}
                    {(values?.actualTestedCoilSP || 0) <
                      (values?.acutalTestedCoilStaticPressureFactor || 0) &&
                      values?.recordOA &&
                      showSummary && (
                        <Alert severity="info" style={{ marginTop: 10 }}>
                          This coil appears to be clean on the surface
                        </Alert>
                      )}
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("coilStatic")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            {/**Filters Schedule */}
            <Grid item xs={12}>
              <SummaryCard
                section="filters"
                avatarIcon={<BlurLinearIcon />}
                avatarColor={orange["A700"]}
                title="System Filters Schedule"
                isInfectiousControl={false}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                initExpanded={false}
                disabledActions={actionsDisabled.includes("filters")}
                cardContent={
                  <>
                    <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
                      <Table stickyHeader aria-label="static components">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ minWidth: 300 }} />
                            <TableCell style={{ textAlign: "center" }}>
                              Expect Energy Consumption (Chillers, Boilers, HVAC
                              Fans, Pumps, etc.) to Increase Energy Consumption
                              by
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", minWidth: 600 }}
                            >
                              Expect Heat Transfer and Efficiency to drop
                            </TableCell>
                            {values?.isComplete &&
                              filters?.map((row, index, staticIdents) => (
                                <TableCell
                                  key={`filters-schedule-header-${row.id}`}
                                  style={{ textAlign: "center" }}
                                >
                                  {row.upStaticRow?.component} Filter - Change @
                                  indicated SP {systemOfMeasurement.get("inwc")}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <strong>Filters Changed @ 5% Loaded:</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>105.26%</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>5%</strong>
                            </TableCell>
                            {values?.isComplete &&
                              filters?.map((row, index, staticIdents) => (
                                <TableCell
                                  key={`filters-schedule-header-${row.id}`}
                                  style={{ textAlign: "center" }}
                                >
                                  <Chip
                                    color="success"
                                    label={(
                                      row.filterChangeScheduleSP5 || 0
                                    ).toLocaleString("en-US", {
                                      useGrouping: true,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  />
                                </TableCell>
                              ))}
                          </TableRow>
                          <TableRow
                            style={{
                              backgroundColor: isDarkTheme
                                ? "#243549"
                                : "#f3f3f3",
                            }}
                          >
                            <TableCell
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <strong>Filters Changed @ 10% Loaded:</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>111.11%</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>10%</strong>
                            </TableCell>
                            {values?.isComplete &&
                              filters?.map((row, index, staticIdents) => (
                                <TableCell
                                  key={`filters-schedule-header-${row.id}`}
                                  style={{ textAlign: "center" }}
                                >
                                  <Chip
                                    color="info"
                                    label={(
                                      row.filterChangeScheduleSP10 || 0
                                    ).toLocaleString("en-US", {
                                      useGrouping: true,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  />
                                </TableCell>
                              ))}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <strong>Filters Changed @ 15% Loaded:</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>117.65%</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>15%</strong>
                            </TableCell>
                            {values?.isComplete &&
                              filters?.map((row, index, staticIdents) => (
                                <TableCell
                                  key={`filters-schedule-header-${row.id}`}
                                  style={{ textAlign: "center" }}
                                >
                                  <Chip
                                    color="warning"
                                    label={(
                                      row.filterChangeScheduleSP15 || 0
                                    ).toLocaleString("en-US", {
                                      useGrouping: true,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  />
                                </TableCell>
                              ))}
                          </TableRow>
                          <TableRow
                            style={{
                              backgroundColor: isDarkTheme
                                ? "#243549"
                                : "#f3f3f3",
                            }}
                          >
                            <TableCell
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <strong>Filters Changed @ 20% Loaded:</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>125.00%</strong>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong style={{ color: "red" }}>20%</strong>
                            </TableCell>
                            {values?.isComplete &&
                              filters?.map((row, index, staticIdents) => (
                                <TableCell
                                  key={`filters-schedule-header-${row.id}`}
                                  style={{ textAlign: "center" }}
                                >
                                  <Chip
                                    color="error"
                                    label={(
                                      row.filterChangeScheduleSP20 || 0
                                    ).toLocaleString("en-US", {
                                      useGrouping: true,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  />
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                }
                collapsibleContent={<></>}
              />
            </Grid>
            {/**Building Pressure */}
            <Grid item xs={12}>
              <SummaryCard
                section="building"
                avatarIcon={<BusinessIcon />}
                avatarColor={green[800]}
                title="Building Pressure"
                isInfectiousControl={isInfectiousControl}
                userRole={user?.role}
                isInReview={studyApprovalStatus?.isInReview || false}
                showFinalReject={
                  infectiousComplianceControl?.studyStatusId ===
                  _STUDY_STATUS_APPEALED
                }
                finalRejectAction={() =>
                  insertApprovalComment("building", "finalreject")
                }
                initExpanded={[6, 7, 8, 9, 10, 11].includes(
                  infectiousComplianceControl?.studyStatusId || 0
                )}
                approveAction={() =>
                  insertApprovalComment("building", "approve")
                }
                rejectAction={() => insertApprovalComment("building", "reject")}
                compliantAction={() =>
                  insertApprovalComment("building", "compliant")
                }
                disabledActions={actionsDisabled.includes("building")}
                currentDecision={getSectionStatus("building")}
                cardContent={
                  <>
                    <Grid container spacing={3}>
                      {project?.buildingPressurizationId === 1 &&
                        summaryDataBlock(
                          "(Design) Positive Building Pressure Minimum:",
                          undefined,
                          true,
                          undefined,
                          "",
                          1,
                          97.5,
                          102.5,
                          '+ 0.02" WC to + 0.05" WC',
                          "darkgreen",
                          "left"
                        )}
                      {project?.buildingPressurizationId === 2 &&
                        summaryDataBlock(
                          "(Design) Negative Building Pressure Minimum:",
                          undefined,
                          true,
                          undefined,
                          "",
                          1,
                          97.5,
                          102.5,
                          '- 0.02" WC to - 0.05" WC',
                          "red",
                          "left"
                        )}
                      {project?.buildingPressurizationId !== 1 &&
                        project?.buildingPressurizationId !== 2 &&
                        summaryDataBlock(
                          "(Design) Combination Building Pressure Minimum:",
                          undefined,
                          true,
                          undefined,
                          "",
                          1,
                          97.5,
                          102.5,
                          '+- 0.02" WC to +- 0.05" WC',
                          "darkorange",
                          "left"
                        )}
                    </Grid>
                    {headSheetActualValues?.id && spacePressurizationValues && (
                      <>
                        <h4 style={{ marginLeft: 40 }}>
                          (Actual) Building Pressure Data
                        </h4>
                        <SpacePressurization
                          headSheetActualId={headSheetActualValues?.id}
                          disableStudy={disableStudy()}
                          isLoading={isLoading}
                          isLoadingSpacePressurization={
                            isLoadingSpacePressurization
                          }
                          systemOfMeasurement={systemOfMeasurement}
                          spacePressurizationValues={spacePressurizationValues}
                          setSpacePressurizationValues={
                            setSpacePressurizationValues
                          }
                          readOnly
                        />
                      </>
                    )}
                  </>
                }
                collapsibleContent={
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12} paddingBottom={5}>
                        <Typography
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="medium"
                        >
                          Approval comments made by AMP's Super Administrator
                        </Typography>
                      </Grid>
                      {renderApprovalComment("building")}
                    </Grid>
                  </>
                }
              />
            </Grid>
            {/**AMP Factor */}
            <Grid item xs={12}>
              <header style={headerStyle}>
                <div style={leftStyle}>AMP Factor</div>
              </header>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {summaryDataBlock(
                  "This systems specific AMP Outside Air (O/A) Factor:",
                  "ampFactorSteadyOff",
                  true,
                  undefined,
                  "",
                  1,
                  97.5,
                  102.5,
                  undefined,
                  green[500],
                  "left"
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {showSummary && (
                <Alert severity="warning">
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    WARNING:
                  </span>{" "}
                  We recommend setting up your O/A prior to making any
                  prescribed changes or finalizing this system. This factor
                  provided is a precision factor specific to this systems
                  current operation.
                  <span style={{ fontWeight: "bold" }}>
                    Your AMP factor can be used at different O/A and Return Air
                    (R/A) temperatures prior to changing any motor speeds or
                    Motor Horse Power/
                    {systemOfMeasurement.get("bhp")}.
                  </span>{" "}
                  Go to your AMP built in calculator tab "E=mc2" and click on "%
                  O/A {systemOfMeasurement.get("temperature")}" and enter your
                  AMP system specific O/A Factor.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 20 }} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              {project && dataReport && values && (
                <CompleteStudyPopup
                  isDialogOpen={isDialogOpen}
                  setIsDialogOpen={setIsDialogOpen}
                  project={project}
                  projectReport={dataReport}
                  completed={values?.isComplete}
                  infectiousComplianceControl={infectiousComplianceControl}
                  parentStudy={parentStudy ?? null}
                  hideReopenStudyButton
                />
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Stack
                direction="row"
                spacing={3}
                justifyContent="center"
                alignItems="center"
                pt={5}
              >
                {showRetest && (
                  <RetestReport
                    reportId={id}
                    projectId={values?.projectId}
                    reportTypeId={values?.reportTypeId || 18}
                    isInfectiousControlProject={
                      project?.isInfectiousControlProject || false
                    }
                  />
                )}
                {showSendToAMP && (
                  <SendToAmp
                    reportId={id}
                    projectId={values?.projectId}
                    reportTypeId={values?.reportTypeId || 18}
                    isInfectiousControlProject={
                      project?.isInfectiousControlProject || false
                    }
                    executeReport={executeReport}
                    code={code || ""}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          {[6, 7, 8, 9, 10, 11].includes(
            infectiousComplianceControl?.studyStatusId || 0
          ) && (
            <Grid container spacing={3}>
              <Grid item xs={12} marginTop={5}>
                <header style={headerStyle}>
                  <div style={leftStyle}>
                    <Typography fontSize="large" fontWeight="bold">
                      <SvgAmp color="#FFFFFF" /> Energy Approval Control
                    </Typography>
                  </div>
                  <div style={rightStyle}>
                    <Typography fontSize="medium" fontWeight="bold">
                      Current Status:{" "}
                      {infectiousComplianceControl?.studyStatus?.name || "N/A"}
                      {user?.role === "SuperAdministrator" ||
                      (infectiousComplianceControl?.studyStatusId === 9 &&
                        infectiousComplianceControl.studyStatusId !==
                          approvalStatus)
                        ? ` | New Status: ${
                            approvalStatus
                              ? _STUDY_STATUS_ENUM[approvalStatus]
                              : "N/A"
                          }`
                        : ""}
                    </Typography>
                  </div>
                </header>
              </Grid>
              {infectiousComplianceControl?.studyStatusId === 6 &&
                user?.role !== "SuperAdministrator" &&
                studyApprovalStatus?.isInReview && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      An AMP professional will review your energy report and
                      send an evaluation shortly.
                      <br />
                      Please wait until the review is complete.
                    </Alert>
                  </Grid>
                )}
              {(infectiousComplianceControl?.studyStatusId === 6 ||
                infectiousComplianceControl?.studyStatusId === 10) &&
                user?.role === "SuperAdministrator" &&
                approvalStatus !== _STUDY_STATUS_FINAL_REJECTED && (
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                      pt={5}
                    >
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        onClick={() => setAllSectionApprovalStatus("approve")}
                        autoFocus
                        startIcon={<ThumbUpAltIcon />}
                      >
                        Approve All Sections
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => setAllSectionApprovalStatus("reject")}
                        autoFocus
                        startIcon={<ThumbDownAltIcon />}
                      >
                        Reject All Sections
                      </Button>
                      <Button
                        variant="outlined"
                        color="warning"
                        size="small"
                        onClick={() => setAllSectionApprovalStatus("compliant")}
                        autoFocus
                        startIcon={<ThumbUpAltIcon />}
                      >
                        Make all Sections Approved but not Compliant
                      </Button>
                    </Stack>
                  </Grid>
                )}
              {infectiousComplianceControl?.studyStatusId !== approvalStatus &&
                approvalStatus && (
                  <Grid item xs={12}>
                    <Card mb={6}>
                      <CardContent>
                        <FormTextArea
                          name="mainApprovalComment"
                          label="Main Approval Comments"
                          value={mainApprovalComment}
                          onChange={handleMainApprovalCommentChange}
                          placeholder="Please type your main comments related to this specific revision/evaluation"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                  pt={5}
                >
                  {((user?.role === "SuperAdministrator" &&
                    [6, 10].includes(
                      infectiousComplianceControl?.studyStatusId || 0
                    )) ||
                    [9].includes(
                      infectiousComplianceControl?.studyStatusId || 0
                    )) && (
                    <SaveEvaluation
                      reportId={id}
                      projectId={values?.projectId}
                      reportTypeId={values?.reportTypeId || 18}
                      isInfectiousControlProject={
                        project?.isInfectiousControlProject || false
                      }
                      statusId={
                        approvalStatus ||
                        infectiousComplianceControl?.studyStatusId ||
                        0
                      }
                      sectionApprovalComments={sectionApprovalComments || []}
                      projectReportId={dataReport?.id || 0}
                      userRole={user?.role}
                      mainComments={mainApprovalComment}
                      user={user}
                      disabled={
                        infectiousComplianceControl?.studyStatusId ===
                          approvalStatus || !mainApprovalComment
                      }
                      getProjectReportData={getProjectReportData}
                    />
                  )}
                </Stack>
              </Grid>
              {showMainApprovalComments && (
                <>
                  <Grid item xs={12}>
                    <Card mb={6}>
                      <CardContent>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: "center" }}>
                              Main Approval Comments History
                            </h3>
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer
                              sx={{ maxHeight: 480 }}
                              component={Paper}
                            >
                              <Table
                                stickyHeader
                                aria-label="static components"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        textAlign: "center",
                                        minWidth: 200,
                                      }}
                                    >
                                      User
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      Comment
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                      Comment Date
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sectionApprovalComments
                                    ?.filter(
                                      (comment) => comment.section === "main"
                                    )
                                    .map((mainComment, index) => (
                                      <TableRow
                                        style={{
                                          backgroundColor:
                                            index % 2 > 0
                                              ? isDarkTheme
                                                ? "#243549"
                                                : "#f3f3f3"
                                              : "inherit",
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          {mainComment.userName !==
                                          user?.userName ? (
                                            <Avatar
                                              sx={{
                                                bgcolor: "GrayText",
                                                marginInline: "auto",
                                              }}
                                              aria-label={`aria-label-${mainComment.id}`}
                                            >
                                              {mainComment.userName
                                                .substring(0, 2)
                                                .toUpperCase()}
                                            </Avatar>
                                          ) : (
                                            <Avatar
                                              sx={{
                                                bgcolor: "green",
                                                marginInline: "auto",
                                              }}
                                              aria-label={`aria-label-${mainComment.id}`}
                                            >
                                              Me
                                            </Avatar>
                                          )}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography>
                                            {mainComment.comment}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <Typography>
                                            {mainComment.date}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          )}
          <FooterButtons
            activeStep={activeStep}
            stepsCount={steps.length}
            projectId={values?.projectId as number}
            companyId={user?.companyId as number}
            isSaving={false}
            disabled={false}
            reportName={title as string}
            previousStepHandler={previousStepHandler}
            nextStepHandler={nextStepHandler}
            disableNext={true || !showSecondPart()}
            saveHandler={saveHandler}
            isComplete={values?.isComplete}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyStep7;
