import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton, FormText } from "src/components/formControls";
import { useAsyncQuery } from "src/hooks";
import { CountryService, StateService, buildingService } from "src/services";
import {
  IBuilding,
  IBuildingSelected,
  ICountry,
  IState,
} from "src/ts/interfaces";
import { useEffect, useState } from "react";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import SelectBuildingPopup from "../../SelectBuildingPopup";

interface Props {
  buildingId?: number;
  isRead: boolean;
  companyId: number;
  values: any;
  setValues: (val: any) => void;
  errors: Record<string, string>;
  showGotoBuildingButton: boolean;
  onSelect: (buildingId: IBuildingSelected) => void;
  projectId?: string;
}

const BuildingSelect = ({
  buildingId,
  isRead,
  companyId,
  values,
  setValues,
  errors,
  showGotoBuildingButton,
  onSelect,
  projectId,
}: Props) => {
  const navigate = useNavigate();
  const [selectBuildingShow, setSelectBuildingShow] = useState(false);
  const {
    execute: getBuilding,
    data: building,
    isLoading,
  } = useAsyncQuery<IBuilding>(buildingService.getById);
  const { execute: getCountry, data: country } = useAsyncQuery<ICountry>(
    CountryService.getById
  );
  const { execute: getState, data: state } = useAsyncQuery<IState>(
    StateService.getById
  );

  useEffect(() => {
    const getData = async () => {
      if (building.countryId) await getCountry(building.countryId);
      if (building.stateId) await getState(building.stateId);
    };
    if (building) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  useEffect(() => {
    const getData = async () => {
      getBuilding(buildingId);
    };
    if (buildingId && buildingId > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId]);

  const selectBuidingHandler = async (selectedBuilding: IBuildingSelected) => {
    await getBuilding(selectedBuilding.id);
    setValues({
      ...values,
      buildingId: selectedBuilding.id,
      measurementSystem: selectedBuilding.measurementSystem,
    });
    onSelect(selectedBuilding);
  };

  return (
    <>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <FormText
              name="buildingId"
              label="Select Location"
              value={`${building?.name ?? ""}`}
              error={errors.buildingId}
              showSkeleton={isLoading}
              readOnlyText={true}
              mode={isRead ? "read" : "read&Write"}
            />
          </Grid>
          <Grid item md={3}>
            {!isRead && (
              <FormButton
                text="Select Building"
                onClick={() => {
                  setSelectBuildingShow(true);
                }}
                size="medium"
                variant="outlined"
                fullWidth={true}
              />
            )}
            {showGotoBuildingButton && (
              <FormButton
                text="Go to Building"
                onClick={() => {
                  if (!projectId)
                    navigate(`/app/Building/${building.id}/${companyId}`);
                  else
                    navigate(
                      `/app/Building/${building.id}/${companyId}/${projectId}`
                    );
                }}
                size="medium"
                variant="outlined"
                fullWidth={true}
              />
            )}
          </Grid>
          <Grid item md={12}>
            {building && country && (
              <>
                <FormReadOnlyField
                  label="Address"
                  value={`${building?.address}, ${building?.address2 ?? ""} ${
                    building?.address2 ? ", " : ""
                  } ${country?.name}, ${state?.name ?? building.stateName}, ${
                    building?.city
                  }, Zip: ${building?.zip}`}
                />

                <FormReadOnlyField
                  label="Building Serial #"
                  value={`${building?.serial}`}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <SelectBuildingPopup
        companyId={companyId}
        isDialogOpen={selectBuildingShow}
        setIsDialogOpen={setSelectBuildingShow}
        onSelect={selectBuidingHandler}
      />
    </>
  );
};

export default BuildingSelect;
