import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form, useLog, usePermissions } from "src/hooks";
import { FormCancelButton } from "src/components/formControls";
import useLoading from "src/hooks/useLoading";
import { UserParams } from "src/ts/types";
import { Validator } from "src/ts/types/Validator";
import FormNextButton from "src/components/formControls/FormNextButton";
import { IEnergyCost, IEnergyCostProcess } from "src/ts/interfaces/energyCost";
import BuildingEnergyCostProcess from "./BuildingEnergyCostProcess";
import energyCostService from "src/services/energyCostService";
import HeaderPage from "src/components/page/HeaderPage";
import buildingService from "src/services/buildingService";
import FormNumeric from "src/components/formControls/FormNumeric";
import { Permission as PermissionTypes } from "src/ts/enums";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IEnergyCost = {
  id: 0,
  anualCost: 0,
  coolingDegreeDay: 0,
  heatingDegreeDay: 0,
  hoursMon: 0,
  hoursTue: 0,
  hoursWed: 0,
  hoursThu: 0,
  hoursFri: 0,
  hoursSat: 0,
  hoursSun: 0,
  totalHoursWeekly: 0,
  totalHoursMonthly: 0,
  totalHoursAnnual: 0,
  buildingId: 0,
  energyCostProcessHeatingId: 0,
  energyCostProcessHeating: {
    id: 0,
    energyCostId: 0,
    processTypeId: 0,
    processType: {
      id: 0,
      name: "",
      statusId: 0,
      status: {
        id: 0,
        name: "",
      },
    },
    limitedDataId: 0,
    limitedData: false,
    annualDetailedId: 0,
    annualDetailedY1: false,
    annualDetailedY2: false,
    annualDetailedY3: false,
    annualCombinedId: 0,
    annualCombinedY1: false,
    annualCombinedY2: false,
    annualCombinedY3: false,
  },
  energyCostProcessCoolingId: 0,
  energyCostProcessCooling: {
    id: 0,
    energyCostId: 0,
    processTypeId: 0,
    processType: {
      id: 0,
      name: "",
      statusId: 0,
      status: {
        id: 0,
        name: "",
      },
    },
    limitedDataId: 0,
    limitedData: false,
    annualDetailedId: 0,
    annualDetailedY1: false,
    annualDetailedY2: false,
    annualDetailedY3: false,
    annualCombinedId: 0,
    annualCombinedY1: false,
    annualCombinedY2: false,
    annualCombinedY3: false,
  },
};

const BuildingEnergyCost = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { id } = useParams<UserParams>();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.administratorType = new Validator(fieldValues, "administratorType")
      .selectedOption(0, "The role is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectdTab, setSelectedTab] = useState(0);
  const [getCompanyId, setGetCompanyId] = useState(0);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();
  const [canEdit, setCanEdit] = useState(false);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Building);

  useEffect(() => {
    const getCost = async (buildingId: number) => {
      startRequest("user");
      const response = await energyCostService.getById(buildingId);

      setValues(response.data);
      endRequest("user");
    };

    const getBuilding = async (buildingId: number) => {
      const response = await buildingService.getById(buildingId);
      setGetCompanyId(response.data.companyId ?? 0);
      setCanEdit(response.data.canEdit);
    };

    if (Number(id) > 0) {
      getCost(Number(id));
      getBuilding(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancel = () => {
    navigate(`/app/Building/${id}/${getCompanyId}`);
  };

  const handleNext = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      if (canEdit) await energyCostService.update(values.id, values);
      if (
        (selectdTab === 0 &&
          values.energyCostProcessHeating &&
          values.energyCostProcessHeating.limitedData === true) ||
        (selectdTab === 1 &&
          values.energyCostProcessCooling &&
          values.energyCostProcessCooling.limitedData === true)
      )
        navigate(
          `/app/BuildingLimitedData/${id}/${
            selectdTab === 0
              ? values.energyCostProcessHeatingId
              : values.energyCostProcessCoolingId
          }/${selectdTab === 0 ? "Heating" : "Cooling"}`
        );
      else
        navigate(
          `/app/BuildingAnnualDetailed/${id}/${
            selectdTab === 0
              ? values.energyCostProcessHeatingId
              : values.energyCostProcessCoolingId
          }/${selectdTab === 0 ? "Heating" : "Cooling"}`
        );
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeHeating = (item: IEnergyCostProcess) => {
    values.energyCostProcessHeating = item;
    setValues({ ...values });
  };

  const handleChangeCooling = (item: IEnergyCostProcess) => {
    values.energyCostProcessCooling = item;
    setValues({ ...values });
  };

  const handleCalcHours = (e: any) => {
    const { name, value } = e.target;
    if (name === "hoursMon") values.hoursMon = Number(value);
    if (name === "hoursTue") values.hoursTue = Number(value);
    if (name === "hoursWed") {
      values.hoursWed = Number(value);
    }
    if (name === "hoursThu") values.hoursThu = Number(value);
    if (name === "hoursFri") values.hoursFri = Number(value);
    if (name === "hoursSat") values.hoursSat = Number(value);
    if (name === "hoursSun") values.hoursSun = Number(value);

    const sum =
      values.hoursMon +
      values.hoursTue +
      values.hoursWed +
      values.hoursThu +
      values.hoursFri +
      values.hoursSat +
      values.hoursSun;
    values.totalHoursWeekly = sum.toFixed(2);
    values.totalHoursAnnual = (sum * 52).toFixed(2);
    values.totalHoursMonthly = ((sum * 52) / 12).toFixed(2);
    setValues({ ...values });
  };

  const steps = ["Energy configuration", "Select Units", "Enter Data"];

  const row = 6;

  return (
    <>
      <HeaderPage
        title={"Energy Cost"}
        parentText={"Buildings"}
        parentLink={`/app/Buildings/${getCompanyId}`}
        actionSection={undefined}
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid item xs={12} md={12}>
              <Stepper alternativeLabel activeStep={0}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Divider my={3}></Divider>
            <Typography variant="h5">System(s) Operating Hours</Typography>
            <Divider my={3}></Divider>
            <Grid container spacing={6}>
              <Grid item xs={row}>
                <FormNumeric
                  name={"hoursMon"}
                  label={"Monday"}
                  value={values.hoursMon}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursMon}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                ></FormNumeric>
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursTue"
                  label="Tuesday"
                  value={values.hoursTue}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursTue}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursWed"
                  label="Wednesday"
                  value={values.hoursWed}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursWed}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursThu"
                  label="Thursday"
                  value={values.hoursThu}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursThu}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursFri"
                  label="Friday"
                  value={values.hoursFri}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursFri}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={row}></Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursSat"
                  label="Saturday"
                  value={values.hoursSat}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursSat}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name="hoursSun"
                  label="Sunday"
                  value={values.hoursSun}
                  onChange={(e) => {
                    handleCalcHours(e);
                  }}
                  error={errors.hoursSun}
                  disabled={isSubmitting || !canEdit}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric
                  name="totalHoursWeekly"
                  label="Total Hours"
                  value={values.totalHoursWeekly}
                  onChange={handleInputChange}
                  error={errors.totalHoursWeekly}
                  disabled={true}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Weekly</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormNumeric
                  name="totalHoursMonthly"
                  label="Total Hours"
                  value={values.totalHoursMonthly}
                  onChange={handleInputChange}
                  error={errors.totalHoursMonthly}
                  disabled={true}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Monthly</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormNumeric
                  name="totalHoursAnnual"
                  label="Total Hours"
                  value={values.totalHoursAnnual}
                  onChange={handleInputChange}
                  error={errors.totalHoursAnnual}
                  disabled={true}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Annual</Typography>
              </Grid>
            </Grid>
            <Divider my={3}></Divider>
            <Grid item xs={12}>
              <Tabs
                value={selectdTab}
                onChange={handleChangeTab}
                aria-label="Buildings"
              >
                <Tab label="Heating" id="0" value={0} />
                <Tab label="Cooling" id="1" value={1} />
              </Tabs>
            </Grid>
            <Divider my={3}></Divider>
            {selectdTab === 0 && (
              <BuildingEnergyCostProcess
                items={values.energyCostProcessHeating}
                onChange={handleChangeHeating}
              ></BuildingEnergyCostProcess>
            )}
            {selectdTab === 1 && (
              <BuildingEnergyCostProcess
                items={values.energyCostProcessCooling}
                onChange={handleChangeCooling}
              ></BuildingEnergyCostProcess>
            )}
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                />
                {((selectdTab === 0 &&
                  values.energyCostProcessHeating &&
                  (values.energyCostProcessHeating.limitedData === true ||
                    values.energyCostProcessHeating.annualDetailedY1 === true ||
                    values.energyCostProcessHeating.annualDetailedY2 === true ||
                    values.energyCostProcessHeating.annualDetailedY3 === true ||
                    values.energyCostProcessHeating.annualCombinedY1 === true ||
                    values.energyCostProcessHeating.annualCombinedY2 === true ||
                    values.energyCostProcessHeating.annualCombinedY3 ===
                      true)) ||
                  (selectdTab === 1 &&
                    values.energyCostProcessCooling &&
                    (values.energyCostProcessCooling.limitedData === true ||
                      values.energyCostProcessCooling.annualDetailedY1 ===
                        true ||
                      values.energyCostProcessCooling.annualDetailedY2 ===
                        true ||
                      values.energyCostProcessCooling.annualDetailedY3 ===
                        true ||
                      values.energyCostProcessCooling.annualCombinedY1 ===
                        true ||
                      values.energyCostProcessCooling.annualCombinedY2 ===
                        true ||
                      values.energyCostProcessCooling.annualCombinedY3 ===
                        true))) && (
                  <FormNextButton
                    onClick={handleNext}
                    isSubmitting={isSubmitting}
                    disabled={isLoading}
                    showSkeleton={isLoading}
                  />
                )}
              </Stack>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export default BuildingEnergyCost;
