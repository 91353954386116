import React, { useState } from "react";
import { ColumnType } from "src/types/enhancedTable";
import { ICurrentCertificate } from "src/ts/interfaces";
import { Typography, Stack, Box, IconButton } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { FileDownloadIcon } from "src/components/icons";
import { userService } from "src/services";
import FileUtils from "src/utils/file";
import { useLog } from "src/hooks";
import {
  CertificationName,
  ColorStyleCertification,
  IDCertification,
} from "src/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { date } from "faker";
interface ProfileFormProp {
  certificationData: ICurrentCertificate[];
}

const Certification = (props: ProfileFormProp) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { certificationData } = props;
  let index = 0;
  const { log } = useLog();
  const downloadCertification = async (row: ICurrentCertificate) => {
    try {
      const response = await userService.getCertificationTechnicians(row.id);
      let fileName = `${row.certification}.pdf`;
      FileUtils.downloadFile(response.data, fileName);
      log.success("Certification was downloaded succesfully");
      setIsSubmitting(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getColumns = (
    downloadCertification: (row: ICurrentCertificate) => void
  ): ColumnType[] => [
    {
      id: "id#",
      label: "#",
      type: "custom",
      sort: true,
      align: "center",
      contentAlign: "center",
      callback: (row: ICurrentCertificate) => {
        index = index + 1;
        return (
          <>
            <Stack ml={7}>
              <Typography> {index}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "CertificationName",
      label: "Certification Name",
      type: "custom",
      sort: true,
      align: "center",
      callback: (row: ICurrentCertificate) => {
        return (
          <>
            <Stack sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color:
                    row.certificationId ===
                    IDCertification.TestingBalancingTechnician
                      ? ColorStyleCertification.TestingBalancingTechnician
                      : ColorStyleCertification.EnergyAuditorInfectiousBuildingControl,
                }}
              >
                {row.certificationId ===
                IDCertification.TestingBalancingTechnician
                  ? CertificationName.TestingBalancingTechnician
                  : CertificationName.EnergyAuditorInfectiousBuildingControl}
                {new Date(row.endCertificationDate) <= new Date(Date.now())
                  ? " (Deactivated)"
                  : ""}
              </Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "certification",
      label: "Certification Number",
      type: "string",
      sort: true,
      align: "center",
      contentAlign: "center",
    },
    {
      id: "endCertificationDate",
      label: "Expiration Date",
      type: "date",
      format: "MM/DD/YYYY",
      sort: true,
      align: "center",
      contentAlign: "center",
    },
    {
      id: "status",
      label: "Download",
      type: "custom",
      sort: false,
      align: "center",
      callback: (row: ICurrentCertificate) => {
        return (
          <div>
            {new Date(row.endCertificationDate) >= new Date(Date.now()) && (
              <Box mr={2}>
                <IconButton
                  aria-label="download"
                  size="large"
                  onClick={() => {
                    setIsSubmitting(true);
                    downloadCertification(row);
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <FileDownloadIcon />
                  )}
                </IconButton>
              </Box>
            )}
          </div>
        );
      },
    },
  ];
  const columns = getColumns(downloadCertification);

  return (
    <>
      <LocalEnhancedTable<ICurrentCertificate>
        columns={columns}
        data={certificationData}
        hidePagination={true}
      />
    </>
  );
};

export default Certification;
