import { IOutletReport } from "src/ts/interfaces";

export const getStudyCode = (currentUrl: string) => {
  let reportType = "";

  if (currentUrl.indexOf("cav") > 0) {
    reportType = "CAVR";
  } else if (currentUrl.indexOf("fpb") > 0) {
    reportType = "FPBR";
  } else if (currentUrl.indexOf("vav") > 0) {
    reportType = "VAVR";
  }

  return reportType;
};

export const getCode = (currentUrl: string) => {
  let reportType = "";

  if (currentUrl.indexOf("cav") > 0) {
    reportType = "cav";
  } else if (currentUrl.indexOf("fpb") > 0) {
    reportType = "fpb";
  } else if (currentUrl.indexOf("vav") > 0) {
    reportType = "vav";
  }

  return reportType;
};

export const initialValues: IOutletReport = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: null,
  systemTotal: false,
  outletTotal: false,
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  minimumOutletCFMRecorded: false,
  systemField: "",
  description: "",
  toleranceMin: null,
  toleranceMax: null,
  diffuserGrilleModel: false,
  fillNextToogle: false,
  temperatureSensibleDryBulb: false,
  totalRequiredCFM: null,
  totalSensibleHeat: null,
  heat: false,
  totalMax: null,
  totalMin: null,
  totalHeat: null,
  totalActualVelocity: null,
  totalActualCFM: null,
  humidityValue: 0,
  dryBulbF: 0,
  dryBulbFId: 0,
  bTUH: 0,
  diffuserGrillerNeckSize: false,
  isActualLocalBarometricPressure: false,
  localBarometricPressure: null,
  barometricalyCompensated: false,
  useSiteSpecificDefaultBool: false,
  useSiteSpecificDefault: null,
  localBarometricPressureActual: null,
  isComplete: false,
  testEndTime: null,
  statusId: 0,
  createdDate: "",
  createdBy: "",
  updatedDate: "",
  updatedBy: "",
  userAuth: "",
  userName: "",
  controlTypeId: -1,
  outletDuctTypeId: null,
  isProjectDisabled: false,
  controlType: null,
  selectAll: false,
};
