/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import projectReportService from "src/services/study/projectReportService";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useFormulas,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEETReportServiceModelDTO,
  IEnergyStudyAirHydronicsDTO,
  IFormulaSystemService,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetActualSpacePressurizationDTO,
  IProjectPurchase,
  IProjectReportInfectiousComplianceControl,
  IReportType,
  IStaticIdentDTO,
  IStaticReportDTO,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import projectService from "src/services/project/projectService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import staticReportService from "src/services/study/staticReportService";
import Popup from "src/components/Popup";
import { FormButton, FormCheckBox } from "src/components/formControls";
import { StaticComponentsGrid } from "../../static/step2/components/ComponentsGrid";
import headSheetService from "src/services/study/headSheetService";
import headSheetActualSpacePressurizationService from "src/services/study/headSheetActualSpacePressurizationService";
import { SpacePressurization } from "../../headsheet/step5/components/SpacePressurization";
import StudyWizard from "../../components/StudyWizard";
import RetestReport from "../components/RetestReport";
import { useConfig } from "src/contexts/EnergyConfigProvider";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const EnergyStep6 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [openHeatTransferDeviceWarning, setOpenHeatTransferDeviceWarning] =
    useState(false);
  const [openNoHeatTransferDeviceWarning, setOpenNoHeatTransferDeviceWarning] =
    useState(false);
  const [
    openNoHeatTransferDeviceSelectedWarning,
    setOpenNoHeatTransferDeviceSelectedWarning,
  ] = useState(false);
  const [proceedWithoutCoil, setProceedWithoutCoil] = useState(false);
  const [staticValues, setStaticValues] = useState<IStaticReportDTO>();
  const [headSheetActualValues, setHeadSheetActualValues] =
    useState<IHeadSheetActualDTO>();
  const [spacePressurizationValues, setSpacePressurizationValues] =
    useState<IHeadSheetActualSpacePressurizationDTO[]>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [projectReportPurchase, setProjectReportPurchase] =
    useState<IProjectPurchase | null>(null);
  const config = useConfig();
  const [gridLenght, setGridLenght] = useState(0);

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 5;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          setValues({ ...dataResult });
          if (dataResult.headSheetId)
            await executeHeadSheet(dataResult.headSheetId);
        };
        getData();
      },
    }
  );

  const { execute: executeStatic, isLoading: isLoadingStatic } =
    useAsyncQuery<IStaticReportDTO>(staticReportService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setStaticValues({ ...dataResult });
        };
        getData();
      },
    });

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectTolerancesByReportId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
          if (!values?.finalStaticReportId && !values?.isComplete) {
            const staticObj = {
              buildingId: dataResult.buildingId,
              projectId: values?.projectId,
              reportTypeId: 7,
            } as IStaticReportDTO;

            const staticReport = (await staticReportService.save(staticObj))
              .data;

            setStaticValues(staticReport);

            setValues({
              ...values,
              finalStaticReportId: staticReport.id,
            });

            await energyStudyAirHydronicsPutService.update(id, {
              ...values,
              finalStaticReportId: staticReport.id,
            });

            setOpenHeatTransferDeviceWarning(true);
          } else executeStatic(values?.finalStaticReportId);
        };
        getData();
      },
    });

  const { execute: executeParent, isLoading: isLoadingParentReport } =
    useAsyncQuery<IProjectReport>(projectReportService.GetParentReport, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setParentStudy(dataResult);
        };
        getData();
      },
    });

  const { execute: executeEndFinalTest } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endFinalTest,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              fInalTestTimeAir: dataResult,
            });
            if (staticValues) {
              await staticReportService.updateIdent(
                staticValues.id,
                staticValues.staticIdent as IStaticIdentDTO[]
              );
            }
          };
          getData();
        },
      }
    );

  const { execute: executeHeadSheet, isLoading: isLoadingHeadSheet } =
    useAsyncQuery<IEETReportServiceModelDTO>(headSheetService.getStepFour, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (dataResult.fans)
            executeFanMotor(dataResult.id, dataResult.fans[0].key);
        };
        getData();
      },
    });

  const { execute: executeFanMotor, isLoading: isLoadingFanMotor } =
    useAsyncQuery<IHeadSheetActualFormulaDTO>(headSheetService.getActuals, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const { actual } = dataResult;
          setHeadSheetActualValues(actual);
          executeSpacePressurization(actual.id);
          window.scrollTo(0, 0);
        };
        getData();
      },
    });

  const {
    execute: executeSpacePressurization,
    isLoading: isLoadingSpacePressurization,
  } = useAsyncQuery<IHeadSheetActualSpacePressurizationDTO[]>(
    headSheetActualSpacePressurizationService.getByHeadSheetActualId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setSpacePressurizationValues(dataResult);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) {
      executeReportType(values?.reportTypeId);
      executeParent(id, values?.reportTypeId);
    }
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeProject(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    const getData = async () => {
      await getInfectiousControlData(dataReport.id);
      await getProjectReportPurchaseData(dataReport.id);
    };

    if (dataReport?.id) getData();
  }, [dataReport?.id, values?.isComplete]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (!isDialogOpen) getData();
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      if (showSecondPart()) await saveSpacePressurizationHandle();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        if (staticValues) {
          await staticReportService.updateIdent(
            staticValues.id,
            staticValues.staticIdent as IStaticIdentDTO[]
          );
        }
        await energyStudyAirHydronicsPutService.update(id, values);
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveSpacePressurizationHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        spacePressurizationValues?.forEach(async (spacePressurization) => {
          await headSheetActualSpacePressurizationService.update(
            spacePressurization.id,
            spacePressurization
          );
        });
        log.success("Space pressurization data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step7/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step5/${id}`);
    } catch (error) {}
  };

  const showFirstPart = () => !!values?.finalTestTimeH2O;
  const showSecondPart = () => showFirstPart() && !!values?.fInalTestTimeAir;

  const handleHeatTransferDeviceWarningClose = () =>
    setOpenHeatTransferDeviceWarning(false);

  const atfdb = () =>
    Math.abs(
      (values?.upStreamCoilAirTemperatureDryBulb || 0) -
        (values?.downStreamCoilAirTemperatureDryBulb || 0)
    );

  const handleEndFinalTestClick = async (e: any) => {
    setProceedWithoutCoil(false);
    const reportCoils = staticValues?.staticIdent?.filter(
      (item) => item.upStaticRow?.category === "Heat Transfer Device"
    );
    const selectedCoils = reportCoils?.filter((item) => item.selectedForTest);
    if (reportCoils?.length === 0) {
      setOpenNoHeatTransferDeviceWarning(true);
      return;
    } else if (selectedCoils?.length === 0) {
      setOpenNoHeatTransferDeviceSelectedWarning(true);
      return;
    }
    await executeEndFinalTest(id, values);
  };

  const handleNoHeatTransferDeviceWarningClose = () =>
    setOpenNoHeatTransferDeviceWarning(false);

  const handleNoHeatTransferDeviceWarningOk = async () => {
    setOpenNoHeatTransferDeviceWarning(false);
    await executeEndFinalTest(id, values);
  };

  const handleNoHeatTransferDeviceSelectedWarningClose = () =>
    setOpenNoHeatTransferDeviceSelectedWarning(false);

  const handleNoHeatTransferDeviceSelectedWarningOk = async () => {
    setOpenNoHeatTransferDeviceSelectedWarning(false);
    await executeEndFinalTest(id, values);
  };

  const handleProceedWithoutCoil = (e: any) => {
    setProceedWithoutCoil(e.target.value);
    if (e.target.vale) {
      const now = new Date(Date.now());
      setValues({
        ...values,
        noCoilAgreementSignature: `${user?.userName} - ${now.toLocaleString()}`,
      });
    }
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReportPurchaseData = async (reportId: number) => {
    try {
      const res = await projectReportService.getProjectReportPurchase(reportId);
      setProjectReportPurchase(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const showRetest = useMemo(
    () =>
      //true,
      values?.isComplete &&
      ((infectiousComplianceControl?.projectPurchase != null &&
        infectiousComplianceControl?.projectPurchase?.paymentStatus ===
          "Paid") ||
        (!project?.isInfectiousControlProject &&
          projectReportPurchase?.paymentStatus === "Paid")),
    [
      project?.isInfectiousControlProject,
      infectiousComplianceControl?.projectPurchase,
      infectiousComplianceControl?.projectPurchase?.paymentStatus,
      projectReportPurchase?.paymentStatus,
      values?.isComplete,
    ]
  );

  return (
    <>
      <Popup
        title="Important"
        openPopup={openHeatTransferDeviceWarning}
        setOpenPopup={setOpenHeatTransferDeviceWarning}
        onClose={handleHeatTransferDeviceWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="warning">
            We highly recommend that you add a Heat Transfer Device to the
            Static Pressure Data section of this page in order to perform a
            static pressure drop across the coil being tested.
            <br />
            <br />
            Click on the "Add Components" button and add the Heat Transfer
            Device that represents the coil being tested for this Energy
            Process, and mark it as the "Coil Tested".
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleHeatTransferDeviceWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openNoHeatTransferDeviceWarning}
        setOpenPopup={setOpenNoHeatTransferDeviceWarning}
        onClose={handleNoHeatTransferDeviceWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            You have not added a heat transfer device to perform a static
            pressure drop across the coil being tested.
            <br />
            Please return and add the heat transfer device being tested.
          </p>
          <FormCheckBox
            name="proceedWithoutCoil"
            label="I understand that moving forward without adding a Heat Transfer Device will cause the results of this Report to be incomplete."
            size="medium"
            onChange={handleProceedWithoutCoil}
            value={proceedWithoutCoil}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleNoHeatTransferDeviceWarningOk}
            autoFocus
            disabled={!proceedWithoutCoil}
          >
            Keep moving forward
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleNoHeatTransferDeviceWarningClose}
            autoFocus
          >
            Close to add a Coil
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openNoHeatTransferDeviceSelectedWarning}
        setOpenPopup={setOpenNoHeatTransferDeviceSelectedWarning}
        onClose={handleNoHeatTransferDeviceSelectedWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            Prior to moving forward, please select the coil that needs to be
            tested.
          </p>
          <FormCheckBox
            name="proceedWithoutCoil"
            label="I understand that moving forward without adding a Heat Transfer Device will cause the results of this Report to be incomplete."
            size="medium"
            onChange={handleProceedWithoutCoil}
            value={proceedWithoutCoil}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleNoHeatTransferDeviceSelectedWarningOk}
            autoFocus
            disabled={!proceedWithoutCoil}
          >
            Keep moving forward
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleNoHeatTransferDeviceSelectedWarningClose}
            autoFocus
          >
            Close to select a Coil
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              {showFirstPart() ? (
                <>
                  <Grid
                    container
                    spacing={0}
                    alignItems="flex-start"
                    alignContent="flex-start"
                  >
                    <Grid item xs={12} md={6}>
                      {config?.actualsSummary.columnLeft(
                        systemOfMeasurement,
                        values
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h2 style={{ textAlign: "center" }}>
                            AIR ACTUALS DATA
                          </h2>
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 20 }}>
                          <Chip
                            label={`System ${systemOfMeasurement.get("cfm")}`}
                            color="warning"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          {values?.target === "Water" && (
                            <p>
                              <span
                                style={{ fontWeight: "bold" }}
                              >{`(Actual) System ${systemOfMeasurement.get(
                                "cfm"
                              )}:`}</span>{" "}
                              {(values?.cfms || 0).toLocaleString("en-US", {
                                useGrouping: true,
                                minimumFractionDigits: 3,
                              })}
                            </p>
                          )}
                          {values?.target === "Air" && (
                            <Alert severity="info">
                              {systemOfMeasurement.get("cfm")} is unknown at
                              this point, the Energy Process is looking for the
                              Air.
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 20 }}>
                          <Chip
                            label={`Actual Airflow - ΔT ${systemOfMeasurement.get(
                              "temperature"
                            )} DB`}
                            color="warning"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`Up Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                            "temperature"
                          )}:`}</span>{" "}
                          {(
                            values?.upStreamCoilAirTemperatureDryBulb || 0
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 3,
                          })}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`ΔT Dry Bulb ${systemOfMeasurement.get(
                            "temperature"
                          )}:`}</span>{" "}
                          {atfdb().toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 3,
                          })}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`Down Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                            "temperature"
                          )}:`}</span>{" "}
                          {(
                            values?.downStreamCoilAirTemperatureDryBulb || 0
                          ).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 3,
                          })}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 20 }}>
                          <Chip
                            label={`M/A Steady State SP Drift ${systemOfMeasurement.get(
                              "inwc"
                            )}`}
                            color="warning"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`M/A Energy Off Steady State SP ${systemOfMeasurement.get(
                            "inwc"
                          )}:`}</span>{" "}
                          {(values?.upStreamCoilSpInWc || 0).toLocaleString(
                            "en-US",
                            {
                              useGrouping: true,
                              minimumFractionDigits: 3,
                            }
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`M/A Steady State SP Drift ${systemOfMeasurement.get(
                            "inwc"
                          )}:`}</span>{" "}
                          {(values?.spInWc || 0).toLocaleString("en-US", {
                            useGrouping: true,
                            minimumFractionDigits: 3,
                          })}
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: 40 }}>
                          <span
                            style={{ fontWeight: "bold" }}
                          >{`M/A Energy On Steady State SP ${systemOfMeasurement.get(
                            "inwc"
                          )}:`}</span>{" "}
                          {(values?.downStreamCoilSpInWc || 0).toLocaleString(
                            "en-US",
                            {
                              useGrouping: true,
                              minimumFractionDigits: 3,
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 20 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <h2>STATIC PRESSURE DATA</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <StaticComponentsGrid
                        staticReportId={values?.finalStaticReportId || 0}
                        values={staticValues}
                        setValues={setStaticValues}
                        disableStudy={
                          disableStudy() || !!values?.fInalTestTimeAir
                        }
                        formulas={formulas}
                        isLoading={isLoading}
                        systemOfMeasurement={systemOfMeasurement}
                        execute={executeStatic}
                        isEnergyProcess
                        setGridLenght={setGridLenght}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      textAlign="center"
                      marginBottom={3}
                      marginTop={3}
                    >
                      <FormButton
                        text="End of this Energy Test"
                        onClick={handleEndFinalTestClick}
                        variant="outlined"
                        color="secondary"
                        disabled={!!values.fInalTestTimeAir}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {!!values?.fInalTestTimeAir && (
                        <p>
                          Energy{" "}
                          <span style={{ fontWeight: "bold" }}>Final Test</span>{" "}
                          Time Stamp Air:{" "}
                          {new Date(
                            values?.fInalTestTimeAir as string
                          ).toLocaleString()}
                        </p>
                      )}
                    </Grid>
                    {showSecondPart() && (
                      <>
                        <Grid item xs={12}>
                          <h2>SPACE PRESSURIZATION DATA</h2>
                        </Grid>
                        <Grid item xs={12}>
                          {headSheetActualValues?.id &&
                            spacePressurizationValues && (
                              <SpacePressurization
                                headSheetActualId={headSheetActualValues?.id}
                                disableStudy={disableStudy()}
                                isLoading={isLoading}
                                isLoadingSpacePressurization={
                                  isLoadingSpacePressurization
                                }
                                systemOfMeasurement={systemOfMeasurement}
                                spacePressurizationValues={
                                  spacePressurizationValues
                                }
                                setSpacePressurizationValues={
                                  setSpacePressurizationValues
                                }
                              />
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          {project && dataReport && values && (
                            <CompleteStudyPopup
                              isDialogOpen={isDialogOpen}
                              setIsDialogOpen={setIsDialogOpen}
                              project={project}
                              projectReport={dataReport}
                              completed={values?.isComplete}
                              infectiousComplianceControl={
                                infectiousComplianceControl
                              }
                              parentStudy={parentStudy ?? null}
                              hideReopenStudyButton
                            />
                          )}
                        </Grid>
                        {showRetest && (
                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <RetestReport
                              reportId={id}
                              projectId={values?.projectId}
                              reportTypeId={values?.reportTypeId || 18}
                              isInfectiousControlProject={
                                project?.isInfectiousControlProject || false
                              }
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <Alert severity="error">
                  You are not allowed to start a Final Test.
                  <br />
                  You must wait until a Technician / Auditor performs the Final
                  Test to continue.
                </Alert>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={false}
                disabled={false}
                reportName={title as string}
                previousStepHandler={previousStepHandler}
                nextStepHandler={nextStepHandler}
                disableNext={!showSecondPart()}
                saveHandler={saveHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyStep6;
