import axios from "../utils/axios";

const payRenewal = async (systemCostCatalogId: number, techId: number) => {
  return axios.post(
    `CertificationRenewals?systemCostCatalogId=${systemCostCatalogId}&techId=${techId}`
  );
};

const certificationRenewalsService = {
  payRenewal,
};

export default certificationRenewalsService;
