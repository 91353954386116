import {
  IconButtonProps,
  IconButton,
  Card as MuiCard,
  CardHeader,
  CardActions,
  Collapse,
  Avatar,
  CardContent,
  Typography,
  Chip,
} from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";
import { ReactNode, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RuleIcon from "@mui/icons-material/Rule";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import SvgAmp from "../assets/SvgAmp";
import GavelIcon from "@mui/icons-material/Gavel";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Card = styled(MuiCard)(spacing);

const ExpandMore = MuiStyled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }: ExpandMoreProps) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }: ExpandMoreProps) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

interface SummaryCardProps {
  section: string;
  avatarIcon: ReactNode;
  avatarColor: string;
  title: string;
  cardContent: ReactNode;
  collapsibleContent: ReactNode;
  isInfectiousControl: boolean;
  userRole: string;
  isInReview: boolean;
  disabledActions?: boolean;
  subHeader?: string;
  initExpanded?: boolean;
  showFinalReject?: boolean;
  approveAction?: () => void;
  rejectAction?: () => void;
  finalRejectAction?: () => void;
  compliantAction?: () => void;
  currentDecision?: string;
}

const SummaryCard = (props: SummaryCardProps) => {
  const {
    section,
    avatarIcon,
    avatarColor,
    title,
    subHeader,
    cardContent,
    collapsibleContent,
    isInfectiousControl,
    userRole,
    isInReview,
    initExpanded,
    showFinalReject,
    approveAction,
    rejectAction,
    finalRejectAction,
    compliantAction,
    disabledActions,
    currentDecision,
  } = props;
  const [expanded, setExpanded] = useState(initExpanded || false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      sx={{ minHeight: 294 }}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: avatarColor }}
            aria-label={`aria-label-${section}`}
          >
            {avatarIcon}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={subHeader}
      />
      <CardContent>{cardContent}</CardContent>
      {isInfectiousControl && isInReview && (
        <>
          <CardActions disableSpacing style={{ marginTop: "auto" }}>
            {userRole === "SuperAdministrator" && (
              <>
                <IconButton
                  aria-label="approve"
                  onClick={approveAction}
                  disabled={disabledActions}
                  color="success"
                >
                  <ThumbUpAltIcon titleAccess="Approve" />
                </IconButton>
                <IconButton
                  aria-label="reject"
                  onClick={rejectAction}
                  disabled={disabledActions}
                  color="error"
                >
                  <ThumbDownAltIcon titleAccess="Reject" />
                </IconButton>
                <IconButton
                  aria-label="approve but not compliant"
                  onClick={compliantAction}
                  disabled={disabledActions}
                  color="warning"
                >
                  <RuleIcon titleAccess="Approve, not compliant" />
                </IconButton>
                {showFinalReject && (
                  <IconButton
                    aria-label="finalreject"
                    onClick={finalRejectAction}
                    disabled={disabledActions}
                    color="error"
                  >
                    <CancelIcon titleAccess="Final Reject" />
                  </IconButton>
                )}
              </>
            )}
            <span
              onClick={handleExpandClick}
              style={{
                display: "inline-flex",
                marginInline: "auto",
                cursor: "pointer",
              }}
            >
              <SvgAmp color="#000000" />
              <Typography margin="auto" fontWeight="bold">
                Approval Status Section
              </Typography>
            </span>
            {!!currentDecision && (
              <Chip
                style={{ marginInline: "auto" }}
                clickable
                onClick={handleExpandClick}
                size="small"
                color={
                  currentDecision === "approve"
                    ? "success"
                    : currentDecision === "compliant"
                    ? "warning"
                    : currentDecision === "appeal"
                    ? "primary"
                    : "error"
                }
                label={
                  currentDecision === "approve" ? (
                    <span style={{ display: "inline-flex" }}>
                      <Typography marginRight={2}>Approved</Typography>{" "}
                      <ThumbUpAltIcon />
                    </span>
                  ) : currentDecision === "compliant" ? (
                    <span style={{ display: "inline-flex" }}>
                      <Typography marginRight={2}>
                        Approved but not Compliant
                      </Typography>{" "}
                      <RuleIcon />
                    </span>
                  ) : currentDecision === "finalreject" ? (
                    <span style={{ display: "inline-flex" }}>
                      <Typography marginRight={2}>Final Rejected</Typography>{" "}
                      <CancelIcon />
                    </span>
                  ) : currentDecision === "appeal" ? (
                    <span style={{ display: "inline-flex" }}>
                      <Typography marginRight={2}>Appeal</Typography>{" "}
                      <GavelIcon fontSize="small" />
                    </span>
                  ) : (
                    <span style={{ display: "inline-flex" }}>
                      <Typography marginRight={2}>Rejected</Typography>{" "}
                      <ThumbDownAltIcon />
                    </span>
                  )
                }
              />
            )}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>{collapsibleContent}</CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default SummaryCard;
