import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Popup from "src/components/Popup";
import { Form, useCountry, useStateCatalog, useTimeZone } from "src/hooks";
import {
  FormSelect,
  FormText,
  FormMaskText,
  FormCheckBox,
} from "src/components/formControls";
import { COUNTRIES } from "src/constants";
import { IUserPerson } from "src/ts/interfaces";

interface ProfileFormProp {
  values: IUserPerson;
  setValues: (val: IUserPerson) => void;
  handleInputChange: (e: any) => void;
  errors: any;
  isSubmitting: boolean;
}

const ProfileForm = (props: ProfileFormProp) => {
  const { values, setValues, handleInputChange, errors, isSubmitting } = props;

  const [informationShowPopUp, setInofrmationShowPopUp] = useState(false);

  const { setSelectedCountryById, selectedCountry, countriesKeyValue } =
    useCountry(values.countryId as number);

  const { states, setCountryId, isLoading } = useStateCatalog();

  const { timeZoneKeyValue } = useTimeZone();

  useEffect(() => {
    if (values?.countryId !== undefined) {
      setSelectedCountryById(Number(values.countryId));
      setCountryId(Number(values.countryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  useEffect(() => {
    if (selectedCountry !== null)
      setValues({ ...values, country: selectedCountry });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const handleClosePopUp = () => {
    setInofrmationShowPopUp(false);
  };

  const handleInputChangeAuth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const newValue = !values.isTwoFactorAuthenticationEnable; // Toggle the value

    setValues({
      ...values,
      [name]: newValue,
    });
  };

  const row = 12;
  return (
    <>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="First Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
              maxLength={200}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleInputChange}
              error={errors.lastName}
              showSkeleton={isLoading}
              maxLength={200}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="address"
              label="Address"
              value={values.address}
              onChange={handleInputChange}
              error={errors.address}
              showSkeleton={isLoading}
              maxLength={500}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="address2"
              label="Address 2"
              value={values.address2}
              onChange={handleInputChange}
              error={errors.address2}
              showSkeleton={isLoading}
              maxLength={500}
            />
          </Grid>
          <Grid item xs={row}>
            <FormSelect
              name="countryId"
              label="Country"
              value={values.countryId.toString()}
              onChange={handleInputChange}
              options={countriesKeyValue}
              error={errors.countryId}
              disable={isSubmitting}
              showSkeleton={isLoading}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="zip"
              label="Zip Code"
              value={values.zip}
              onChange={handleInputChange}
              maxLength={5}
              error={errors.zip}
              placeholder=""
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="city"
              label="city"
              value={values.city}
              onChange={handleInputChange}
              error={errors.city}
              showSkeleton={isLoading}
              maxLength={50}
            />
          </Grid>
          {values.countryId !== Number.MIN_SAFE_INTEGER &&
          values.countryId === COUNTRIES.USA ? (
            <Grid item xs={row}>
              <FormSelect
                name="stateId"
                label="State"
                value={values.stateId?.toString()}
                onChange={handleInputChange}
                options={states}
                error={errors.stateId}
                disable={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
          ) : (
            <Grid item xs={row}>
              <FormText
                name="stateName"
                label="State"
                value={values.stateName}
                onChange={handleInputChange}
                error={errors.stateName}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <Stack direction="row" spacing={2}>
              <FormText
                name="countryCode"
                label="Country Code"
                value={selectedCountry?.countryCode?.toString() ?? ""}
                error={errors.countryCode}
                disabled={true}
                fullWidth={false}
              />
              <FormMaskText
                name="phone"
                mask="(999) 999 - 9999"
                label="Cell Phone"
                onChange={handleInputChange}
                value={values.phone ?? ""}
                error={errors.phone}
              />
            </Stack>
          </Grid>
          <Grid item xs={row}>
            <Stack direction="row" spacing={2}>
              <FormText
                name="countryCode"
                label="Country Code"
                value={selectedCountry?.countryCode?.toString() ?? ""}
                error={errors.countryCode}
                disabled={true}
                fullWidth={false}
              />
              <FormMaskText
                name="fixedPhone"
                mask="(999) 999 - 9999"
                label="Phone"
                onChange={handleInputChange}
                value={values.fixedPhone ?? ""}
                error={errors.fixedPhone}
              />
            </Stack>
          </Grid>
          <Grid item xs={row}>
            <FormSelect
              name="timeZoneId"
              label="Time zone"
              value={values.timeZoneId?.toString() ?? ""}
              onChange={handleInputChange}
              options={timeZoneKeyValue}
              error={errors.timeZoneId}
              disable={isSubmitting}
              showSkeleton={isLoading}
            />
          </Grid>
          <Grid item xs={row}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={values.isTwoFactorAuthenticationEnable}
                  onChange={handleInputChangeAuth}
                  name="isTwoFactorAuthenticationEnable"
                />
              }
              label="Enable Two-Factor Authentication"
            />
            <Stack direction="row" alignItems="center">
              <FormCheckBox
                label=""
                name="confirmIdentityBySms"
                value={values.confirmIdentityBySms}
                onChange={handleInputChange}
              />
              <Typography>
                I agree to receive the authentication code by{" "}
                <a
                  href="https://auditmaster.pro/legal?id=sms"
                  target="_blank"
                  rel="noreferrer"
                >
                  SMS in accordance with SMS Terms of Service.
                </a>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Form>

      <Popup
        title="Information"
        openPopup={informationShowPopUp}
        setOpenPopup={setInofrmationShowPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography>
            <b>
              The acronym SMWIA specifically represents the Sheet Metal Workers
              International Association, which pertains to union affiliation.
            </b>
          </Typography>
          <br />
          <Typography>
            <b>
              Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
              and Transportation workers.
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={handleClosePopUp}
          >
            Close
          </Button>
        </Stack>
      </Popup>
    </>
  );
};

export default ProfileForm;
