import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Tabs,
  Tab,
  Chip as MuiChip,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import {
  useForm,
  Form,
  useLog,
  useLoading,
  useAuth,
  useAsyncQuery,
  usePermissions,
} from "src/hooks";
import { FormText } from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import HeaderPage from "src/components/page/HeaderPage";
import { useTable } from "src/hooks/useTable";
import TechnicianService from "src/services/technicianService";
import { getQueryString } from "./util/common";
import { DataAssignment } from "src/ts/interfaces/technician";
import { CompanyService } from "src/services";
import { ICompany } from "src/ts/interfaces";
import { AuthUser } from "src/types/auth";
import RequestTransferTechnicianService from "src/services/RequestTransferTechnicianService";
import { DataRequestTransfer } from "src/ts/interfaces/requestTransfer";
import RemoveGPSPermissions from "./components/popUp/RemoveGPSPermissions";
import RequestTransfer from "./components/popUp/RequestTransfer";
import TransferDecision from "./components/popUp/TransferDecision";
import { CertificationID, CertificationName, ROLES } from "src/constants";
import PrimaryEnergyAirCouponsPopUp from "./PrimaryEnergyAirCouponsPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)<ChipProps>(spacing);

const initialValues = {
  name: "",
};

const defaultCurrentRow = {
  id: 0,
  companyAddress: "",
  name: "",
  email: "",
  companyId: 0,
  headquarters: false,
  techniciansAssignementsRole: [],
  requestTransfer: false,
  remamingTimeGpsHours: 0,
  remamingTimeGpsDays: 0,
  projectIdGps: 0,
  permanentPermission: false,
  permanentPermissionForAll: false,
};

const columns = (
  navigate: NavigateFunction,
  user: AuthUser,
  companyId: number,
  companyInfo: ICompany,
  setRemoveShowPopUp: (val: boolean) => void,
  setTypeRemove: (val: string) => void,
  setRequestTransferShowPopUp: (val: boolean) => void,
  setCurrentRow: (val: DataAssignment) => void,
  satelliteValues: boolean,
  setOpenPrimaryEnergyAirCouponsPopUp: (val: boolean) => void,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "companyAddress",
    label: "Company Address",
    type: "custom",
    sort: true,
    hideColumn: satelliteValues ?? false,
    callback: (row: DataAssignment) => {
      return (
        <>
          <Typography>{`${row.companyAddress}`}</Typography>
          {row.headquarters && (
            <Chip label={"Headquarters"} color={"warning"} size="small" />
          )}
          {!row.headquarters && (
            <Chip label={"Satellite"} color={"default"} size="small" />
          )}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: DataAssignment) => {
      return <>{`${row.name}`}</>;
    },
  },
  {
    id: "emailUserName",
    label: "Email/Username",
    type: "custom",
    sort: true,
    callback: (row: DataAssignment) => {
      var input = row.email ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}@</Stack>
          <Stack>{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "certificationType",
    label: "Certification Type",
    type: "custom",
    sort: true,
    align: "center",
    callback: (row: DataAssignment) => {
      return (
        <>
          {row?.techniciansAssignementsRole?.map((item) => {
            return (
              <>
                {item.certificationId ===
                CertificationID.TestingBalancingTechnician ? (
                  <>
                    <Typography color={"primary"} fontWeight={"bold"}>
                      {CertificationName.TestingBalancingTechnician}
                    </Typography>
                    <Typography>
                      {item.status === false && <> (In Progress)</>}
                      {item.isDeactivated && <> (Deactivated)</>}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography color={"green"} fontWeight={"bold"}>
                      {CertificationName.EnergyAuditorInfectiousBuildingControl}
                    </Typography>
                    <Typography>
                      {item.status === false && <> (In Progress)</>}
                      {item.isDeactivated && <> (Deactivated)</>}
                    </Typography>
                  </>
                )}
              </>
            );
          })}
        </>
      );
    },
  },
  {
    id: "assignedToProject",
    label: "Assigned to project",
    type: "custom",
    sort: true,
    callback: (row: DataAssignment) => {
      return (
        <>
          {row.techniciansAssignementsRole !== undefined &&
          row?.techniciansAssignementsRole[0]?.project === null ? (
            <Chip label="No" color="error" m={1} size="small" />
          ) : (
            <Chip label="Yes" color="success" m={1} size="small" />
          )}
        </>
      );
    },
  },
  {
    id: "projectNAme",
    label: "Project Name",
    type: "custom",
    sort: true,
    align: "center",
    callback: (row: DataAssignment) => {
      return (
        <>
          {row.techniciansAssignementsRole !== undefined &&
            row.techniciansAssignementsRole.length > 0 &&
            row.techniciansAssignementsRole[0]?.project && (
              <Typography
                onClick={() => {
                  navigate(
                    `/app/Project/Edit/${row.techniciansAssignementsRole[0]?.project?.id}/${row.techniciansAssignementsRole[0]?.project?.companyId}`
                  );
                }}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                color={"primary.main"}
                textAlign="center"
              >
                {row.techniciansAssignementsRole[0]?.project?.name}
              </Typography>
            )}
        </>
      );
    },
  },
  {
    id: "requestTransfer",
    label: "Request Transfer",
    type: "custom",
    sort: true,
    hideColumn: companyInfo?.satelliteCount === 0 && companyInfo?.headquarters,
    callback: (row: DataAssignment) => {
      return (
        <>
          {Number(companyId) !== row.companyId && (
            <GridActionButton
              type="compare"
              onClick={() => {
                setCurrentRow(row);
                setRequestTransferShowPopUp(true);
              }}
            />
          )}
        </>
      );
    },
  },
  {
    id: "gpsPermissionsTime",
    label: "GPS Permission Time Remaining",
    type: "custom",
    sort: true,
    align: "center",
    callback: (row: DataAssignment) => {
      return (
        <>
          {row.permanentPermissionForAll && (
            <Stack direction="column" textAlign="center">
              <Typography textAlign="center">
                {" "}
                Permanent GPS permission for all projects
              </Typography>
              {Number(companyId) === row.companyId && fullAccess && (
                <Typography
                  onClick={() => {
                    setTypeRemove("removePermanent");
                    setCurrentRow(row);
                    setRemoveShowPopUp(true);
                  }}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  color={"error"}
                  textAlign="center"
                >
                  Remove
                </Typography>
              )}
            </Stack>
          )}

          {row.techniciansAssignementsRole !== undefined &&
            row.techniciansAssignementsRole[0].project !== null &&
            row.techniciansAssignementsRole[0].project.id ===
              row.projectIdGps &&
            row.permanentPermission && (
              <Stack direction="column" textAlign="center">
                <Typography textAlign="center">
                  {" "}
                  Permanent permission for this project
                </Typography>
                {fullAccess && (
                  <Typography
                    onClick={() => {
                      setTypeRemove("remove");
                      setCurrentRow(row);
                      setRemoveShowPopUp(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    color={"error"}
                    textAlign="center"
                  >
                    Remove
                  </Typography>
                )}
              </Stack>
            )}

          {row.techniciansAssignementsRole !== undefined &&
            row.techniciansAssignementsRole[0].project !== null &&
            row.projectIdGps ===
              row.techniciansAssignementsRole[0].project.id &&
            !row.permanentPermission &&
            !row.permanentPermissionForAll &&
            (row.remamingTimeGpsDays > 0 || row.remamingTimeGpsHours > 0) && (
              <Stack direction="column" textAlign="center">
                <Typography textAlign="center">
                  {`${row.remamingTimeGpsDays} days, `}
                  {`${(
                    row.remamingTimeGpsHours -
                    row.remamingTimeGpsDays * 24
                  ).toFixed(1)} hours,`}
                </Typography>
                {Number(companyId) === row.companyId && fullAccess && (
                  <Typography
                    onClick={() => {
                      setTypeRemove("remove");
                      setCurrentRow(row);
                      setRemoveShowPopUp(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    color={"error"}
                    textAlign="center"
                  >
                    Remove
                  </Typography>
                )}
              </Stack>
            )}
        </>
      );
    },
  },
  {
    id: "permissions",
    label: "Actions",
    type: "custom",
    sort: true,
    minWidth: "125px",
    callback: (row: DataAssignment) => {
      let hasEnergyCertification = row?.techniciansAssignementsRole.find(
        function (element) {
          return (
            element.certificationId ===
            CertificationID.EnergyAuditorInfectiousBuildingControl
          );
        }
      );

      return (
        <>
          {Number(companyId) === row.companyId &&
            (user?.role === ROLES.SuperAdministrator ||
              user?.role === ROLES.Administrator ||
              user?.role === ROLES.CompanyOwner) && (
              <>
                <GridActionButton
                  type="key"
                  onClick={() => {
                    navigate(
                      `/app/UserPermissions/${row.id}/${companyId}/assignments`
                    );
                  }}
                  tooltip="Permissions"
                />
                {hasEnergyCertification && (
                  <GridActionButton
                    type="sell"
                    onClick={() => {
                      setCurrentRow(row);
                      setOpenPrimaryEnergyAirCouponsPopUp(true);
                    }}
                    tooltip="Coupons Usage"
                  />
                )}
              </>
            )}
        </>
      );
    },
  },
];

const columnsDataTransfer = (
  handlerDecision: (
    val: DataRequestTransfer,
    val2: string,
    val3: boolean
  ) => void
): ColumnType[] => [
  {
    id: "newCompanyFullAddress",
    label: "Company Address",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "emailUserName",
    label: "Email/UserName",
    type: "custom",
    sort: true,
    callback: (row: DataRequestTransfer) => {
      var input = row.email ?? "";
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}@</Stack>
          <Stack>{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "certificationType",
    label: "Certification Type",
    type: "custom",
    sort: true,
    align: "center",
    callback: (row: DataRequestTransfer) => {
      return (
        <>
          {row?.technicianTemp?.map((item) => {
            return (
              <>
                <Chip
                  label={
                    item.certificationId ===
                    CertificationID.TestingBalancingTechnician
                      ? CertificationName.TestingBalancingTechnician
                      : CertificationName.EnergyAuditorInfectiousBuildingControl
                  }
                  color={
                    item.certificationId ===
                    CertificationID.TestingBalancingTechnician
                      ? "primary"
                      : "success"
                  }
                  m={1}
                  size="small"
                  ml={
                    item.certificationId ===
                    CertificationID.TestingBalancingTechnician
                      ? 10
                      : 0
                  }
                />
                <br />
                <Chip
                  key={item.certificationId + "_status"}
                  label="In progress"
                  color="default"
                  m={1}
                  size="small"
                  ml={20}
                />
              </>
            );
          })}
        </>
      );
    },
  },
  {
    id: "requestedBy",
    label: "Requested By",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date/Time",
    type: "date",
    sort: true,
    format: "MM/DD/YYYY A",
  },
  {
    id: "comments",
    label: "Comments",
    type: "string",
    sort: true,
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: DataRequestTransfer) => {
      return (
        <>
          <Stack direction="row">
            <GridActionButton
              type="enable"
              onClick={() => {
                handlerDecision(row, "accept", true);
              }}
              tooltip="Accept"
            />
            <GridActionButton
              type="disable"
              onClick={() => {
                handlerDecision(row, "reject", true);
              }}
              tooltip="Reject"
            />
          </Stack>
        </>
      );
    },
  },
];

const AuditorTechniciansAssignmentss = () => {
  let params = useParams<{ companyId: string }>();

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const { startRequest, endRequest } = useLoading();

  const [isActive, setIsActive] = useState<number>(0);
  const [removeShowPopUp, setRemoveShowPopUp] = useState(false);
  const [
    openPrimaryEnergyAirCouponsPopUp,
    setOpenPrimaryEnergyAirCouponsPopUp,
  ] = useState(false);
  const [transferDecisionShow, setTransferDecisionShow] = useState(false);
  const [requestTransferShowPopUp, setRequestTransferShowPopUp] =
    useState(false);
  const [typeRemove, setTypeRemove] = useState("");
  const [decision, setDecision] = useState("");
  const [currentRow, setCurrentRow] = useState<DataAssignment>();
  const [currentTransferRow, setCurrentTransferRow] =
    useState<DataRequestTransfer>();
  const { fullAccess } = usePermissions(PermissionTypes.Technicians);
  const [refreshPage, setRefreshPage] = useState(false);

  const { execute, data } = useAsyncQuery<ICompany>(CompanyService.getById);

  const { log } = useLog();
  const { user } = useAuth();
  let navigate = useNavigate();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const satelliteValues = (() => {
    if (
      data?.headquarters &&
      data?.satelliteCount !== undefined &&
      data?.satelliteCount > 0
    ) {
      return false;
    } else if (data?.headquarters === false) {
      return false;
    }
    return true;
  })();

  const handlerDecision = (
    row: DataRequestTransfer,
    decision: string,
    transferDecision: boolean
  ) => {
    setCurrentTransferRow(row);
    setDecision(decision);
    setTransferDecisionShow(transferDecision);
  };

  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    setOrderBy,
    setOrder,
  } = useTable(
    columns(
      navigate,
      user,
      companyId ?? 0,
      data,
      setRemoveShowPopUp,
      setTypeRemove,
      setRequestTransferShowPopUp,
      setCurrentRow,
      satelliteValues,
      setOpenPrimaryEnergyAirCouponsPopUp,
      fullAccess
    ),
    {
      rowsPerPageOptions: [10],
      defaultOrder: "desc",
    }
  );

  const {
    Table: TransferTable,
    setRowsPerPage: setTransferRowsPerPage,
    page: transferPage,
    setDataSet: setTransferDataSet,
    setCount: setTransferCount,
    orderBy: orderTransferBy,
    order: orderTransfer,
    setOrderBy: setTransferOrderBy,
    setOrder: setTransferOrder,
  } = useTable(columnsDataTransfer(handlerDecision), {
    rowsPerPageOptions: [10],
    defaultOrder: "desc",
  });

  useEffect(() => {
    const getCompany = async () => {
      execute(Number(companyId));
    };
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Number(companyId) !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyId,
    refreshPage,
    page,
    orderBy,
    order,
    transferPage,
    orderTransferBy,
    orderTransfer,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const getData = async () => {
    try {
      startRequest("AuditorTechn");
      if (isActive === 0) {
        const request = await TechnicianService.getAllAssignments(
          {
            page: page + 1,
            filter: filterValues.name,
            sortColumn: orderBy ?? "id",
            sortDec: order === "desc" ? true : false,
            countryId: 235,
            stateId: 13,
            stateName: "",
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } else {
        const request =
          await RequestTransferTechnicianService.getTransferRequestTechnicians(
            {
              page: transferPage + 1,
              filter: filterValues.name,
              sortColumn: orderTransferBy,
              sortDec: orderTransfer === "desc" ? true : false,
              companyId: Number(companyId),
            },
            getQueryString
          );

        setTransferCount(request.data.count);
        setTransferDataSet(request.data.data);
        setTransferRowsPerPage(10);
      }

      endRequest("AuditorTechn");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      endRequest("AuditorTechn");
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIsActive(newValue);
    setRefreshPage(!refreshPage);
  };

  return (
    <>
      <HeaderPage
        title={"Technicians Assignments"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={undefined}
        pageYoutube=""
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={isActive}
                onChange={handleChangeTab}
                aria-label="AuditTech"
              >
                <Tab label="Assignments" id="0" value={0} />

                {!satelliteValues && (
                  <Tab label="Transfer Requests" id="1" value={1} />
                )}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {isActive === 0 ? <Table /> : <TransferTable />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {removeShowPopUp && (
        <RemoveGPSPermissions
          removeShowPopUp={removeShowPopUp}
          setRemoveShowPopUp={setRemoveShowPopUp}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          currentRow={currentRow ?? defaultCurrentRow}
          typeRemove={typeRemove}
        />
      )}
      {requestTransferShowPopUp && (
        <RequestTransfer
          requestTransferShowPopUp={requestTransferShowPopUp}
          setRequestTransferShowPopUp={setRequestTransferShowPopUp}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          currentRow={currentRow ?? defaultCurrentRow}
        />
      )}
      {currentTransferRow !== undefined && transferDecisionShow && (
        <TransferDecision
          transferDecisionShow={transferDecisionShow}
          setTransferDecisionShow={setTransferDecisionShow}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          currentTransferRow={currentTransferRow}
          decision={decision}
        />
      )}
      {openPrimaryEnergyAirCouponsPopUp && (
        <>
          <PrimaryEnergyAirCouponsPopUp
            showList={openPrimaryEnergyAirCouponsPopUp}
            setShowList={setOpenPrimaryEnergyAirCouponsPopUp}
            userId={currentRow?.id ?? 0}
            technicianName={currentRow?.name ?? ""}
          ></PrimaryEnergyAirCouponsPopUp>
        </>
      )}
    </>
  );
};

export default AuditorTechniciansAssignmentss;
