import React, { useState, useEffect } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { getAllDistinct } from "src/ts/interfaces/catalogs/youtubeTrainingVideos";
import youtubeVideos from "src/services/catalogs/youtubeVideos";
import FormAccordion from "src/components/formControls/FormAccordion";
import { useLog } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface InformationProps {
  showEditTraningVideo: boolean;
  setShowEditTraningVideo: (val: boolean) => void;
  dataRow: getAllDistinct;
  tag: string;
}

export default function EditTrainingVideosModal({
  showEditTraningVideo,
  setShowEditTraningVideo,
  dataRow,
  tag,
}: InformationProps) {
  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataRole, setDataRole] = useState<getAllDistinct[]>([]);
  const [dataRowReport, setDataRowReport] = useState<getAllDistinct>(dataRow);
  const [expandedAccordion, setExpandedAccordion] = React.useState<
    string | false
  >("panel0");

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  useEffect(() => {
    const getDataRole = async () => {
      try {
        const response = await youtubeVideos.getDataRole(
          dataRow.pageName ?? ""
        );
        setDataRole(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getDataRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePopUp = () => {
    setShowEditTraningVideo(false);
  };

  const handleOnChange = (e: any, index: number, field: string) => {
    const { name, value } = e.target;
    const newArray = [...dataRole];
    if (field === "sameData") {
      if (tag === "pages") {
        for (var i = 0; i < dataRole.length; i++) {
          newArray[i] = { ...newArray[i], [name]: value };
        }

        setDataRole(newArray);
      } else {
        setDataRowReport({ ...dataRowReport, [name]: value });
      }
    } else {
      newArray[index] = { ...newArray[index], [name]: value };
      setDataRole(newArray);
    }
  };

  const handleOnClick = () => {
    const putYoutube = async () => {
      try {
        setIsSubmitting(true);

        if (tag === "pages") {
          await youtubeVideos.putYoutubeVideo(dataRole);
        } else {
          await youtubeVideos.putYoutubeVideo([dataRowReport]);
        }
        log.success("The Youtube training video was updated");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setShowEditTraningVideo(false);
      }
    };
    putYoutube();
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <>
      <Popup
        title="Edit"
        openPopup={showEditTraningVideo}
        setOpenPopup={setShowEditTraningVideo}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          {tag === "pages" ? (
            <>
              <Typography variant="subtitle1">{dataRow.pageName}</Typography>
              <FormCheckBox
                name="sameData"
                label="Apply same Title and URL for all users"
                value={dataRole[0] === undefined ? false : dataRole[0].sameData}
                onChange={(e) => {
                  handleOnChange(e, 0, "sameData");
                }}
                disabled={readOnly}
              />
              {dataRole[0] !== undefined ? (
                dataRole[0].sameData ? (
                  <Stack>
                    <FormText
                      name="title"
                      placeholder="Title"
                      label="Title"
                      value={dataRole[0].title ?? ""}
                      onChange={(e) => {
                        handleOnChange(e, 0, "sameData");
                      }}
                      disabled={readOnly}
                    />
                    <br />
                    <FormText
                      name="url"
                      placeholder="Url"
                      label="Url"
                      value={dataRole[0].url ?? ""}
                      onChange={(e) => {
                        handleOnChange(e, 0, "sameData");
                      }}
                      disabled={readOnly}
                    />
                    <FormCheckBox
                      name="publish"
                      label="Publish"
                      value={dataRole[0].publish}
                      onChange={(e) => {
                        handleOnChange(e, 0, "sameData");
                      }}
                      disabled={readOnly}
                    />
                    <br />
                  </Stack>
                ) : null
              ) : null}

              {dataRole?.map((item: any, index: number) => (
                <FormAccordion
                  title={item.rolAlias}
                  key={index}
                  disabled={item.sameData ? true : false}
                  onChange={handleChange(`panel${index.toString()}`)}
                  expanded={
                    item.sameData
                      ? false
                      : expandedAccordion === `panel${index.toString()}`
                  }
                >
                  <Stack mt={2}>
                    <FormText
                      name="title"
                      placeholder="Title"
                      label="Title"
                      onChange={(e) => {
                        handleOnChange(e, index, "title");
                      }}
                      value={item.title ?? ""}
                      disabled={readOnly}
                    />
                    <br />
                    <FormText
                      name="url"
                      placeholder="Url"
                      label="Url"
                      value={item.url ?? ""}
                      onChange={(e) => {
                        handleOnChange(e, index, "url");
                      }}
                      disabled={readOnly}
                    />
                    <FormCheckBox
                      name="publish"
                      label="Publish"
                      value={item.publish}
                      onChange={(e) => {
                        handleOnChange(e, index, "publish");
                      }}
                      disabled={readOnly}
                    />
                  </Stack>
                </FormAccordion>
              ))}
            </>
          ) : (
            <>
              <Typography variant="subtitle1" mb={3}>
                {dataRowReport.reportType?.name}
              </Typography>
              <Stack>
                <FormText
                  name="title"
                  placeholder="Title"
                  label="Title"
                  value={dataRowReport.title ?? ""}
                  onChange={(e) => {
                    handleOnChange(e, 0, "sameData");
                  }}
                  disabled={readOnly}
                />
                <br />
                <FormText
                  name="url"
                  placeholder="Url"
                  label="Url"
                  value={dataRowReport.url ?? ""}
                  onChange={(e) => {
                    handleOnChange(e, 0, "sameData");
                  }}
                  disabled={readOnly}
                />
                <FormCheckBox
                  name="publish"
                  label="Publish"
                  value={dataRowReport.publish}
                  onChange={(e) => {
                    handleOnChange(e, 0, "sameData");
                  }}
                  disabled={readOnly}
                />
                <br />
              </Stack>
            </>
          )}
        </>

        <Stack direction="row" spacing={4}>
          <FormButton
            text="Close"
            color="primary"
            variant="outlined"
            onClick={() => {
              setShowEditTraningVideo(false);
            }}
          />
          {fullAccess && (
            <FormSaveButton
              onClick={handleOnClick}
              isSubmitting={isSubmitting}
            />
          )}
        </Stack>
      </Popup>
    </>
  );
}
