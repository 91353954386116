import axios from "../utils/axios";
import { IPostUnionCompany } from "../ts/interfaces";

const UnionAffiliatedCompany = async (id: number) => {
  return axios.get(`UnionAffiliated/Company?id=${id}`);
};

const UnionAffiliatedUser = async (id: number) => {
  return axios.get(`UnionAffiliated/User?id=${id}`);
};

const updateUnionAffiliatedCompany = async (data: IPostUnionCompany) => {
  return axios.put(`UnionAffiliated/union?isCompany=true&newRoute=true`, data);
};

const removeAffiliation = async (companyId: number, comment: string) => {
  return axios.put(
    `UnionAffiliated/RemoveAffiliation?companyId=${companyId}&comment=${comment}`,
    null
  );
};

const removeAffiliationUser = async (userId: number, comment: string) => {
  return axios.put(
    `UnionAffiliated/RemoveAffiliationUser?userId=${userId}&comment=${comment}`,
    null
  );
};

const isAffiliated = async (
  companyId: number,
  comment: string,
  isAffiliated: boolean,
  unionAffiliationNumbersId: number,
  unionAffiliationNumbersOther: string
) => {
  return axios.put(
    `UnionAffiliated/ByCompany?companyId=${companyId}&isAffiliated=${isAffiliated}&UnionAffiliationNumbersId=${
      unionAffiliationNumbersId ? unionAffiliationNumbersId : 0
    }&comments=${comment}&unionAffiliationNumbersOther=${unionAffiliationNumbersOther}`,
    null
  );
};

const isAffiliatedByUser = async (
  userId: number,
  memeber: string,
  comment: string,
  isAffiliated: boolean,
  unionAffiliationNumbersId: number,
  unionAffiliationNumbersOther: string
) => {
  if (unionAffiliationNumbersId)
    return axios.put(
      `UnionAffiliated/ByUser?userId=${userId}&isAffiliated=${isAffiliated}&memeber=${memeber}&comments=${comment}&unionAffiliationNumbersOther=${unionAffiliationNumbersOther}&UnionAffiliationNumbersId=${unionAffiliationNumbersId}`,
      null
    );
  else
    return axios.put(
      `UnionAffiliated/ByUser?userId=${userId}&isAffiliated=${isAffiliated}&memeber=${memeber}&comments=${comment}&unionAffiliationNumbersOther=${unionAffiliationNumbersOther}`,
      null
    );
};

const GetByCode = async (code: string) => {
  return axios.get(`UnionAffiliated/GetByCode?code=${code}`);
};

const ModifyUnionAffiliated = async (
  code: string,
  isAffiliated: boolean,
  comments: string
) => {
  return axios.put(
    `UnionAffiliated?code=${code}&isAffiliated=${isAffiliated}&comments=${comments}`,
    null
  );
};

const unionAffiliatedService = {
  UnionAffiliatedCompany,
  updateUnionAffiliatedCompany,
  removeAffiliation,
  isAffiliated,
  removeAffiliationUser,
  isAffiliatedByUser,
  UnionAffiliatedUser,
  GetByCode,
  ModifyUnionAffiliated,
};

export default unionAffiliatedService;
