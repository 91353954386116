import { Save } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import { canvasPreview } from "src/pages/Buildings/canvasPreview";
import { useDebounceEffect } from "src/pages/Buildings/useDebounceEffect";

interface Props {
  save: (file: string) => void;
  imgSrc: string;
  isSubmitting: boolean;
  aspect?: number;
  scale?: number;
  rotate?: number;
  lockedResize?: boolean;
}

const FormCropImage = (props: Props) => {
  const {
    save,
    imgSrc,
    isSubmitting,
    aspect = 16 / 9,
    scale = 1,
    rotate = 0,
    lockedResize,
  } = props;
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef("");
  const scrollToBottom = () => {
    const section = document.querySelector("#cropImage");
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const blobToBase64 = async (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
    scrollToBottom();
  }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }
    goToTop();

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    const base64data = (await blobToBase64(blob)) as string;
    await save(base64data);
    setCrop(undefined);
    setCompletedCrop(undefined);
  }

  return (
    <Grid container id={"cropImage"}>
      {!!completedCrop && (
        <Grid container>
          <Grid item md={12} sx={{ p: 0, m: 0, textAlign: "center" }}>
            <Button
              sx={{ ml: 5, mt: 3, mb: 3 }}
              type="submit"
              variant="contained"
              color="primary"
              onClick={onDownloadCropClick}
              disabled={isSubmitting}
              startIcon={<Save></Save>}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={6}>
        <Grid item md={6}>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={aspect === 2.83 ? 100 : 200}
              disabled={isSubmitting}
              locked={lockedResize ?? true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                onLoad={onImageLoad}
                width={"100%"}
              />
            </ReactCrop>
          )}
        </Grid>
        <Grid item md={6}>
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormCropImage;
