import axios from "src/utils/axios";
import {
  IActualFormulaFanMotorDTO,
  IEETReportServiceModelDTO,
  IFanDTO,
  IFanMotorDTO,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetDTO,
  IHeadSheetStarterDTO,
  IHeadsheetActualRpmPdAlertsDTO,
} from "src/ts/interfaces";

const updateFormula = (id: number, crud: IHeadSheetActualDTO) => {
  return axios.put<IHeadSheetActualDTO>(`HeadSheetReport/formula/${id}`, crud);
};

const headSheetReportService = {
  updateFormula,
};

export default headSheetReportService;
