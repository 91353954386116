import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
} from "src/components/formControls";
import { Stack, Grid, Typography } from "@mui/material";
import { IContractor } from "src/ts/interfaces/contractor";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ICatalogType, ICompanyTrainingCourse } from "src/ts/interfaces";
import { CompanyService } from "src/services";
import NewContractorAddedPopUp from "../projectOutForBid/Components/NewContractorAddedPopUp";
import moment from "moment";
import { CertificationType } from "src/constants";

interface Props {
  showContractorPopUp: boolean;
  setShowContractorPopUp: (val: boolean) => void;
  trainingCourses?: ICatalogType;
  setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGrid: boolean;
}

const columns = (
  handleSelectStudent: (id: number, select: boolean) => void,
  setValue: React.Dispatch<
    React.SetStateAction<ICompanyTrainingCourse | undefined>
  >,
  isSubmitting: boolean
): ColumnType[] => [
  {
    id: "trainingCourse",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: ICompanyTrainingCourse) => {
      return (
        <FormCheckBox
          name={"isSelectedTrainingCourse"}
          label={undefined}
          value={row.isSelectedTrainingCourse}
          onChange={() => {
            row.isSelectedTrainingCourse = !row.isSelectedTrainingCourse;
            setValue({ ...row });
            handleSelectStudent(row.id, row.isSelectedTrainingCourse);
          }}
          showSkeleton={isSubmitting}
        ></FormCheckBox>
      );
    },
  },
  {
    id: "name",
    label: "Company/Student Name",
    type: "string",
    sort: true,
  },
  {
    id: "owner",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "companyAddress",
    label: "Address",
    type: "string",
    sort: true,
  },
];

export default function AddUserTrainingCoursePopUp({
  showContractorPopUp,
  setShowContractorPopUp,
  trainingCourses,
  setRefreshGrid,
  refreshGrid,
}: Props) {
  const [value, setValue] = useState<ICompanyTrainingCourse>();
  const { execute, isLoading, data } = useAsyncQuery(
    CompanyService.getByTrainingCoursesId
  );
  const { execute: executeTrainingCourse, isSubmitting } = useAsyncMutation(
    CompanyService.updateTrainingCourses,
    {
      successfulMessage: "The current training course was updated",
      onSuccess: () => {
        setRefreshGrid(!refreshGrid);
      },
    }
  );
  const [newContractorAddedShowPopUp, setNewContractorAddedShowPopUp] =
    useState(false);

  const handleClosePopUp = () => {
    setShowContractorPopUp(false);
  };

  useEffect(() => {
    const getData = async () => {
      await handleGetAll();
    };
    if (showContractorPopUp) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContractorPopUp, trainingCourses]);

  const handleGetAll = async () => {
    await execute(trainingCourses?.id);
  };

  const handleSelectStudent = async (id: number, select: boolean) => {
    await executeTrainingCourse(id, trainingCourses?.id, select);
  };

  return (
    <>
      <Popup
        title="Add Students"
        openPopup={showContractorPopUp}
        setOpenPopup={setShowContractorPopUp}
        onClose={handleClosePopUp}
        size="md"
      >
        <Stack direction="row">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  Name:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {trainingCourses?.name}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  Description:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {trainingCourses?.description}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  Begin Date:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {moment(trainingCourses?.beginDate).format("MM/DD/YYYY")}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  End Date:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {moment(trainingCourses?.endDate).format("MM/DD/YYYY")}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  Certification:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {trainingCourses?.certification ===
                  CertificationType.EnergyAuditorInfectiousBuildingControl
                    ? "Energy Auditor Infectious Building Control"
                    : trainingCourses?.certification ===
                      CertificationType.TestingAndBalancing
                    ? "Testing And Balancing"
                    : "Energy Auditor Infectious Building Control / Testing And Balancing"}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display={"inline"}>
                  School:{" "}
                </Typography>
                <Typography display={"inline"}>
                  {trainingCourses?.schools?.name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IContractor>
                refreshGrid={true}
                columns={columns(handleSelectStudent, setValue, isSubmitting)}
                data={Object.values(data ?? [])}
                showSkeleton={isLoading}
                orderColumn="desc"
                defaultSortColumn="createdDate"
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormButton
            text="Close"
            size="small"
            variant="outlined"
            onClick={() => {
              setShowContractorPopUp(false);
            }}
          />
        </Stack>
      </Popup>
      {newContractorAddedShowPopUp && (
        <NewContractorAddedPopUp
          newContractorAddedShowPopUp={newContractorAddedShowPopUp}
          setNewContractorAddedShowPopUp={setNewContractorAddedShowPopUp}
          setShowContractorPopUp={setShowContractorPopUp}
          preferred={false}
        />
      )}
    </>
  );
}
