import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
} from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import FormAccordion from "src/components/formControls/FormAccordion";
import { useEffect, useState } from "react";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useAsyncQuery, useForm, useLoading, useLog } from "src/hooks";
import termsAndConditionsService from "src/services/termsAndConditionsService";
import { ITermsAndConditions } from "src/ts/interfaces/catalogs/termsAndConditions";
import termsAndConditionsContractorService from "src/services/termsAndConditionsContractorService";
import projectContestService from "src/services/projectContestService";
import { useNavigate } from "react-router-dom";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import moment from "moment";
import { ITermsAndConditionsOptions } from "src/ts/interfaces/catalogs/termsAndConditionsOptions";

interface ContestTermsProps {
  contestTermsShowPopUp: boolean;
  setContestTermsShowPopUpShowPopUp: (val: boolean) => void;
  currentRow: IProjectOutForBidData | undefined;
}

const initialValues = {
  clause0: false,
  clause1: false,
  clause2: false,
};

export default function ContestTermsPopUp({
  contestTermsShowPopUp,
  setContestTermsShowPopUpShowPopUp,
  currentRow,
}: ContestTermsProps) {
  const [expanded, setExpanded] = useState<string | false>("panel0");
  const [termsAndConditionsOptions, setTermsAndConditionsOptions] =
    useState<ITermsAndConditionsOptions>();
  const [disableButton, setDisableButton] = useState(true);

  const { data, execute, isLoading } = useAsyncQuery<ITermsAndConditions>(
    termsAndConditionsService.getCurrent
  );
  const {
    isLoading: isLoadingAccepted,
    startRequest,
    endRequest,
  } = useLoading();
  const { log } = useLog();
  const { values, setValues } = useForm(initialValues, false, []);
  let navigate = useNavigate();

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const changestatusButton = data.termsAndConditionsTabs.map(
        (value, index) => {
          return values[`clause${index}`];
        }
      );
      const result = changestatusButton.every((value) => value === true);

      if (result) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleCheck = (e: any, item: any) => {
    const { name } = e.target;
    setValues({
      ...values,
      [name]: !values?.[name],
    });
    const number = parseInt(name.match(/\d+/)[0]);
    setExpanded(`panel${number + 1}`);
    item.acceptTerms = !item.acceptTerms;
    setTermsAndConditionsOptions(item);
  };

  const handlerAcceptTerms = async () => {
    try {
      startRequest("Accepted");
      await termsAndConditionsContractorService.postAcceptedTerms(
        currentRow?.id ?? 0,
        { ...data, acceptTerms: true }
      );
      await projectContestService.postCurrentContest(currentRow?.id ?? 0);
      endRequest("Accepted");
      navigate(`/app/TemporalProjectContest/${currentRow?.id}`);
    } catch (error: any) {
      endRequest("Accepted");
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Popup
        title={`Contest for a project: ${currentRow?.code}`}
        openPopup={contestTermsShowPopUp}
        setOpenPopup={setContestTermsShowPopUpShowPopUp}
        onClose={() => {
          setContestTermsShowPopUpShowPopUp(false);
        }}
        size="md"
        disableClickOutside={true}
        hideClose={isLoadingAccepted}
      >
        <>
          <Stack>
            <Stack direction="row" spacing={11}>
              <Typography> Project Name: </Typography>
              <FormLabel text={currentRow?.name} showSkeleton={isLoading} />
            </Stack>
            <Stack direction="row" spacing={20} mt={2}>
              <Typography> Building: </Typography>
              <Stack direction="row" spacing={2}>
                <FormLabel
                  text={currentRow?.buildingName}
                  showSkeleton={isLoading}
                  sx={{ fontWeight: "bold" }}
                />

                <FormLabel
                  text={`${currentRow?.buildingAddress}`}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={5} mt={2}>
              <Typography> Contest due date: </Typography>
              <FormLabel
                text={`${moment(currentRow?.contestDueDate ?? "")?.format(
                  "MM/DD/YYYY h:mm a"
                )}`}
                showSkeleton={isLoading || isLoadingAccepted}
              />
            </Stack>
          </Stack>
          <br />
          {data?.termsAndConditionsTabs.map((item, index) => {
            const value = (
              <FormTextEditor
                value={item.termsAndConditionsOptions?.[0]?.description ?? ""}
                onChange={() => {}}
                name={"description"}
                title={""}
                readonly={true}
              />
            );

            return (
              <FormAccordion
                title={item.description}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <FormCheckBox
                  label={value}
                  name={`clause${index}`}
                  value={values[`clause${index}`]}
                  onClick={(e: any) => {
                    handleCheck(e, item.termsAndConditionsOptions?.[0]);
                  }}
                  labelPlacement="end"
                />
              </FormAccordion>
            );
          })}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            onClick={() => {
              setContestTermsShowPopUpShowPopUp(false);
            }}
            disabled={isLoadingAccepted}
            variant="outlined"
          />
          <FormButton
            size="small"
            text="Accept and Continue"
            color="primary"
            onClick={handlerAcceptTerms}
            disabled={disableButton || isLoadingAccepted}
          />
        </Stack>
      </Popup>
    </>
  );
}
