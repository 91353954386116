import { Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { FormCancelButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { Form } from "src/hooks/useForm";
import { IAdministratorsData } from "src/ts/interfaces";
import { ROLES } from "src/constants";
import PermissionsSwitchDialog from "src/pages/auth/Components/PermissionsSwitchDialog";

interface IPermissionsPopupProps {
  disablePopup: boolean;
  setDisablePopup: (val: boolean) => void;
  currentValues?: IAdministratorsData;
  onSave: () => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  role: string;
  showInUi?: boolean | true;
}

const PermissionsPopup = ({
  disablePopup,
  setDisablePopup,
  currentValues,
  onSave,
  onClick,
  role,
  showInUi,
}: IPermissionsPopupProps) => {
  const [isSubmitting] = useState(false);

  const handleClose = () => {
    setDisablePopup(false);
  };

  return (
    <Popup
      title={
        (role === ROLES.SuperAdministrator
          ? "AMP Administrator"
          : role === ROLES.SysAdmin
          ? "AMP CEO"
          : role === ROLES.UnionAdministrator
          ? "Union Administrator"
          : role === ROLES.ArchitectAux
          ? "Architect Assistant"
          : "Trainer") + " Permissions"
      }
      openPopup={disablePopup}
      setOpenPopup={setDisablePopup}
      onClose={handleClose}
    >
      <>
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <b>Name: </b>{" "}
              <span>{`${currentValues?.name} ${currentValues?.lastName}`}</span>
              <br />
              <b>Phone: </b> <span>{currentValues?.phone}</span>
              <br />
              <b>Email: </b> <span>{currentValues?.email}</span>
              <br />
            </Grid>
            <Grid item xs={12} my={5}>
              <PermissionsSwitchDialog
                userId={currentValues?.id ?? 0}
                role={role}
                permissionType={
                  role === "SysAdmin"
                    ? "System_Administrators"
                    : "Administrators"
                }
                showInUi={showInUi ?? true}
              ></PermissionsSwitchDialog>
            </Grid>
          </Grid>
        </Form>
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormCancelButton
          text="Close"
          onClick={handleClose}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </Popup>
  );
};

export default PermissionsPopup;
