import { CardContent, Grid, Card as MuiCard } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useState } from "react";
import { useEffectOnce } from "src/hooks";
import { IProjectReportInfectiousComplianceControl } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { IElectricCoil } from "src/ts/interfaces/study/electricCoil";
import electricCoilService from "src/services/study/electricCoilService";

interface ElectriCoilStep4 {
  executeReport: (val1: number, val2: string) => void;
  valueId: number;
  codeType: string;
}

const Card = styled(MuiCard)(spacing);

const ElectricCoilStep4 = (props: ElectriCoilStep4) => {
  const { executeReport, codeType, valueId } = props;
  const params = useParams<{ id: string; code: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();

  const [electricCoil, setElectricCoil] = useState<IElectricCoil | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const electricCoilResponse = await electricCoilService.getById(id);
      setElectricCoil(electricCoilResponse.data);
      await getParentReportIsComplete(
        electricCoilResponse.data.id,
        electricCoilResponse.data?.reportTypeId === 28 ? "COEA" : "COET"
      );
      await getProject(electricCoilResponse.data.projectId);
      await getProjectReport(
        electricCoilResponse.data.id,
        electricCoilResponse.data.reportTypeId,
        electricCoilResponse.data.projectId
      );
      await getInfectiousControlData(electricCoilResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(`/studies/ElectricCoil/Step3/${id}/`);
  };

  const handlerActionCompleted = async () => {
    await executeReport(valueId, codeType);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && electricCoil && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={electricCoil?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                      handlerCustomAccept={handlerActionCompleted}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ElectricCoilStep4;
