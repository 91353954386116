import React, { createContext, ReactNode, useContext } from "react";
import { energyElectricConfig } from "src/pages/studies/energy/config/energyElectricConfig";
import { energyHydronicsConfig } from "src/pages/studies/energy/config/energyHydronicsConfig";

const EnergyConfigContext = createContext<
  typeof energyHydronicsConfig | typeof energyElectricConfig | null
>(null);

export const EnergyConfigProvider = ({
  version,
  children,
}: {
  version: string;
  children: ReactNode;
}) => {
  const getConfig = (version: string) => {
    switch (version) {
      case "Electric":
        return energyElectricConfig;
      default:
        return energyHydronicsConfig;
    }
  };
  const config = getConfig(version);
  return (
    <EnergyConfigContext.Provider value={config}>
      {children}
    </EnergyConfigContext.Provider>
  );
};

export const useConfig = () => useContext(EnergyConfigContext);
