import axios from "src/utils/axios";
import { IEnergyStudyElectricBanks } from "src/ts/interfaces";

const getBanksByItemId = (id: number) => {
  return axios.get<IEnergyStudyElectricBanks[]>(
    `EnergyStudyAirHydronics/banks?reportId=${id}`
  );
};

const saveBank = (item: IEnergyStudyElectricBanks) => {
  return axios.put<IEnergyStudyElectricBanks>(
    `EnergyStudyAirHydronics/bank/${item.id}`,
    item
  );
};

const energyStudyElectricBanksService = {
  getBanksByItemId,
  saveBank,
};

export default energyStudyElectricBanksService;
