import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useCountry, useForm, useLog } from "src/hooks";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormMaskText,
  FormSaveButton,
  FormSelect,
  FormSelectState,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { ISchools } from "src/ts/interfaces/catalogs/schools";
import schoolsService from "src/services/catalogs/schoolsService";
import { COUNTRIES } from "src/constants";
import useZipCode from "src/hooks/useZipCode";

interface SchoolFormProps {
  selectedValue?: ISchools;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: ISchools = {
  id: 0,
  name: "",
  address: "",
  address2: "",
  countryId: 235,
  countryCode: "1",
  city: "",
  stateId: 0,
  stateName: "",
  zip: "",
  getFullAddress: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  contactExtension: "",
  statusId: 1,
};

const SchoolForm = (props: SchoolFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();

  const statuses = [
    { key: 1, value: "Enabled" },
    { key: 2, value: "Disabled" },
  ];

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.countryId === COUNTRIES.USA)
      if (fieldValues.stateId === null)
        temp.stateId = "This field is required.";
      else temp.stateId = "";

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The name is required")
      .maxLength(60, "Only 60 character are allowed")
      .toString();

    temp.contactName = new Validator(fieldValues, "contactName")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    if (fieldValues.contactEmail !== "" && fieldValues.contactEmail !== null) {
      temp.contactEmail = new Validator(fieldValues, "contactEmail")
        .maxLength(50, "Only 50 character are allowed")
        .emailFormat("Insert a correct email. demo@bootlab.io")
        .toString();
    }

    if (fieldValues.statusId === 0) {
      temp.statusId = new Validator(fieldValues, "statusId")
        .selectedOption(0, "This field is required")
        .toString();
    } else temp.statusId = "";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { countries, countriesKeyValue } = useCountry();
  const { zipValue } = useZipCode(values.zip);
  const [isStateLoading, setIsStateLoading] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      try {
        setIsLoading(true);
        if (selectedValue?.id === 0) {
          let newValues = { ...selectedValue };
          newValues.statusId = 1;
          setValues({ ...newValues });
        } else setValues(selectedValue);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const handleSave = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      if (values.id === 0) {
        await schoolsService.add(values);
        log.success("The School was added succesfully");
      } else {
        await schoolsService.update(values);
        log.success("The School was updated succesfully");
      }
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    setIsDialogOpen(false);
    onUpdate();
  };

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    if (countries && values.countryCode !== "1") {
      const code = countries.filter((item: any) => {
        return item.id === values.countryId;
      });

      if (values.id === 0 && code.length > 0) {
        setValues({
          ...values,
          countryCode: code[0].countryCode,
        });
      } else if (values.id !== 0 && code.length > 0) {
        setValues({
          ...values,
          countryCode: code[0].countryCode,
        });
      } else {
        setValues({
          ...values,
          countryCode: values.countryCode ?? "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId, countries, values.country]);

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={values.id === 0 ? "New School" : "Edit School"}
        onClose={() => setIsDialogOpen(false)}
        size="sm"
        isSubmitting={isSubmitting}
        disableClickOutside={true}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="address"
                label="Address"
                value={values.address}
                onChange={handleInputChange}
                error={errors.address}
                disabled={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="address2"
                label="Address 2"
                value={values.address2}
                onChange={handleInputChange}
                error={errors.address2}
                disabled={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name="countryId"
                label="Country"
                value={values.countryId}
                onChange={handleInputChange}
                options={countriesKeyValue}
                error={errors.countryId}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="zip"
                label="Zip Code"
                value={values.zip}
                onChange={handleInputChange}
                error={errors.zip}
                disabled={isSubmitting}
                maxLength={6}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="city"
                label="City"
                value={values.city}
                onChange={handleInputChange}
                error={errors.city}
                disabled={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              {values && values.countryId === COUNTRIES.USA ? (
                <FormSelectState
                  stateId={values.stateId}
                  stateName={values.stateName}
                  countryId={values.countryId}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  disable={isSubmitting}
                />
              ) : (
                <FormText
                  name="stateName"
                  label="State"
                  value={values.stateName}
                  onChange={handleInputChange}
                  error={errors.stateName}
                  disabled={isSubmitting}
                  showSkeleton={isLoading}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="contactName"
                label="Contact´s Name"
                value={values.contactName}
                onChange={handleInputChange}
                error={errors.contactName}
                disabled={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="contactEmail"
                label="Contact's Email"
                value={values.contactEmail}
                onChange={handleInputChange}
                error={errors.contactEmail}
                disabled={isSubmitting}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <FormText
                    name="countryCode"
                    label="Code"
                    value={"+ " + values.countryCode}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormMaskText
                    name="contactPhone"
                    mask="(999) 999 - 9999"
                    label="Contact's Phone Number"
                    onChange={handleInputChange}
                    value={values?.contactPhone}
                    error={errors.contactPhone}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="contactExtension"
                label="Extension Number"
                value={values.contactExtension}
                onChange={handleInputChange}
                error={errors.contactExtension}
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name="statusId"
                label="Status"
                value={values.statusId}
                onChange={handleInputChange}
                options={statuses}
                error={errors.statusId}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton
              onClick={handleClose}
              isSubmitting={isSubmitting}
            />
            {fullAccess && (
              <FormSaveButton
                onClick={handleSave}
                isSubmitting={isSubmitting}
                text={values.id === 0 ? "Add" : "Update"}
              />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default SchoolForm;
