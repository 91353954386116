import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  FormLabelProps,
  FormControlProps,
} from "@mui/material";
import { Mode } from "src/ts/types";
import FormReadOnlyField from "./FormReadOnlyField";

interface RadioGroupProps {
  name: string;
  label?: string;
  value?: number | string | null | undefined;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  items?: any[] | null;
  defaultValue?: any;
  disabled?: boolean;
  showSkeleton?: boolean;
  row?: boolean;
  error?: any;
  mode?: Mode;
  textColor?: string;
  labelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  radioGroupProps?: RadioGroupProps;
}

export default function FormRadioGroup(props: RadioGroupProps) {
  const {
    name,
    label,
    onChange,
    items,
    defaultValue,
    value,
    disabled,
    showSkeleton,
    row,
    error,
    mode,
    labelProps,
    formControlProps,
    radioGroupProps,
  } = props;
  if (mode && mode === "read") {
    // eslint-disable-next-line eqeqeq
    const selected = items?.find((item) => item.key == value);

    return label || selected?.labelCustom ? (
      <FormReadOnlyField
        label={label ? label : selected.labelCustom}
        value={selected?.value ?? ""}
      />
    ) : (
      <></>
    );
  }

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <FormControl
          {...formControlProps}
          {...(error && { error: true, helperText: error })}
        >
          {label ? (
            <FormLabel tabIndex={-1} {...labelProps}>
              {label}
            </FormLabel>
          ) : null}
          <RadioGroup
            {...radioGroupProps}
            row={row}
            name={name}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            tabIndex={-1}
          >
            {items &&
              items.map((item, index) => (
                <>
                  <FormControlLabel
                    key={index}
                    tabIndex={-1}
                    value={item.key}
                    control={<Radio />}
                    label={item.labelCustom ? item.labelCustom : item.value}
                    disabled={disabled || item.disabled}
                    sx={{
                      ...(item.color !== undefined && { color: item.color }),
                    }}
                  />
                  {item.children && item.children()}
                </>
              ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
}
