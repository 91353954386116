import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import { useMemo, useState } from "react";
import { IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const/const";
import { useNavigate, useParams } from "react-router-dom";
import towerService from "src/services/study/towerService";
import TowerList from "./components/TowerList";
import TowerForm from "./components/TowerForm";
import useTowerStudy from "../hooks/useTowerStudy";
import { IChillerTowerFanMotor, ITower } from "src/ts/interfaces/study/tower";
import { Mode } from "src/ts/types";
import FanMotorList from "../towerStep31/components/FanMotorList";
import FanMotorForm from "./components/FanMotorForm";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 1;

const TowerStep2 = () => {
  const {
    setTowerCondenserValues,
    setTowerCondensers,
    towerCondensers,
    saveTowerCondenser,
    fanMotorValues,
    setFanMotorValues,
    fanMotors,
    setFanMotors,
  } = useTowerStudy();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();

  const [tower, setTower] = useState<ITower>();
  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const towerReponse = await towerService.getBydId(id);
        setTower(towerReponse.data);

        const condensersReponse = await towerService.towerCondensers(id);
        setTowerCondensers(condensersReponse.data);

        if (condensersReponse.data.length > 0) {
          setTowerCondenserValues(condensersReponse.data[0]);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);

        await systemOfMeasurement.getByProject(towerReponse.data.projectId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const mode: Mode = tower?.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      tower?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    tower?.isComplete,
  ]);

  const nextStepHandler = async () => {
    const route = `/studies/Tower/step3/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }

    if (
      (fanMotorValues.nameplateHorsePowerId == null ||
        fanMotorValues.nameplateHorsePowerId === 0) &&
      !fanMotorValues.horsepowerUnknown
    ) {
      log.error(
        'To move forward, Nameplate HP is required. If you don\'t know the HP, select the "Nameplate HP unknown" checkbox.'
      );
      return;
    }

    await saveHandler();
    navigate(route);
  };

  const previousStepHandler = async () => {
    const route = `/studies/Tower/step1/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }

    await saveHandler();
    navigate(route);
  };

  const saveHandler = async () => {
    await saveTowerCondenser();
    await saveFanMotor();
  };

  const selectMotorHandler = async (
    selectedFanMotor: IChillerTowerFanMotor
  ) => {
    if (!disableStudy) {
      await saveFanMotor();
    }

    const fanMotorRes = await towerService.getFanMotorsById(
      selectedFanMotor.id
    );
    setFanMotorValues(fanMotorRes.data);
  };

  const saveFanMotor = async () => {
    await towerService.updateFanMotor(fanMotorValues.id, fanMotorValues);
  };

  const [fanMotorForDelete, setFanMotorForDelete] =
    useState<IChillerTowerFanMotor | null>(null);
  const showModalDeleteFanMotor = (fanMotor: IChillerTowerFanMotor) => {
    setFanMotorForDelete(fanMotor);
    setShowDeleteModal(true);
  };

  const deleteFanMotor = async () => {
    if (fanMotorForDelete === null) return;

    await towerService.removeFanMotor(fanMotorForDelete.id);
    setShowDeleteModal(false);

    if (fanMotors === null) return;

    const index = fanMotors?.findIndex((x) => x.id === fanMotorForDelete.id);
    const newFanMotors = [...fanMotors];
    newFanMotors.splice(index, 1);
    setFanMotors(newFanMotors);
    if (newFanMotors.length > 0) setFanMotorValues(newFanMotors[0]);
  };

  const addFanMotor = async (
    fanMotor: IChillerTowerFanMotor,
    index: number
  ) => {
    await towerService.updateFanMotor(fanMotorValues.id, fanMotorValues);

    const chillerFanMotor: any = {
      chillerTowerCondenserId: fanMotorValues.chillerTowerCondenserId,
    };

    const fanMotorRes = await towerService.addFanMotor(chillerFanMotor);

    if (fanMotors === null) return;
    const newFanMotors = [...fanMotors];

    newFanMotors.splice(index + 1, 0, fanMotorRes.data);
    setFanMotors(newFanMotors);
    setFanMotorValues(fanMotorRes.data);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: tower?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Tower/Condenser Nameplate Data
                </Typography>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TowerList
                    towerCondensers={towerCondensers}
                    isStudyComplete={tower?.isComplete}
                    saveFanMotor={saveFanMotor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TowerForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Fan/Motors</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FanMotorList
                    showDelete={true}
                    showDuplicate={true}
                    onSelect={selectMotorHandler}
                    onDelete={showModalDeleteFanMotor}
                    onAdd={addFanMotor}
                    disableStudy={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FanMotorForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={tower?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Delete Fan/Motor."}
        text={"Are certain you want to remove this Fan/Motor?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => {
          deleteFanMotor();
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default TowerStep2;
