import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Typography,
  Skeleton,
} from "@mui/material";
import { useLog, useAuth, useAsyncQuery } from "src/hooks";
import { FormButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import CompleteValidateBuildingPicture from "./CompleteValidateBuildingPicture";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import buildingPhotoService from "src/services/buildingPhotoService";
import CompleteAcceptance from "./CompleteAcceptance";
import moment from "moment";
import {
  commonService,
  pdfExportService,
  projectReportChild,
  reportFileService,
} from "src/services";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import {
  PictureAsPdfIcon,
  SpeedOutlinedIcon,
  ViewListIcon,
  RestartAltIcon,
} from "src/components/icons";
import {
  IProjectReporDisplayt,
  IProjectReportInfectiousComplianceControl,
  IReportType,
} from "src/ts/interfaces";
import reportTypeService from "src/services/study/reportTypeService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FileUtils from "src/utils/file";
import ProgressBarModal from "src/components/modals/ProgressBarModal";
import recycleBinService from "src/services/recycleBinService";
import { useNavigate } from "react-router-dom";
import projectService from "src/services/project/projectService";
import Study from "src/pages/project/projectStudies/components/Study";
import CompleteValidateStudies from "src/pages/project/projectStudies/components/CompleteValidateStudies";
import IncompleteStudies from "src/pages/project/projectStudies/components/IncompleteStudies";
import CompletePayment from "src/pages/project/projectStudies/components/CompletePayment";
import CompletePaymentStudy from "./CompletePaymentStudy";

let urls: string[] = [];
urls[1] = "PumpReport/EndTest/#reportId?endDate=#date";
urls[2] = "HeadSheetReport/EndTest/#reportId?endDate=#date";
urls[3] = "HeadSheetReport/EndTest/#reportId?endDate=#date";
urls[4] = "Coils/EndTest/#reportId?endDate=#date";
urls[5] = "PitotTraverseTest/EndTest/#reportId?endDate=#date";
urls[6] = "VelgridMatrix/endTest/#reportId?endDate=#date";
urls[7] = "StaticReport/endTest/#reportId?date=#date";
urls[9] = "OutletMaster/endTest/#reportId?endDate=#date";
urls[10] = "GeneralMatrix/endTest/#reportId?endDate=#date";
urls[11] = "GeneralMatrix/endTest/#reportId?endDate=#date";
urls[12] = "GeneralMatrix/endTest/#reportId?endDate=#date";
urls[13] = "GeneralMatrix/endTest/#reportId?endDate=#date";
urls[14] = "OutletReport/EndTest/#reportId?endDate=#date";
urls[15] = "OutletReport/EndTest/#reportId?endDate=#date";
urls[16] = "OutletReport/EndTest/#reportId?endDate=#date";
urls[17] = "Coils/EndTest/#reportId?endDate=#date";
urls[18] =
  "EnergyStudyAirHydronics/EndTest/#reportId?endDate=#date&sendToCart=#sendToCart&coupon=#coupon";
urls[19] = "BoilerTest/EndTest/#reportId?endDate=#date";
urls[20] = "PitotTraverseTest/EndTest/#reportId?endDate=#date";
urls[21] = "Chiller/EndTest/#reportId?endDate=#date";
urls[22] = "Tower/EndTest/#reportId?endDate=#date";
urls[24] =
  "EnergyStudyAirHydronics/EndTest/#reportId?endDate=#date&sendToCart=#sendToCart&coupon=#coupon";
urls[27] = "ElectricCoil/EndTest/#reportId?endDate=#date";
urls[28] = "ElectricCoil/EndTest/#reportId?endDate=#date";

enum WizardStepsEnum {
  ValidateBuildingPicture,
  ValidateIncompleteStudies,
  EquipmentValidation,
  Acceptance,
  Comments,
  Complete,
  PaymentConfirmation,
}

interface CompleteProjectStep {
  label: string;
  key: number;
  type: WizardStepsEnum;
}

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  project: ProjectDTO;
  projectReport: IProjectReport;
  completed: boolean;
  infectiousComplianceControl: IProjectReportInfectiousComplianceControl | null;
  parentStudy: IProjectReport | null;
  singleStep?: boolean;
  handlerCustomAccept?: () => void;
  handlerPreCustomAccept?: () => void;
  hideReopenStudyButton?: boolean;
}

const CompleteStudyPopup = (props: ConfirmModalProps) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    project,
    projectReport,
    completed: initialCompleted,
    infectiousComplianceControl,
    parentStudy,
    singleStep,
    handlerCustomAccept,
    hideReopenStudyButton,
    handlerPreCustomAccept,
  } = props;
  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [steps, setSteps] = useState<CompleteProjectStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [switchBackStudiesModal, setSwitchBackStudiesModal] = useState(false);
  const [reportType, setReportType] = useState<IReportType | null>(null);
  const [completed, setCompleted] = useState(initialCompleted);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [showStudyIncompleteDialog, setShowStudyIncompleteDialog] =
    useState(false);
  const [showNoCompletionFeeDialog, setShowNoCompletionFeeDialog] =
    useState(false);
  const [showReopenDialog, setShowReopenDialog] = useState(false);

  const { execute, data, isLoading } = useAsyncQuery<IProjectReporDisplayt[]>(
    projectReportChild.getChildReports
  );

  const [showIncompleteStudiesPopup, setShowIncompleteStudiesPopup] =
    useState(false);

  const {
    execute: executeParent,
    data: dataParent,
    isLoading: isLoadingPArent,
  } = useAsyncQuery<IProjectReporDisplayt[]>(
    projectReportChild.getChildReportsParents
  );

  const { execute: executeChild, data: dataChild } = useAsyncQuery<
    IProjectReporDisplayt[]
  >(projectReportChild.getChildReports);

  const getReportType = async () => {
    try {
      const reportType = await reportTypeService.getReportTypeById(
        projectReport.reportTypeId
      );
      setReportType(reportType.data);
    } catch (error) {}
  };

  const setupWizard = async () => {
    if (handlerPreCustomAccept) handlerPreCustomAccept();
    const hasBuildingResponse = await buildingPhotoService.hasBuildingProject(
      project.id
    );

    const hasBuildingPhoto = hasBuildingResponse.data;

    await getReportType();
    let wizardSteps: CompleteProjectStep[] = [];
    let xitems = -1;

    if (!hasBuildingPhoto) {
      wizardSteps.push({
        key: xitems++,
        label: "Validate Building Picture",
        type: WizardStepsEnum.ValidateBuildingPicture,
      });
    }

    if (dataChild) {
      var incompleteReports = dataChild.filter(function (item) {
        return item.studyStatus !== "Complete";
      });

      if (incompleteReports.length > 0) {
        wizardSteps.push({
          key: xitems++,
          label: "Validate Incomplete Studies",
          type: WizardStepsEnum.ValidateIncompleteStudies,
        });
      }
    }

    if (
      projectReport.reportTypeId === 18 ||
      projectReport.reportTypeId === 24
    ) {
      wizardSteps.push({
        key: xitems++,
        label: "Confirmation",
        type: WizardStepsEnum.PaymentConfirmation,
      });
    } else {
      wizardSteps.push({
        key: xitems++,
        label: "Confirmation",
        type: WizardStepsEnum.Acceptance,
      });
    }

    setSteps([...wizardSteps]);
    setActiveStep(0);
  };

  useEffect(() => {
    if (isDialogOpen) {
      setupWizard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );

    executeChild(
      projectReport.projectId,
      projectReport.reportId,
      projectReport.reportTypeId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousStepHandler = () => {
    setActiveStep(activeStep - 1);
  };

  const nexStepHandler = async () => {
    setActiveStep(activeStep + 1);
  };

  const cancelHandler = () => {
    setActiveStep(0);
    //resetAceptanceForm();
    setIsDialogOpen(false);
  };

  const completProject = async (url: string) => {
    try {
      await commonService.put(url, null);
      setCompleted(true);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const generatePdf = async (projectReport: IProjectReport) => {
    try {
      await pdfExportService.generatePdfStudyAndSubstudyWithConfirmation(
        projectReport.projectId,
        projectReport.reportId,
        projectReport.reportTypeId
      );
    } catch (error) {
      log.error(
        "Report was completed, but was not possible to generate the pdf report."
      );
    }
  };

  const getValidatedDate = () => {
    const localDate = moment().toDate();
    let validatedDate = moment(localDate).format("MM/DD/YYYY HH:mm");
    return validatedDate.toLocaleString();
  };

  const completeStudyHandler = async () => {
    try {
      setIsSubmitting(true);
      const templateUrl = urls[projectReport.reportTypeId];

      const validatedDate = getValidatedDate();

      const url = templateUrl
        .replace("#reportId", projectReport.reportId.toString())
        .replace("#date", validatedDate)
        .replace(
          "#sendToCart",
          project.isInfectiousControlProject ? "false" : "null"
        );

      await completProject(url);
      //cart

      await studiesHistoryService.save(
        projectReport.projectId,
        projectReport.reportTypeId,
        projectReport.reportId,
        "completed"
      );
      log.info("Report was completed succesfully");
      if (handlerCustomAccept) {
        handlerCustomAccept();
      }
      setIsDialogOpen(false);
      generatePdf(projectReport);
    } catch (error) {
      log.error(
        "Report was completed, but was not possible to generate the pdf report."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const viewReportPdfHandler = async () => {
    if (project !== undefined) {
      if (
        project.isInfectiousControlProject &&
        infectiousComplianceControl?.projectPurchase.paymentStatus === "Paid"
      ) {
        download();
      } else if (
        project.isPaid !== 0 &&
        project.isPaid !== null &&
        infectiousComplianceControl?.projectPurchase != null &&
        infectiousComplianceControl?.projectPurchase.paymentStatus === "Paid"
      ) {
        if (
          projectReport.studyStatusId === 5 &&
          reportType?.reportCategoryTypeId === 3
        ) {
          setShowStudyIncompleteDialog(true);
        } else {
          download();
        }
      } else if (project.projectStatusId === 3) {
        if (
          project.isPaid === 0 ||
          project.isPaid == null ||
          (project.isInfectiousControlProject &&
            (infectiousComplianceControl?.projectPurchase == null ||
              infectiousComplianceControl?.projectPurchase.paymentStatus !==
                "Paid"))
        ) {
          setShowNoCompletionFeeDialog(true);
        } else {
          //TODO
          if (projectReport.studyStatusId === 5) {
            setShowStudyIncompleteDialog(true);
          } else {
            download();
          }
        }
      } else if (
        infectiousComplianceControl?.projectPurchase != null &&
        infectiousComplianceControl?.projectPurchase.paymentStatus === "Paid" &&
        projectReport.studyStatusId !== 5
      ) {
        download();
      } else {
        //TODO
        if (projectReport.studyStatusId === 5) {
          setShowStudyIncompleteDialog(true);
        } else {
          setShowNoCompletionFeeDialog(true);
        }
      }
    }
  };
  const [progress, setProgress] = useState(0);

  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };
  const [showProgressBarModal, setProgressBarModal] = useState(false);
  const download = async () => {
    try {
      setProgressBarModal(true);
      setProgress(0);

      const response = await pdfExportService.downloadStudy(
        projectReport.id,
        onProgressDownload
      );

      FileUtils.downloadFile(response.data.content, response.data.filename);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setProgressBarModal(false);
    }
  };

  const showReopenStudyButton =
    completed &&
    !hideReopenStudyButton &&
    (project.projectStatusId === 1 || project.projectStatusId === 2) &&
    !project.isInfectiousControlProject;

  const reopenStudyHandler = async () => {
    const recoverStudyRes = await recycleBinService.recoverStudy(
      projectReport?.id
    );
    await studiesHistoryService.save(
      projectReport.projectId,
      projectReport.reportTypeId,
      projectReport.reportId,
      "reopened"
    );
    await reportFileService.remove(projectReport.id);
    if (handlerCustomAccept) {
      handlerCustomAccept();
    }
    if (window.location.pathname === `/studies/${recoverStudyRes.data}`) {
      setCompleted(false);
      navigate(`/studies/${recoverStudyRes.data}`, { replace: true });
    } else {
      navigate(`/studies/${recoverStudyRes.data}`);
    }
  };

  const hasChildReports = false; //TODO
  const hideBackToStudiesButton =
    completed || parentStudy?.parentReportId != null;

  const handleSwitchBack = async () => {
    setSwitchBackStudiesModal(true);
    await execute(
      projectReport.projectId,
      projectReport.reportId,
      projectReport.reportTypeId
    );
    await executeParent(projectReport.projectId, projectReport.reportId);
  };

  const onSelectStudy = (study: any) => {
    if (study.isComplete) {
      navigate(`/studies/${study.reportTypeRouteComplete}`.replace("//", "/"));
    } else {
      navigate(`/studies/${study.reportTypeRoute}`.replace("//", "/"));
    }
  };

  const completeProjectHandler = async (
    sendToCart: boolean,
    coupons: string
  ) => {
    try {
      setIsSubmitting(true);
      const templateUrl = urls[projectReport.reportTypeId];

      const validatedDate = getValidatedDate();

      const url = templateUrl
        .replace("#reportId", projectReport.reportId.toString())
        .replace("#date", validatedDate)
        .replace("#coupon", coupons)
        .replace("#sendToCart", String(sendToCart));
      await completProject(url);
      //cart

      await studiesHistoryService.save(
        projectReport.projectId,
        projectReport.reportTypeId,
        projectReport.reportId,
        "completed"
      );
      log.info("Report was completed succesfully");
      setIsDialogOpen(false);
      generatePdf(projectReport);
    } catch (error) {
      log.error(
        "Report was completed, but was not possible to generate the pdf report."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        {!completed && (
          <FormButton
            onClick={() => {
              setIsDialogOpen(true);
            }}
            isSubmitting={false}
            text={
              parentStudy?.parentReportId != null &&
              parentStudy.parentReportId > 0
                ? "Complete this Individual Study"
                : "Complete this Main/Primary Study"
            }
            size="small"
            startIcon={<SpeedOutlinedIcon />}
            disabled={
              !projectReport.isPartOfProject ||
              disableAfterSteadyStep ||
              singleStep
            }
          />
        )}
        {completed && (
          <FormButton
            onClick={viewReportPdfHandler}
            isSubmitting={false}
            text={"Download this Individual Study as a PDF File"}
            size="small"
            startIcon={<PictureAsPdfIcon />}
          />
        )}

        {projectReport.parentReportStatusId !== 3 && showReopenStudyButton && (
          <FormButton
            onClick={() => setShowReopenDialog(true)}
            isSubmitting={false}
            text={
              parentStudy?.parentReportId != null &&
              parentStudy.parentReportId > 0
                ? "Reopen this Individual Study"
                : "Reopen this Main/Primary Study"
            }
            size="small"
            variant="outlined"
            startIcon={<RestartAltIcon />}
          />
        )}
        {!hideBackToStudiesButton && (
          <FormButton
            onClick={handleSwitchBack}
            isSubmitting={false}
            text={
              hasChildReports
                ? "Switch Back to Studies/Sub-Studies"
                : "Switch Back to Studies"
            }
            size="small"
            variant="outlined"
            startIcon={<ViewListIcon />}
          />
        )}
      </Stack>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title="Complete Study"
        onClose={cancelHandler}
        size="md"
        disableClickOutside={true}
        isSubmitting={isSubmitting}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.key}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {steps[activeStep]?.type ===
                  WizardStepsEnum.ValidateBuildingPicture && (
                  <CompleteValidateBuildingPicture
                    buildingId={project.buildingId}
                    companyId={user?.companyId}
                  />
                )}
                {steps[activeStep]?.type ===
                  WizardStepsEnum.ValidateIncompleteStudies && (
                  <CompleteValidateStudies
                    showIncompleteStudiesPopup={() => {
                      setShowIncompleteStudiesPopup(true);
                    }}
                    cancelHandler={cancelHandler}
                  />
                )}
                {steps[activeStep]?.type === WizardStepsEnum.Acceptance && (
                  <CompleteAcceptance
                    onComplete={completeStudyHandler}
                    isSubmitting={isSubmitting}
                  />
                )}
                {steps[activeStep]?.type ===
                  WizardStepsEnum.PaymentConfirmation && (
                  <CompletePaymentStudy
                    onCompleteProject={completeProjectHandler}
                    projectId={project.id}
                    typeId={projectReport.reportTypeId}
                    isInfect={project.isInfectiousControlProject}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {activeStep > 0 && (
              <FormButton
                text="← Previous Step"
                onClick={previousStepHandler}
                size="small"
                variant="outlined"
                disabled={isSubmitting}
              />
            )}
            <FormButton
              text="Cancel"
              onClick={cancelHandler}
              size="small"
              color="primary"
              variant="outlined"
              disabled={isSubmitting}
            />
            {activeStep < steps.length - 1 && (
              <FormButton
                text="Next Step →"
                onClick={nexStepHandler}
                size="small"
                disabled={isSubmitting}
              />
            )}
          </Stack>
        </>
      </Popup>
      <DialogMessagePopup
        title="Study Uncompleted"
        text={
          "The Study is incomplete and has been closed. The PDF will not be available."
        }
        showPopup={showStudyIncompleteDialog}
        setShowPopup={setShowStudyIncompleteDialog}
        onSave={() => setShowStudyIncompleteDialog(false)}
        onCancel={() => {
          setShowStudyIncompleteDialog(false);
        }}
        isSubmitting={false}
      />

      <DialogMessagePopup
        title="Completion Fee not yet Received"
        text={
          "You will see the PDF Report once the completion fee is received."
        }
        showPopup={showNoCompletionFeeDialog}
        setShowPopup={setShowNoCompletionFeeDialog}
        onSave={() => setShowNoCompletionFeeDialog(false)}
        onCancel={() => {
          setShowNoCompletionFeeDialog(false);
        }}
        isSubmitting={false}
        hideAccept={true}
        cancelTextButton="Close"
      />

      <DialogMessagePopup
        title="Information"
        text={"Are you certain you want to reopen this Report Study?"}
        showPopup={showReopenDialog}
        setShowPopup={setShowReopenDialog}
        onSave={reopenStudyHandler}
        onCancel={() => {
          setShowReopenDialog(false);
        }}
        isSubmitting={false}
      />

      <ProgressBarModal
        progress={progress}
        isDialogOpen={showProgressBarModal}
        setIsDialogOpen={setProgressBarModal}
        text={"Download zip file"}
      />
      <Popup
        title={"Switch Back to Studies"}
        openPopup={switchBackStudiesModal}
        setOpenPopup={setSwitchBackStudiesModal}
        onClose={() => {
          setSwitchBackStudiesModal(false);
        }}
      >
        <>
          {data && (
            <>
              <Typography variant="h4">Sub studies</Typography>
              <Grid container xs={12} spacing={0} item>
                {data?.map((item) => (
                  <Grid item xs={12} md={12} key={item.id}>
                    <Study
                      study={item}
                      projectId={projectReport.projectId}
                      onSelectStudy={onSelectStudy}
                      isSelectable={false}
                      readOnly={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {dataParent && (
            <>
              <Typography variant="h4">Other studies</Typography>
              <Grid container xs={12} spacing={0} item>
                {dataParent?.map((item) => (
                  <Grid item xs={12} md={12} key={item.id}>
                    <Study
                      study={item}
                      projectId={projectReport.projectId}
                      onSelectStudy={onSelectStudy}
                      isSelectable={false}
                      readOnly={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {!data && !dataParent && !isLoading && !isLoadingPArent && (
            <>
              <Typography>No other studies has been created</Typography>
            </>
          )}
          {(isLoading || isLoadingPArent) && (
            <>
              <Skeleton height={80}></Skeleton>
              <Skeleton height={80}></Skeleton>
              <Skeleton height={80}></Skeleton>
              <Skeleton height={80}></Skeleton>
            </>
          )}
        </>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormButton
              text="Close"
              onClick={() => {
                setSwitchBackStudiesModal(false);
              }}
              size="small"
              color="primary"
              variant="outlined"
              disabled={isSubmitting}
            />
          </Stack>
        </>
      </Popup>
      <IncompleteStudies
        isDialogOpen={showIncompleteStudiesPopup}
        setIsDialogOpen={setShowIncompleteStudiesPopup}
        studies={dataChild}
      />
    </>
  );
};

export default CompleteStudyPopup;
