import React, { useEffect, useState } from "react";

import { Grid, Typography, Stack, Chip, Radio, Button } from "@mui/material";
import Popup from "src/components/Popup";
import {
  Form,
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  usePermissions,
} from "src/hooks";
import FormNumeric from "src/components/formControls/FormNumeric";
import { IKeyValue, IUnionAffiliated, IUserPerson } from "src/ts/interfaces";
import moment from "moment";
import { CatalogService, localUnionsService } from "src/services";
import {
  FormCancelButton,
  FormCheckBox,
  FormSaveButton,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { ILaborUnions } from "src/ts/interfaces/catalogs/laborUnions";
import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ROLES } from "src/constants";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import unionAffiliatedService from "src/services/unionAffiliatedService";
import { convertUTCDateToLocalDate } from "src/utils/utils";
interface ProfileFormProp {
  values: IUserPerson;
  setValues: (val: IUserPerson) => void;
  errors: any;
}

const UnionSection = (props: ProfileFormProp) => {
  const { values, setValues, errors } = props;
  const { user } = useAuth();
  const [informationShowPopUp, setInofrmationShowPopUp] = useState(false);
  const [dataLaborUnions, setDataLaborUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLaborUnions, setIsLoadingLaborUnions] = useState(false);
  const [dataLocalUnions, setDataLocalUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLocalUnions, setIsLoadingLaocalUnions] = useState(false);
  const { fullAccess: fullAccessUnion } = usePermissions(
    PermissionTypes.User_Union_Affiliation_Section
  );
  const [commentsUpdate, setCommentsUpdate] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [isAffiliated, setIsAffiliated] = useState(false);
  const [proceedUpdate, setProceedUpdate] = useState(false);
  const [unionChange, setUnionChange] = useState(false);
  const { execute: executeUnionAffiliated, data: valuesUnion } =
    useAsyncQuery<IUnionAffiliated>(unionAffiliatedService.UnionAffiliatedUser);
  useEffect(() => {
    setValues({
      ...values,
      unionAffiliated: valuesUnion,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesUnion]);
  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaborUnions(true);
      var result = await CatalogService.getAllDropdown("LaborUnions");
      setDataLaborUnions(result);
      setIsLoadingLaborUnions(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(values?.id);
  useEffect(() => {
    const getData = async () => {
      executeUnionAffiliated(values?.id);
    };
    if (values?.id && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.id]);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaocalUnions(true);
      var result = await localUnionsService.getKeyValuesByLabor(
        values?.unionAffiliated?.laborUnionsId ?? 0
      );
      setDataLocalUnions(result);
      setIsLoadingLaocalUnions(false);
      if (result.length === 1) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            localUnionsId: result[0].key,
          },
        });
      }
    };

    if (
      values?.unionAffiliated?.laborUnionsId &&
      values?.unionAffiliated?.laborUnionsId > 0
    )
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.unionAffiliated?.laborUnionsId]);

  const { execute: saveAffiliation, isSubmitting: isSubmitingAffiliation } =
    useAsyncMutation(unionAffiliatedService.isAffiliatedByUser, {
      successfulMessage: "The current affiliation was saved",
      onSuccess: () => {
        setCommentsUpdate("");
        setProceedUpdate(false);
        setUpdatePopup(false);
        setUnionChange(!unionChange);
        executeUnionAffiliated(values?.id);
      },
    });

  const isOtherLaborUnion = () => {
    if (!dataLaborUnions) return false;
    const found = dataLaborUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.unionAffiliated?.laborUnionsId === found?.key;
  };

  const isOtherLocalUnion = () => {
    if (!dataLocalUnions) return false;
    const found = dataLocalUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.unionAffiliated?.localUnionsId === found?.key;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      unionAffiliated: { ...values.unionAffiliated, [name]: value },
    });
    if (name === "laborUnionsId") {
      const found = dataLaborUnions.find(
        (element) => element.value === "SMWIA/SMART"
      );
      if (found?.key === value) setInofrmationShowPopUp(true);
      const labor: ILaborUnions = {
        id: found?.key ?? 0,
        name: found?.value ?? "",
        orderPosition: 0,
        createdById: 0,
        updatedId: 0,
        statusId: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
      };

      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          laborUnions: labor,
          [name]: value,
        },
      });
    }

    if (name === "localUnionsId") {
      const found = dataLocalUnions.find(
        (element) => element.value === "SMWIA/SMART"
      );
      if (found?.key === value) setInofrmationShowPopUp(true);
      const local: ILocalUnions = {
        id: found?.key ?? 0,
        name: found?.value ?? "",
        orderPosition: 0,
        createdById: 0,
        updatedId: 0,
        statusId: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
        laborUnionsId: 0,
        laborUnionsName: "",
      };

      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          localUnions: local,
          [name]: value,
        },
      });
    }
  };

  const handleClosePopUp = () => {
    setInofrmationShowPopUp(false);
  };

  const handleOnChangeText = (e: any) => {
    setValues({
      ...values,
      unionAffiliated: {
        ...values.unionAffiliated,
        [e.target.name]: e.target.value,
        laborUnionsId: values?.unionAffiliated?.laborUnionsId,
        localUnionsId: values?.unionAffiliated?.localUnionsId,
      },
    });
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    const answerValue = value === "true" ? false : true;

    if (name === "apprentice") {
      setValues({
        ...values,
        unionAffiliated: {
          ...values.unionAffiliated,
          apprentice: value === "true" ? false : true,
          laborUnionsId: values.unionAffiliated?.laborUnionsId,
          localUnionsId: values.unionAffiliated?.localUnionsId,
          localUnionsOther: values.unionAffiliated?.localUnionsOther,
        },
      });
    } else {
      if (answerValue) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            [e.target.name]: value === "true" ? false : true,
            apprentice: false,
            smwiaMember: "",
            localUnionsOther: "",
            unionAffiliationNumbers: {
              localUnion: "",
            },
            laborUnionsId: 0,
            localUnionsId: 0,
          },
        });
      } else {
        setValues({
          ...values,
          unionAffiliated: null,
        });
      }

      if (values?.unionAffiliated?.unionAffiliatedAnswer === false) {
        setValues({
          ...values,
          unionAffiliated: {
            ...values.unionAffiliated,
            unionAffiliatedAnswer: true,
            apprentice: false,
            smwiaLocalUnion: "",
            smwiaMember: "",
            localUnionsOther: "",
            laborUnionsId: 0,
            localUnionsId: 0,
            unionAffiliationNumbers: {
              localUnion: "",
            },
          },
        });
      }
    }
  };
  const isUnderEvaluation =
    values.unionAffiliated != null &&
    values.unionAffiliated.code != null &&
    values.unionAffiliated.isAffiliated == null;

  const disableUnionNumber =
    values.unionAffiliated != null &&
    (values.unionAffiliated.isAffiliated === true || isUnderEvaluation) &&
    !(isUnderEvaluation && user?.role === ROLES.UnionAdministrator);

  const commentsAcceptedRejected =
    values.unionAffiliated?.updatedAcceptRejectDate &&
    values.unionAffiliated.isAffiliated !== null &&
    (values.unionAffiliated.isAffiliated === true ||
      values.unionAffiliated.isAffiliated === false);

  const row = 12;
  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={row}>
            {values.unionAffiliated !== null && (
              <>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    {isUnderEvaluation ? (
                      <Chip
                        label="Under evaluation by Union administrator"
                        color="primary"
                        size="small"
                      />
                    ) : null}
                    {values?.unionAffiliated?.isAffiliated === true &&
                      values?.unionAffiliated?.isCanceled === false && (
                        <Chip label="VALIDATED" color="success" size="small" />
                      )}
                    {values?.unionAffiliated?.isAffiliated === false &&
                      values?.unionAffiliated?.isCanceled === false && (
                        <Chip
                          label="NOT APPROVED"
                          color="warning"
                          size="small"
                        />
                      )}
                    {values?.unionAffiliated?.isCanceled && (
                      <Chip label="CANCELED" color="error" size="small" />
                    )}
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={row}>
            <Grid container>
              <Grid item xs={5}>
                <Typography>Union Affiliated:</Typography>
                <Stack direction="row" alignContent="center">
                  <Typography mt={2}>Yes</Typography>
                  <Radio
                    checked={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? false
                    }
                    onChange={handleOnChange}
                    value={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? false
                    }
                    name="unionAffiliatedAnswer"
                    disabled={
                      (values.unionAffiliated != null &&
                        (values.unionAffiliated.isAffiliated === true ||
                          isUnderEvaluation)) ||
                      !fullAccessUnion
                    }
                  />
                  <Typography mt={2}>No</Typography>
                  <Radio
                    checked={
                      !values.unionAffiliated?.unionAffiliatedAnswer ?? true
                    }
                    onChange={handleOnChange}
                    value={
                      values.unionAffiliated?.unionAffiliatedAnswer ?? true
                    }
                    name="unionAffiliatedAnswer"
                    disabled={
                      (values.unionAffiliated != null &&
                        (values.unionAffiliated.isAffiliated === true ||
                          isUnderEvaluation)) ||
                      !fullAccessUnion
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                {values.unionAffiliated?.unionAffiliatedAnswer ? (
                  <>
                    <Stack>
                      <Typography>Apprentice:</Typography>
                      <Stack direction="row" alignContent="center">
                        <Typography mt={2}>Yes</Typography>
                        <Radio
                          checked={values.unionAffiliated.apprentice}
                          onChange={handleOnChange}
                          value={values.unionAffiliated.apprentice}
                          name="apprentice"
                          disabled={
                            values.unionAffiliated != null &&
                            (values.unionAffiliated.isAffiliated === true ||
                              isUnderEvaluation) &&
                            !(
                              isUnderEvaluation &&
                              user?.role === ROLES.UnionAdministrator
                            )
                          }
                        />
                        <Typography mt={2}>No</Typography>
                        <Radio
                          checked={!values.unionAffiliated.apprentice}
                          onChange={handleOnChange}
                          value={values.unionAffiliated.apprentice}
                          name="apprentice"
                          disabled={
                            values.unionAffiliated != null &&
                            (values.unionAffiliated.isAffiliated === true ||
                              isUnderEvaluation) &&
                            !(
                              isUnderEvaluation &&
                              user?.role === ROLES.UnionAdministrator
                            )
                          }
                        />
                      </Stack>
                    </Stack>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={row}>
                <Stack mt={5}></Stack>
              </Grid>
              {values.unionAffiliated !== null ? (
                <>
                  <Grid item xs={row}>
                    <Grid container>
                      <Grid item xs={5}>
                        {values?.unionAffiliated?.unionAffiliatedAnswer ? (
                          <Grid container spacing={5}>
                            <Grid item xs={12}>
                              <FormSelect
                                name="laborUnionsId"
                                label="Labor Unions"
                                value={values.unionAffiliated?.laborUnionsId?.toString()}
                                onChange={handleInputChange}
                                options={dataLaborUnions}
                                error={errors.laborUnionsId}
                                showSkeleton={isLoadingLaborUnions}
                                disable={disableUnionNumber}
                              />
                            </Grid>
                            {isOtherLaborUnion() && (
                              <Grid item xs={12}>
                                <FormText
                                  name={"laborUnionsOther"}
                                  label={"Labor Unions Other"}
                                  value={
                                    values.unionAffiliated?.laborUnionsOther
                                  }
                                  onChange={handleInputChange}
                                  disabled={disableUnionNumber}
                                ></FormText>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <FormSelect
                                name="localUnionsId"
                                label="Local Unions"
                                value={values.unionAffiliated?.localUnionsId?.toString()}
                                onChange={handleInputChange}
                                options={dataLocalUnions}
                                error={errors.localUnionsId}
                                showSkeleton={isLoadingLocalUnions}
                                disable={disableUnionNumber}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {isOtherLocalUnion() && (
                                <FormText
                                  name={"localUnionsOther"}
                                  label={"Local Unions Other"}
                                  value={
                                    values.unionAffiliated?.localUnionsOther
                                  }
                                  onChange={handleInputChange}
                                  disabled={disableUnionNumber}
                                  error={errors.localUnionsOther}
                                ></FormText>
                              )}
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4}>
                        {values?.unionAffiliated?.unionAffiliatedAnswer ? (
                          <FormNumeric
                            name="smwiaMember"
                            value={values?.unionAffiliated?.smwiaMember ?? ""}
                            onChange={handleOnChangeText}
                            label="Member #:"
                            disabled={disableUnionNumber}
                            error={errors.smwiaMember}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={row}></Grid>
              {commentsAcceptedRejected ? (
                <>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Typography mt={2} width={100} textAlign={"right"}>
                        <b>
                          {values?.unionAffiliated?.isAffiliated
                            ? "Approved"
                            : "Rejected"}{" "}
                          Date:
                        </b>
                      </Typography>
                      <Typography mt={2} ml={2}>
                        {moment(
                          convertUTCDateToLocalDate(
                            values?.unionAffiliated?.updatedAcceptRejectDate
                              ? values?.unionAffiliated?.updatedAcceptRejectDate?.toString()
                              : ""
                          )
                        ).format("MM/DD/yyyy h:mm a")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <Typography mt={2} width={100} textAlign={"right"}>
                        <b>
                          {values?.unionAffiliated?.isAffiliated
                            ? "Approved"
                            : "Rejected"}{" "}
                          By:
                        </b>
                      </Typography>
                      <Typography mt={2} ml={2}>
                        {values?.unionAffiliated?.updatedAcceptRejectBy?.name +
                          " " +
                          values?.unionAffiliated?.updatedAcceptRejectBy
                            ?.lastName}
                      </Typography>
                    </Stack>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                {commentsAcceptedRejected ? (
                  <Stack direction="row">
                    <Typography mt={2} width={100} textAlign={"right"}>
                      <b> Comments:</b>{" "}
                    </Typography>
                    <Typography ml={2} mt={2}>
                      {values?.unionAffiliated?.affiliatedComments}
                    </Typography>
                  </Stack>
                ) : null}
              </Grid>
              {valuesUnion?.isCanceled && (
                <>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack direction="row" mt={5}>
                          <Grid item xs={12}>
                            <Stack direction="row">
                              <Typography
                                mt={2}
                                width={100}
                                textAlign={"right"}
                              >
                                <b>Canceled Date:</b>
                              </Typography>
                              <Typography mt={2} ml={2}>
                                {moment(
                                  convertUTCDateToLocalDate(
                                    valuesUnion?.updatedCanceledDate
                                      ? valuesUnion?.updatedCanceledDate?.toString()
                                      : ""
                                  )
                                ).format("MM/DD/yyyy h:mm a")}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Typography mt={2} width={100} textAlign={"right"}>
                            <b>Canceled By:</b>
                          </Typography>
                          <Typography mt={2} ml={2}>
                            {valuesUnion?.updatedCanceledBy?.name +
                              " " +
                              valuesUnion?.updatedCanceledBy?.lastName}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <Typography mt={2} width={100} textAlign={"right"}>
                            <b>Comments: </b>
                          </Typography>
                          <Typography ml={2} mt={2}>
                            {valuesUnion?.comments}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {isUnderEvaluation && user?.role === ROLES.UnionAdministrator && (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    pt={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormSaveButton
                      onClick={() => {
                        setCommentsUpdate("");
                        setProceedUpdate(false);
                        setUpdatePopup(true);
                        setIsAffiliated(true);
                      }}
                      isSubmitting={false}
                      text="Yes, affiliated"
                    ></FormSaveButton>
                    <FormCancelButton
                      onClick={() => {
                        setCommentsUpdate("");
                        setProceedUpdate(false);
                        setUpdatePopup(true);
                        setIsAffiliated(false);
                      }}
                      isSubmitting={false}
                      text="No, not affiliated"
                    ></FormCancelButton>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <Popup
        title="Information"
        openPopup={informationShowPopUp}
        setOpenPopup={setInofrmationShowPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography>
            <b>
              The acronym SMWIA specifically represents the Sheet Metal Workers
              International Association, which pertains to union affiliation.
            </b>
          </Typography>
          <br />
          <Typography>
            <b>
              Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
              and Transportation workers.
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={handleClosePopUp}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Union Affiliation"}
        text={
          <>
            <Typography>
              Are you certain you want to set the user {values.name}{" "}
              {values.lastName} as {isAffiliated ? "" : "non "}Union Affiliated?
            </Typography>
            <FormCheckBox
              label="Yes, proceed"
              name="proceed"
              value={proceedUpdate}
              onChange={() => {
                setProceedUpdate(!proceedUpdate);
              }}
            />
            {proceedUpdate && (
              <Typography pb={3}>
                Reason for {isAffiliated ? "" : "do not "}authorize the Union
                affiliation:
              </Typography>
            )}
            {proceedUpdate && (
              <FormTextArea
                name="comment"
                label="Comments"
                value={commentsUpdate}
                onChange={(e: any) => {
                  setCommentsUpdate(e.target.value);
                }}
                placeholder="Be descriptive when entering reason"
              />
            )}
          </>
        }
        showPopup={updatePopup}
        setShowPopup={setUpdatePopup}
        isSubmitting={isSubmitingAffiliation}
        disabled={!proceedUpdate || commentsUpdate.length === 0}
        onSave={() => {
          saveAffiliation(
            values?.id,
            valuesUnion?.smwiaMember,
            commentsUpdate,
            isAffiliated,
            valuesUnion?.unionAffiliationNumbersId,
            valuesUnion?.unionAffiliationNumbersOther
          );
        }}
      />
    </>
  );
};

export default UnionSection;
