import { TextField, Typography } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types/common";

interface Props {
  value?: number | string | null;
  onChange?: (e: any) => void;
  name: string;
  decimalScale?: number | undefined;
  maxValue?: number | undefined;
  disabled?: boolean;
  mode?: Mode;
  onBlur?: (e: any) => void;
  placeholder?: string;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  emptyFieldAccept?: boolean;
}

const SpreadsheetNumericInput2 = ({
  value,
  onChange,
  name,
  decimalScale,
  maxValue,
  disabled,
  mode,
  onBlur,
  placeholder,
  fixedDecimalScale,
  thousandSeparator,
  emptyFieldAccept,
}: Props) => {
  const valueRef = React.useRef<string>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    var input = inputRef.current;
    if (input) {
      input.value = value as string;
    }
  }, [value]);

  const handleFocus = (event: any) => {
    event.target.select();
    var input = inputRef.current;
    if (input) {
      valueRef.current = input.value;
    }
  };

  const handleBlur = () => {
    if (onChange) {
      var input = inputRef.current;
      if (input && input.value !== valueRef.current) {
        const ev = {
          target: {
            name: name,
            value: input.value,
          },
        };
        onChange(ev);
      }
    }
  };

  if (mode && mode === "read")
    return (
      <Typography
        style={{
          width: "100%",

          paddingLeft: "2px",
          paddingRight: "2px",
        }}
        variant="body1"
      >
        <NumericFormat
          displayType="text"
          value={value}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
        />
      </Typography>
    );

  return (
    <NumericFormat
      size="small"
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      inputRef={inputRef}
      value={value}
      customInput={TextField}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale ?? 3}
      onBlurCapture={onBlur}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue === undefined && emptyFieldAccept) return true;
        if (floatValue !== 0 && !floatValue) return false;

        if (maxValue) return floatValue < maxValue;

        return true;
      }}
      style={{ paddingLeft: "2px", paddingRight: "2px", width: "100%" }}
      placeholder={placeholder}
    />
  );
};

export default SpreadsheetNumericInput2;
