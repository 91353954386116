import React, { useEffect, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

import {
  useAuth,
  useAppDispatch,
  useLog,
  useAppSelector,
  useAsyncQuery,
} from "../hooks";
import { signalrActions } from "../redux/slices/signalrActions";
import DialogMessagePopup from "./DialogMessagePopup";
import { useNavigate } from "react-router-dom";
import { Typography, Divider as MuiDivider } from "@mui/material";
import { spacing, styled } from "@mui/system";
import floatingBarPdfGeneration from "src/redux/slices/flotatingBarPdfGeneration";
import { AuthenticationService } from "src/services";
import { setSession } from "src/utils/jwt";

const SignalRComponent = () => {
  const { isAuthenticated, token, signOut } = useAuth();
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [closeProjectModal, setCloseProjectModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signalRUrl = process.env.REACT_APP_AMP_SIGNALR ?? "";
  const { log } = useLog();
  const [companyUpdatedModal, setCompanyUpdatedModal] = useState(false);
  const [gPSPermissionResponse, setGPSPermissionResponse] = useState(false);
  const [gPSPermissionResponseReject, setGPSPermissionResponseReject] =
    useState(false);
  const [rejectedMessage, setRejectedMessage] = useState("");
  const Divider = styled(MuiDivider)(spacing);
  const pdfGeneration = useAppSelector(
    (state) => state.pdfGeneration.pdfNotifications
  );
  const signalRStatus = useAppSelector((state) => state.signalR);

  const { execute: executeChange, isLoading } = useAsyncQuery(
    AuthenticationService.refreshSession,
    {
      successfulMessage: "Current Company Updated",
      onSuccess: (response) => {
        const authorizationData = response;
        const { access_token, ...user } = authorizationData;
        setSession(authorizationData);
      },
    }
  );

  useEffect(() => {
    if (isAuthenticated && token !== null) {
      const connect = new HubConnectionBuilder()
        .withUrl(signalRUrl, {
          accessTokenFactory: () => {
            return token!;
          },
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }

    if (
      !isAuthenticated &&
      connection?.state !== HubConnectionState.Disconnected
    ) {
      connection?.stop().then(() => {
        dispatch(signalrActions.setIsConnected(false));
      });
      setConnection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          dispatch(signalrActions.setIsConnected(true));
          connection.on("ReceiveMessage", (message) => {});
          connection.on("Logout", (message) => {
            handleLogout(message);
          });
          connection.on("Notification", (message) => {
            const objNotification = JSON.parse(message);
            log.success(objNotification.notification.title);
          });
          connection.on("StartWorkingDay", () => {
            window.location.reload();
          });
          connection.on("OnGpsPermissionActivated", (message) => {
            dispatch(signalrActions.setMessageCode("OnGpsPermissionActivated"));
            setGPSPermissionResponse(true);
          });
          connection.on("OnGpsPermissionRejected", (message) => {
            dispatch(signalrActions.setMessageCode("OnGpsPermissionActivated"));
            setRejectedMessage(message);
            setGPSPermissionResponseReject(true);
          });
          connection.on("PdfGeneration", (result) => {
            let currentNotification = JSON.parse(result);
            if (
              currentNotification.isProject ||
              currentNotification.name.includes("Energy Air") ||
              currentNotification.name.includes("Zip File")
            ) {
              let newPdfGeneration = [...pdfGeneration, currentNotification];
              dispatch(
                floatingBarPdfGeneration.actions.setPdfNotifications(
                  newPdfGeneration
                )
              );
            }
          });
          connection.on("FinishPdfGeneration", () => {
            dispatch(floatingBarPdfGeneration.actions.setOpen(true));
            dispatch(floatingBarPdfGeneration.actions.setComplete(false));
          });
          connection.on("MessagePdfGeneration", () => {
            dispatch(floatingBarPdfGeneration.actions.setComplete(true));
          });
          connection.on("NewBuilding", () => {
            dispatch(signalrActions.newBuilding());
          });
          connection.on("DeleteBuilding", () => {
            dispatch(signalrActions.newBuilding());
          });
          connection.on("ChangeStatusBuilding", () => {
            dispatch(signalrActions.newBuilding());
          });
          connection.on("ClosedProject", () => {
            setCloseProjectModal(true);
          });
          connection.on("PdfGenerationIsPaid", (result) => {
            dispatch(signalrActions.paidId(result));
          });
          connection.on("NewProject", () => {
            dispatch(signalrActions.projectList());
          });
          connection.on("DeleteProject", () => {
            dispatch(signalrActions.projectList());
          });
          connection.on("ChangeStatusProject", () => {
            dispatch(signalrActions.projectList());
          });
          connection.on("CompleteProject", () => {
            dispatch(signalrActions.projectList());
          });
          connection.on("NewTicket", () => {
            dispatch(signalrActions.ticketList());
          });
          connection.on("DeleteTicket", () => {
            dispatch(signalrActions.ticketList());
          });
          connection.on("TicketChangeStatus", () => {
            dispatch(signalrActions.ticketList());
          });
          connection.on("NewTechnicianTemp", () => {
            dispatch(signalrActions.technicianList());
          });
          connection.on("CertTechnicianTemp", () => {
            dispatch(signalrActions.technicianList());
          });
          connection.on("NewCreditCard", () => {
            dispatch(signalrActions.walletList());
          });
          connection.on("DeleteCard", () => {
            dispatch(signalrActions.walletList());
          });
          connection.on("CardChangeStatus", () => {
            dispatch(signalrActions.walletList());
          });
          connection.on("StopWorkingDay", () => {
            navigate("/app/Working");
          });
          connection.on("CoutNewTicket", (result) => {
            dispatch(
              signalrActions.coutNewTicket(signalRStatus.coutNewTicket + 1)
            );
          });
          connection.on("NewTechnician", (result) => {
            dispatch(
              signalrActions.newTechnician(signalRStatus.newTechnician + 1)
            );
          });
          connection.on("NewOutToBid", (result) => {
            dispatch(signalrActions.newOutToBid(signalRStatus.newOutToBid + 1));
          });
        })
        .catch((error) => {
          dispatch(signalrActions.setIsConnected(false));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  const handleLogout = async (message: any) => {
    const objMessage = JSON.parse(message);
    if (objMessage.type === "DuplicateSession") {
      setLogoutModal(true);
    }
    if (objMessage.type === "ChangeCompany") {
      setCompanyUpdatedModal(true);
      await executeChange();
    }
  };

  const handleAcceptLogout = async () => {
    setLogoutModal(false);
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleGotoProjectsList = () => {
    navigate("/app/Working");
  };

  return (
    <>
      <DialogMessagePopup
        title={"Duplicated Session"}
        text={
          "It appears you are have logged-in with the same User Name and Password while another\nbrowser is already opened with the same credentials.\n\nIf you don't recognize this action, please contact us at +1-630-480-7667.\nOur customer service representatives can be reached by phone\nMonday - Friday 8:00 am - 7:00 pm (CST), Saturday 8:00 am - 3:00 pm (CST),\nand Sunday 9:00 am - 2:00 pm (CST)."
        }
        showPopup={logoutModal}
        setShowPopup={setLogoutModal}
        onSave={() => {
          handleAcceptLogout();
        }}
        onCancel={() => {
          handleAcceptLogout();
        }}
        isSubmitting={false}
        hideCancel={true}
        disableClickOutside={true}
      />
      <DialogMessagePopup
        title={"GPS Permission Response"}
        text={"Your GPS Permission has been approved"}
        showPopup={gPSPermissionResponse}
        setShowPopup={setGPSPermissionResponse}
        isSubmitting={false}
        onSave={() => {
          setGPSPermissionResponse(false);
        }}
        hideAccept={true}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title={"GPS Permission Response"}
        text={
          <>
            <Typography>Your GPS Permission has been rejected</Typography>
            <Divider my={1} />
            <Typography variant="h6">Comments</Typography>
            <Typography>{rejectedMessage}</Typography>
          </>
        }
        showPopup={gPSPermissionResponseReject}
        setShowPopup={setGPSPermissionResponseReject}
        isSubmitting={false}
        onSave={() => {
          setGPSPermissionResponseReject(false);
        }}
        hideAccept={true}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title={"Information"}
        disableClickOutside={true}
        text={
          <>
            <Typography>
              Your current project was closed by the building owner.
            </Typography>
            <Typography>
              For further information please contact your company's
              administrator.
            </Typography>
          </>
        }
        showPopup={closeProjectModal}
        setShowPopup={setCloseProjectModal}
        isSubmitting={false}
        hideCancel={true}
        onSave={handleGotoProjectsList}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={"Current Company Updated"}
        text={
          <>
            <Typography paddingBottom={3}>
              Your current company has been updated to Administrator Company
            </Typography>
          </>
        }
        showPopup={companyUpdatedModal}
        setShowPopup={setCompanyUpdatedModal}
        hideCancel={true}
        isSubmitting={isLoading}
        onSave={() => {
          window.location.reload();
        }}
        disableClickOutside={true}
      />
    </>
  );
};

export default SignalRComponent;
