import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import Actions from "./Actions";

import { buildingService, projectCurrentService } from "src/services";
import { useAppDispatch, useAsyncQuery, useAuth } from "src/hooks";
import { BuildingDTO, ProjectSelectedDashboardDTO } from "src/ts/interfaces";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { COMMON_MENU } from "src/constants";
import { useNavigate } from "react-router-dom";
import DashboarItem from "./DashboarItem";
import {
  CorporateFareOutlined,
  HomeRepairServiceOutlined,
  HomeWorkOutlined,
} from "@mui/icons-material";
import FolderCopyOutlined from "@mui/icons-material/FolderCopyOutlined";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function AuditorDashboard() {
  const { user } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    execute,
    data: projectCurrent,
    isLoading: isProjectCurrentLoading,
  } = useAsyncQuery<ProjectSelectedDashboardDTO>(
    projectCurrentService.getProjectCurrent
  );

  const {
    execute: getBuilding,
    data: building,
    isLoading: isBuildingLoading,
  } = useAsyncQuery<BuildingDTO>(buildingService.getBuildingByProject);

  const {
    execute: getAuditorCount,
    data: amountOfProjects,
    isLoading: isgetAuditorCountLoading,
  } = useAsyncQuery<number>(getProjectSingleService.getAuditorCount);

  const {
    execute: getCountCompleteAuditor,
    data: countCompleteAuditor,
    isLoading: isCountCompleteAuditorLoading,
  } = useAsyncQuery<number>(getProjectSingleService.getCountCompleteAuditor);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    const getAuditorCountData = async () => {
      await getAuditorCount();
    };

    const getCountCompleteAuditorData = async () => {
      await getCountCompleteAuditor();
    };

    const getProjectID = () => {
      dispatch(floatingBarActions.setOptionMenu(COMMON_MENU));
    };
    getProjectID();

    getData();
    getAuditorCountData();
    getCountCompleteAuditorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      await getBuilding(projectCurrent.project.id);
    };

    if (
      projectCurrent?.project &&
      projectCurrent.project.buildingId &&
      projectCurrent.project.buildingId > 0
    )
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCurrent]);

  const showUnderConstruction = () => {
    setShowDeleteModal(true);
  };

  const isLoading = () => {
    return (
      isProjectCurrentLoading &&
      isBuildingLoading &&
      isgetAuditorCountLoading &&
      isCountCompleteAuditorLoading
    );
  };

  const getBuildingAddress = () => {
    if (building == null) return "";

    let stateName = "";
    if (building?.state == null) {
      stateName = building?.stateName ?? "";
    } else {
      stateName = building?.state?.name ?? "";
    }

    return `${building?.address ?? ""}${building?.address2 ? ", " : ""}${
      building?.address2 ?? ""
    }, ${building?.city ?? ""}, ${stateName}, ${building?.zip ?? ""}`;
  };

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">AMP Dashboard</Typography>
        </Grid>
        <Grid item>
          <Actions
            showUnderConstruction={showUnderConstruction}
            projectCurrent={projectCurrent}
            companyId={user?.companyId}
          />
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title={"Current Project"}
            label1={projectCurrent?.project?.name}
            label2={
              <>
                {projectCurrent?.project?.name ? (
                  <>
                    <Typography>{getBuildingAddress()}</Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      No current project has been defined.
                    </Typography>
                    <br></br>
                  </>
                )}
                <HomeWorkOutlined style={{ float: "right", fontSize: 40 }} />
              </>
            }
            showSkeleton={isLoading()}
            action={() => {
              if (projectCurrent?.project?.name)
                navigate(
                  `/app/Project/Edit/${projectCurrent?.project.id}/${user?.companyId}`
                );
              else navigate(`/app/Working`);
            }}
            chip={""}
            chipColor="primary"
            textDetails={"View details"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title="Field Test Equipment - List & Calibration"
            label1="&nbsp;"
            label2={
              <>
                &nbsp; <br></br>
                <HomeRepairServiceOutlined
                  style={{ float: "right", fontSize: 40 }}
                />
              </>
            }
            action={() => {
              navigate(`/app/companyAssets/${building.companyId}/EQIP`);
            }}
            showSkeleton={isLoading()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title="Completed Projects"
            chip={`${countCompleteAuditor ?? 0}`}
            action={() => {
              navigate(`/app/Working/Complete`);
            }}
            showSkeleton={isLoading()}
            label2={
              <>
                &nbsp; <br />
                <br />
                <CorporateFareOutlined
                  style={{ float: "right", fontSize: 40 }}
                />
              </>
            }
            chipColor="primary"
            label1={""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title="Project Database"
            chip={`${amountOfProjects ?? 0}`}
            action={() => {
              navigate(`/app/Working`);
            }}
            showSkeleton={isLoading()}
            label2={
              <>
                &nbsp; <br />
                <br />
                <FolderCopyOutlined style={{ float: "right", fontSize: 40 }} />
              </>
            }
            chipColor="primary"
            label1={""}
          />
        </Grid>
      </Grid>

      <DialogMessagePopup
        title={"Information"}
        text={"under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
      />
    </React.Fragment>
  );
}

export default AuditorDashboard;
