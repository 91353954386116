import React, { useEffect, useState } from "react";
import {
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
} from "src/components/formControls";
import { useAsyncMutation, useAsyncQuery, useAuth } from "src/hooks";
import signUpService from "src/services/signUpService";
import { IWizard } from "src/ts/interfaces/wizard";
import { currencyformatter } from "src/utils/format";
import ApplyCoupon from "src/pages/common/ApplyCoupon";
import { CouponService, WalletService } from "src/services";
import { ICoupon, IWalletList } from "src/ts/interfaces";
import CreditCardIconSelector from "src/components/icons/CreditCardIcon";

interface Props {
  wizard: IWizard;
  setModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: (cardConfirm: boolean) => Promise<void>;
  setIdCard: (val: number) => void;
}
function ConfirmPay({ wizard, setModalCancel, handleNext, setIdCard }: Props) {
  const { user } = useAuth();
  const [acceptValue, setAcceptValue] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [dataCard, setDataCard] = useState<IWalletList>();
  const { execute, isSubmitting } = useAsyncMutation(signUpService.confirm, {
    hideSuccessfulMessage: true,
    onSuccess: async () => {
      setIdCard(dataCard?.id ?? 0);
      await handleNext(false);
    },
  });

  const { execute: executeCoupon, data: dataCoupon } =
    useAsyncMutation<ICoupon>(CouponService.getByCode, {
      hideSuccessfulMessage: true,
      errorMessage: "The coupon code is invalid or was expired",
    });

  const { execute: executeCard } = useAsyncQuery<IWalletList[]>(
    WalletService.getWalletList,
    {
      hideSuccessfulMessage: true,
      onSuccess: (result) => {
        setDataCard(result[0]);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeCard(user?.companyId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {
    await execute(wizard.signUpWizard.id, dataCard?.id, coupon);
  };

  const cancelHandler = () => {
    setModalCancel(true);
  };

  const handleApplyCoupon = async (coupon: string) => {
    setCoupon(coupon);
    await executeCoupon(coupon);
  };

  return (
    <>
      <FormTitleBox title={"Confirmation"}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h5">Review your order</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Subscription</TableCell>
                    <TableCell> Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>{wizard?.subscription.description}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {currencyformatter.format(wizard?.price)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="body2" align="right">
                        Subtotal:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1} align="right">
                      {currencyformatter.format(wizard?.price)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="body2" align="right">
                        Taxes:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        $0.00
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="body2" align="right">
                        Discount:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        {currencyformatter.format(
                          ((wizard?.price ?? 0) / 100) *
                            (dataCoupon?.value ?? 0)
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="body2" align="right">
                        TOTAL:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        {currencyformatter.format(
                          wizard?.price -
                            (wizard?.price / 100) * (dataCoupon?.value ?? 0)
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <ApplyCoupon
              validateCoupon={handleApplyCoupon}
              isLoadingPay={false}
              dataCoupon={dataCoupon}
            ></ApplyCoupon>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h5">Payment method</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={3} textAlign={"center"} mt={2}>
                    <Typography>{dataCard?.alias} </Typography>
                  </Grid>
                  <Grid item xs={3} textAlign={"center"} mt={2}>
                    <Typography>{dataCard?.cardNumber} </Typography>
                  </Grid>
                  <Grid item xs={1.5} textAlign={"center"}>
                    {dataCard?.cardType && (
                      <CreditCardIconSelector
                        typeCreditCard={dataCard?.cardType}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3} textAlign={"center"} mt={2}>
                    <Typography>
                      {dataCard?.name} {dataCard?.lastName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h5">
              <Typography display={"inline"}>
                Signup Returns and Refunds Policy: If you are unhappy with your
                purchase please contact us at
              </Typography>
              <Link href="mailto:contact@auditmaster.pro" target="_blank">
                {" "}
                contact@auditmaster.pro{" "}
              </Link>
              <Typography display={"inline"}>within 3 days.</Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <FormCheckBox
              name={"accept"}
              label={"I accept."}
              value={acceptValue}
              onChange={() => {
                setAcceptValue(!acceptValue);
              }}
            ></FormCheckBox>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormCancelButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
              />
              <FormAcceptButton
                text="Confirm"
                onClick={handleConfirm}
                isSubmitting={isSubmitting}
                disabled={!acceptValue}
              ></FormAcceptButton>
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default ConfirmPay;
