import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Toolbar,
  styled,
  Typography,
  Fab as MuiFab,
  Divider as MuiDivider,
  Alert as MuiAlert,
  Skeleton,
  Slider,
} from "@mui/material";
import {
  IElectricCoilBank,
  IElectricCoilItem,
} from "src/ts/interfaces/study/electricCoil";
import {
  FormCheckBox,
  FormNumeric2,
  FormText,
} from "src/components/formControls";
import { useAsyncQuery } from "src/hooks";
import electricCoilItemService from "src/services/study/electricCoilItemService";
import energyStudyElectricBanksService from "src/services/study/energyStudyElectricBanksService";
import { spacing } from "@mui/system";
import { HeatingElectricResistancePhase } from "src/constants";
import {
  IEnergyStudyAirHydronicsDTO,
  IEnergyStudyElectricBanks,
} from "src/ts/interfaces";
import DialogMessagePopup from "src/components/DialogMessagePopup";

type InferBank<T> = T extends true
  ? IElectricCoilBank
  : IEnergyStudyElectricBanks;

type InferParent<T> = T extends true
  ? IElectricCoilItem
  : IEnergyStudyAirHydronicsDTO;

const Divider = styled(MuiDivider)(spacing);
const Fab = styled(MuiFab)(spacing);
const Alert = styled(MuiAlert)(spacing);

const initialValues: any = {
  id: 0,
  notOperational: false,
  line1Burned: false,
  line2Burned: false,
  line3Burned: false,
  bankIdentifier: "",
  overAmpningWarning: "",
};

const ElectricBank = <IsCoilBank extends boolean>({
  data,
  setData,
  saveData,
  setLoadingTable,
  saveParent,
  refreshCircuits,
  disableStudy,
  showSkeleton,
  isCoilBank,
}: {
  data: InferParent<IsCoilBank>;
  setData: Dispatch<SetStateAction<InferParent<IsCoilBank> | undefined>>;
  saveData: (...params: any[]) => Promise<void>;
  setLoadingTable: Dispatch<SetStateAction<boolean>>;
  saveParent: () => void;
  refreshCircuits: boolean;
  disableStudy: boolean;
  showSkeleton: boolean;
  isCoilBank: IsCoilBank;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bank, setBank] = useState<InferBank<IsCoilBank>>(
    isCoilBank
      ? { ...initialValues, electricCoilItemtId: 0 }
      : { ...initialValues, energyStudyReportId: 0 }
  );
  const [indexBank, setIndexBank] = useState(0);
  const [refreshBank, setRefreshBank] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [newNumberOfCircuits, setNewNumberOfCircuits] = useState(0);
  const [decreaseModal, setDecreaseModal] = useState(false);
  const [loadingSlide, setLoadingSlide] = useState(true);

  const {
    execute: executeBank,
    data: dataBank,
    setData: setDataBank,
  } = useAsyncQuery<InferBank<IsCoilBank>[]>(
    isCoilBank
      ? electricCoilItemService.getBanksByItemId
      : energyStudyElectricBanksService.getBanksByItemId,
    {
      onSuccess: (result) => {
        chekIndex(result);
        setBank(result[0]);
        setLoadingSlide(true);
      },
    }
  );

  const { execute: executeSaveBank } = useAsyncQuery<InferBank<IsCoilBank>>(
    isCoilBank
      ? electricCoilItemService.saveBank
      : energyStudyElectricBanksService.saveBank,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (!isCoilBank) {
            setBank(dataResult);
          }
        };
        getData();
        saveParent();
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoadingItem(false);
      await executeBank(data.id);
    };
    if (data && data?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    setIsLoadingItem(false);
    if (dataBank) setBank(dataBank[indexBank]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexBank]);

  useEffect(() => {
    setRefreshBank(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank]);

  useEffect(() => {
    setIsLoadingItem(showSkeleton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkeleton]);

  useEffect(() => {
    const getData = async () => {
      setLoadingSlide(false);
      await executeBank(data.id);
    };
    if (data && data.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCircuits]);

  useEffect(() => {
    if (bank) {
      let newBank = bank;
      const actualAmpsPhase1 = bank?.actualOperationalAmpsLine1 ?? 0;
      const actualAmpsPhase2 = bank?.actualOperationalAmpsLine2 ?? 0;
      const actualAmpsPhase3 = bank?.actualOperationalAmpsLine3 ?? 0;
      const line1ActualAmpsValid =
        actualAmpsPhase1 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      const line2ActualAmpsValid =
        actualAmpsPhase2 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      const line3ActualAmpsValid =
        actualAmpsPhase3 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      newBank.overAmpningWarning = "";
      if (!line1ActualAmpsValid && line2ActualAmpsValid && line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 1";
      if (line1ActualAmpsValid && !line2ActualAmpsValid && line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 2";
      if (line1ActualAmpsValid && line2ActualAmpsValid && !line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 3";
      if (
        !line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1 & 2";
      if (
        !line1ActualAmpsValid &&
        line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1 & 3";
      if (
        line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 2 & 3";
      if (
        !line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1, 2 & 3";
      setBank(newBank);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bank?.actualOperationalAmpsLine1,
    bank?.actualOperationalAmpsLine2,
    bank?.actualOperationalAmpsLine3,
    data.heatingElectricResistanceRatedAmperage,
  ]);

  const handleBlurBank = async (e: any, showSkeleton: boolean = false) => {
    let newValues = { ...bank, [e.target.name]: e.target.value };
    if (e.target.name === "line1Burned") {
      newValues = {
        ...newValues,
        actualOperationalVoltsLine1: undefined,
        actualOperationalVoltsLine2: undefined,
        actualOperationalVoltsLine3: undefined,
        actualOperationalAmpsLine1: undefined,
        actualOperationalAmpsLine2: undefined,
        actualOperationalAmpsLine3: undefined,
      } as any;
    }
    if (e.target.name === "line2Burned") {
      newValues = {
        ...newValues,
        actualOperationalVoltsLine1: undefined,
        actualOperationalVoltsLine2: undefined,
        actualOperationalVoltsLine3: undefined,
        actualOperationalAmpsLine1: undefined,
        actualOperationalAmpsLine2: undefined,
        actualOperationalAmpsLine3: undefined,
      } as any;
    }
    if (e.target.name === "line3Burned") {
      newValues = {
        ...newValues,
        actualOperationalVoltsLine1: undefined,
        actualOperationalVoltsLine2: undefined,
        actualOperationalVoltsLine3: undefined,
        actualOperationalAmpsLine1: undefined,
        actualOperationalAmpsLine2: undefined,
        actualOperationalAmpsLine3: undefined,
      } as any;
    }
    setBank(newValues);
    await executeSaveBank(newValues);
  };

  const handleChangeBlur = async (item: any, e: any) => {
    item[e.target.name] = e.target.value;
    setBank(item);
    setLoadingTable(true);
    await executeSaveBank(item);
  };

  const chekIndex = (data: InferBank<IsCoilBank>[]) => {
    if (data.length < indexBank + 1) setIndexBank(0);
  };

  const applyChanges = (indePrev: number, newbank: InferBank<IsCoilBank>) => {
    const unmodified = dataBank.map((item, index) =>
      index === indePrev ? newbank : item
    );
    setDataBank(unmodified);
  };

  const saveNumberOfCircuit = async (newItem: number) => {
    if (isCoilBank) {
      setData({
        ...data,
        numberOfCircuits: newItem,
      });
      await saveData({ ...data, numberOfCircuits: newItem });
    } else {
      setData({
        ...data,
        numberOfCircuitsInHeatingCoil: newItem,
      });
      await saveData(data.id, {
        ...data,
        numberOfCircuitsInHeatingCoil: newItem,
      });
    }
    setLoadingSlide(true);
  };

  const handleAcceptDecrease = async () => {
    await saveNumberOfCircuit(newNumberOfCircuits);
    setLoadingSlide(true);
  };

  const handleCancelDecrease = () => {
    setLoadingSlide(true);
  };

  const handleChangeSlider = async (e: any, newValue: any) => {
    setLoadingSlide(false);
    if (dataBank) {
      if (dataBank.length > newValue) {
        setNewNumberOfCircuits(newValue);
        setDecreaseModal(true);
      } else {
        await saveNumberOfCircuit(newValue);
      }
    }
  };

  return (
    <>
      {dataBank && dataBank.length > 0 && (
        <>
          <Grid item xs={12} md={6} marginTop={isCoilBank ? 0 : 5}>
            <Typography>
              Indicate the Actual number of Electric Resistance Heating Devices:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} marginTop={isCoilBank ? 0 : 5}>
            {!!data && !isLoadingItem && loadingSlide && (
              <Slider
                defaultValue={dataBank.length}
                step={1}
                marks
                valueLabelDisplay="on"
                min={1}
                max={25}
                onChangeCommitted={handleChangeSlider}
                disabled={disableStudy}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    display: "block",
                  }}
                >
                  <>
                    {dataBank.map((item, index) => (
                      <>
                        {index !== indexBank && (
                          <>
                            <IconButton
                              size="medium"
                              onClick={() => {
                                setIsLoadingItem(true);
                                applyChanges(indexBank, bank);
                                setIndexBank(index);
                              }}
                              sx={{ border: "1px solid", marginRight: "8px" }}
                            >
                              <Typography
                                variant="h5"
                                width={30}
                                height={30}
                                pt={1}
                              >
                                {index + 1}
                              </Typography>
                            </IconButton>
                          </>
                        )}
                        {index === indexBank && (
                          <>
                            <Fab
                              mx={2}
                              color="primary"
                              size="medium"
                              aria-label="Selected Item"
                            >
                              <Typography
                                variant="h5"
                                width={30}
                                height={30}
                                pt={1}
                              >
                                {index + 1}
                              </Typography>
                            </Fab>
                          </>
                        )}
                      </>
                    ))}
                  </>
                </Toolbar>
              </Grid>
              <Grid item xs={12}>
                {isLoadingItem || refreshBank ? (
                  <Skeleton></Skeleton>
                ) : (
                  <Typography variant="h5" textAlign={"center"}>
                    Bank {indexBank + 1}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <FormCheckBox
                  name={"notOperational"}
                  label={"Bank Not Operational/Not Used At Time of testing"}
                  value={dataBank && bank?.notOperational}
                  onChange={(e: any) => {
                    handleChangeBlur(bank, e);
                  }}
                  disabled={disableStudy}
                ></FormCheckBox>
              </Grid>
              <Grid item xs={4} textAlign={"right"}>
                <Typography variant="subtitle2" pt={2}>
                  Bank Identifier
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name={"bankIdentifier"}
                  label={"Circuit #, Specific Code for this Bank"}
                  showSkeleton={isLoadingItem}
                  onBlur={(e: any) => {
                    handleBlurBank(e);
                  }}
                  value={bank?.bankIdentifier || ""}
                  disabled={disableStudy || !dataBank || bank?.notOperational}
                ></FormText>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
              {!isCoilBank && (
                <>
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"heatingElectricResistanceActualWatts"}
                      label={"Actual Watts for this Bank"}
                      showSkeleton={isLoadingItem}
                      value={
                        (bank as any).heatingElectricResistanceActualWatts || ""
                      }
                      disabled={true}
                      mode="read"
                      decimalScale={2}
                      thousandSeparator
                      fixedDecimalScale
                    ></FormNumeric2>
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"heatingElectricResistanceActualKWatts"}
                      label={"Actual kilowatts kW for this Bank"}
                      showSkeleton={isLoadingItem}
                      value={
                        (bank as any).heatingElectricResistanceActualKWatts ||
                        ""
                      }
                      disabled={true}
                      mode="read"
                      decimalScale={2}
                      thousandSeparator
                      fixedDecimalScale
                    ></FormNumeric2>
                  </Grid>
                  <Grid item xs={12} md={2}></Grid>
                </>
              )}
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2" pt={2} textAlign="center">
                  Line 1
                </Typography>
              </Grid>
              {data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.ThreePhase ||
              data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.TwoWireSinglePhase ? (
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2" pt={2} textAlign="center">
                    Line 2
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} md={7} />
              )}
              {data?.heatingElectricResistancePhase ===
              HeatingElectricResistancePhase.ThreePhase ? (
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2" pt={2} textAlign="center">
                    Line 3
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} md={4} />
              )}
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={3} textAlign="center">
                {(data?.heatingElectricResistancePhase ===
                  HeatingElectricResistancePhase.ThreePhase ||
                  data?.heatingElectricResistancePhase ===
                    HeatingElectricResistancePhase.TwoWireSinglePhase) && (
                  <FormCheckBox
                    name={"line1Burned"}
                    label={"Not Operational"}
                    value={bank?.line1Burned}
                    onChange={(e: any) => {
                      handleBlurBank(e, true);
                    }}
                    showSkeleton={isLoadingItem}
                    disabled={disableStudy || !dataBank || bank?.notOperational}
                  ></FormCheckBox>
                )}
              </Grid>
              {data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.ThreePhase ||
              data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.TwoWireSinglePhase ? (
                <Grid item xs={3} textAlign="center">
                  <FormCheckBox
                    name={"line2Burned"}
                    label={"Not Operational"}
                    value={bank?.line2Burned}
                    onChange={(e: any) => {
                      handleBlurBank(e, true);
                    }}
                    showSkeleton={isLoadingItem}
                    disabled={disableStudy || !dataBank || bank?.notOperational}
                  ></FormCheckBox>
                </Grid>
              ) : (
                <Grid item xs={12} md={7} />
              )}
              {data?.heatingElectricResistancePhase ===
              HeatingElectricResistancePhase.ThreePhase ? (
                <>
                  <Grid item xs={3} textAlign="center">
                    <FormCheckBox
                      name={"line3Burned"}
                      label={"Not Operational"}
                      value={bank?.line3Burned}
                      onChange={(e: any) => {
                        handleBlurBank(e, true);
                      }}
                      showSkeleton={isLoadingItem}
                      disabled={
                        disableStudy || !dataBank || bank?.notOperational
                      }
                    ></FormCheckBox>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={4} />
              )}
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={3}>
                <FormNumeric2
                  name={"actualOperationalVoltsLine1"}
                  label={
                    data?.heatingElectricResistancePhase ===
                      HeatingElectricResistancePhase.ThreePhase &&
                    !(
                      bank?.line1Burned ||
                      bank?.line2Burned ||
                      bank?.line3Burned
                    )
                      ? "Volts (L1 to L2)"
                      : "Volts (Phase to Ground)"
                  }
                  value={bank?.actualOperationalVoltsLine1 || ""}
                  showSkeleton={isLoadingItem || refreshBank}
                  onBlur={(e: any) => {
                    handleBlurBank(e);
                  }}
                  disabled={
                    disableStudy ||
                    !dataBank ||
                    bank?.notOperational ||
                    (bank?.line1Burned &&
                      bank?.line2Burned &&
                      bank?.line3Burned)
                  }
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
              {data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.ThreePhase ||
              data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.TwoWireSinglePhase ? (
                <>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"actualOperationalVoltsLine2"}
                      label={"Volts (L1 to L3)"}
                      value={bank?.actualOperationalVoltsLine2 || ""}
                      onBlur={(e: any) => {
                        handleBlurBank(e);
                      }}
                      showSkeleton={isLoadingItem || refreshBank}
                      disabled={
                        disableStudy ||
                        !dataBank ||
                        bank?.notOperational ||
                        bank?.line1Burned ||
                        bank?.line2Burned ||
                        bank?.line3Burned
                      }
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={7} />
              )}
              {data?.heatingElectricResistancePhase ===
              HeatingElectricResistancePhase.ThreePhase ? (
                <>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"actualOperationalVoltsLine3"}
                      label={"Volts  (L2 to L3)"}
                      value={bank?.actualOperationalVoltsLine3 || ""}
                      onBlur={(e: any) => {
                        handleBlurBank(e);
                      }}
                      showSkeleton={isLoadingItem || refreshBank}
                      disabled={
                        disableStudy ||
                        !dataBank ||
                        bank?.notOperational ||
                        bank?.line3Burned ||
                        bank?.line2Burned ||
                        bank?.line1Burned
                      }
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={4} />
              )}
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={3}>
                <FormNumeric2
                  name={"actualOperationalAmpsLine1"}
                  label={"Amps"}
                  value={bank?.actualOperationalAmpsLine1 || ""}
                  onBlur={(e: any) => {
                    handleBlurBank(e);
                  }}
                  showSkeleton={isLoadingItem || refreshBank}
                  disabled={
                    disableStudy ||
                    !dataBank ||
                    bank?.notOperational ||
                    (bank?.line1Burned &&
                      bank?.line2Burned &&
                      bank?.line3Burned)
                  }
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
              {data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.ThreePhase ||
              data?.heatingElectricResistancePhase ===
                HeatingElectricResistancePhase.TwoWireSinglePhase ? (
                <>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"actualOperationalAmpsLine2"}
                      label={"Amps"}
                      value={bank?.actualOperationalAmpsLine2 || ""}
                      onBlur={(e: any) => {
                        handleBlurBank(e);
                      }}
                      showSkeleton={isLoadingItem || refreshBank}
                      disabled={
                        disableStudy ||
                        !dataBank ||
                        bank?.notOperational ||
                        (bank?.line1Burned && bank?.line2Burned) ||
                        (bank?.line1Burned && bank?.line3Burned) ||
                        (bank?.line2Burned && bank?.line3Burned)
                      }
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={7} />
              )}
              {data?.heatingElectricResistancePhase ===
              HeatingElectricResistancePhase.ThreePhase ? (
                <>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name={"actualOperationalAmpsLine3"}
                      label={"Amps"}
                      value={bank?.actualOperationalAmpsLine3 || ""}
                      onBlur={(e: any) => {
                        handleBlurBank(e);
                      }}
                      showSkeleton={isLoadingItem || refreshBank}
                      disabled={
                        disableStudy ||
                        !dataBank ||
                        bank?.notOperational ||
                        bank?.line1Burned ||
                        bank?.line2Burned ||
                        bank?.line3Burned
                      }
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={4} />
              )}
              <Grid item xs={12}>
                {dataBank &&
                  bank?.overAmpningWarning &&
                  bank?.overAmpningWarning !== "" && (
                    <Alert mb={4} severity="error">
                      {bank?.overAmpningWarning}
                    </Alert>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <DialogMessagePopup
        title={"Electric Resistance Heating Devices"}
        text={
          <>
            <Typography>
              You are about to decrease the number of Electric Resistance
            </Typography>
            <Typography>
              Heating Devices in this unit. You will lose the data permanently.
            </Typography>
            <Typography>Are you certain you want to continue? </Typography>
          </>
        }
        showPopup={decreaseModal}
        setShowPopup={setDecreaseModal}
        onSave={handleAcceptDecrease}
        isSubmitting={false}
        onCancel={handleCancelDecrease}
      ></DialogMessagePopup>
    </>
  );
};

export default ElectricBank;
