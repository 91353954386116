import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Tab,
  Tabs,
  Grid,
  Stack,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import ActiveCompanies from "./ActiveCompanies";
import InactiveCompanies from "./InactiveCompanies";
import IncompleteCompanies from "./IncompleteCompanies";
import { useForm, Form, usePermissions, useLog, useAuth } from "src/hooks";
import { COUNTRIES, ROLES } from "src/constants";
import FormText from "src/components/formControls/FormText";
import {
  FormSelect,
  FormActionButton,
  FormCheckBox,
} from "src/components/formControls";

import { IKeyValue } from "src/ts/interfaces";
import SubscriptionService from "src/services/subscriptionService";
import StateService from "src/services/stateService";
import { FilterType } from "./types/FilterTypes";
import useLoading from "src/hooks/useLoading";
import Permission from "src/components/permissions/Permission";
import { Permission as PermissionTypes } from "src/ts/enums";
import { localUnionsService, userCountryAccessService } from "src/services";
import laborUnionsService from "src/services/catalogs/laborUnionsService";
import CanceledCompanies from "./CanceledCompanies";
import PendingCompanies from "./PendingCompanies";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: FilterType = {
  filter: "",
  subscriptionId: "",
  countryId: "",
  stateId: Number.MIN_SAFE_INTEGER,
  stateName: "",
  isUnionAffiliated: false,
  localUnionId: Number.MIN_SAFE_INTEGER,
  laborUnionId: Number.MIN_SAFE_INTEGER,
};

export type ParentCompanyParams = {
  id: string;
};

function Companies() {
  const { log } = useLog();
  const { user } = useAuth();
  let navigate = useNavigate();
  let { id } = useParams<ParentCompanyParams>();
  const parentCompanyId = parseInt(id ?? "0");
  const { isLoading, startRequest, endRequest } = useLoading();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { values, setValues, errors, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  const [subcriptionData, setSubcriptionData] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [countryData, setCountryData] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const [statesData, setStatesData] = useState<IKeyValue<number, string>[]>([]);
  const [laborUnion, setLaborUnion] = useState<IKeyValue<number, string>[]>([]);
  const [localUnion, setLocalUnion] = useState<IKeyValue<number, string>[]>([]);
  const [refresh, setRefresh] = useState(false);
  const { fullAccess: fullAccessIncomplete, readOnly: readOnlyIncomplete } =
    usePermissions(PermissionTypes.Companies_Incomplete_Tab);
  //Load/OnChange
  useEffect(() => {
    const getSubscriptions = async () => {
      startRequest("subscription");
      const request = await SubscriptionService.getKeyValues();
      endRequest("subscription");
      setSubcriptionData([
        {
          key: Number.MIN_SAFE_INTEGER,
          value: "All",
        },
        ...request,
      ]);
      const laborUnionResult = await laborUnionsService.getKeyValues();

      setLaborUnion([
        {
          key: Number.MIN_SAFE_INTEGER,
          value: "All",
        },
        ...laborUnionResult.filter((item) => item.value !== "Other"),
      ]);
      const localUnionResult = await localUnionsService.getKeyValues();
      setLocalUnion([
        {
          key: Number.MIN_SAFE_INTEGER,
          value: "All",
        },
        ...localUnionResult.filter((item) => item.value !== "Other"),
      ]);
    };

    const getCountries = async () => {
      startRequest("country");
      const request = await userCountryAccessService.getKeyValues();
      endRequest("country");
      setCountryData(request);
      let foundItem = request.find((count) => count.key === COUNTRIES.USA);
      setValues({
        ...values,
        countryId: foundItem ? COUNTRIES.USA : request[0].key,
        subscriptionId: Number.MIN_SAFE_INTEGER,
        stateId: Number.MIN_SAFE_INTEGER,
        laborUnionId: Number.MIN_SAFE_INTEGER,
        localUnionId: Number.MIN_SAFE_INTEGER,
      });
    };

    getSubscriptions();
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getState = async () => {
      try {
        startRequest("getstates");
        const request = await StateService.getKeyValues(values.countryId);
        setStatesData([
          {
            key: Number.MIN_SAFE_INTEGER,
            value: "All",
          },
          ...request,
        ]);
      } catch (error: any) {
        endRequest("getstates");
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("getstates");
      }
    };

    if (values.countryId !== "") getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  useEffect(() => {
    if (values.countryId !== "") handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  //Handlers
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleNewLicensee = () => {
    navigate("/app/company/0");
  };

  return (
    <React.Fragment>
      <Helmet />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {process.env.REACT_APP_ENVIRONMENT !== "SCHOOL"
              ? "Companies"
              : "Companies / Students"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Companies</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Permission to={PermissionTypes.Companies}>
              <FormActionButton
                mr={2}
                text="New Licensee"
                size="medium"
                onClick={handleNewLicensee}
                type="add"
              />
            </Permission>
          </Stack>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={6}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Active" id="1" disabled={isLoading} />
                <Tab label="Inactive" id="2" disabled={isLoading} />
                {parentCompanyId === 0 &&
                  (fullAccessIncomplete || readOnlyIncomplete) && (
                    <Tab label="Incomplete" id="3" disabled={isLoading} />
                  )}
                {user?.role === "UnionAdministrator" && (
                  <Tab label="Canceled" id="4" disabled={isLoading} />
                )}
                {user?.role === "UnionAdministrator" && (
                  <Tab label="Pending" id="5" disabled={isLoading} />
                )}
              </Tabs>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="flex-end"></Stack>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={5}>
                    <FormText
                      name="filter"
                      label={
                        process.env.REACT_APP_ENVIRONMENT !== "SCHOOL"
                          ? "Search in Companies"
                          : "Search in Companies / Students"
                      }
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                      // showSkeleton={isLoading}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <FormSelect
                      name="subscriptionId"
                      label="Subscription"
                      value={values.subscriptionId}
                      onChange={handleInputChange}
                      options={subcriptionData}
                      error={errors.subscriptionId}
                      // showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefresh}
                      isSubmitting={isLoading}
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormSelect
                      name="countryId"
                      label="Country"
                      value={values.countryId}
                      onChange={handleInputChange}
                      options={countryData}
                      error={errors.countryId}
                      // showSkeleton={isLoading}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    {values.countryId !== Number.MIN_SAFE_INTEGER &&
                    values.countryId === COUNTRIES.USA ? (
                      <FormSelect
                        name="stateId"
                        label="State"
                        value={values.stateId}
                        onChange={handleInputChange}
                        options={statesData}
                        error={errors.stateId}
                        // showSkeleton={isLoading}
                      />
                    ) : (
                      <FormText
                        name="stateName"
                        label="State"
                        value={values.stateName}
                        onChange={handleInputChange}
                        error={errors.stateName}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {process.env.REACT_APP_ENVIRONMENT !== "UNION" &&
                      user?.role !== ROLES.UnionAdministrator &&
                      process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" && (
                        <FormCheckBox
                          label="Union Affiliated"
                          name="isUnionAffiliated"
                          value={values.isUnionAffiliated}
                          onChange={handleInputChange}
                        />
                      )}
                  </Grid>
                  {(process.env.REACT_APP_ENVIRONMENT === "UNION" ||
                    user?.role === ROLES.UnionAdministrator) && (
                    <Grid item xs={5}>
                      <FormSelect
                        name="laborUnionId"
                        label="Labor Union"
                        value={values.laborUnionId}
                        onChange={handleInputChange}
                        options={laborUnion}
                        error={errors.laborUnionId}
                      />
                    </Grid>
                  )}
                  {(process.env.REACT_APP_ENVIRONMENT === "UNION" ||
                    user?.role === ROLES.UnionAdministrator) && (
                    <Grid item xs={5}>
                      <FormSelect
                        name="localUnionId"
                        label="Local Union"
                        value={values.localUnionId}
                        onChange={handleInputChange}
                        options={localUnion}
                        error={errors.localUnionId}
                      />
                    </Grid>
                  )}
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              {values.stateId !== "" &&
                values.countryId !== "" &&
                value === 0 && (
                  <ActiveCompanies
                    filters={values}
                    refresh={refresh}
                    parentCompanyId={parentCompanyId}
                  />
                )}
              {values.stateId !== "" &&
                values.countryId !== "" &&
                value === 1 && (
                  <InactiveCompanies
                    filters={values}
                    refresh={refresh}
                    parentCompanyId={parentCompanyId}
                  />
                )}
              {values.stateId !== "" &&
                values.countryId !== "" &&
                user?.role !== "UnionAdministrator" &&
                value === 2 && (
                  <IncompleteCompanies
                    filters={values}
                    refresh={refresh}
                    parentCompanyId={parentCompanyId}
                  />
                )}
              {values.stateId !== "" &&
                values.countryId !== "" &&
                user?.role === "UnionAdministrator" &&
                value === 2 && (
                  <CanceledCompanies
                    filters={values}
                    refresh={refresh}
                    parentCompanyId={parentCompanyId}
                  />
                )}
              {values.stateId !== "" &&
                values.countryId !== "" &&
                value === 3 && (
                  <PendingCompanies
                    filters={values}
                    refresh={refresh}
                    parentCompanyId={parentCompanyId}
                  />
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default Companies;
