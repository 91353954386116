import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckSwitch,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IKeyValue } from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { IElectricCoil } from "src/ts/interfaces/study/electricCoil";
import electricCoilService from "src/services/study/electricCoilService";
import { CatalogService } from "src/services";
import { steps } from "./const/const";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";
import ElectricCoilStep2 from "./ElectricCoilStep2";
const initialValues: IElectricCoil = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  roomAreaServed: "",
  description: "",
  totalBTUSystem: false,
  vavSystemTotal: false,
  vavOutletTotal: false,
  fillNext: false,
  deviceCoefficient: false,
  airflowFlowingCoilsId: 0,
  nameOfPerson: "",
  title: "",
  company: "",
  dateWhenTold: "",
  designAirflowDB: false,
  actualAirflowDB: false,
  designAirflow: false,
  actualAirflow: false,
  rh: false,
  wb: false,
  dp: false,
  designAirflowSP: false,
  actualAirflowSP: false,
  designBTUH: false,
  actualBTUH: false,
  designBTUHTotal: false,
  actualBTUHTotal: false,
  systemSteadyThisTest: false,
  systemSteadyWillBeMonitored: false,
  step: 0,
  isComplete: false,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  mandatoryMinimum: false,
  isProjectDisabled: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ElectricCoilStep1 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [airflowFlowingCoils, setAirflowFlowingCoils] = useState<
    IKeyValue<number, string>[]
  >([]);
  let navigate = useNavigate();
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(electricCoilService.update, {
      successfulMessage: "The report was updated successfully",
      onSuccess: async (result) => {
        await setValues({
          ...result,
        });
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IElectricCoil>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IElectricCoil>(
    electricCoilService.getById,
    {
      onSuccess: (result) => {
        setValues(result);
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffectOnce(() => {
    const getData = async () => {
      const response = await CatalogService.getAllDropdown(
        "AirflowFlowingCoils"
      );
      setAirflowFlowingCoils(response);
    };

    getData();
  });

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeReport(id, values?.reportTypeId === 28 ? "COEA" : "COET");
    };

    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/ElectricCoil/step2/${id}/`);
  };

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [user?.userId, values?.isComplete, dataReport]);

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate,
    [isLoading, isSubmittingUpdate]
  );

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: values?.reportTypeId === 28 ? "COEA" : "COET",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={0}
            projectId={values?.projectId}
            reportId={id}
            reportTypeId={values?.reportTypeId}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="roomAreaServed"
                    label="Room/Area Served"
                    value={values?.roomAreaServed}
                    onChange={handleInputChange}
                    error={errors.roomAreaServed}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCheckSwitch
                    name={"fillNext"}
                    label={"Automatically Fill Next Row & Item"}
                    value={values?.fillNext}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12}>
                  <FormTextArea
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                  ></FormTextArea>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Note: It is recommended the airflow flowing through the
                    coil(s) listed below be balanced with known flows available
                    prior to recording heat transfer data.
                  </Typography>
                  <Typography pt={2}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" pt={2} pr={2}>
                          Airflow(s) that effect the coils was balanced with
                          "known flows/values" prior to performing detailed Coil
                          Heat Transfer Performance Recordings:
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ minWidth: "200px" }}>
                        <FormSelect
                          name={"airflowFlowingCoilsId"}
                          label={""}
                          value={values?.airflowFlowingCoilsId.toString()}
                          onChange={handleInputChange}
                          options={airflowFlowingCoils}
                          defaultValue={{
                            key: 0,
                            value: "Select Airflow Response",
                          }}
                          colorLabel={
                            values?.airflowFlowingCoilsId === 0
                              ? "red"
                              : undefined
                          }
                          disabled={disableStudy}
                        ></FormSelect>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ElectricCoilStep2
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    setErrors={setErrors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={0}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName="Electric Coil AHU Mixed Air Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      disableNext={values?.airflowFlowingCoilsId === 0}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ElectricCoilStep1;
