import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stack,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { steps } from "./const/const";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
} from "src/hooks";
import outletReportService from "src/services/study/outletReportService";
import { useMemo, useState } from "react";
import { IKeyValue, IOutletReport, IReportType } from "src/ts/interfaces";
import controlTypeService from "src/services/study/controlTypeService";
import outletDuctTypeService from "src/services/study/outletDuctTypeService";
import { FormSelect } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { Mode, Validator } from "src/ts/types";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import { getCode, getStudyCode, initialValues } from "./util/util";
import StudyWizard from "../components/StudyWizard";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const PNEUMATIC_ID = 4;

const OutletStep1 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const { data: controlTypes } = useAsyncQuery<IKeyValue<number, string>[]>(
    controlTypeService.getKeyValue,
    { immediate: true }
  );

  const { data: outletDuctTypes } = useAsyncQuery<IKeyValue<number, string>[]>(
    outletDuctTypeService.getKeyValue,
    { immediate: true }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: updateOutletReport } = useAsyncMutation(
    outletReportService.update,
    {
      hideSuccessfulMessage: true,
      errorMessage: "Error at edit",
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.controlTypeId = new Validator(fieldValues, "controlTypeId")
      .selectedOption(null, "Select Control Type")
      .toString();

    temp.outletDuctTypeId = new Validator(fieldValues, "outletDuctTypeId")
      .validateIf(fieldValues.controlTypeId === PNEUMATIC_ID)
      .isRequired("Select Duct Type")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [reportType, setReportType] = useState<IReportType | null>();

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IOutletReport>(initialValues, false, validate);

  useEffectOnce(() => {
    const getData = async () => {
      const res = await outletReportService.getById(id);
      setValues(res.data);
      const studyType = getStudyCode(location.pathname);
      const reportTypeRes = await reportTypeService.getReportTypeByCode(
        studyType
      );
      setReportType(reportTypeRes.data);
      await executeReport(id, reportTypeRes.data.code);
    };

    if (id > 0) getData();
  });

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  const showDuctType = useMemo(() => {
    if (values?.controlTypeId !== null && values?.controlTypeId > 0) {
      const type = controlTypes.find(
        (item) => item.key === values.controlTypeId
      );
      return type?.value === "Pneumatic";
    }
  }, [controlTypes, values.controlTypeId]);

  const mode: Mode = values.isComplete ? "read" : "read&Write";

  const nextStepHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      if (!validate()) return;
      await updateOutletReport(id, values);
    }

    const route = `/studies/${getCode(location.pathname)}/step2/${id}`;
    navigate(route);
  };

  const saveHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      if (!validate()) return;
      await updateOutletReport(id, values);
    }
  };

  const activeStep = 0;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: reportType?.code,
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
            code={getCode(location.pathname)}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="center">
                    <FormSelect
                      fullWidth={false}
                      width="60%"
                      name="controlTypeId"
                      label="External User"
                      value={values.controlTypeId ?? ""}
                      onChange={handleInputChange}
                      options={controlTypes}
                      error={errors.controlTypeId}
                      defaultValue={{
                        key: -1,
                        value: "Select Control Type",
                      }}
                      disabled={disableStudy}
                      mode={mode}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  {showDuctType && (
                    <Stack direction="row" justifyContent="center">
                      <FormSelect
                        name="outletDuctTypeId"
                        label="Duct Type"
                        value={values.outletDuctTypeId}
                        onChange={handleInputChange}
                        options={outletDuctTypes}
                        error={errors.outletDuctTypeId}
                        disabled={disableStudy}
                        mode={mode}
                        width="60%"
                        defaultValue={{
                          key: -1,
                          value: "Select duct Type",
                        }}
                      />
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={values?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    isComplete={values?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OutletStep1;
