import React, { useEffect, useMemo, useState } from "react";
import { Grid, Stack, Tooltip, IconButton, Zoom } from "@mui/material";
import {
  Form,
  useAsyncQuery,
  useAuth,
  useLog,
  usePermissions,
} from "src/hooks";
import {
  FormSelect,
  FormText,
  FormTextArea,
  FormDatePicker,
  FormTimePicker,
  FormCheckSwitch,
  FormButton,
  FormCheckBox,
  FormNumeric2,
} from "src/components/formControls";
import { Validator } from "src/ts/types";
import { MeasurementSystemEnum } from "src/ts/enums/project";
import useFormTyped from "src/hooks/useFormTyped";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import projectService from "src/services/project/projectService";
import projectCatalogs from "src/services/project/projectCatalogs";
import { ISafetyEquipment } from "src/ts/interfaces/project/porjectCatalog";
import {
  IBuilding,
  IBuildingSelected,
  ICountry,
  IProjectBomaType,
  IReportGroup,
} from "src/ts/interfaces";
import {
  CountryService,
  buildingService,
  projectActiveService,
  projectBomaTypeService,
  projectPutService,
} from "src/services";
import { ARCHITECTDATA, ROLES, projectStatus } from "src/constants";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { Permission as PermissionTypes } from "src/ts/enums";
import InfoIcon from "@mui/icons-material/Info";
import projectHistoryService from "src/services/project/projectHistoryService";
import { ForwardIcon } from "src/components/icons";
import BuildingSelect from "./BuildingSelect";
import SelectBuildingPopup from "../../SelectBuildingPopup";
import templateServices from "src/services/templateServices";
import projectReportGroupService from "src/services/ProjectReportGroupService";
import FormNextButton from "src/components/formControls/FormNextButton";

export type ProjectParams = {
  projectId: string;
  companyId: string;
};

interface ProjectFormProps {
  project: ProjectWithBogus;
  setProjectResponse?: (val: number) => void;
  setActiveStep?: (val: number) => void;
}

const BOTH = 3;

const ProjectFormArchitect = ({
  project,
  setProjectResponse,
  setActiveStep,
}: ProjectFormProps) => {
  let params = useParams<ProjectParams>();
  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );

  const createdProject = projectId > 0;
  const newProject = projectId === 0;

  const navigate = useNavigate();
  const { log } = useLog();
  const { user } = useAuth();
  const { fullAccess } = usePermissions(PermissionTypes.Project);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectBuildingShow, setSelectBuildingShow] = useState(false);
  const [loadAuditorsSheduled] = useState(0);
  const [gotoNext, setGotoNext] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [selectedProjectBomaType, setSelectedProjectBomaType] =
    useState<IProjectBomaType | null>(null);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Project Name is required")
      .toString();

    temp.bogusStartDate = new Validator(fieldValues, "bogusStartDate")
      .validateIf(!fieldValues.proposedStartDateTimeUnknown)
      .isRequired("Proposed Start Date is required")
      .toString();

    temp.bogusStartDateTime = new Validator(fieldValues, "bogusStartDateTime")
      .validateIf(!fieldValues.proposedStartDateTimeUnknown)
      .isRequired("Proposed Start Time is required")
      .toString();

    temp.buildingId = new Validator(fieldValues, "buildingId")
      .selectedOption(-1, "The project's building is required.")
      .toString();

    temp.auditAreaSqFt = new Validator(fieldValues, "auditAreaSqFt")
      .validateIf(fieldValues.buildingId > 0 && !fieldValues.auditAreaUnknown)
      .isRequired("This field is required.")
      .toString();

    temp.auditAreaCuFt = new Validator(fieldValues, "auditAreaCuFt")
      .validateIf(
        fieldValues.buildingId > 0 && !fieldValues.auditAreaUnknownCuFt
      )
      .isRequired("This field is required.")
      .toString();

    temp.projectBomaTypeId = new Validator(fieldValues, "projectBomaTypeId")
      .isRequired("Project Type is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ProjectWithBogus>(project, false, validate);

  const { execute: getSafetyEquipment, data: safetyEquipmentsData } =
    useAsyncQuery<ISafetyEquipment[]>(projectCatalogs.safetyEquipments);

  const { execute: getProjectBomaType, data: projectBomaType } = useAsyncQuery<
    IProjectBomaType[]
  >(projectBomaTypeService.getAll);

  const { execute: getBuilding, data: building } = useAsyncQuery<IBuilding>(
    buildingService.getById
  );

  const { execute: getCountry, data: country } = useAsyncQuery<ICountry>(
    CountryService.getById
  );

  const { execute: executeStudies } = useAsyncQuery(
    templateServices.createStudies
  );
  const { execute: executeGetStudies, data: dataGetStudies } =
    useAsyncQuery<IReportGroup>(
      projectReportGroupService.getProjectReportGrupo
    );

  useEffect(() => {
    if (project.id > 0) {
      if (project?.startDate && project.startDate !== null) {
        const [date, time] = project?.startDate.split("T");
        setValues({
          ...project,
          bogusStartDate: date,
          bogusStartDateTime: time,
        });
      } else {
        setValues({
          ...project,
          bogusStartDate: null,
          bogusStartDateTime: null,
        });
      }
      executeGetStudies(projectId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    const getData = async () => {
      await getProjectBomaType();
      await getSafetyEquipment();
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getDataDetail = async () => {
      await getCountry(building.countryId);
    };

    if (building) {
      getDataDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  useEffect(() => {
    const getBuildingData = async () => {
      await getBuilding(project.buildingId);
    };

    if (project.buildingId > 0) {
      getBuildingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (building && building.id > 0) {
      setValues({ ...values, measurementSystem: building.measurementSystem });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  useEffect(() => {
    if (newProject && country && (country.id || 0) > 0) {
      setValues({ ...values, suppliedPowerGridFrequency: country.frequency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (loadAuditorsSheduled > 0) {
      showCreateProjectButton
        ? saveHandler()
        : gotoNext
        ? nextStep()
        : updateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAuditorsSheduled]);

  useEffect(() => {
    if (
      values?.auditAreaSqFt !== undefined &&
      values?.auditAreaSqFt !== null &&
      values?.auditAreaSqFt >= 0
    ) {
      const multiplier =
        values.measurementSystem === MeasurementSystemEnum.Imperial ? 10 : 3;

      setValues({
        ...values,
        auditAreaCuFt: values.auditAreaSqFt * multiplier,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.auditAreaSqFt]);

  useEffect(() => {
    if (values.projectBomaTypeId && values.projectBomaTypeId > 0) {
      const type = projectBomaType?.find(
        (type) => type.id === values.projectBomaTypeId
      );
      setSelectedProjectBomaType(type ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectBomaTypeId]);

  const saveHandler = async () => {
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }

    try {
      setIsSubmitting(true);

      let publishInAuditMasterPro = false;
      let safetyEquipments: ISafetyEquipment[] =
        values?.bogusSafetyEquipments ?? [];

      if (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) {
        var defaultSafetyEquipment = [
          "Hard hat",
          "Steel toe boots",
          "Safety glasses",
        ];
        publishInAuditMasterPro = true;
        safetyEquipments = safetyEquipmentsData.filter((x) =>
          defaultSafetyEquipment.includes(x.name)
        );
      }

      const project = {
        ...values,
        startDate: values.bogusStartDate
          ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
          : null,
        actualStartDate: values.bogusActualStartDate
          ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
          : null,
        visibleToAllContractors: true,
        publishInAuditMasterPro: publishInAuditMasterPro,
        sendProposalThroughAMP: true,
        bogusSafetyEquipments: safetyEquipments ? [...safetyEquipments] : [],
        projectTypeId: BOTH,
      };

      const createProjectResponse = await projectService.create(
        companyId!,
        project
      );

      const createdProject = createProjectResponse.data;

      await projectHistoryService.log(createdProject.id, "Project was updated");

      await modifyActiveProject(createdProject.id);
      await executeStudies(createdProject.id);
      log.success("The project was successfully created");
      setIsSubmitting(false);

      navigate(`/app/TolerancesWizard/Edit/${createdProject.id}/${companyId}`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const modifyActiveProject = async (projectId: number) => {
    try {
      await projectActiveService.modifyActiveProject(projectId);
    } catch (error: any) {
      log.info(error.message || "Something went wrong");
    }
  };

  const updateHandler = async () => {
    setGotoNext(false);
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }

    const project = {
      ...values,
      startDate: values.bogusStartDate
        ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
        : null,
      actualStartDate: values.bogusActualStartDate
        ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
        : null,
    };

    try {
      await projectPutService.update(projectId, project);
      log.success("The project has been succesfully updated.");
      setIsSubmitting(false);
    } catch (error: any) {
      log.info(error.message || "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const filterByTab = useMemo(() => {
    for (const key in ARCHITECTDATA) {
      if (ARCHITECTDATA[key].queryData === project.projectStatusId) {
        return ARCHITECTDATA[key].name;
      }
    }
    return "all";
  }, [project.projectStatusId]);

  const cancelHandler = () => {
    navigate(`/app/ProjectArchitect/${companyId}/${filterByTab}`);
  };

  const nextStep = async () => {
    setGotoNext(true);

    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }

    const viewMode = false;

    if (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) {
      if (
        values?.projectStatus?.name !== projectStatus.Complete.name &&
        values?.projectStatus?.name !== projectStatus.Rejected.name &&
        values?.projectStatus?.name !== projectStatus.Approved.name &&
        !viewMode
      ) {
        const project = {
          ...values,
          startDate: values.bogusStartDate
            ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
            : null,
          actualStartDate: values.bogusActualStartDate
            ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
            : null,
        };

        try {
          await projectPutService.update(projectId, project);

          if (dataGetStudies.groupType3 === null) {
            executeStudies(projectId);
          }
          setIsSubmitting(false);
          log.success("The information was saved succesfully");
          await projectHistoryService.log(projectId, "Project was updated");

          if (
            values?.projectStatus?.name === projectStatus.Contest.name ||
            values?.projectStatus?.name === projectStatus.NewContest.name
          )
            if (setProjectResponse && setActiveStep) {
              navigate(`/app/TolerancesWizard/Edit/${projectId}/${companyId}`);
            } else navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
        } catch (error: any) {
          log.error("Error at edit");
        } finally {
          setIsSubmitting(false);
        }
      } else {
        if (
          values?.projectStatus?.name === projectStatus.Contest.name ||
          values?.projectStatus?.name === projectStatus.NewContest.name
        )
          navigate("Reports/" + projectId + "/" + user?.companyId);
        else navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
      }
    } else {
      if (
        values?.projectStatus?.name !== projectStatus.Complete.name &&
        !(
          projectId > 0 &&
          (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux)
        ) &&
        fullAccess
      ) {
        const uniqueCode = true;
        if (!validate() || !uniqueCode) {
          log.warning("The form is invalid, please correct.");
        }

        const project = {
          ...values,
          startDate: values.bogusStartDate
            ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
            : null,
          actualStartDate: values.bogusActualStartDate
            ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
            : null,
        };

        if (
          user?.canAddBuildingsAndProjects === "true" ||
          user?.canAddBuildingsAndProjects === true ||
          user?.canAddTechniciansToProject === "true" ||
          user?.canAddTechniciansToProject === true
        ) {
          try {
            await projectPutService.update(projectId, project);
            setIsSubmitting(false);
            log.info("The information was saved succesfully");
            projectHistoryService.log(projectId, "Project was updated");
            navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
          } catch (error: any) {
            log.info(error.message || "Something went wrong");
          } finally {
            setIsSubmitting(false);
          }
        } else {
          navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
        }
      } else {
        navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
      }
    }
  };

  const isLoading = false;

  const selectBuidingHandler = async (selectedBuilding: IBuildingSelected) => {
    await getBuilding(selectedBuilding.id);

    setValues({
      ...values,
      buildingId: selectedBuilding.id,
      measurementSystem: selectedBuilding.measurementSystem,
    });
  };

  const row = 12;

  let viewMode =
    values.disabled ||
    values?.projectStatus?.name === projectStatus.InReview.name ||
    values?.projectStatus?.name === projectStatus.Complete.name ||
    values?.projectStatus?.name === projectStatus.Canceled.name ||
    values?.projectStatus?.name === projectStatus.Rejected.name ||
    values?.projectStatus?.name === projectStatus.Approved.name ||
    values?.projectStatus?.name === projectStatus.Incomplete.name ||
    user?.role === ROLES.SysAdmin ||
    !fullAccess;

  const mode = viewMode ? "read" : "read&Write";

  const showGotoBuildingButton =
    (viewMode || createdProject) &&
    user?.role !== ROLES.SysAdmin &&
    (user?.canAddBuildingsAndProjects === "true" ||
      user?.canAddBuildingsAndProjects === true ||
      !(
        user?.role === ROLES.Auditor ||
        user?.role === ROLES.EnergyAuditor ||
        user?.role === ROLES.TemporalAuditor ||
        user?.role === ROLES.TemporalEnergyAuditor
      ));

  const otherBomaTypeIsSet =
    values?.projectBomaType != null
      ? values?.projectBomaType.name === "Other"
        ? true
        : false
      : null;

  const showProjectType =
    (user?.role === ROLES.Architect ||
      user?.role === ROLES.SysAdmin ||
      user?.role === ROLES.SuperAdministrator ||
      user?.role === ROLES.ArchitectAux) &&
    (otherBomaTypeIsSet == null || !otherBomaTypeIsSet);

  const showCreateProjectButton =
    newProject &&
    (user?.role !== ROLES.Architect ||
      user?.role !== ROLES.SysAdmin ||
      user?.role === ROLES.SuperAdministrator ||
      user?.role !== ROLES.ArchitectAux);

  const nextStepButtonLabel =
    user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux
      ? values?.projectStatusId === 9 ||
        values?.projectStatusId === 1 ||
        values?.projectStatusId === 3 ||
        values?.projectStatusId === 11 ||
        values?.projectStatusId === 10 ||
        values?.projectStatusId === 7
        ? "Continue"
        : "Next Step"
      : values?.statusBeforeClosed === 1
      ? "Continue"
      : "Next Step";

  const showUnknownStart =
    (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) &&
    values.startDate === null;

  const filterProjectType = (data: IProjectBomaType[]) => {
    const filter = projectBomaType
      ?.filter((itemFilter) => {
        if (values.isInfectiousControlProject) {
          return itemFilter.id === 9;
        } else {
          return itemFilter.id !== 9;
        }
      })
      .map((type) => {
        return { key: type.id, value: type.name };
      });
    return filter ?? [];
  };

  return (
    <>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="Project Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
              mode={createdProject ? "read" : "read&Write"}
            />
          </Grid>
          {createdProject && (
            <Grid item xs={row}>
              <FormReadOnlyField
                label="Created Date"
                value={convertUTCDateToLocalDate(
                  values?.createdDate ?? ""
                )?.format("MM/DD/yyyy")}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <Grid container>
              <Grid item xs={4}>
                <FormDatePicker
                  name="bogusStartDate"
                  label="Proposed Start Date"
                  value={values.bogusStartDate}
                  onChange={handleInputChange}
                  error={errors.bogusStartDate}
                  disablePast={true}
                  mode={
                    createdProject && values.startDate !== null
                      ? "read"
                      : "read&Write"
                  }
                  disable={values.proposedStartDateTimeUnknown}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTimePicker
                  label="Proposed Start Time"
                  value={values.bogusStartDateTime}
                  onChange={handleInputChange}
                  name={"bogusStartDateTime"}
                  error={errors.bogusStartDateTime}
                  mode={
                    createdProject && values.startDate !== null
                      ? "read"
                      : "read&Write"
                  }
                  disable={values.proposedStartDateTimeUnknown}
                />
              </Grid>
              <Grid item xs={4}>
                {showUnknownStart && (
                  <FormCheckBox
                    name="proposedStartDateTimeUnknown"
                    value={values.proposedStartDateTimeUnknown}
                    label="Unknown at this time"
                    onChange={handleInputChange}
                    disabled={viewMode}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <BuildingSelect
            buildingId={project.buildingId}
            isRead={viewMode || createdProject}
            companyId={companyId}
            values={values}
            setValues={setValues}
            errors={errors}
            showGotoBuildingButton={showGotoBuildingButton}
            onSelect={function (buildingId: IBuildingSelected): void {
              throw new Error("Function not implemented.");
            }}
          ></BuildingSelect>

          {values?.buildingId > -1 && (
            <>
              <Grid item xs={row}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormNumeric2
                      name="auditAreaSqFt"
                      suffix={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? " ft\u00b2"
                          : " mt\u00b2"
                      }
                      label={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Total square feet (Sq/Ft) of audit area being tested"
                          : "Total square meters (Sq/Mt) of audit area being tested"
                      }
                      value={values.auditAreaSqFt?.toString() ?? ""}
                      onChange={handleInputChange}
                      error={errors.auditAreaSqFt}
                      mode={mode}
                      disabled={values.auditAreaUnknown}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3} direction={"row"}>
                    <FormCheckBox
                      name="auditAreaUnknown"
                      value={values.auditAreaUnknown}
                      label="Unknown at this time"
                      onChange={handleInputChange}
                      disabled={viewMode}
                    />
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Building Total Cubic Feet defaults using a cellining height value of 10'"
                          : "Building Total Cubic Meters defaults using a cellining height value of 3m"
                      }
                    >
                      <span>
                        <IconButton aria-label="delete" size="large">
                          <InfoIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                <Stack direction={"row"} spacing={2}></Stack>
              </Grid>
              <Grid item xs={row}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormNumeric2
                      name="auditAreaCuFt"
                      suffix={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? " ft\u00b3"
                          : " mt\u00b3"
                      }
                      label={
                        values.measurementSystem ===
                        MeasurementSystemEnum.Imperial
                          ? "Total cubic feet (Cu/Ft) of audit area being tested"
                          : "Total cubic meters (Cu/Mt) of audit area being tested"
                      }
                      value={values.auditAreaCuFt?.toString() ?? ""}
                      onChange={handleInputChange}
                      error={errors.auditAreaCuFt}
                      mode={mode}
                      disabled={values.auditAreaUnknownCuFt}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormCheckBox
                      name="auditAreaUnknownCuFt"
                      value={values.auditAreaUnknownCuFt}
                      label="Unknown at this time"
                      onChange={handleInputChange}
                      disabled={viewMode}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
            </>
          )}
          {values?.buildingId > -1 && (
            <Grid item xs={row}>
              <FormCheckSwitch
                label="Parking area is part of this test"
                name="isParkingAreaPartOfThisTest"
                value={values.isParkingAreaPartOfThisTest}
                onChange={handleInputChange}
                mode={mode}
              />
            </Grid>
          )}
          {showProjectType && (
            <Grid item xs={row}>
              <FormSelect
                name="projectBomaTypeId"
                label="Project Type"
                value={values?.projectBomaTypeId}
                onChange={handleInputChange}
                options={filterProjectType(projectBomaType)}
                error={errors.projectBomaTypeId}
                disable={isSubmitting}
                showSkeleton={isLoading}
                mode={
                  createdProject && !values.isInfectiousControlProject
                    ? "read"
                    : "read&Write"
                }
              />
            </Grid>
          )}
          {selectedProjectBomaType && selectedProjectBomaType.name === "Other" && (
            <Grid item xs={row}>
              <FormText
                name="otherBomaType"
                label="Other Project Type"
                value={values.otherBomaType}
                onChange={handleInputChange}
                error={errors.otherBomaType}
                showSkeleton={isLoading}
                maxLength={200}
                mode={mode}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <FormTextArea
              name="projectDescription"
              label="Project Description"
              value={values.projectDescription ?? ""}
              onChange={handleInputChange}
              error={errors.projectDescription}
              placeholder=""
              mode={mode}
            />
          </Grid>
          <Grid item xs={row}>
            <FormCheckSwitch
              label="Required Comprehensive Energy Test"
              name="requiredComprehensiveEnergyTest"
              value={values.requiredComprehensiveEnergyTest}
              onChange={handleInputChange}
              mode={mode}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
                variant="outlined"
                size="small"
                text="Close"
              />
              {showCreateProjectButton && (
                <FormNextButton
                  onClick={() => saveHandler()}
                  isSubmitting={isSubmitting}
                  text={"Next Step"}
                  size="small"
                  variant="outlined"
                  color="primary"
                  endIcon={<ForwardIcon />}
                />
              )}

              {createdProject && (
                <FormNextButton
                  onClick={nextStep}
                  isSubmitting={isSubmitting || !dataGetStudies}
                  text={nextStepButtonLabel}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
      <SelectBuildingPopup
        companyId={companyId}
        isDialogOpen={selectBuildingShow}
        setIsDialogOpen={setSelectBuildingShow}
        onSelect={selectBuidingHandler}
      />
    </>
  );
};

export default ProjectFormArchitect;
