import { SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Toolbar,
  Divider as MuiDivider,
  styled,
  Typography,
  Fab as MuiFab,
  Alert as MuiAlert,
} from "@mui/material";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import { AddIcon, DeleteIcon } from "src/components/icons";
import { Mode } from "src/ts/types/common";
import {
  IElectricCoil,
  IElectricCoilItem,
} from "src/ts/interfaces/study/electricCoil";
import { FormNumeric2, FormText } from "src/components/formControls";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import electricCoilItemService from "src/services/study/electricCoilItemService";
import { spacing } from "@mui/system";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import ElectricBank from "../../components/ElectricBank";
import { IKeyValue } from "src/ts/interfaces";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import electricCoilService from "src/services/study/electricCoilService";
import { studiesConfig } from "src/constants";
const Divider = styled(MuiDivider)(spacing);
const Fab = styled(MuiFab)(spacing);
const Alert = styled(MuiAlert)(spacing);
interface Props {
  id: number;
  isLoading: boolean;
  mode: Mode;
  readOnly: boolean;
  saveItems: boolean;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
  disableStudy: boolean;
  electricCoil: IElectricCoil;
  setElectricCoil: (value: SetStateAction<IElectricCoil>) => void;
}

const motorPhase: IKeyValue<string, string>[] = [
  { key: "3 Phase", value: "3 Phase" },
  { key: "Single Phase", value: "Single Phase" },
];

const ratedHz = [
  { key: 60, value: 60 },
  { key: 50, value: 50 },
];

const ElectricCoilGrid = ({
  id,
  isLoading,
  mode,
  readOnly,
  saveItems,
  systemOfMeasurement,
  disableStudy,
  electricCoil,
  setElectricCoil,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<IElectricCoilItem>();
  const [indexv, setIndexv] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [refreshCircuits, setRefreshCircuits] = useState(false);
  const { execute: executeUpdate } = useAsyncQuery<number>(
    electricCoilService.update,
    {
      onSuccess: async (result) => {
        setElectricCoil({
          ...result,
        });
      },
    }
  );
  const { execute, data, setData } = useAsyncQuery<IElectricCoilItem[]>(
    electricCoilItemService.getByCoilId,
    {
      onSuccess: () => {
        setLoadingTable(false);
      },
    }
  );

  const { execute: executeCoil, data: dataCoil } = useAsyncQuery<IElectricCoil>(
    electricCoilItemService.getByCoilId
  );

  const { execute: executeSaveAll } = useAsyncQuery(
    electricCoilItemService.saveItems
  );

  const { execute: executeCreate, isSubmitting: isSubmittingCreate } =
    useAsyncMutation(electricCoilItemService.create, {
      successfulMessage: "The item was created",
      onSuccess: (resultItem) => {
        setData([...data, resultItem]);
      },
    });

  const { execute: executeRemove, isSubmitting: isSubmittingRemove } =
    useAsyncMutation(electricCoilItemService.remove, {
      successfulMessage: "The item was removed",
      onSuccess: async () => {
        const newList = data.filter((item) => item.id !== deleteItem);
        setData(newList);
        if (data.length === indexv + 1) setIndexv(0);
      },
    });

  const { execute: executeSave } = useAsyncQuery<IElectricCoilItem>(
    electricCoilItemService.save,
    {
      onSuccess: async (result) => {
        let oldData = [...data];
        oldData[indexv] = result;
        setData(oldData);
        setLoadingTable(false);
      },
    }
  );

  const { execute: executeSaveSum } = useAsyncQuery<IElectricCoilItem>(
    electricCoilItemService.save,
    {
      onSuccess: async (result) => {
        let oldData = [...data];
        let resultIndex = oldData.findIndex((x) => x.id === result.id);
        oldData[resultIndex] = result;
        setData(oldData);
        setLoadingTable(false);
        await executeUpdate(electricCoil.id, electricCoil);
      },
    }
  );

  const { execute: executeSaveCircuits } = useAsyncQuery<IElectricCoilItem>(
    electricCoilItemService.save,
    {
      onSuccess: () => {
        setRefreshCircuits(!refreshCircuits);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeSaveAll(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    setIsLoadingItem(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexv]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeCoil(id);
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (item)
      setData(
        data.map((dataItem) =>
          dataItem.id === item.id
            ? { ...dataItem, numberOfCircuits: item?.numberOfCircuits }
            : dataItem
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.numberOfCircuits]);

  const updateRow = (row: any, e: any) => {
    row[e.target.name] = e.target.value;
    setItem(row);
  };

  const columns: ISpreadsheetColumn[] = [
    {
      name: "index",
      type: "index",
      label: "#",
      align: "center",
      disabled: true,
    },
    {
      name: "roomAreaServed",
      type: "text",
      label: "Room or Area Served",
      disabled: readOnly || disableStudy,
      hide: electricCoil.reportTypeId !== studiesConfig.COET.reportTypeId,
      minWidth: "100px",
    },
    {
      name: "heatingElectricResistanceHz",
      type: "select",
      label: "Rated Hz",
      disabled: readOnly || disableStudy,
      items: ratedHz,
      minWidth: "70px",
    },
    {
      name: "heatingElectricResistancePhase",
      type: "select",
      label: "Rated Phase",
      disabled: readOnly || disableStudy,
      items: motorPhase,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistancePhaseActual",
      type: "select",
      label: "Actual Phase",
      disabled: true,
      items: motorPhase,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistanceRatedVoltage",
      type: "numericInput",
      label: "Rated Volt",
      disabled: readOnly || disableStudy,
      decimalScale: 2,
      mode: mode,
      thousandSeparator: true,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistanceRatedAmperage",
      type: "numericInput",
      label: "Rated Amp",
      disabled: readOnly || disableStudy,
      decimalScale: 2,
      mode: mode,
      thousandSeparator: true,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistanceSteps",
      type: "numericInput",
      label: "Steps",
      disabled: readOnly || disableStudy,
      decimalScale: 0,
      mode: mode,
      thousandSeparator: true,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistanceStages",
      type: "numericInput",
      label: "Stages",
      disabled: readOnly || disableStudy,
      mode: mode,
      showTotals: true,
      decimalScale: 0,
      thousandSeparator: true,
      minWidth: "110px",
    },
    {
      name: "heatingElectricResistanceRatedKws",
      type: "custom",
      label: "Rated kW",
      disabled: readOnly || disableStudy,
      mode: mode,
      showTotals: true,
      render: (
        row: IElectricCoilItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell minWidth={110}>
            <SpreadsheetNumericInput2
              value={row.heatingElectricResistanceRatedKws}
              onChange={(e: any) => {
                updateRow(row, e);
              }}
              onBlur={(e: any) => {
                handlechangeconstRatedKws(e, row);
              }}
              name="heatingElectricResistanceRatedKws"
              decimalScale={3}
              maxValue={1000000000}
              disabled={readOnly || disableStudy}
              mode={mode}
              thousandSeparator={true}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "heatingElectricResistanceActualKWatts",
      type: "numericInput",
      label: "Actual kW",
      disabled: true,
      mode: "read",
      showTotals: true,
      decimalScale: 2,
      thousandSeparator: true,
      minWidth: "110px",
    },
  ];

  const handlechangeconstRatedKws = (e: any, row: IElectricCoilItem) => {
    const newSave = {
      ...row,
      heatingElectricResistanceRatedKws: e.target.value,
    };
    executeSaveSum(newSave);
  };

  const addItem = async () => {
    if (electricCoil?.fillNext) {
      await executeCreate(data[data.length - 1]);
    } else {
      const create = {
        coildId: id,
        electricCoilId: id,
        heatingElectricResistancePhase: "3 Phase",
        heatingElectricResistancePhaseActual: "3 Phase",
      };
      await executeCreate(create);
    }
  };

  const handleChange = (item: any, e: any) => {
    item[e.target.name] = e.target.value;
    setItem({ ...item });
  };

  const handleBlur = async (item: any) => {
    await executeSave(item);
  };

  const handleDeleteItem = (deleteItem: number) => {
    setDeleteItem(deleteItem);
    setDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await executeRemove(deleteItem);
  };

  const handleSaveCurrentItem = async () => {
    await executeSaveSum(data[indexv]);
  };

  return (
    <>
      <Spreadsheet
        items={data ?? []}
        setItems={setData}
        cols={columns}
        defaultRowPerPage={25}
        showRowTotals={true}
        showSkeleton={isLoading || loadingTable}
        totals={{
          heatingElectricResistanceStages: "Totals",
          heatingElectricResistanceRatedKws: electricCoil.designkWTotal,
          heatingElectricResistanceActualKWatts: electricCoil.actualkWTotal,
        }}
      />
      {electricCoil.reportTypeId === 28 && (
        <Grid container spacing={4} pt={4}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} textAlign={"right"}>
            <FormNumeric2
              name={"actualElectricTotalBTUH"}
              label={`Actual System Total Combined Electric ${systemOfMeasurement.get(
                "btuh"
              )}`}
              value={electricCoil?.actualElectricTotalBTUH}
              showSkeleton={isLoadingItem}
              disabled={disableStudy}
              decimalScale={3}
              mode="read"
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
        </Grid>
      )}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <>
          {data?.map((item, index) => (
            <>
              {item.id !== data[indexv].id && (
                <>
                  <IconButton
                    size="medium"
                    onClick={() => {
                      setIsLoadingItem(true);
                      setIndexv(index);
                    }}
                    sx={{ border: "1px solid" }}
                  >
                    <Typography variant="h5" width={30} height={30} pt={1}>
                      {index + 1}
                    </Typography>
                  </IconButton>
                  {!disableStudy && (
                    <Fab
                      mx={2}
                      color="error"
                      size="small"
                      aria-label="Delete Item"
                      disabled={isSubmittingCreate || isSubmittingRemove}
                      sx={{
                        height: "23px",
                        width: "23px",
                        minHeight: "23px",
                        margin: "0px",
                        marginBottom: "30px",
                      }}
                      onClick={() => {
                        handleDeleteItem(item.id);
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          height: "15px",
                          width: "15px",
                          minHeight: "15px",
                        }}
                      ></DeleteIcon>
                    </Fab>
                  )}
                </>
              )}
              {item.id === data[indexv].id && (
                <>
                  <Fab
                    mx={2}
                    color="primary"
                    size="medium"
                    aria-label="Selected Item"
                  >
                    <Typography variant="h5" width={30} height={30} pt={1}>
                      {index + 1}
                    </Typography>
                  </Fab>
                </>
              )}
            </>
          ))}
          {!readOnly && !disableStudy && (
            <Fab
              onClick={addItem}
              mx={2}
              color="success"
              size="medium"
              aria-label="Add"
              disabled={isSubmittingCreate}
            >
              <AddIcon />
            </Fab>
          )}
        </>
      </Toolbar>
      <Divider my={6}></Divider>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">Item: {indexv + 1}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name={"heatingElectricResistanceManufacturer"}
                label={"Device Mfg"}
                value={
                  data && data[indexv]?.heatingElectricResistanceManufacturer
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
              ></FormText>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name={"heatingElectricResistanceModel"}
                label={"Device Model"}
                value={data && data[indexv]?.heatingElectricResistanceModel}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
              ></FormText>
            </Grid>
            {electricCoil?.designBTUH && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.designBTUH && (
              <Grid item xs={12}>
                <Typography color="warning.main" variant="h5">
                  Design Total Electric Coil{" "}
                  {`${systemOfMeasurement.get("btuh")}`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.designBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"totalBTUHHeating"}
                  label={`(Plans/Print) Design Coil ${systemOfMeasurement.get(
                    "btuh"
                  )} Total`}
                  value={data && data[indexv]?.totalBTUHHeating}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualBTUH && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.actualBTUH && (
              <Grid item xs={12}>
                <Typography color="warning.main" variant="h5">
                  Actual Total Electric Coil{" "}
                  {`${systemOfMeasurement.get("btuh")}`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.actualBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"heatingElectricResistanceActualBTUh"}
                  label={`Actual Total Coil ${systemOfMeasurement.get("btuh")}`}
                  value={
                    data && data[indexv]?.heatingElectricResistanceActualBTUh
                  }
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"heatingElectricResistanceBTUhPercentageOfDesign"}
                  label={"% of Design"}
                  value={
                    data &&
                    data[indexv]
                      ?.heatingElectricResistanceBTUhPercentageOfDesign
                  }
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.designBTUHTotal && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.designBTUHTotal && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow Coil {`${systemOfMeasurement.get("btuh")}`}{" "}
                  Total
                </Typography>
              </Grid>
            )}
            {electricCoil?.designBTUHTotal && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"designTotalCoilBTUH"}
                  label={`(Plans/Print) Design Total Coil ${systemOfMeasurement.get(
                    "btuh"
                  )}`}
                  value={data && data[indexv]?.designTotalCoilBTUH}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualBTUHTotal && electricCoil?.actualAirflow && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.actualBTUHTotal && electricCoil?.actualAirflow && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Actual Airflow Coil {`${systemOfMeasurement.get("btuh")}`}{" "}
                  Total
                </Typography>
              </Grid>
            )}
            {electricCoil?.actualBTUHTotal && electricCoil?.actualAirflow && (
              <Grid item xs={6}>
                {!dataCoil?.isPaid && (
                  <>
                    <Typography>
                      Actual Total Coil {systemOfMeasurement.get("btuh")}
                    </Typography>
                    <Alert mb={4} severity="warning">
                      Data will be available when payment is received
                    </Alert>
                  </>
                )}
                {dataCoil?.isPaid && (
                  <FormNumeric2
                    name={"actualTotalAirflowBTUH"}
                    label={`Actual Total Coil ${systemOfMeasurement.get(
                      "btuh"
                    )}`}
                    value={data && data[indexv]?.actualTotalAirflowBTUH}
                    onChange={(e: any) => {
                      handleChange(data[indexv], e);
                    }}
                    showSkeleton={isLoadingItem}
                    onBlur={() => {
                      handleBlur(data[indexv]);
                    }}
                    disabled={disableStudy}
                    decimalScale={3}
                    thousandSeparator={true}
                  ></FormNumeric2>
                )}
              </Grid>
            )}
            {electricCoil?.actualBTUHTotal && electricCoil?.actualAirflow && (
              <Grid item xs={6}>
                {!dataCoil?.isPaid && (
                  <>
                    <Typography>% of Design</Typography>
                    <Alert mb={4} severity="warning">
                      Data will be available when payment is received
                    </Alert>
                  </>
                )}
                {dataCoil?.isPaid && (
                  <FormNumeric2
                    name={"percentageOfDesignTotalBTUH"}
                    label={"% of Design"}
                    value={data && data[indexv]?.percentageOfDesignTotalBTUH}
                    onChange={(e: any) => {
                      handleChange(data[indexv], e);
                    }}
                    showSkeleton={isLoadingItem}
                    onBlur={() => {
                      handleBlur(data[indexv]);
                    }}
                    disabled={disableStudy}
                    decimalScale={3}
                    thousandSeparator={true}
                  ></FormNumeric2>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} pb={4}>
              <Typography color="warning.main" variant="h5">
                Electric Resistance Heating Device(s)
              </Typography>
            </Grid>
            {data && data[indexv] && (
              <ElectricBank
                data={data && data[indexv]}
                setData={setItem}
                saveData={executeSaveCircuits}
                setLoadingTable={setLoadingTable}
                saveParent={handleSaveCurrentItem}
                refreshCircuits={refreshCircuits}
                disableStudy={disableStudy}
                showSkeleton={isLoadingItem}
                isCoilBank
              ></ElectricBank>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            {electricCoil?.designAirflowDB && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - ΔT{" "}
                  {`${systemOfMeasurement.get("temperature")}`} DB
                </Typography>
              </Grid>
            )}
            {electricCoil?.designAirflowDB && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesignCoilAirTemperture"}
                  label={`(Plans/Print) Design Coil Air Temperature ΔT ${systemOfMeasurement.get(
                    "temperature"
                  )} DB`}
                  value={
                    data && data[indexv]?.plansPrintDesignCoilAirTemperture
                  }
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.designAirflowDB && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography color="success.main" variant="h5">
                Actual Airflow - ΔT{" "}
                {`${systemOfMeasurement.get("temperature")}`} DB
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"upStreamCoilAirTemperatureDryBulb"}
                label={`Up Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                value={data && data[indexv]?.upStreamCoilAirTemperatureDryBulb}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"atfdb"}
                label={`ΔT ${systemOfMeasurement.get("temperature")} DB`}
                value={data && data[indexv]?.atfdb}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                mode={"read"}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"downStreamCoilAirTemperatureDryBulb"}
                label={`Down Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                value={
                  data && data[indexv]?.downStreamCoilAirTemperatureDryBulb
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            {electricCoil?.designAirflow && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.designAirflow && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - {electricCoil?.rh && "RH"}
                  {electricCoil?.wb && "WB"}
                  {electricCoil?.dp && "DP"} {` %`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.designAirflow && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansDesignCoilAir"}
                  label={"(Plans/Print) Design Coil Air"}
                  value={data && data[indexv]?.plansDesignCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualAirflow && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.actualAirflow && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Actual Airflow - {electricCoil?.rh && "RH"}
                  {electricCoil?.wb && "WB"}
                  {electricCoil?.dp && "DP"} {` %`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.actualAirflow && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"upStreamCoilAir"}
                  label={`Up Stream Coil Air ${electricCoil?.rh && "RH"}
                ${electricCoil?.wb ? "WB" : ""}
                ${electricCoil?.dp ? "DP" : ""} %`}
                  value={data && data[indexv]?.upStreamCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualAirflow && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"downStreamCoilAir"}
                  label={`Down Stream Coil Air ${electricCoil?.rh ? "RH" : ""}
                ${electricCoil?.wb ? "WB" : ""}
                ${electricCoil?.dp ? "DP" : ""} %`}
                  value={data && data[indexv]?.downStreamCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.designAirflowSP && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.designAirflowSP && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - Coil Δ SP{" "}
                  {`${systemOfMeasurement.get("inwc")}`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.designAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesginCoilSpInWc"}
                  label={`(Plans/Print) Design Coil Δ SP ${systemOfMeasurement.get(
                    "inwc"
                  )}`}
                  value={data && data[indexv]?.plansPrintDesginCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualAirflowSP && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {electricCoil?.actualAirflowSP && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Actual Airflow - Coil Δ SP{" "}
                  {`${systemOfMeasurement.get("inwc")}`}
                </Typography>
              </Grid>
            )}
            {electricCoil?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"upStreamCoilSpInWc"}
                  label={`Up Stream Coil SP ${systemOfMeasurement.get("inwc")}`}
                  value={data && data[indexv]?.upStreamCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"spInWc"}
                  label={`Δ SP ${systemOfMeasurement.get("inwc")}`}
                  value={data && data[indexv]?.spInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {electricCoil?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"downStreamCoilSpInWc"}
                  label={`Down Stream Coil SP ${systemOfMeasurement.get(
                    "inwc"
                  )}`}
                  value={data && data[indexv]?.downStreamCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  onBlur={() => {
                    handleBlur(data[indexv]);
                  }}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"requiredAirflowCFM"}
                label={`(Plans/Print) Design Airflow ${systemOfMeasurement.get(
                  "cfm"
                )}`}
                value={data && data[indexv]?.requiredAirflowCFM}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"actualAirflowCFM"}
                label={`Actual Airflow ${systemOfMeasurement.get("cfm")}`}
                value={data && data[indexv]?.actualAirflowCFM}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"percentageOfDesign"}
                label={"% Design"}
                value={data && data[indexv]?.percentageOfDesign}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                mode={"read"}
                showSkeleton={isLoadingItem}
                onBlur={() => {
                  handleBlur(data[indexv]);
                }}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={handleConfirmDelete}
      ></MatchWordConfirmModal>
    </>
  );
};

export default ElectricCoilGrid;
