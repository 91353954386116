import { Stack } from "@mui/material";
import StudiesFormCancelButton from "./StudiesFromCancelButton";
import FormBackButton from "src/components/formControls/FormBackButton";
import { FormSaveButton } from "src/components/formControls";
import FormNextButton from "src/components/formControls/FormNextButton";
import { MouseEvent } from "react";
interface footerButtonsProps {
  activeStep: number;
  stepsCount: number;
  projectId: number;
  companyId: number;
  isSaving: boolean;
  disabled: boolean;
  reportName: string;
  saveHandler?: (e: MouseEvent<HTMLElement>) => void;
  previousStepHandler?: (e: MouseEvent<HTMLElement>) => void;
  nextStepHandler?: (e: MouseEvent<HTMLElement>) => void;
  disableNext?: boolean;
  hideSaveButton?: boolean;
  isComplete: boolean;
  singleStep?: boolean;
  hideNextButton?: boolean;
}

export const FooterButtons = (props: footerButtonsProps) => {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        pt={5}
      >
        {props.activeStep > 0 && !props.singleStep && (
          <FormBackButton
            onClick={
              props.previousStepHandler as (e: MouseEvent<HTMLElement>) => void
            }
            size="small"
            variant="outlined"
            isSubmitting={false}
          />
        )}
        {props.activeStep < props.stepsCount - 1 &&
          props.saveHandler &&
          !props.hideSaveButton && (
            <FormSaveButton
              onClick={props.saveHandler}
              isSubmitting={props.isSaving}
              disabled={props.disabled}
            />
          )}
        <StudiesFormCancelButton
          isSubmitting={false}
          projectId={props.projectId}
          companyId={props.companyId}
          reportName={props.reportName}
          isComplete={props.isComplete}
        />
        {props.activeStep < props.stepsCount - 1 &&
          !props.singleStep &&
          !props.hideNextButton && (
            <FormNextButton
              onClick={
                props.nextStepHandler as (e: MouseEvent<HTMLElement>) => void
              }
              size="small"
              isSubmitting={false}
              disabled={props.disableNext}
            />
          )}
      </Stack>
    </>
  );
};
