import axios from "../utils/axios";
import {
  IAdministratorsData,
  IUploadFile,
  IUser,
  IUserPerson,
  IUserPersonRole,
  IUserPost,
  IUserStatus,
  IUploadDocumentTech,
  IUserNew,
  IRolePost,
  IRoleUpdate,
  ICurrentCertificate,
  IUserContactInfo,
  IKeyValue,
  IUserPersonAuditor,
  IUserContactInfoSignup,
} from "../ts/interfaces";
import { IUnionAffiliationNumbers } from "src/ts/interfaces/catalogs";
import { ISatelliteUsers } from "src/ts/interfaces/companyAssets";
import { ITechnicianPermission } from "src/ts/interfaces/permissionTechnician";
import { ICreateByAdmin } from "src/ts/interfaces/unionAffiliated";
import { ITeamMembersTechnician } from "src/ts/interfaces/teamMembers";

const GetCurrentContactInfo = async () => {
  return axios.get<IUserContactInfoSignup>(`users/GetCurrentContactInfo`);
};

const getForContactInfo = async () => {
  return axios.get<IUserContactInfoSignup>(`users/GetForContactInfo`);
};

const getAll = async () => {
  return axios.get<IAdministratorsData[]>(`users/administrators`);
};
const getAllSysadmins = async () => {
  return axios.get<IAdministratorsData[]>(`users/sysadmins`);
};

const getById = async (id: number) => {
  return axios.get<IAdministratorsData>(`users/` + id);
};

const getCurrent = async () => {
  return axios.get<IUserPerson>("UsersCurrent/Current");
};

const update = async (id: number, validate: boolean, data: IUser) => {
  return axios.put<any>("Users/" + id + "?validate=" + validate, data);
};

const create = async (data: IUserPost) => {
  return axios.post<any>("Users/administrator?newRoute=true", data);
};

const getUsersByCompany = (
  companyId: number,
  pending: boolean = false,
  canceled: boolean = false
) => {
  return axios.get<IUserPersonRole[]>(
    `Users/GetByCompany?compId=${companyId}&pending=${pending}&canceled=${canceled}`
  );
};

const sendEmail = async (userId: number, isCompany: boolean) => {
  return axios.put(
    `Users/ResendInvitation?userId=${userId}&newRoute=true&isCompany=${isCompany}`
  );
};

const uploadProfilePic = async (file: IUploadFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload", file.content);

  return await axios({
    method: "post",
    url: "/Users/UpdateCurrentProfilePicture",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateCurrent = (user: IUserPerson) => {
  return axios.put("Users/UpdateCurrent", user);
};

const getUserInformation = async () => {
  return axios.get(`UsersCurrent/Current`);
};

const getUserDocumentaionTecn = async (id: number) => {
  return axios.get<IUploadDocumentTech[]>(
    `UserDocument/technicianDocument?userId=${id}`
  );
};

const validateUser = (id: number) => {
  return axios.put(`Users/validateUser?id=${id}`);
};

const getUserInofmration = async (id: number) => {
  return axios.get<IAdministratorsData>(`users/` + id);
};

const currentCompanyList = () => {
  return axios.get(`UsersCurrent/byCurrentCompanyList`);
};

const getDistinctUsersByCompanyList = (hideCurrentUser: boolean = false) => {
  return axios.get(
    `UsersCurrent/DistinctUsersByCompanyList?hideCurrentUser=${hideCurrentUser}`
  );
};

const getDistinctTechnicianList = (projectId: number) => {
  return axios.get(
    `UsersCurrent/DistinctTechnicianList?projectId=${projectId}`
  );
};

const teamMemberList = () => {
  return axios.get(`teamMember/keyValueForward`);
};

const userStatus = async (userId: number, data: IUserStatus) => {
  return axios.post(`users/unibesStatusUser/${userId}`, data);
};

const userPost = async (data: IUserNew) => {
  return axios.post(`Users`, data);
};

const userAccountPost = async (data: IRolePost) => {
  return axios.post("Account/Register?newRoute=true", data);
};

const userAccountUpdateRole = async (data: IRoleUpdate) => {
  return axios.put(`Account/updateRoles`, data);
};

const existEmailInTeamMember = async (email: string) => {
  return axios.get<ITeamMembersTechnician>(
    `teamMember/teamMemberEmail?email=${email}`
  );
};

const getUnionAffiliationNumbers = async () => {
  return axios.get<IUnionAffiliationNumbers[]>("UnionAffiliationNumbers");
};
const unionAffiliatedUser = async (data: ICreateByAdmin) => {
  return axios.put(`UnionAffiliated/CreateByAdmin?newRoute=true`, data);
};

const getCheckUserPermissionRoute = async (companyId: number) => {
  return axios.get(
    `Permissions/checkUserPermissionRoute?companyId=${companyId}`
  );
};

const getAuthorityByUserName = async (userName: string) => {
  return await axios.get<string>(`Users/GetAuthority?userName=${userName}`);
};
const getCurrentCertification = () => {
  return axios.get<ICurrentCertificate[]>("Technicians/CurrentCertifications");
};

const getCurrentCertificationByRole = () => {
  return axios.get<ICurrentCertificate>("Technicians/CurrentCertification");
};

const getCertificationTechnicians = async (id: number) => {
  return axios.get(`Technicians/downloadCertificationTech?technicianId=${id}`);
};

const getAuditors = async (companyId: number, projectId: number) => {
  return axios.get<IUserPerson[]>(
    `Users/AuditorByCert?companyId=${companyId}&certType=3&projectId=${projectId}`
  );
};

const getUserInfo = async (userId: number) => {
  return axios.get<IUserContactInfo>(`Users/contactInfo?id=${userId}`);
};

const regiserAdministrator = async () => {
  return axios.post<any>(`users/administrators`);
};

const getKeyUsersTransferTo = (companyId: number) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ISatelliteUsers>>(`Users/Equipment?companyId=${companyId}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (user) => {
            return {
              key: user.id,
              value: user.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getOwnerData = async (id: number) => {
  return axios.get(`Users/GetOwnerData?id=${id}`);
};

const getUserCurrenCompany = async () => {
  return axios.get(`users/byCurrentCompanyNotificationBid`);
};

const getAuditorByCer = async (companyId: number, projectId: number) => {
  return axios.get(
    `Users/AuditorByCert?companyId=${companyId}&certType=3&projectId=${projectId}`
  );
};

const getAdministrators = async () => {
  return axios.get("Users/administrators");
};

const getTicketAdministrators = async () => {
  return axios.get("Users/ticketAdministrators");
};

const getCountUsers = async () => {
  return axios.get<number>("Users/CountUsers");
};

const getByEmail = async (email: string, certification: string) => {
  return axios.get(
    `Users/GetByEmail?email=${email}&certification=${certification}`
  );
};

const getAuditorByCert = async (companyId: number, cert: number) => {
  return axios.get<IUserPersonAuditor[]>(
    `Users/AuditorByCert?CompanyId=${companyId}&certType=${cert}`
  );
};

const getTechnicianPermissions = async (userID: number) => {
  return axios.get(`users/permissions/${userID}`);
};
const putTechnicianPermissions = async (
  userID: number,
  data: ITechnicianPermission
) => {
  return axios.put(`users/permissions/${userID}`, data);
};

const putRemovePermanentPermission = async (technicianId: number) => {
  return axios.put(`Users/RemovePermanentPermission?techId=${technicianId}`);
};

const updateProfile = async (data: IUserContactInfoSignup) => {
  return axios.put(`Users/profile`, data);
};

const lockoutUser = async (
  userId: string,
  LockoutEnabled: boolean,
  IsLockout: boolean
) => {
  return axios.put(
    `User/LockoutUser/${userId}?LockoutEnabled=${LockoutEnabled}&IsLockout=${IsLockout}`
  );
};

const userService = {
  lockoutUser,
  GetCurrentContactInfo,
  getForContactInfo,
  getCountUsers,
  getAdministrators,
  getAuditorByCer,
  getAll,
  getAllSysadmins,
  getById,
  getCurrent,
  update,
  create,
  getUsersByCompany,
  sendEmail,
  uploadProfilePic,
  updateCurrent,
  getUserInformation,
  validateUser,
  getUserInofmration,
  currentCompanyList,
  userStatus,
  getUserDocumentaionTecn,
  userPost,
  existEmailInTeamMember,
  getUnionAffiliationNumbers,
  userAccountPost,
  unionAffiliatedUser,
  getCheckUserPermissionRoute,
  userAccountUpdateRole,
  getAuthorityByUserName,
  getCurrentCertification,
  getCertificationTechnicians,
  teamMemberList,
  getAuditors,
  getDistinctUsersByCompanyList,
  getUserInfo,
  regiserAdministrator,
  getKeyUsersTransferTo,
  getOwnerData,
  getUserCurrenCompany,
  getByEmail,
  getAuditorByCert,
  getTechnicianPermissions,
  putTechnicianPermissions,
  putRemovePermanentPermission,
  getTicketAdministrators,
  updateProfile,
  getDistinctTechnicianList,
  getCurrentCertificationByRole,
};

export default userService;
