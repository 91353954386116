/* eslint-disable react-hooks/exhaustive-deps */
import { CardContent, Grid, Card as MuiCard } from "@mui/material";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import projectReportService from "src/services/study/projectReportService";
import getProjectSingleService from "src/services/getProjectSingleService";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StaticService from "src/services/study/staticReportService";
import { useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import { IStaticReportDTO } from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

interface step3Props {
  gridLenght?: number;
  execute: (val: number) => void;
  saveHandler: () => void;
}

const Card = styled(MuiCard)(spacing);

const StaticStep3 = (props: step3Props) => {
  const { gridLenght, execute, saveHandler } = props;
  const { log } = useLog();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IStaticReportDTO>(initialValues, false, validate);

  const getData = async () => {
    try {
      const res = await StaticService.getById(id);
      setValues(res.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (id !== 0) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    getProject(values.projectId);
    getProjectReport(
      values.id,
      values.reportTypeId as number,
      values.projectId
    );
    getParentReportIsComplete(values.id, "STCT");
  }, [values]);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const handlerCustomComplete = async () => {
    await execute(values.id);
  };

  const handlerPreCustomComplete = async () => {
    await saveHandler();
  };

  const disableStudyComplete = useMemo(() => gridLenght === 0, [gridLenght]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && values && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={values?.isComplete}
                      infectiousComplianceControl={null}
                      parentStudy={parentStudy ?? null}
                      singleStep={disableStudyComplete}
                      handlerCustomAccept={handlerCustomComplete}
                      handlerPreCustomAccept={handlerPreCustomComplete}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StaticStep3;
