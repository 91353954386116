import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { steps } from "../const/const";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import outletReportService from "src/services/study/outletReportService";
import { useEffect, useMemo, useState } from "react";
import {
  IKeyValue,
  IOutletReport,
  IOutletReportComponent,
  IReportType,
} from "src/ts/interfaces";
import {
  FormCheckSwitch,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { Mode } from "src/ts/types";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import { getCode, getStudyCode, initialValues } from "../util/util";
import { psycometrics } from "src/constants";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import ComponentForm from "./components/ComponentForm";
import meterDeviceService from "src/services/catalogs/meterDeviceService";
import useOutletReport from "src/pages/studies/outlet/hooks/useOutletReport";
import OutletReportGrid from "./components/OutletReportGrid";
import ComponentList from "../componets/ComponentList";
import StudyWizard from "../../components/StudyWizard";
import { NumericFormat } from "react-number-format";
import { FooterButtons } from "../../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const row = 12;
const OutletStep2 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const {
    setOutletReportComponentId,
    outletReportComponentId,
    componentValues,
    setComponentValues,
    updateAllItems,
  } = useOutletReport();
  const { log } = useLog();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { data: meterDeviceKeyValue } = useAsyncQuery<
    IKeyValue<number, string>[]
  >(meterDeviceService.getKeyValues, { immediate: true });

  const { execute: updateOutletReport } = useAsyncMutation(
    outletReportService.update,
    {
      hideSuccessfulMessage: true,
      errorMessage: "Error at edit",
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [reportType, setReportType] = useState<IReportType | null>();

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IOutletReport>(initialValues, false, validate);

  const [components, setComponents] = useState<IOutletReportComponent[]>([]);

  const setComponentHandler = (components: IOutletReportComponent[]) => {
    setComponents(components);
  };

  useEffectOnce(() => {
    const getData = async () => {
      const res = await outletReportService.getById(id);
      const newValaues = res.data;
      const selected =
        newValaues?.fillNextToogle &&
        newValaues?.temperatureSensibleDryBulb &&
        newValaues?.heat &&
        newValaues?.diffuserGrillerNeckSize &&
        newValaues?.minimumOutletCFMRecorded &&
        newValaues?.diffuserGrilleModel;
      setValues({ ...newValaues, selectAll: selected });
      const studyType = getStudyCode(location.pathname);
      const reportTypeRes = await reportTypeService.getReportTypeByCode(
        studyType
      );
      setReportType(reportTypeRes.data);
      await executeReport(id, reportTypeRes.data.code);

      const componentsRes = await outletReportComponentService.report(id);
      setComponents(componentsRes.data);
      if (componentsRes.data.length > 0) {
        setOutletReportComponentId(componentsRes.data[0].id);
      }

      await systemOfMeasurement.getByProject(res.data.projectId);
    };

    if (id > 0) getData();
  });

  useEffect(() => {
    if (values.heat) {
      setValues({
        ...values,
        dryBulbFId: values.dryBulbFId === 0 ? 1 : values?.dryBulbFId,
      });
    } else {
      setValues({
        ...values,
        dryBulbFId: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.heat]);

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  const mode: Mode = values.isComplete ? "read" : "read&Write";

  const nextStepHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      await saveHandler();
    }

    const route = `/studies/${getCode(location.pathname)}/step3/${id}`;
    navigate(route);
  };

  const saveHandler = async () => {
    try {
      await Promise.all([
        updateOutletReport(id, values),
        updateComponent(),
        updateAllItems(),
      ]);
      log.info("Report was updated successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const previousStepHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      await saveHandler();
    }

    const route = `/studies/${getCode(location.pathname)}/step1/${id}`;
    navigate(route);
  };

  const isLoading = false;

  const changeInputWithVerificationHandler = (e: any) => {
    const { name, value } = e.target;
    if (values?.isComplete || values?.isProjectDisabled) return;

    const newValues = { ...values, [name]: value };

    const switches = [
      newValues?.fillNextToogle,
      newValues?.temperatureSensibleDryBulb,
      newValues?.heat,
      newValues?.diffuserGrillerNeckSize,
      newValues?.minimumOutletCFMRecorded,
      newValues?.diffuserGrilleModel,
    ];

    const allSwitchesOn = switches.every(Boolean);

    setValues({ ...newValues, selectAll: allSwitchesOn });
  };

  const selectAllHandler = (e: any) => {
    const switchValue = e.target.value;
    setValues({
      ...values,
      fillNextToogle: switchValue,
      temperatureSensibleDryBulb: switchValue,
      heat: switchValue,
      diffuserGrillerNeckSize: switchValue,
      minimumOutletCFMRecorded: switchValue,
      diffuserGrilleModel: switchValue,
      selectAll: switchValue,
    });
  };

  const updateComponent = async () => {
    const updateRes = await outletReportComponentService.update(
      componentValues
    );
    setComponentValues(updateRes.data);
  };

  const activeStep = 1;

  const hideWarningCFM =
    componentValues?.totalRequiredCFM &&
    componentValues?.designMinimumCfm &&
    componentValues?.designMaximumCfm &&
    componentValues?.totalRequiredCFM > componentValues?.designMinimumCfm &&
    componentValues?.totalRequiredCFM < componentValues?.designMaximumCfm;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: reportType?.code,
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
            code={getCode(location.pathname)}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    label="Select All"
                    name="selectAll"
                    value={values.selectAll}
                    onChange={selectAllHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Fill Next Item"
                    name="fillNextToogle"
                    value={values.fillNextToogle}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Temperature Sensible Dry Bulb"
                    name="temperatureSensibleDryBulb"
                    value={values.temperatureSensibleDryBulb}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Total Heat"
                    name="heat"
                    value={values.heat}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Diffuser/Grille Neck Size"
                    name="diffuserGrillerNeckSize"
                    value={values.diffuserGrillerNeckSize}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label={`Minimum Outlet ${systemOfMeasurement.get(
                      "cfm"
                    )} Recorded`}
                    name="minimumOutletCFMRecorded"
                    value={values.minimumOutletCFMRecorded}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Diffuser / Grille Model"
                    name="diffuserGrilleModel"
                    value={values.diffuserGrilleModel}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                {values?.heat && (
                  <Grid item xs={6}>
                    <FormRadioGroup
                      name="dryBulbFId"
                      label=""
                      row={true}
                      value={values?.dryBulbFId === 0 ? 1 : values?.dryBulbFId}
                      onChange={handleInputChange}
                      items={psycometrics}
                      showSkeleton={isLoading}
                      mode={mode}
                      disabled={disableStudy}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <ComponentList
                  components={components}
                  setComponentHandler={setComponentHandler}
                  outletReport={values}
                  showActions={!disableStudy}
                  updateStudy={saveHandler}
                />
              </Grid>
              <Grid item xs={12}>
                {systemOfMeasurement && (
                  <ComponentForm
                    components={components}
                    setComponentHandler={setComponentHandler}
                    outletReport={values}
                    meterDeviceKeyValue={meterDeviceKeyValue}
                    componentId={outletReportComponentId}
                    mode={mode}
                    systemOfMeasurement={systemOfMeasurement}
                    disableStudy={disableStudy}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <OutletReportGrid
                  outletReport={values}
                  mode={mode}
                  disabledStudy={disableStudy}
                  meterDeviceKeyValue={meterDeviceKeyValue}
                  updateComponent={updateComponent}
                />
              </Grid>
              {componentValues.actualInletSqtFt != null &&
                componentValues.actualInletSqtFt > 0 && (
                  <Grid item xs={12}>
                    {!hideWarningCFM && (
                      <Alert severity="error">
                        <div>
                          Calculated {systemOfMeasurement.get("cfm")} Range
                        </div>
                        <p>
                          Approximate Industry Calculated{" "}
                          {systemOfMeasurement.get("cfm")} Range for this VAV
                          based on its{" "}
                          <NumericFormat
                            displayType="text"
                            value={componentValues.size}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />{" "}
                          {systemOfMeasurement.get("in")} inlet neck size: [
                          <NumericFormat
                            displayType="text"
                            value={componentValues.designMinimumCfm}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          ] {systemOfMeasurement.get("cfm")} Minimum and [
                          <NumericFormat
                            displayType="text"
                            value={componentValues.designMaximumCfm}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          ] {systemOfMeasurement.get("cfm")} Maximum
                        </p>
                      </Alert>
                    )}
                  </Grid>
                )}
              <Grid item xs={12}>
                <Alert severity="warning">
                  Make sure the AK Factor is correct for all Meter/Devices being
                  used
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <FooterButtons
                  activeStep={activeStep}
                  stepsCount={steps.length}
                  projectId={values?.projectId}
                  companyId={user?.companyId as number}
                  isSaving={false}
                  disabled={false}
                  reportName={reportType?.name ?? ""}
                  previousStepHandler={previousStepHandler}
                  saveHandler={saveHandler}
                  nextStepHandler={nextStepHandler}
                  isComplete={values?.isComplete ?? true}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OutletStep2;
