import axios from "../utils/axios";
import {
  IChangeStatusRequest,
  ICompany,
  ICompanyDataPage,
  CompanyContractorData,
  IKeyValue,
  ICompanyFilter,
  ICompanyData,
} from "../ts/interfaces";
import { getFilter } from "../utils/utils";
import { ISatelliteUsers } from "src/ts/interfaces/companyAssets";

const getAll = async (
  filter: ICompanyFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<ICompanyDataPage>(`Company/GetAll?${queryString}`);
};

const changeStatus = async ({ id, isActive, reason }: IChangeStatusRequest) => {
  return await axios.put(
    `Company/changeStatus?id=${id}&isActive=${isActive}&reason=${reason}`
  );
};

const changeStatusSatellite = async ({
  id,
  isActive,
  reason,
}: IChangeStatusRequest) => {
  return await axios.put(
    `Company/changeStatusSatellite?id=${id}&isActive=${isActive}&reason=${reason}`
  );
};

const getById = async (companyId: number) => {
  return axios.get<ICompany>(`Company/${companyId}`);
};

const getByTrainingCoursesId = async (trainingCourseId: number) => {
  return axios.get<ICompany>(`Company/trainingCourse/${trainingCourseId}`);
};

const updateTrainingCourses = async (
  companyId: number,
  traininCourseId: number,
  isSelected: number
) => {
  return axios.put(
    `Company/trainingCourse/${companyId}/${traininCourseId}/${isSelected}`
  );
};

const getByIdWithSchool = async (companyId: number) => {
  return axios.get<ICompany>(`Company/school?companyId=${companyId}`);
};

const getByIdData = async (companyId: number) => {
  return axios.get<ICompanyData>(`Company/${companyId}`);
};

const getSatellites = async (companyId: number) => {
  return axios.get(`Company/satellite/${companyId}`);
};

const getIsActiveParentCompany = async (companyId: number) => {
  return axios.get(`Company/GetIsActive?companyId=${companyId}`);
};

const update = (company: ICompany) => {
  return axios.put(`company/${company.id}`, company);
};

const add = (company: ICompany) => {
  return axios.post("company?newRoute=true", company);
};

const companyUser = async (companyId: number) => {
  return axios.get(`company/${companyId}`);
};

const IncompleteCompanyDelete = async (companyId: number) => {
  return axios.delete(`Company/DeleteIncomplete?companyId=${companyId}`);
};

const getContractorData = async (id: number) => {
  return axios.get<CompanyContractorData>(`Company/Data?id=${id}`);
};

const getLastSatellite = async (id: number) => {
  return axios.get<ICompany>(`Company/LastSatellite/${id}`);
};

const updateAddress = async (company: ICompany) => {
  return axios.put<ICompany>(`Company/address`, company);
};

const downloadAcceptanceFile = async (documentId: number) => {
  return axios.get(`Company/fileAcceptance?id=${documentId}`);
};

const getKeyValues = (companyId: number) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ISatelliteUsers>>(`Company/satelliteAddress/${companyId}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (user) => {
            return {
              key: user.id,
              value: `${user.address}  ,  ${user.city} ,  ${
                user.stateName != null ? user.stateName : user.state.name
              }`,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateWallerAutoReload = async (company: ICompany) => {
  return axios.put(`Company/${company.id}/wallerAutoReload`, company);
};

const getContractorBoma = async (
  isUnion: boolean,
  search?: string,
  stateName?: string,
  countryId?: number,
  stateId?: number
) => {
  return axios.get(
    `Company/typeWithPreferredContractor?isUnion=${isUnion}${
      search === null ? "" : `&search=${search}`
    }${stateName === null ? "" : `&stateName=${stateName}`}${
      countryId === null ? "" : `&countryId=${countryId}`
    }${
      stateId === null || stateId === Number.MIN_SAFE_INTEGER
        ? ""
        : `&stateId=${stateId}`
    }`
  );
};

const resendEmail = async () => {
  return axios.get(`Company/resendEmail`);
};

const CompanyService = {
  updateAddress,
  updateWallerAutoReload,
  getLastSatellite,
  getById,
  getByIdData,
  getSatellites,
  getAll,
  changeStatus,
  getIsActiveParentCompany,
  update,
  add,
  companyUser,
  IncompleteCompanyDelete,
  getContractorData,
  getKeyValues,
  getContractorBoma,
  resendEmail,
  changeStatusSatellite,
  getByIdWithSchool,
  downloadAcceptanceFile,
  getByTrainingCoursesId,
  updateTrainingCourses,
};

export default CompanyService;
