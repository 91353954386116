import * as React from "react";
import Button from "@mui/material/Button";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import DialogContentText from "@mui/material/DialogContentText";
import { FormRadioGroup, FormTextArea } from "src/components/formControls";
import {
  IProjectReport,
  IProjectReportComments,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import { Grid, Alert, Stack, useTheme, Typography } from "@mui/material";
import { IReportType } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import Popup from "src/components/Popup";
import { useAsyncQuery, useAuth, useLog } from "src/hooks";
import { ROLES } from "src/constants";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import technicianService from "src/services/technicianService";
import { ITechniciansTemp } from "src/ts/interfaces/technician";

interface SteadyStateProps {
  reportId: number;
  reportTypeId: number;
  projectId: number;
  reportCompleted: boolean;
  fullWidth?: boolean | undefined;
  setDisableEditData?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSteady?: (val: boolean) => void;
}

interface CommetnsObject {
  SST: string | null;
  SSM: string | null;
  PPT: string | null;
}

const columns: ColumnType[] = [
  { id: "question", label: "Question", type: "string", sort: true },
  { id: "comments", label: "Comment", type: "string", sort: true },
  { id: "createdDate", label: "Added", type: "date", sort: true },
  { id: "createdBy", label: "Added By", type: "string", sort: true },
];

const SteadyStateDialog = (props: SteadyStateProps) => {
  const { log } = useLog();
  const theme = useTheme();
  const {
    reportId,
    reportTypeId,
    projectId,
    reportCompleted,
    setDisableEditData,
    setShowSteady,
  } = props;
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [projectReport, setProjectReport] = React.useState<IProjectReport>();
  const [reportType, setReportType] = React.useState<IReportType>();
  const [projectReportComments, setProjectReportComments] = React.useState<
    IProjectReportComments[] | null
  >();
  const [commentsObj, setCommentsObj] = React.useState({
    SST: null,
    SSM: null,
    PPT: null,
  } as CommetnsObject);
  const [ssmQuestion, setSsmQuestion] = React.useState(false);
  const [warningModal, setWarningModal] = React.useState(false);
  const [sstQuestion, setSstQuestion] = React.useState(false);
  const [pptQuestion, setPptQuestion] = React.useState(false);
  const [ssmValid, setSsmValid] = React.useState(false);
  const [sstValid, setSstValid] = React.useState(false);
  const [pptValid, setPptValid] = React.useState(false);
  const [comments, setComments] = React.useState(false);
  const [pptComments, setPptComments] = React.useState("");
  const [sstComments, setSstComments] = React.useState("");
  const [ssmComments, setSsmComments] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const disableStudy = () => {
    return (
      reportCompleted ||
      (projectReport?.isInUse && projectReport?.isInUseById !== user?.userId) ||
      projectReport?.isPartOfProject === false
    );
  };

  const { execute: executeReport } = useAsyncQuery<IProjectReport>(
    projectReportService.projectReport,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProjectReport({ ...dataResult });
        };
        getData();
      },
    }
  );

  const { execute: executeReportType } = useAsyncQuery<IReportType>(
    reportTypeService.getReportTypeById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setReportType({ ...dataResult });
        };
        getData();
      },
    }
  );

  React.useEffect(() => {
    const getData = async () => {
      let ceritficationActivated = false;
      try {
        await executeReport(reportId, reportTypeId, projectId);
        await executeReportType(reportTypeId);

        if (
          user?.role === ROLES.Auditor ||
          user?.role === ROLES.EnergyAuditor
        ) {
          const request = await technicianService.getCertificationsByUser(
            user?.userName
          );
          const roleSelected = request.data.filter(
            (item) => item.certificationType === user?.role
          );

          ceritficationActivated = dateCompare(roleSelected);
        }

        if (
          user?.role === ROLES.Administrator ||
          user?.role === ROLES.ProjectManager ||
          user?.role === ROLES.CompanyOwner ||
          user?.role === ROLES.Architect ||
          user?.role === ROLES.ArchitectAux ||
          user?.role === ROLES.SysAdmin ||
          user?.role === ROLES.TemporalAuditor ||
          user?.role === ROLES.TemporalEnergyAuditor ||
          ceritficationActivated
        ) {
          setWarningModal(true);
          if (setDisableEditData) setDisableEditData(true);
        } else setOpen(!disableStudy());
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (
      reportId !== undefined &&
      reportTypeId !== undefined &&
      projectId !== undefined &&
      !reportCompleted
    ) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reportTypeId, projectId, user?.role]);

  React.useEffect(() => {
    if (
      ssmValid &&
      sstValid &&
      (pptValid || (reportType?.code !== "CHIL" && reportType?.code !== "TOWE"))
    ) {
      projectReportService.systemSteady((projectReport as IProjectReport).id, {
        ...(projectReport as IProjectReport),
        systemSteadyStateForThisTest: sstQuestion,
        systemSteadyStateWillBeMonitoredForThisTest: ssmQuestion,
        chillerSteadyStateForThisTest: pptQuestion,
      });

      if (
        ssmQuestion &&
        sstQuestion &&
        (pptQuestion ||
          (reportType?.code !== "CHIL" && reportType?.code !== "TOWE"))
      ) {
        setOpen(false);
      } else if ((projectReport as IProjectReport).id !== undefined) {
        const getProjectReportComments = async () => {
          const projectReportCommentsResponse =
            await projectReportService.getProjectReportComments(
              (projectReport as IProjectReport).id
            );

          setProjectReportComments(projectReportCommentsResponse.data);
          setComments(true);
        };

        getProjectReportComments();
      }
    }
  }, [
    sstValid,
    ssmValid,
    pptValid,
    reportType,
    projectReport,
    sstQuestion,
    ssmQuestion,
    pptQuestion,
  ]);

  const steadyStateHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if ((name as string) === "sstSteadyState") {
      setSstQuestion(value === "Yes");
      setSstValid(true);
    }
    if ((name as string) === "ssmSteadyState") {
      setSsmQuestion(value === "Yes");
      setSsmValid(true);
    }
    if ((name as string) === "pptSteadyState") {
      setPptQuestion(value === "Yes");
      setPptValid(true);
    }
  };

  const handleAddComment = () => {
    let commentsTemp = "";
    Object.entries(commentsObj).forEach(([k, v]) => {
      if (v !== null) commentsTemp += k + ";;;" + (v as string) + ";;;";
    });
    if (commentsTemp.length > 0)
      commentsTemp = commentsTemp.substring(0, commentsTemp.length - 3); //removes the last ";;;"
    try {
      projectReportService.systemSteady((projectReport as IProjectReport).id, {
        ...(projectReport as IProjectReport),
        comments: commentsTemp,
      });
      if (setShowSteady) {
        setShowSteady(false);
      }

      setOpen(false);
    } catch (error) {}
  };

  const handleCommentsChange = (e: any) => {
    const { name, value } = e.target;
    switch (name as string) {
      case "SST":
        setCommentsObj({ ...commentsObj, SST: value as string });
        setSstComments(value as string);
        break;
      case "SSM":
        setCommentsObj({ ...commentsObj, SSM: value as string });
        setSsmComments(value as string);
        break;
      case "PPT":
        setCommentsObj({ ...commentsObj, PPT: value as string });
        setPptComments(value as string);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setComments(false);
    setCommentsObj({
      SST: null,
      SSM: null,
      PPT: null,
    } as CommetnsObject);
    setProjectReport({
      ...(projectReport as IProjectReport),
      systemSteadyStateForThisTest: null,
      systemSteadyStateWillBeMonitoredForThisTest: null,
      chillerSteadyStateForThisTest: null,
      comments: "",
    });
    setSsmQuestion(false);
    setSstQuestion(false);
    setPptQuestion(false);
    setSsmValid(false);
    setSstValid(false);
    setPptValid(false);
  };

  const dateCompare = (array: ITechniciansTemp[]) => {
    const compareDate = new Date();

    if (array.length > 0) {
      if (array[0].certificationDate) {
        const certificationDate = new Date(array[0].certificationDate);
        certificationDate.setFullYear(certificationDate.getFullYear() + 1);
        if (compareDate > certificationDate) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <Popup
        openPopup={open}
        setOpenPopup={setOpen}
        disableEscKeyDown
        disableClickOutside
        onClose={handleClose}
        hideClose
        title="Response required to these questions before continuing"
        titleStyle={{ textAlign: "center", color: theme.palette.error.main }}
      >
        <>
          {!comments && (
            <>
              <Grid container spacing={2}>
                {reportType?.code === "CHIL" && (
                  <>
                    <Grid item xs={12} md={9}>
                      <DialogContentText
                        id="ppt-steady-state"
                        style={{ textAlign: "right" }}
                        marginTop={2}
                        marginRight={2}
                      >
                        <b>Prior to performance testing:</b> Confirm Chiller is
                        fully loaded/challenged and has been operating for a
                        minimum of 30 minutes and is at steady state.
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormRadioGroup
                        name="pptSteadyState"
                        items={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        row
                        onChange={steadyStateHandleChange}
                      />
                    </Grid>
                  </>
                )}
                {reportType?.code === "TOWE" && (
                  <>
                    <Grid item xs={12} md={9}>
                      <DialogContentText
                        id="ppt-steady-state"
                        style={{ textAlign: "right" }}
                        marginTop={2}
                        marginRight={2}
                      >
                        <b>Prior to performance testing this cell:</b> Confirm
                        system is fully loaded/challenged and has been operating
                        for a minimum 30 minutes and is at steady state.
                        <br />
                        Testing to be performed providing the prevailing wind
                        speed is 24.0 KMH/15.0 MPH or less.
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormRadioGroup
                        name="pptSteadyState"
                        items={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        row
                        onChange={steadyStateHandleChange}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={9}>
                  <DialogContentText
                    id="sst-steady-state"
                    style={{ textAlign: "right" }}
                    marginTop={2}
                    marginRight={2}
                  >
                    <b>System at steady state for this test.</b>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormRadioGroup
                    name="sstSteadyState"
                    items={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    row
                    onChange={steadyStateHandleChange}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <DialogContentText
                    id="ssm-steady-state"
                    style={{ textAlign: "right" }}
                    marginTop={2}
                    marginRight={2}
                  >
                    <b>
                      System steady state will be monitored and maintained for
                      this test.
                    </b>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormRadioGroup
                    name="ssmSteadyState"
                    items={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    row
                    onChange={steadyStateHandleChange}
                  />
                </Grid>
              </Grid>
              <br />
            </>
          )}
          <p id="steady-state-footer" style={{ textAlign: "justify" }}>
            "Steady State" Defined as a system that is maintaining constant flow
            and/or temperature based on the testing being performed.
          </p>
          {comments && (
            <>
              <Grid container spacing={2}>
                {(projectReportComments as IProjectReportComments[]).length >
                  0 && (
                  <Grid item xs={12} md={12}>
                    <LocalEnhancedTable<IProjectReportComments>
                      columns={columns}
                      data={projectReportComments as IProjectReportComments[]}
                      defaultRowPerPage={5}
                      defaultSortColumn="createdDate"
                      orderColumn="desc"
                    />
                  </Grid>
                )}
                {!pptQuestion && pptValid && (
                  <Grid item xs={12} md={12} marginTop={2}>
                    <FormTextArea
                      name="PPT"
                      label="Comments: Chiller steady state for this test"
                      placeholder="Comments: Chiller steady state for this test"
                      showSkeleton={false}
                      onChange={handleCommentsChange}
                      value={pptComments}
                    />
                  </Grid>
                )}
                {!sstQuestion && sstValid && (
                  <Grid item xs={12} md={12} marginTop={2}>
                    <FormTextArea
                      name="SST"
                      label="Comments: System steady state for this test"
                      placeholder="Comments: System steady state for this test"
                      showSkeleton={false}
                      value={sstComments}
                      onChange={handleCommentsChange}
                    />
                  </Grid>
                )}
                {!ssmQuestion && ssmValid && (
                  <Grid item xs={12} md={12} marginTop={2}>
                    <FormTextArea
                      name="SSM"
                      label="Comments: System steady state will be monitored and maintained for this test"
                      placeholder="Comments: System steady state will be monitored and maintained for this test"
                      showSkeleton={false}
                      value={ssmComments}
                      onChange={handleCommentsChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} marginTop={2}>
                  <Alert severity="error">
                    "IMPORTANT" Since you are indicating this Equipment/Device
                    is not at steady state, the system will keep showing this
                    Pop-Up during the following steps until the test is
                    completed.
                  </Alert>
                </Grid>
              </Grid>
            </>
          )}
        </>
        <Stack direction="row" spacing={3}>
          {comments && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={handleBack}
                size="small"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={handleAddComment}
                autoFocus
              >
                Add
              </Button>
            </>
          )}
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Information"}
        text={
          <>
            <Typography>Actual values cannot be entered.</Typography>
            <Typography>
              The information will be displayed in read-only mode.
            </Typography>
            <Typography>
              Only certified technicians are authorized to edit the data.
            </Typography>
          </>
        }
        showPopup={warningModal}
        setShowPopup={setWarningModal}
        onSave={() => {}}
        hideAccept={true}
        isSubmitting={false}
        cancelTextButton="Close"
      ></DialogMessagePopup>
    </>
  );
};

export default SteadyStateDialog;
