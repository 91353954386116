import {
  IconButton,
  List,
  ListItem,
  ListItemButton as ListItemButtonMui,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import { CopyAllOutlinedIcon, DeleteIcon } from "src/components/icons";
import { IOutletReport, IOutletReportComponent } from "src/ts/interfaces";
import useOutletReport from "../hooks/useOutletReport";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import outletReportItemService from "src/services/study/outletReportItemService";
import { useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useAsyncQuery, useLog } from "src/hooks";

const ListItemButton = styled(ListItemButtonMui)`
  padding-right: 25px;
  width: 130px;
`;

interface Props {
  components: IOutletReportComponent[];
  setComponentHandler: (components: IOutletReportComponent[]) => void;
  outletReport: IOutletReport;
  showActions?: boolean;
  updateStudy: () => Promise<void>;
}

const ComponentList = ({
  setComponentHandler: setComponents,
  components,
  outletReport,
  showActions,
  updateStudy,
}: Props) => {
  const theme = useTheme();
  const { outletReportComponentId, setOutletReportComponentId, items } =
    useOutletReport();
  const [showDeleteComponentPopup, setShowDeleteComponentPopup] =
    useState(false);
  const [showDeleteComponentConfirmPopup, setShowDeleteComponentConfirmPopup] =
    useState(false);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const { log } = useLog();

  const selectComponetHandler = async (component: IOutletReportComponent) => {
    if (!outletReport.isComplete) {
      await updateStudy();
    }

    setOutletReportComponentId(component.id);
  };

  const { execute: updateOrder } = useAsyncQuery(
    outletReportComponentService.updateOrder
  );

  const addComponetHandler = async (
    component: IOutletReportComponent,
    index: number
  ) => {
    const newComponent: any = {
      ouletReportId: outletReport.id,
      order: (component.order ?? 0) + 1,
      manufacturer: component.manufacturer,
      coilTypeLinked: component.coilTypeLinked,
      hydronicTypeCoilLinked: component.hydronicTypeCoilLinked,
      name: (index + 1).toString(),
    };
    const addComponentRes = await outletReportComponentService.add(
      outletReport.id,
      newComponent
    );

    const newComponets = [...components];
    newComponets.splice(index + 1, 0, addComponentRes.data);
    setComponents(newComponets);

    const outletReportItems: any = {};

    if (items.length === 0) outletReportItems.order = 1;
    else outletReportItems.order = (items[items.length - 1]?.order ?? 0) + 1;

    outletReportItems.device = "Hood";
    outletReportItems.outletReportComponentId = addComponentRes.data.id;
    outletReportItemService.addItem(outletReportItems);
  };

  const [componentForDelete, setComponentForDelete] = useState<{
    component: IOutletReportComponent;
    index: number;
  } | null>(null);

  const deleteComponetHandler = (
    component: IOutletReportComponent,
    index: number
  ) => {
    setComponentForDelete({ component: component, index: index });
    setShowDeleteComponentPopup(true);
  };

  const confirmDelete = () => {
    setShowDeleteComponentPopup(false);
    setShowDeleteComponentConfirmPopup(true);
  };

  const deleteHandler = async () => {
    if (
      componentForDelete === undefined ||
      componentForDelete?.component?.id === null
    )
      return;

    try {
      await outletReportItemService.removeByComponent(
        componentForDelete?.component.id!
      );

      const removeComponents = [...components];
      removeComponents.splice(componentForDelete?.index!, 1);
      setComponents(removeComponents);

      await outletReportComponentService.remove(
        componentForDelete?.component.id!
      );

      if (removeComponents.length > 0) {
        //getOutletReportComponentByIdDelete(vm.Components[0].id);
        setOutletReportComponentId(removeComponents[0].id);
      }
      log.success("Item was deleted");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
    }
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
  };

  const handleDrop = async (
    event: React.DragEvent<HTMLLIElement>,
    index: number
  ) => {
    event.preventDefault();

    if (draggedIndex === null || draggedIndex === index) return;

    const updatedComponents = [...components];
    const [draggedItem] = updatedComponents.splice(draggedIndex, 1);
    updatedComponents.splice(index, 0, draggedItem);
    updatedComponents.map((item, count) => (item.order = count));

    setOutletReportComponentId(updatedComponents[0].id);
    setComponents(updatedComponents);
    await updateOrder(updatedComponents);
    setDraggedIndex(null);
  };

  return (
    <>
      <List component={Stack} direction="row" flexWrap={"wrap"}>
        {components.map((component, index) => {
          return (
            <ListItem
              key={component.id}
              disablePadding
              sx={{
                borderColor: theme.palette.primary.main,
                borderWidth: "1px",
                borderStyle: "solid",
                cursor: "move",
              }}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(event) => handleDragOver(event)}
              onDrop={(event) => handleDrop(event, index)}
            >
              <ListItemButton
                selected={outletReportComponentId === component.id}
                onClick={() => selectComponetHandler(component)}
              >
                <ListItemText
                  primary={
                    component.name ? component.name.substring(0, 10) : index + 1
                  }
                />
              </ListItemButton>

              <ListItemSecondaryAction>
                <>
                  {showActions && (
                    <>
                      <IconButton
                        aria-label="comments"
                        onClick={() => addComponetHandler(component, index)}
                      >
                        <CopyAllOutlinedIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteComponetHandler(component, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <DialogMessagePopup
        title="Delete Component"
        text="Are certain you want to remove this Box?"
        showPopup={showDeleteComponentPopup}
        setShowPopup={setShowDeleteComponentPopup}
        onSave={confirmDelete}
        isSubmitting={false}
      />
      <DialogMessagePopup
        title="Delete Component"
        text={
          <>
            <p>
              It is understood that if you continue, you will lose all the data,
              items and parameters within this Box.
            </p>
            <p>Are certain you want to continue?</p>
          </>
        }
        showPopup={showDeleteComponentConfirmPopup}
        setShowPopup={setShowDeleteComponentConfirmPopup}
        onSave={deleteHandler}
        isSubmitting={false}
      />
    </>
  );
};

export default ComponentList;
