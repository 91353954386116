import React, { useState } from "react";

import { Grid, Stack, Chip } from "@mui/material";

import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import { GridActionButton } from "src/components/gridControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { useLog } from "src/hooks";
import { ISchools } from "src/ts/interfaces/catalogs/schools";
import schoolsService from "src/services/catalogs/schoolsService";
import SchoolForm from "./SchoolForm";

interface ActiveSchoolProps {
  data: ISchools[];
  isSubmitting: boolean;
  filterValues: string;
  onUpdate: () => void;
}

const InactiveSchools = (props: ActiveSchoolProps) => {
  const { data, isSubmitting, filterValues, onUpdate } = props;

  const { log } = useLog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ISchools>();

  const handleEdit = (row: ISchools) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const selectDeleteHandler = (row: ISchools) => {
    setCurrentRow(row);
    setConfirmDialogIsOpen(true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await schoolsService.remove(currentRow?.id);
        log.success("The item was deleted");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        onUpdate();
      }
    }
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
      hide: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "getFullAddress",
      label: "Address",
      type: "string",
      sort: true,
    },
    {
      id: "statusId",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: ISchools) => {
        return (
          <>
            {row.statusId === 1 ? (
              <Chip label="Enabled" color="primary" size="small" />
            ) : (
              <Chip
                label="Disabled"
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ISchools) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={"edit"}
              onClick={() => {
                handleEdit(row);
                setIsDialogOpen(true);
              }}
              tooltip={"Edit"}
            />
            {row?.canDelete && (
              <GridActionButton
                type="delete"
                onClick={() => selectDeleteHandler(row)}
                tooltip="Delete"
              />
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <LocalEnhancedTable<ISchools>
          refreshGrid={true}
          columns={columns}
          data={data ?? []}
          showSkeleton={isSubmitting}
          query={filterValues}
        />
      </Grid>

      {isDialogOpen && (
        <SchoolForm
          selectedValue={currentRow}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          onUpdate={onUpdate}
        />
      )}

      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete the school: ?`}
        matchWord={"DELETE"}
        isDialogOpen={confirmDialogIsOpen}
        setIsDialogOpen={setConfirmDialogIsOpen}
      />
    </>
  );
};

export default InactiveSchools;
