import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import { FormAcceptButton } from "src/components/formControls";
import { useAsyncMutation, useAuth, useLog } from "src/hooks";
import signUpService from "src/services/signUpService";
import { useNavigate } from "react-router-dom";
import { WalletService } from "src/services";
import { ILastWalletMovement } from "src/ts/interfaces";
import { currencyformatter } from "src/utils/format";

interface Props {
  wizardId: number;
  cardId: number;
}
function Redirect({ wizardId, cardId }: Props) {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { log } = useLog();
  const [cardData, setCardData] = useState<ILastWalletMovement>();

  const { execute, isSubmitting } = useAsyncMutation(signUpService.done, {
    hideSuccessfulMessage: true,
    onSuccess: async () => {
      await signOut();
      navigate("/auth/sign-in");
    },
  });
  const handleConfirm = async () => {
    await execute(wizardId);
  };

  useEffect(() => {
    const getMovement = async () => {
      try {
        const response = await WalletService.getLastCardMovement(cardId);
        setCardData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (cardId !== 0) getMovement();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormTitleBox title={"Payment and Account Creation Confirmation"}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            {cardData && (
              <>
                <Typography variant="subtitle2" pt={2}>
                  {`Thank you! We have successfully processed your payment of ${currencyformatter.format(
                    cardData?.amount ?? 0
                  )} to your card ending in ****-****- ${cardData?.creditCard.cardNumber.toString()}.`}
                </Typography>
                <Typography variant="subtitle2" pt={2}>
                  Your account has also been successfully created. Click "OK" to
                  Log In to your new account.
                </Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormAcceptButton
                text="Ok"
                onClick={handleConfirm}
                isSubmitting={isSubmitting}
              ></FormAcceptButton>
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default Redirect;
