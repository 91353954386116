/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Chip,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useAppDispatch,
  useFormulas,
} from "src/hooks";
import { FormCheckSwitch } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEnergyStudyAirHydronicsDTO,
  IEnergyStudySubStudiesTemplateDTO,
  IFormulaSystemService,
  IProjectInfectiousComplianceControl,
  IProjectReporDisplayt,
  IReportType,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { PROJECTMENU_ALL } from "src/constants";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Popup from "src/components/Popup";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import projectService from "src/services/project/projectService";
import { projectReportChild } from "src/services";
import { useSystemOfMeasurement } from "src/hooks";
import energyStudyAirHydronicsService from "src/services/study/energyStudyAirHydronicsService";
import StudiesList from "src/pages/project/projectStudies/components/StudiesList";
import projectAuditorService from "src/services/project/projectAuditorService";
import StudyWizard from "../../components/StudyWizard";
import { useConfig } from "src/contexts/EnergyConfigProvider";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

interface ITemplateCompleted {
  reportTypeId: number;
  completed: boolean;
}

const EnergyStep2 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [moveNextStep, setMoveNextStep] = useState(false);
  const [movePrevStep, setMovePrevStep] = useState(false);
  const [studies, setStudies] = useState<IProjectReporDisplayt[]>([]);
  const [missingHeadSheet, setMissingHeadSheet] = useState(false);
  const [missingCoil, setMissingCoil] = useState(false);
  const [missingPump, setMissingPump] = useState(false);
  const [refreshStudies, setRefreshStudies] = useState(false);
  const [isPartOfProject, setIsPartOfProject] = useState(false);
  const [showProjectCompletionFee, setShowProjectCompletionFee] =
    useState(false);
  const [openIncompleteSubStudiesAlert, setOpenIncompleteSubStudiesAlert] =
    useState(false);
  const [openIncompleteSubStudiesAlert2, setOpenIncompleteSubStudiesAlert2] =
    useState(false);
  const config = useConfig();

  let navigate = useNavigate();

  const validate: any = (fieldValues: IEnergyStudyAirHydronicsDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 1;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const {
    execute: getProjectInfectiousControlById,
    data: projectInfectiousControl,
    setData: setprojectInfectiousControl,
  } = useAsyncQuery<IProjectInfectiousComplianceControl>(
    projectService.getProjectInfectiousControlById
  );

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({
            ...dataResult,
            humidityMeasure: !!dataResult.humidityMeasure
              ? dataResult.humidityMeasure
              : "WB",
          });
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectTolerancesByReportId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
          executeChildReports(values?.projectId, id, values?.reportTypeId);
          getIsPartOfProject(dataResult.id);
          if (dataResult.isInfectiousControlProject) {
            await getProjectInfectiousControlById(dataResult.id);
          }
        };
        getData();
      },
    });

  const { execute: executeTemplate, isLoading: isLoadingTemplate } =
    useAsyncQuery<IEnergyStudySubStudiesTemplateDTO[]>(
      energyStudyAirHydronicsService.getTemplate,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const template = dataResult as IEnergyStudySubStudiesTemplateDTO[];
            const templateCompletedTemp = template.map((item) => ({
              reportTypeId: item.reportTypeId,
              completed: false,
            }));
            let headSheetId = 0;
            let coilsReportId = 0;
            let pumpReportId = 0;

            template?.forEach(async (item, index, base) => {
              let projectObject = {
                projectId: values?.projectId,
                reportTypeId: item.reportTypeId,
                buildingId: project?.buildingId,
              };

              const additionalDataObj = item.additionalDataJSON
                ? JSON.parse(item.additionalDataJSON)
                : null;
              if (additionalDataObj != null) {
                let truth = { ...additionalDataObj[0] };
                for (const [key, value] of Object.entries(
                  additionalDataObj[0]
                )) {
                  if (typeof value == "string") {
                    if (value.startsWith("${") && value.endsWith("}")) {
                      const energyAttName = value.slice(
                        2,
                        -1
                      ) as keyof typeof values;
                      truth = {
                        ...truth,
                        [key]: values[energyAttName],
                      };
                    }
                  }
                }
                projectObject = Object.assign(projectObject, truth);
              }
              const newStudy =
                await energyStudyAirHydronicsService.saveSubStudyGeneric(
                  item.reportType?.controllerName || "",
                  projectObject
                );

              headSheetId =
                item.reportTypeId === 3 ? newStudy.data.id : headSheetId;
              coilsReportId =
                item.reportTypeId === 17 ? newStudy.data.id : coilsReportId;
              pumpReportId =
                item.reportTypeId === 1 ? newStudy.data.id : pumpReportId;

              const projectReport = {
                reportId: newStudy.data.id,
                projectId: values?.projectId,
                reportTypeId: newStudy.data.reportTypeId,
                parentReportId: id,
                parentReportTypeId: values?.reportTypeId,
                required: item.required,
              } as IProjectReport;

              const projectReportNew = await projectReportService.save(
                projectReport
              );

              if (index + 1 === base.length) {
                const valuesUpdated = (
                  await energyStudyAirHydronicsPutService.update(id, {
                    ...values,
                    headSheetId,
                    coilsReportId,
                    pumpReportId,
                  })
                ).data;
                setValues({
                  ...values,
                  ...valuesUpdated,
                });
                await executeChildReports(
                  values?.projectId,
                  id,
                  values?.reportTypeId
                );
              }
            });
          };
          getData();
        },
      }
    );

  const { execute: executeChildReports, isLoading: isLoadingChildReports } =
    useAsyncQuery<IProjectReporDisplayt[]>(projectReportChild.getChildReports, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (dataResult) {
            setStudies(dataResult);
          } else {
            await executeTemplate(
              values?.target,
              values?.reportTypeId,
              values?.testMode
            );
          }
        };
        getData();
      },
    });

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  const getIsPartOfProject = async (projectId: number) => {
    try {
      if (
        user?.role === "Administrator" &&
        project?.companyId?.toString() !== user?.companyId.toString()
      ) {
        setIsPartOfProject(false);
      } else {
        const response = await projectAuditorService.getIsPartOfProject(
          projectId
        );
        setIsPartOfProject(response.data);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeProject(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const saveHandler = async () => {
    setMoveNextStep(false);
    setMovePrevStep(false);
    if (!validate()) return;
    try {
      await saveEnergyHandler();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await energyStudyAirHydronicsPutService.update(id, values);
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const incompleteSubStudies = () => {
    let missingH = false;
    let missingC = false;
    let missingP = false;
    const pendingStatusArray = ["Complete", "Incomplete", "Removed"];
    const pendingReports = studies?.filter(
      (report) =>
        (report.reportTypeCode === "HSFS" ||
          report.reportTypeCode === "HSFA" ||
          report.reportTypeCode === "COAH" ||
          report.reportTypeCode === "PUMP") &&
        pendingStatusArray.indexOf(report.studyStatus) === -1
    );
    missingH =
      pendingReports?.filter(
        (x) => x.reportTypeCode === "HSFS" || x.reportTypeCode === "HSFA"
      ).length > 0;
    setMissingHeadSheet(missingH);
    missingC =
      pendingReports?.filter((x) => x.reportTypeCode === "COAH").length > 0;
    setMissingCoil(missingC);
    missingP =
      pendingReports?.filter((x) => x.reportTypeCode === "PUMP").length > 0;
    setMissingPump(missingP);
    return pendingReports?.length > 0;
  };

  const nextStepHandler = async () => {
    setMoveNextStep(true);
    if (incompleteSubStudies()) {
      setOpenIncompleteSubStudiesAlert(true);
      return;
    }
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step3/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    setMovePrevStep(true);
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step1/${id}`);
    } catch (error) {}
  };

  const selectProjectStudyHandler = (study: IProjectReporDisplayt) => {
    //TODO: check if tolerances were save and show tolerances modal
    if (study.reportTypeRouteComplete !== "" && study.reportTypeRoute !== "") {
      if (study.isComplete) {
        navigate(
          `/studies/${study.reportTypeRouteComplete}`.replace("//", "/")
        );
      } else {
        navigate(`/studies/${study.reportTypeRoute}`.replace("//", "/"));
      }
    }
  };

  const showSubStudies = () =>
    values?.val1TheMechanicalCoilBeingTested &&
    values?.val2IfItsDeterminedFluidFlow &&
    values?.val3IfOtherCoilsArePresent &&
    values?.val4IfItsDeterminedOtherConsecutive &&
    values?.val6IfPresent;

  const handleIncompleteSubStudiesAlertNo = () =>
    setOpenIncompleteSubStudiesAlert(false);

  const handleIncompleteSubStudiesAlertYes = () => {
    setOpenIncompleteSubStudiesAlert(false);
    setOpenIncompleteSubStudiesAlert2(true);
  };

  const handleIncompleteSubStudiesAlert2No = () =>
    setOpenIncompleteSubStudiesAlert2(false);

  const handleIncompleteSubStudiesAlert2Yes = async () => {
    setOpenIncompleteSubStudiesAlert2(false);
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step3/${id}`);
    } catch (error) {}
  };

  return (
    <>
      <Popup
        title="Warning"
        openPopup={openIncompleteSubStudiesAlert}
        setOpenPopup={setOpenIncompleteSubStudiesAlert}
        onClose={handleIncompleteSubStudiesAlertNo}
      >
        <>
          <Alert severity="warning">
            It is highly recommended that you fill out as much data as possible
            in the Engineered Fan Report (or Array) and the Coil AHU Mixed Air
            Report prior to moving forward.
            <br />
            {missingCoil && (
              <div>
                <br />
                <h4 style={{ color: "darkred" }}>
                  You're missing Coil Sheet Actuals Data
                </h4>
                Additionally:
                <br />
                Actual Coil {systemOfMeasurement.get("gpm")}, Entering/Leaving
                Water Temperatures needs to be entered. UpStream Coil DB Temp, &
                UpStream Coil (WB/RH/DP) if required.
                <br />
              </div>
            )}
            {missingHeadSheet && (
              <div>
                <br />
                <h4 style={{ color: "darkred" }}>
                  You're missing Engineered Fan Report/Fan Array Actuals Data
                </h4>
                Additionally:
                <br />
                If the motor is in the airstream, the Motor Heat needs to be
                accounted for to get accurate results.
                <br />
                Motor Nameplate Horse Power, Motor Nameplate Volts, Motor
                Nameplate Amps needs to be entered. Actual Motor Volts, & Amps.
                <br />
              </div>
            )}
            <br />
            Are you certain you want to continue?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleIncompleteSubStudiesAlertNo}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleIncompleteSubStudiesAlertYes}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={openIncompleteSubStudiesAlert2}
        setOpenPopup={setOpenIncompleteSubStudiesAlert2}
        onClose={handleIncompleteSubStudiesAlert2No}
      >
        <>
          <p>
            Are you certain you want to move forward?
            <br />
            Certain data is required in these studies to provide a full and
            comprehensive report with findings, recommendations and suggested
            solutions.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleIncompleteSubStudiesAlert2No}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleIncompleteSubStudiesAlert2Yes}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Divider />
              <h3>Initial Checklist</h3>
              <Grid container spacing={3} marginLeft={5}>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    name="val1TheMechanicalCoilBeingTested"
                    label={config?.labels.val1TheMechanicalCoilBeingTested}
                    value={values?.val1TheMechanicalCoilBeingTested}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    size="medium"
                    switchStyle="Android"
                    showSkeleton={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    name="val2IfItsDeterminedFluidFlow"
                    label={config?.labels.val2IfItsDeterminedFluidFlow}
                    value={values?.val2IfItsDeterminedFluidFlow}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                    showSkeleton={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    name="val3IfOtherCoilsArePresent"
                    label={
                      'Validate "if other coils are present", whether other consecutive or parallel heat transfer coils that are installed and active can potentially contaminate/skew the energy testing results they will also need to be isolated.'
                    }
                    value={values?.val3IfOtherCoilsArePresent}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                    showSkeleton={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    name="val4IfItsDeterminedOtherConsecutive"
                    label="If its determined other consecutive or parallel flows can't be fully isolated, have system isolation issue repaired to fully isolate the coil or install isolation valves."
                    value={values?.val4IfItsDeterminedOtherConsecutive}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                    showSkeleton={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    name="val6IfPresent"
                    label={
                      'Validate "if present", whether other consecutive or parallel heat transfer coils can also be isolated.'
                    }
                    value={values?.val6IfPresent}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                    showSkeleton={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                  />
                </Grid>
              </Grid>
              <Divider />
              {showSubStudies() && (
                <>
                  <h3>Energy Sub-Studies Templates</h3>
                  <StudiesList
                    projectId={project?.id || 0}
                    refreshStudies={refreshStudies}
                    onSelectStudy={selectProjectStudyHandler}
                    project={project as ProjectDTO}
                    isPartOfProject={isPartOfProject}
                    projectInfectiousControl={projectInfectiousControl}
                    setRefreshStudies={setRefreshStudies}
                    studies={studies}
                    setShowProjectCompletionFee={setShowProjectCompletionFee}
                    isLoading={
                      isLoadingTemplate ||
                      isLoading ||
                      isLoadingChildReports ||
                      isLoadingProject ||
                      isLoadingReportType
                    }
                    isEnergy
                  />
                  <Alert severity="warning">
                    It's highly recommended that the Sub-Studies above marked as{" "}
                    <Chip label="REQUIRED" color="error" size="small" /> have
                    been completed prior to moving forward.
                  </Alert>
                </>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingReportType ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                disableNext={!showSubStudies()}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyStep2;
