/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
  FormText,
  FormStopwatch,
  FormButton,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IEnergyStudyAirHydronicsDTO, IReportType } from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Popup from "src/components/Popup";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import projectService from "src/services/project/projectService";
import { useSystemOfMeasurement } from "src/hooks";
import SteadyStateDialog from "../../components/SteadyState";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import StudyWizard from "../../components/StudyWizard";
import { useConfig } from "src/contexts/EnergyConfigProvider";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const meterMeasuredAsOptions = [
  { key: "RH", value: "RH" },
  { key: "WB", value: "WB" },
  { key: "DP", value: "DP" },
];

const EnergyHydronicsStep5 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [openHydWaterIsolationWarning, setOpenHydWaterIsolationWarning] =
    useState(false);
  const [openMeterAvgWaring, setOpenMeterAvgWarning] = useState(false);
  const [openBleedThroughWarning, setOpenBleedThroughWarning] = useState(false);
  const [beginFinalTestTimeH2O, setBeginFinalTestTimeH2O] = useState<Date>();
  const [finalTestTimeH2O, setFinalTestTimeH2O] = useState<Date>();
  const [startEnergyOnTestTimer, setStartEnergyOnTestTimer] = useState(false);
  const [beginSteadyOnTimeStamp, setBeginSteadyOnTimeStamp] = useState<Date>();
  const [endSteadyOffTimeStamp, setEndSteadyOffTimeStamp] = useState<Date>();
  const [startSteadyOffTimer, setStartSteadyOnTimer] = useState(false);
  const [disableEditData, setDisableEditData] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [refreshCircuits, setRefreshCircuits] = useState(false);
  const config = useConfig();

  let navigate = useNavigate();

  const validate: any = (fieldValues: IEnergyStudyAirHydronicsDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 4;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({ ...dataResult });
          setBeginFinalTestTimeH2O(dataResult.beginFinalTestTimeH2O);
          setBeginSteadyOnTimeStamp(dataResult.beginSteadyOnTestTimeH2O);
          setFinalTestTimeH2O(dataResult.finalTestTimeH2O);
          setEndSteadyOffTimeStamp(dataResult.steadyOnTestTimeH2O);
          setStartEnergyOnTestTimer(
            !!dataResult.beginFinalTestTimeH2O && !dataResult.finalTestTimeH2O
          );
          setStartSteadyOnTimer(
            !!dataResult.beginSteadyOnTestTimeH2O &&
              !dataResult.steadyOnTestTimeH2O
          );
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectTolerancesByReportId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
        };
        getData();
      },
    });

  const { execute: executeUpdtMetersAvg } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              meterAverage: dataResult.meterAverage,
              meter1Enthalpy: dataResult.meter1Enthalpy,
              meter1Deviation: dataResult.meter1Deviation,
              meter2Enthalpy: dataResult.meter1Enthalpy,
              meter2Deviation: dataResult.meter1Deviation,
              meter3Enthalpy: dataResult.meter1Enthalpy,
              meter3Deviation: dataResult.meter1Deviation,
            });
            log.info("Meters deviation saved correctly");
          };
          getData();
        },
      }
    );

  const { execute: executeBeginTimeEmergyOn } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.beginTimeEnergyOn,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              beginFinalTestTimeH2O: dataResult,
            });
            setBeginFinalTestTimeH2O(new Date(dataResult));
            setStartEnergyOnTestTimer(true);
          };
          getData();
        },
      }
    );

  const { execute: executeEndTimeEnergyOn } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endTimeEnergyOn,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              finalTestTimeH2O: dataResult.finalTestTimeH2O,
            });
            setStartEnergyOnTestTimer(false);
          };
          getData();
        },
      }
    );

  const { execute: executeBeginTimeSteadyOn } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.beginTimeSteadyOn,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              beginSteadyOnTestTimeH2O: dataResult,
            });
            setBeginSteadyOnTimeStamp(new Date(dataResult));
            setStartSteadyOnTimer(true);
          };
          getData();
        },
      }
    );

  const { execute: executeEndTimeSteadyOn } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endTimeSteadyOn,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              steadyOnTestTimeH2O: dataResult.steadyOnTestTimeH2O,
            });
            setStartSteadyOnTimer(false);
            if (
              dataResult.psychrometricsSteadyOnMessage !== "" &&
              dataResult.steadyOnMaValue !== null &&
              dataResult.steadyOnMaValue !== 0 &&
              dataResult.steadyOnMaDryBulb !== null &&
              dataResult.steadyOnMaDryBulb !== 0
            ) {
              if (dataResult.psychrometricsSteadyOnMessageIsError) {
                log.error(
                  "Error in Energy On Steady State M/A: " +
                    dataResult.psychrometricsSteadyOnMessage
                );
              } else {
                log.message(
                  "Message for Energy On Steady State M/A: " +
                    dataResult.psychrometricsSteadyOnMessage
                );
              }
            }
          };
          getData();
        },
      }
    );

  const { execute: executeSaveCircuits } = useAsyncQuery(
    energyStudyAirHydronicsPutService.update,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({
            ...values,
            numberOfCircuitsInHeatingCoil:
              dataResult.numberOfCircuitsInHeatingCoil,
            heatingElectricResistanceActualWatts:
              dataResult.heatingElectricResistanceActualWatts,
            heatingElectricResistanceActualKWatts:
              dataResult.heatingElectricResistanceActualKWatts,
          });
          setRefreshCircuits(!refreshCircuits);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeProject(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    let lowest = 999999999;
    let highest = 0;

    lowest =
      (values?.hamiltonErrorTemp1 || 999999999) < lowest
        ? values?.hamiltonErrorTemp1 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp2 || 999999999) < lowest
        ? values?.hamiltonErrorTemp2 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp3 || 999999999) < lowest
        ? values?.hamiltonErrorTemp3 || 0
        : lowest;

    highest =
      (values?.hamiltonErrorTemp1 || 0) > highest
        ? values?.hamiltonErrorTemp1 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp2 || 0) > highest
        ? values?.hamiltonErrorTemp2 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp3 || 0) > highest
        ? values?.hamiltonErrorTemp3 || 0
        : highest;

    const deviation = Math.abs(highest - lowest);
    setValues({
      ...values,
      hamiltonErrorDeviation: deviation,
    });
  }, [
    values?.hamiltonErrorTemp1,
    values?.hamiltonErrorTemp2,
    values?.hamiltonErrorTemp3,
  ]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        const dataResult = (
          await energyStudyAirHydronicsPutService.update(id, values)
        ).data;
        if (
          dataResult.psychrometricsSteadyOnMessage !== "" &&
          dataResult.steadyOnMaValue !== null &&
          dataResult.steadyOnMaValue !== 0 &&
          dataResult.steadyOnMaDryBulb !== null &&
          dataResult.steadyOnMaDryBulb !== 0
        ) {
          if (dataResult.psychrometricsSteadyOnMessageIsError) {
            log.error(
              "Error in Energy On Steady State M/A: " +
                dataResult.psychrometricsSteadyOnMessage
            );
          } else {
            log.message(
              "Message for Energy On Steady State M/A: " +
                dataResult.psychrometricsSteadyOnMessage
            );
          }
        }
        setValues({
          ...values,
          heatingElectricResistanceActualWatts:
            dataResult.heatingElectricResistanceActualWatts,
          heatingElectricResistanceActualKWatts:
            dataResult.heatingElectricResistanceActualKWatts,
        });
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveEnergyHandlerMini = async () => {
    try {
      if (!disableStudy()) {
        const dataResult = (
          await energyStudyAirHydronicsPutService.update(id, values)
        ).data;
        setValues({
          ...values,
          heatingElectricResistanceActualWatts:
            dataResult.heatingElectricResistanceActualWatts,
          heatingElectricResistanceActualKWatts:
            dataResult.heatingElectricResistanceActualKWatts,
        });
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step6/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step4/${id}`);
    } catch (error) {}
  };

  const showEnergyOnButton = () =>
    !!values?.endTestTimeStamp ||
    values?.systemIs100OA ||
    values?.systemIs100RA;

  const showEnergyOnTest = () =>
    showEnergyOnButton() && values.beginFinalTestTimeH2O;

  const showEnOnTestEntry = () =>
    showEnergyOnTest() &&
    ((values?.haveHydronicControlValveOpen &&
      values?.openAnyCoilValvePreviouslyClosed) ||
      !values?.systemIsMA);

  const showSteadyOnStartButton = () =>
    showEnOnTestEntry() && !!values?.finalTestTimeH2O;

  const showSteadyOnTest = () =>
    showSteadyOnStartButton() && !!values?.beginSteadyOnTestTimeH2O;

  const showSteadyOnTestEntry = () =>
    showSteadyOnTest() &&
    values?.steadyStateIsConsidered &&
    values?.isReachedRecordTheirValues;

  const enableNextButton = () =>
    showSteadyOnTestEntry() && !!values?.steadyOnTestTimeH2O;

  const handleHydWaterIsolationWarningClose = () =>
    setOpenHydWaterIsolationWarning(false);

  const handleMetersWithinToleranceChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value) setOpenMeterAvgWarning(true);
  };

  const handleMeterAvgWarningClose = () => {
    setOpenMeterAvgWarning(false);
    setValues({
      ...values,
      metersWithinTolerance: false,
      meter1DB: 0,
      meter1Value: 0,
      meter1MeasuredAs: "",
      meter1Location: "",
      meter1Label: "Meter 1",
      meter2DB: 0,
      meter2Value: 0,
      meter2MeasuredAs: "",
      meter2Location: "",
      meter2Label: "Meter 2",
      meter3DB: 0,
      meter3Value: 0,
      meter3MeasuredAs: "",
      meter3Location: "",
      meter3Label: "Meter 3",
    });
  };

  const handleMeterAvgWarningOk = () => {
    setOpenMeterAvgWarning(false);
    executeUpdtMetersAvg(id, { ...values, metersWithinTolerance: true });
  };

  const handleBleedThroughWarningClose = () =>
    setOpenBleedThroughWarning(false);

  const hanldeMeterLocationChange = (e: any) => {
    const name = (e.target.name as string).split(
      "-"
    )[0] as keyof IEnergyStudyAirHydronicsDTO;
    const location = (e.target.name as string).split("-")[1];
    setValues({
      ...values,
      [name]: values[name] !== location ? location : "",
    });
  };

  const handleValidateMeterAvgFrm = () =>
    (!!values?.meter1Label &&
      !!values?.meter1Location &&
      values?.meter1DB !== null &&
      values?.meter1Value !== null &&
      !!values?.meter1MeasuredAs &&
      !!values?.meter2Label &&
      !!values?.meter2Location &&
      values?.meter2DB !== null &&
      values?.meter2Value !== null &&
      !!values?.meter2MeasuredAs &&
      !!values?.meter3Label &&
      !!values?.meter3Location &&
      values?.meter3DB !== null &&
      values?.meter3Value !== null &&
      !!values?.meter3MeasuredAs) ||
    (!!values?.meter1Label &&
      !!values?.meter1Location &&
      values?.meter1DB !== null &&
      values?.meter1Value !== null &&
      !!values?.meter1MeasuredAs &&
      !!values?.meter2Label &&
      !!values?.meter2Location &&
      values?.meter2DB !== null &&
      values?.meter2Value !== null &&
      !!values?.meter2MeasuredAs &&
      !values?.systemIsMA);

  const handleBeginEnergyOnTestTimerClick = async (e: any) => {
    setValues({ ...values, preTestTimeStamp: true });
    await executeBeginTimeEmergyOn(id, { ...values, preTestTimeStamp: true });
  };

  const handleEndEnergyOnTestTimerClick = async (e: any) => {
    await executeEndTimeEnergyOn(id, values);
  };

  const handleBeginSteadyOnTimerClick = async (e: any) => {
    await executeBeginTimeSteadyOn(id, { ...values, preTestTimeStamp: true });
  };

  const handleEndSteadyOnTimerClick = async (e: any) => {
    await executeEndTimeSteadyOn(id, values);
  };

  const hanldeHumidityMeasureChange = (e: any) => {
    if (user?.role === "Training") {
      log.error("Training Mode will allow to use only WB");
      return;
    }
    const name = (e.target.name as string).split(
      "-"
    )[0] as keyof IEnergyStudyAirHydronicsDTO;
    const location = (e.target.name as string).split("-")[1];
    setValues({
      ...values,
      [name]: values[name] !== location ? location : "",
    });
  };

  const handleStaticPressureSignChange = (
    sign: "-" | "+",
    name: keyof IEnergyStudyAirHydronicsDTO
  ) => {
    if (!disableStudy())
      setValues({
        ...values,
        [name]: sign === "-",
      });
  };

  const handleFinalReadingsChange = (e: any) => {
    const name = e.target.name as keyof IEnergyStudyAirHydronicsDTO;
    const nameDrift = `${name}Drift` as keyof IEnergyStudyAirHydronicsDTO;
    let driftValue = 0;
    let preName = "" as keyof IEnergyStudyAirHydronicsDTO;
    if (name !== "finalMaDryBulb")
      preName = name.replace(
        "final",
        "pre"
      ) as keyof IEnergyStudyAirHydronicsDTO;
    else
      preName = name.replace(
        "final",
        "testing"
      ) as keyof IEnergyStudyAirHydronicsDTO;
    driftValue = e.target.value - ((values[preName] as number) || 0);
    setValues({
      ...values,
      [name]: e.target.value,
      [nameDrift]: driftValue,
    });
  };

  const handleSteadyOnReadingsChange = (e: any) => {
    const name = e.target.name as keyof IEnergyStudyAirHydronicsDTO;
    const nameDrift = `${name}Drift` as keyof IEnergyStudyAirHydronicsDTO;
    let driftValue = 0;
    let preName = "" as keyof IEnergyStudyAirHydronicsDTO;
    if (name !== "steadyOnMaDryBulb")
      preName = name.replace(
        "steadyOn",
        "pre"
      ) as keyof IEnergyStudyAirHydronicsDTO;
    else
      preName = name.replace(
        "steadyOn",
        "testing"
      ) as keyof IEnergyStudyAirHydronicsDTO;
    driftValue = e.target.value - ((values[preName] as number) || 0);
    setValues({
      ...values,
      [name]: e.target.value,
      [nameDrift]: driftValue,
    });
  };

  return (
    <>
      <SteadyStateDialog
        reportId={values?.id as number}
        reportTypeId={values?.reportTypeId as number}
        projectId={values?.projectId as number}
        reportCompleted={values?.isComplete || false}
        setDisableEditData={setDisableEditData}
      />
      <Popup
        title="Energy Off, Hydronic Water Isolation"
        openPopup={openHydWaterIsolationWarning}
        setOpenPopup={setOpenHydWaterIsolationWarning}
        onClose={handleHydWaterIsolationWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p style={{ textAlign: "justify" }}>
            To ensure accurate energy measurement and airflow rates in systems
            utilizing hot water, chilled water, and condenser water, it is
            crucial to verify the complete isolation of entering and leaving
            water via isolation valves. This involves ensuring no bleed-through
            or trickle is present, which could cause undesired delta
            temperatures due to leakage. Failure to properly isolate these
            valves can compromise system integrity, leading to inaccurately low
            airflow volume rates.
            <br />
            Additionally, temperature measurements should not be taken too close
            to a coil's supply and return water headers to avoid misinterpreting
            hydronic water bleed-through and thermal conduction as system flaws.
          </p>
          <p style={{ textAlign: "justify", color: "red" }}>
            Neglecting to account for water bleed-through can result in
            underreported airflow readings.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleHydWaterIsolationWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Meters Deviation"
        openPopup={openMeterAvgWaring}
        setOpenPopup={setOpenMeterAvgWarning}
        onClose={handleMeterAvgWarningClose}
        disableClickOutside
        hideClose
        size="lg"
      >
        <>
          <h3 style={{ textAlign: "center" }}>
            Comparing the Individual Enthalpy Meters to each other
          </h3>
          <p style={{ textAlign: "justify" }}>
            Place the temperature/enthalpy meters being used for this test in a
            steady-state temperature airstream (O/A or R/A) and ensure that the
            sensing element is only being affected by the fluid medium and allow
            proper time for each of the meters temperature elements to
            stabilize. Identify each meter by an identification mark and its
            association for each point being tested.
          </p>
          <Table stickyHeader aria-label="static components">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>#</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Identifier
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Location/Point
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>Reading</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>1</TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormText
                    label=""
                    name="meter1Label"
                    value={values?.meter1Label}
                    onChange={handleInputChange}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormCheckBox
                    name="meter1Location-OA"
                    showSkeleton={isLoading}
                    label="OA"
                    value={values?.meter1Location === "OA"}
                    disabled={
                      values?.meter2Location === "OA" ||
                      values?.meter3Location === "OA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter1Location-RA"
                    showSkeleton={isLoading}
                    label="RA"
                    value={values?.meter1Location === "RA"}
                    disabled={
                      values?.meter2Location === "RA" ||
                      values?.meter3Location === "RA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter1Location-MA"
                    showSkeleton={isLoading}
                    label="MA"
                    value={values?.meter1Location === "MA"}
                    disabled={
                      values?.meter2Location === "MA" ||
                      values?.meter3Location === "MA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`DB ${systemOfMeasurement.get("temperature")}`}
                        name="meter1DB"
                        onChange={handleInputChange}
                        value={values?.meter1DB}
                        showSkeleton={isLoading}
                        error={errors.meter1DB}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`${values?.meter1MeasuredAs || ""}`}
                        suffix={
                          values?.meter1MeasuredAs === "RH"
                            ? "%"
                            : systemOfMeasurement.get("temperature")
                        }
                        name="meter1Value"
                        onChange={handleInputChange}
                        value={values?.meter1Value}
                        showSkeleton={isLoading}
                        error={errors.meter1Value}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormRadioGroup
                        showSkeleton={isLoading}
                        name="meter1MeasuredAs"
                        label=""
                        items={meterMeasuredAsOptions}
                        value={values?.meter1MeasuredAs}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        row
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>1</TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormText
                    label=""
                    name="meter2Label"
                    value={values?.meter2Label}
                    onChange={handleInputChange}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormCheckBox
                    name="meter2Location-OA"
                    showSkeleton={isLoading}
                    label="OA"
                    value={values?.meter2Location === "OA"}
                    disabled={
                      values?.meter1Location === "OA" ||
                      values?.meter3Location === "OA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter2Location-RA"
                    showSkeleton={isLoading}
                    label="RA"
                    value={values?.meter2Location === "RA"}
                    disabled={
                      values?.meter1Location === "RA" ||
                      values?.meter3Location === "RA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter2Location-MA"
                    showSkeleton={isLoading}
                    label="MA"
                    value={values?.meter2Location === "MA"}
                    disabled={
                      values?.meter1Location === "MA" ||
                      values?.meter3Location === "MA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`DB ${systemOfMeasurement.get("temperature")}`}
                        name="meter2DB"
                        onChange={handleInputChange}
                        value={values?.meter2DB}
                        showSkeleton={isLoading}
                        error={errors.meter2DB}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`${values?.meter2MeasuredAs || ""}`}
                        suffix={
                          values?.meter2MeasuredAs === "RH"
                            ? "%"
                            : systemOfMeasurement.get("temperature")
                        }
                        name="meter2Value"
                        onChange={handleInputChange}
                        value={values?.meter2Value}
                        showSkeleton={isLoading}
                        error={errors.meter2Value}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormRadioGroup
                        showSkeleton={isLoading}
                        name="meter2MeasuredAs"
                        label=""
                        items={meterMeasuredAsOptions}
                        value={values?.meter2MeasuredAs}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        row
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {values?.systemIsMA && (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>1</TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormText
                      label=""
                      name="meter3Label"
                      value={values?.meter3Label}
                      onChange={handleInputChange}
                      showSkeleton={isLoading}
                      disabled={disableStudy()}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormCheckBox
                      name="meter3Location-OA"
                      showSkeleton={isLoading}
                      label="OA"
                      value={values?.meter3Location === "OA"}
                      disabled={
                        values?.meter1Location === "OA" ||
                        values?.meter2Location === "OA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                    <FormCheckBox
                      name="meter3Location-RA"
                      showSkeleton={isLoading}
                      label="RA"
                      value={values?.meter3Location === "RA"}
                      disabled={
                        values?.meter1Location === "RA" ||
                        values?.meter2Location === "RA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                    <FormCheckBox
                      name="meter3Location-MA"
                      showSkeleton={isLoading}
                      label="MA"
                      value={values?.meter3Location === "MA"}
                      disabled={
                        values?.meter1Location === "MA" ||
                        values?.meter2Location === "MA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <FormNumeric2
                          disabled={disableStudy()}
                          placeholder="0.00"
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          label={`DB ${systemOfMeasurement.get("temperature")}`}
                          name="meter3DB"
                          onChange={handleInputChange}
                          value={values?.meter3DB}
                          showSkeleton={isLoading}
                          error={errors.meter3DB}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormNumeric2
                          disabled={disableStudy()}
                          placeholder="0.00"
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          label={`${values?.meter3MeasuredAs || ""}`}
                          suffix={
                            values?.meter3MeasuredAs === "RH"
                              ? "%"
                              : systemOfMeasurement.get("temperature")
                          }
                          name="meter3Value"
                          onChange={handleInputChange}
                          value={values?.meter3Value}
                          showSkeleton={isLoading}
                          error={errors.meter3Value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormRadioGroup
                          showSkeleton={isLoading}
                          name="meter3MeasuredAs"
                          label=""
                          items={meterMeasuredAsOptions}
                          value={values?.meter3MeasuredAs}
                          onChange={handleInputChange}
                          disabled={disableStudy() || isLoading}
                          row
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleMeterAvgWarningClose}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleMeterAvgWarningOk}
            autoFocus
            disabled={!handleValidateMeterAvgFrm()}
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Potential Coil Bleedthrough"
        openPopup={openBleedThroughWarning}
        setOpenPopup={setOpenBleedThroughWarning}
        onClose={handleBleedThroughWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            The Mixed Air (M/A) temperature during the "Energy Off" phase is
            lower than the temperature value of either the Outside Air (O/A) or
            Return Air (R/A). This suggests a possible issue with your M/A
            Temperature selection point or coil bleed through.
          </p>
          <p>
            Please review your M/A temperature placement, your coil shut-off
            procedures, and re-examine the positions of your O/A and R/A
            sensors.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleBleedThroughWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Divider />
              {(values?.systemIs100OA || values?.systemIs100RA) && (
                <>
                  <Grid container spacing={3} marginLeft={5}>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="metersWithinTolerance"
                        label="Ensure O/A, R/A, & M/A Temperature/Enthalpy meters are within tolerance of each other."
                        value={values?.metersWithinTolerance}
                        onChange={handleMetersWithinToleranceChange}
                        disabled={
                          disableStudy() ||
                          isLoading ||
                          values?.metersWithinTolerance
                        }
                        switchStyle="Android"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {values?.metersWithinTolerance && (
                <>
                  {showEnergyOnButton() && (
                    <>
                      <Divider />
                      <Grid
                        container
                        spacing={3}
                        marginTop={1}
                        marginBottom={2}
                      >
                        <Grid item xs={12} md={4} textAlign="center">
                          <FormButton
                            text="Begin Test, Energy First Time On"
                            onClick={handleBeginEnergyOnTestTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values?.beginFinalTestTimeH2O}
                          />
                          {!!values?.beginFinalTestTimeH2O && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                First Time On, Begin Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.beginFinalTestTimeH2O as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormStopwatch
                            date={beginFinalTestTimeH2O || new Date()}
                            start={startEnergyOnTestTimer}
                            endDate={finalTestTimeH2O}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showEnergyOnTest() && values?.systemIsMA && (
                    <>
                      <Divider />
                      <Grid container spacing={3} marginLeft={5}>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="haveHydronicControlValveOpen"
                            label={config?.labels.haveHydronicControlValveOpen}
                            value={values?.haveHydronicControlValveOpen}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            size="medium"
                            switchStyle="Android"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="openAnyCoilValvePreviouslyClosed"
                            label={
                              config?.labels.openAnyCoilValvePreviouslyClosed
                            }
                            value={values?.openAnyCoilValvePreviouslyClosed}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            switchStyle="Android"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showEnOnTestEntry() && (
                    <>
                      <Divider />
                      <Grid container spacing={3}>
                        {(values?.systemIs100OA || values?.systemIs100RA) && (
                          <>
                            <Grid item xs={12} md={3}>
                              <p>When collecting Airflow Transfer Data use:</p>
                            </Grid>
                            <Grid item xs={12} md={9}>
                              <FormCheckBox
                                name="humidityMeasure-WB"
                                showSkeleton={isLoading}
                                label="WB"
                                value={values?.humidityMeasure === "WB"}
                                onChange={hanldeHumidityMeasureChange}
                                size="medium"
                              />
                              <FormCheckBox
                                name="humidityMeasure-RH"
                                showSkeleton={isLoading}
                                label="RH"
                                value={values?.humidityMeasure === "RH"}
                                onChange={hanldeHumidityMeasureChange}
                                size="medium"
                              />
                              <FormCheckBox
                                name="humidityMeasure-DP"
                                showSkeleton={isLoading}
                                label="DP"
                                value={values?.humidityMeasure === "DP"}
                                onChange={hanldeHumidityMeasureChange}
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Alert severity="info">
                            Point when Energy On is First Started
                          </Alert>
                        </Grid>
                        {/**Record OA */}
                        {values?.recordOA && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>O/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="finalOaDryBulb"
                                onChange={handleFinalReadingsChange}
                                value={values?.finalOaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.finalOaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="finalOaValue"
                                onChange={handleInputChange}
                                value={values?.finalOaValue}
                                showSkeleton={isLoading}
                                error={errors.finalOaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.recordOA &&
                                !values.finalTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "finalOaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "finalOaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.finalOaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="finalOaStaticPressure"
                                value={values?.finalOaStaticPressure}
                                onChange={handleFinalReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.finalTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record RA */}
                        {values?.ra && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>R/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="finalRaDryBulb"
                                onChange={handleFinalReadingsChange}
                                value={values?.finalRaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.finalRaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="finalRaValue"
                                onChange={handleInputChange}
                                value={values?.finalRaValue}
                                showSkeleton={isLoading}
                                error={errors.finalRaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.ra &&
                                !values.finalTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "finalRaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "finalRaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.finalRaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="finalRaStaticPressure"
                                value={values?.finalRaStaticPressure}
                                onChange={handleFinalReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.finalTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record MA */}
                        {values?.ma && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>M/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="finalMaDryBulb"
                                onChange={handleFinalReadingsChange}
                                value={values?.finalMaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.finalMaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  width: "100%",
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label="DB Drift #1: N/A"
                                color="default"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalMaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="finalMaValue"
                                onChange={handleInputChange}
                                value={values?.finalMaValue}
                                showSkeleton={isLoading}
                                error={errors.finalMaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.ma &&
                                !values.finalTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "finalMaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "finalMaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.finalMaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="finalMaStaticPressure"
                                value={values?.finalMaStaticPressure}
                                onChange={handleFinalReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.finalTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record EA */}
                        {values?.eaUsingDB && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>E/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="finalEaDryBulb"
                                onChange={handleFinalReadingsChange}
                                value={values?.finalEaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.finalEaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.finalTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="finalEaValue"
                                onChange={handleInputChange}
                                value={values?.finalEaValue}
                                showSkeleton={isLoading}
                                error={errors.finalEaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.eaUsingDB &&
                                !values.finalTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "finalEaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "finalEaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.finalEaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="finalEaStaticPressure"
                                value={values?.finalEaStaticPressure}
                                onChange={handleFinalReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.finalTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          textAlign="center"
                          marginBottom={3}
                        >
                          <FormButton
                            text="End Test, Energy First Time On"
                            onClick={handleEndEnergyOnTestTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.finalTestTimeH2O}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {!!values?.finalTestTimeH2O && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                First Time On, End Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.finalTestTimeH2O as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showSteadyOnStartButton() && (
                    <>
                      <Divider />
                      <Grid
                        container
                        spacing={3}
                        marginTop={1}
                        marginBottom={2}
                      >
                        <Grid item xs={12} md={4} textAlign="center">
                          <FormButton
                            text="Begin Test, Energy On Steady State"
                            onClick={handleBeginSteadyOnTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.beginSteadyOnTestTimeH2O}
                          />
                          {!!values?.beginSteadyOnTestTimeH2O && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                On Steady State, Begin Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.beginSteadyOnTestTimeH2O as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormStopwatch
                            date={beginSteadyOnTimeStamp || new Date()}
                            start={startSteadyOffTimer}
                            endDate={endSteadyOffTimeStamp}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showSteadyOnTest() && (
                    <>
                      <Divider />
                      <Grid container spacing={3} marginLeft={5}>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="steadyStateIsConsidered"
                            label="Steady State is considered when the energy going across the DB and WB sensors stabilize and rise or fall slightly."
                            value={values?.steadyStateIsConsidered}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            size="medium"
                            switchStyle="Android"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="isReachedRecordTheirValues"
                            label="Once the Primary Steady State DB & WB is reached record their values."
                            value={values?.isReachedRecordTheirValues}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            switchStyle="Android"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showSteadyOnTestEntry() && (
                    <>
                      <Divider />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            Point of Energy On Steady State
                          </Alert>
                        </Grid>
                        {/**Record OA */}
                        {values?.recordOA && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>O/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="steadyOnOaDryBulb"
                                onChange={handleSteadyOnReadingsChange}
                                value={values?.steadyOnOaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.steadyOnOaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #3: ${(
                                  values?.steadyOnOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="steadyOnOaValue"
                                onChange={handleInputChange}
                                value={values?.steadyOnOaValue}
                                showSkeleton={isLoading}
                                error={errors.steadyOnOaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              {!disableStudy() &&
                                values?.recordOA &&
                                !values.steadyOnTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "steadyOnOaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "steadyOnOaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.steadyOnOaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 160,
                                }}
                                name="steadyOnOaStaticPressure"
                                value={values?.steadyOnOaStaticPressure}
                                onChange={handleSteadyOnReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.steadyOnTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #3: ${(
                                  values?.steadyOnOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record RA */}
                        {values?.ra && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>R/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="steadyOnRaDryBulb"
                                onChange={handleSteadyOnReadingsChange}
                                value={values?.steadyOnRaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.steadyOnRaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #3: ${(
                                  values?.steadyOnRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="steadyOnRaValue"
                                onChange={handleInputChange}
                                value={values?.steadyOnRaValue}
                                showSkeleton={isLoading}
                                error={errors.steadyOnRaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              {!disableStudy() &&
                                values?.ra &&
                                !values.steadyOnTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "steadyOnRaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "steadyOnRaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.steadyOnRaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 160,
                                }}
                                name="steadyOnRaStaticPressure"
                                value={values?.steadyOnRaStaticPressure}
                                onChange={handleSteadyOnReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.steadyOnTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #3: ${(
                                  values?.steadyOnRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record MA */}
                        {values?.ma && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>M/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="steadyOnMaDryBulb"
                                onChange={handleSteadyOnReadingsChange}
                                value={values?.steadyOnMaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.steadyOnMaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  width: "100%",
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label="DB Drift #1: N/A"
                                color="default"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalMaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #3: ${(
                                  values?.steadyOnMaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="steadyOnMaValue"
                                onChange={handleInputChange}
                                value={values?.steadyOnMaValue}
                                showSkeleton={isLoading}
                                error={errors.steadyOnMaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              {!disableStudy() &&
                                values?.ma &&
                                !values.steadyOnTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "steadyOnMaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "steadyOnMaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.steadyOnMaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 160,
                                }}
                                name="steadyOnMaStaticPressure"
                                value={values?.steadyOnMaStaticPressure}
                                onChange={handleSteadyOnReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.steadyOnTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #3: ${(
                                  values?.steadyOnMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record EA */}
                        {values?.eaUsingDB && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>E/A:</p>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="steadyOnEaDryBulb"
                                onChange={handleSteadyOnReadingsChange}
                                value={values?.steadyOnEaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.steadyOnEaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #1: ${(
                                  values?.testingEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #2: ${(
                                  values?.finalEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift #3: ${(
                                  values?.steadyOnEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.steadyOnTestTimeH2O
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="steadyOnEaValue"
                                onChange={handleInputChange}
                                value={values?.steadyOnEaValue}
                                showSkeleton={isLoading}
                                error={errors.steadyOnEaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              {!disableStudy() &&
                                values?.eaUsingDB &&
                                !values.steadyOnTestTimeH2O && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "steadyOnEaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "steadyOnEaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.steadyOnEaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 160,
                                }}
                                name="steadyOnEaStaticPressure"
                                value={values?.steadyOnEaStaticPressure}
                                onChange={handleSteadyOnReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.steadyOnTestTimeH2O
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #1: ${(
                                  values?.testingEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #2: ${(
                                  values?.finalEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift #3: ${(
                                  values?.steadyOnEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          textAlign="center"
                          marginBottom={3}
                        >
                          <FormButton
                            text="End Test, Energy Off Steady State"
                            onClick={handleEndSteadyOnTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.steadyOnTestTimeH2O}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {!!values?.steadyOnTestTimeH2O && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                On Steady State, End Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.steadyOnTestTimeH2O as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                        {!!values?.steadyOnTestTimeH2O &&
                          config?.labels.heatingElectricResistanceTitle()}
                        {!!values?.steadyOnTestTimeH2O &&
                          config?.inputs.cfms(
                            values,
                            handleInputChange,
                            isLoading,
                            disableStudy,
                            systemOfMeasurement,
                            errors
                          )}
                        {!!values?.steadyOnTestTimeH2O &&
                          config?.inputs.heatingElectricResistanceActualWatts(
                            values,
                            isLoading,
                            errors
                          )}
                        {!!values?.steadyOnTestTimeH2O &&
                          config?.inputs.heatingElectricResistanceActualKWatts(
                            values,
                            isLoading,
                            errors
                          )}
                        {!!values?.steadyOnTestTimeH2O &&
                          config?.inputs.banks(
                            values,
                            setValues as any,
                            executeSaveCircuits,
                            saveEnergyHandlerMini,
                            refreshCircuits,
                            disableStudy,
                            isLoading,
                            setLoadingTable
                          )}
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Divider />
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingReportType ||
                  isLoadingProject ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                disableNext={!enableNextButton()}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyHydronicsStep5;
