import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "Coil data configuration",
    url: "/studies/ElectricCoil/Step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Data capture and report",
    url: "/studies/ElectricCoil/Step2/:id",
  },
];
