/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useFormulas,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  IFormulaSystemService,
  IStaticIdentDTO,
  IStaticReportDTO,
} from "src/ts/interfaces";
import SteadyStateDialog from "../../components/SteadyState";
import staticReportService from "src/services/study/staticReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import { StaticComponentsGrid } from "./components/ComponentsGrid";
import StaticStep3 from "../step3";

interface StaticReportProp {
  values: IStaticReportDTO;
  setValues:
    | Dispatch<SetStateAction<IStaticReportDTO>>
    | Dispatch<SetStateAction<IStaticReportDTO | undefined>>;
  errors: any;
  setErrors: (val: any) => void;
  setDataReport: (val: IProjectReport) => void;
}

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const StaticStep2 = (props: StaticReportProp) => {
  const { values, setValues, errors, setErrors, setDataReport } = props;
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableEditData, setDisableEditData] = useState(false);
  const [gridLenght, setGridLenght] = useState(0);

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const activeStep = 1;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IStaticReportDTO>(
    staticReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          setValues({ ...dataResult });
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? values.projectId
          );
          setGridLenght(dataResult.staticIdent.length);
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
        await executeReport(id, "STCT");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    setDataReport(dataReport);
  }, [dataReport]);

  const saveHandler = async () => {
    try {
      setIsUpdating(true);
      await staticReportService.update(id, values);
      await staticReportService.updateIdent(
        values.id,
        values.staticIdent as IStaticIdentDTO[]
      );
      log.success("Report was updated successfully");
      setIsUpdating(false);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      if (!values?.isComplete) {
        setIsSubmitting(true);
        await staticReportService.update(id, values);
        await staticReportService.updateIdent(
          values.id,
          values.staticIdent as IStaticIdentDTO[]
        );
        log.success("Report was updated successfully");
        setIsSubmitting(false);
      }
      navigate(`/studies/static/step1/${id}`);
    } catch (error) {}
  };

  const nexStepHandler = async () => {
    if (!validate()) return;
    try {
      if (!values?.isComplete) {
        setIsSubmitting(true);
        await staticReportService.update(id, values);
        await staticReportService.updateIdent(
          values.id,
          values.staticIdent as IStaticIdentDTO[]
        );
        log.success("Report was updated successfully");
        setIsSubmitting(false);
      }
      navigate(`/studies/static/step3/${id}`);
    } catch (error) {}
  };

  const updateProcess = async () => {
    await staticReportService.update(id, values);
    await execute(id);
    await executeReport(id, "STCT");
  };

  return (
    <>
      <SteadyStateDialog
        reportId={values?.id}
        reportTypeId={values?.reportTypeId as number}
        projectId={values?.projectId}
        reportCompleted={values?.isComplete}
        setDisableEditData={setDisableEditData}
      />
      <Divider my={6} />
      <h3>Readings</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StaticComponentsGrid
                staticReportId={id}
                values={values}
                setValues={setValues}
                disableStudy={disableStudy()}
                formulas={formulas}
                isLoading={isLoading}
                systemOfMeasurement={systemOfMeasurement}
                execute={updateProcess}
                setGridLenght={setGridLenght}
              />
              <br />
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId}
                companyId={values.user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating || isLoading || isSubmitting || values?.isComplete
                }
                reportName="Static Report"
                previousStepHandler={previousStepHandler}
                saveHandler={saveHandler}
                nextStepHandler={nexStepHandler}
                hideSaveButton={disableStudy()}
                isComplete={values?.isComplete ?? true}
                singleStep={true}
              />
              <Grid item xs={12}>
                <StaticStep3
                  gridLenght={gridLenght}
                  execute={execute}
                  saveHandler={saveHandler}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StaticStep2;
