import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { IEnergyHistory } from "src/ts/interfaces/study/study";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useState } from "react";
import { pdfExportService } from "src/services";
import FileUtils from "src/utils/file";
import { useLog } from "src/hooks";
import ProgressBarModal from "src/components/modals/ProgressBarModal";

interface HistoryProps {
  historyShowPopUp: boolean;
  setHistoryShowPopUp: (val: boolean) => void;
  energyHistoryData: IEnergyHistory[];
}

const getColumnsVersions = (
  navigate: NavigateFunction,
  downloadFile: (val: number) => void
): ColumnType[] => [
  {
    id: "createdByName",
    label: "Created by",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Version Date",
    type: "utcDate",
    sort: true,
    format: "MMM DD, YYYY hh:mm:ss A",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IEnergyHistory) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="download"
              onClick={() => {
                downloadFile(row.projectReportId);
              }}
              tooltip="Download"
            />
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(
                  `/studies/EnergyStudyAirHydronics/step7/${row.energyStudyAirHydronicsId}`
                );
              }}
              tooltip="Go to Project"
            />
          </Stack>
        </>
      );
    },
  },
];

export default function HistoryStudiesPopUp({
  historyShowPopUp,
  setHistoryShowPopUp,
  energyHistoryData,
}: HistoryProps) {
  let navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [showProgressBarModal, setProgressBarModal] = useState(false);

  const { log } = useLog();

  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };

  const downloadFile = async (projectId: number) => {
    try {
      setProgressBarModal(true);
      setProgress(0);
      const response = await pdfExportService.downloadStudy(
        projectId,
        onProgressDownload
      );

      FileUtils.downloadFile(response.data.content, response.data.filename);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setProgressBarModal(false);
    }
  };

  const columns = getColumnsVersions(navigate, downloadFile);
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={historyShowPopUp}
        setOpenPopup={setHistoryShowPopUp}
        onClose={() => {
          setHistoryShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <LocalEnhancedTable<IEnergyHistory>
            refreshGrid={false}
            columns={columns}
            data={energyHistoryData}
            showSkeleton={false}
            rowsPerPageOptions={[5]}
            skeletonRows={5}
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setHistoryShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
      <ProgressBarModal
        progress={progress}
        isDialogOpen={showProgressBarModal}
        setIsDialogOpen={setProgressBarModal}
        text={"Download zip file"}
      />
    </>
  );
}
