import { useAsyncQuery, useLog } from "src/hooks";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Skeleton, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import pitotTraverseActualTestService from "src/services/study/pitotTraverseActualTestService";
import ChangeReadingsPopUp from "../../components/ChangeReadingsPopUp";
import { IPitotTraverseTest } from "src/ts/interfaces/study/pitot";

interface IOvalaAction {
  ovalAction: boolean;
  yesOvalAction: boolean;
  noOvalAction: boolean;
}

interface Props {
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number, totalItems: number) => void;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
  values: any;
  dataBase: number;
  dataHeight: number;
  setDataBase: (val: number) => void;
  dataInsulation: number;
  setDataInsulation: (val: number) => void;
  setDataHeight: (val: number) => void;
  stepContinuos: string;
  ductSizeBase: number;
  ductSizeHeight: number;
  setShowSteady: (total: boolean) => void;
  showSteady: boolean;
  internalInsulation: number;
  setPitotTraverseGrid: (val: boolean) => void;
  setChangeMatrixButton: (val: boolean) => void;
  setGridLoading: (val: boolean) => void;
  pitotTraverseGrid: boolean;
  executeRefresh: (id: number, values: IPitotTraverseTest) => void;
  ovalAction: IOvalaAction;
  setOvalAction: (val: IOvalaAction) => void;
}

const PitotFanTraverseTTGrid = ({
  saveItems,
  disabled,
  showSkeleton,
  onBlur,
  systemOfMeasurement,
  values,
  dataBase,
  setDataBase,
  dataHeight,
  setDataHeight,
  stepContinuos,
  ductSizeBase,
  ductSizeHeight,
  setShowSteady,
  showSteady,
  dataInsulation,
  setDataInsulation,
  internalInsulation,
  setPitotTraverseGrid,
  pitotTraverseGrid,
  setChangeMatrixButton,
  setGridLoading,
  executeRefresh,
  ovalAction,
  setOvalAction,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<any>();
  const [showChangeXYModal, setShowChangeXYModal] = useState(false);

  const { log } = useLog();
  const { execute, data, setData } = useAsyncQuery<any[]>(
    pitotTraverseActualTestService.getByPitotId
  );

  const { execute: executeSave } = useAsyncQuery(
    pitotTraverseActualTestService.saveItems,
    {
      onSuccess: (result) => {
        setData(result);
        setItem(result);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeSave(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    if (values?.ductTypeId !== 0) setItem([]);
  }, [values?.ductTypeId]);

  useEffect(() => {
    const matrixChange = async () => {
      setChangeMatrixButton(true);
    };

    const matrixNotChange = async () => {
      setChangeMatrixButton(false);
    };

    const getMatrix = async () => {
      try {
        const response = await pitotTraverseActualTestService.getByPitotId(
          values?.id
        );
        await executeSave(response.data);
        setDataBase(values?.ductSizeBase ?? 0);
        setDataHeight(values?.ductSizeHeight ?? 0);
        setDataInsulation(values?.internalInsulation ?? 0);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (
      item &&
      item.length > 0 &&
      values &&
      stepContinuos === "step2" &&
      ((ductSizeBase > 0 && values?.ductSizeBase !== dataBase) ||
        (ductSizeHeight > 0 && values?.ductSizeHeight !== dataHeight) ||
        (internalInsulation >= 0 &&
          values?.internalInsulation !== dataInsulation))
    ) {
      matrixChange();
    } else if (
      item &&
      item.length > 0 &&
      values &&
      stepContinuos === "step2" &&
      ((ductSizeBase > 0 && values?.ductSizeBase === dataBase) ||
        (ductSizeHeight > 0 && values?.ductSizeHeight === dataHeight) ||
        (internalInsulation >= 0 &&
          values?.internalInsulation === dataInsulation))
    ) {
      matrixNotChange();
    } else if (
      ((data && data.length === 0) || data === null) &&
      ductSizeBase > 0 &&
      internalInsulation >= 0 &&
      stepContinuos === "step2"
    ) {
      getMatrix();
    } else if (stepContinuos === "step2") {
      getMatrix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values?.internalInsulation,
    values?.conditionId,
  ]);

  useEffect(() => {
    if (pitotTraverseGrid && !ovalAction.ovalAction) {
      setShowChangeXYModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pitotTraverseGrid]);

  useEffect(() => {
    if (ovalAction.yesOvalAction) {
      changeMatrixXY();
      setOvalAction({
        ovalAction: ovalAction.ovalAction,
        noOvalAction: false,
        yesOvalAction: false,
      });
    } else if (ovalAction.noOvalAction) {
      cancelModalXY();
      setOvalAction({
        ovalAction: ovalAction.ovalAction,
        noOvalAction: false,
        yesOvalAction: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovalAction]);

  const sumItems = (currentIndex: number, currentValue: number) => {
    let sumT = 0;
    data.forEach((current, index) => {
      if (current?.item1 && (index !== currentIndex || currentValue !== 1))
        sumT = sumT + current?.item1;
      if (current?.item2 && (index !== currentIndex || currentValue !== 2))
        sumT = sumT + current?.item2;
      if (current?.item3 && (index !== currentIndex || currentValue !== 3))
        sumT = sumT + current?.item3;
      if (current?.item4 && (index !== currentIndex || currentValue !== 4))
        sumT = sumT + current?.item4;
      if (current?.item5 && (index !== currentIndex || currentValue !== 5))
        sumT = sumT + current?.item5;
      if (current?.item6 && (index !== currentIndex || currentValue !== 6))
        sumT = sumT + current?.item6;
      if (current?.item7 && (index !== currentIndex || currentValue !== 7))
        sumT = sumT + current?.item7;
      if (current?.item8 && (index !== currentIndex || currentValue !== 8))
        sumT = sumT + current?.item8;
      if (current?.item9 && (index !== currentIndex || currentValue !== 9))
        sumT = sumT + current?.item9;
      if (current?.item10 && (index !== currentIndex || currentValue !== 10))
        sumT = sumT + current?.item10;
      if (current?.item11 && (index !== currentIndex || currentValue !== 11))
        sumT = sumT + current?.item11;
      if (current?.item12 && (index !== currentIndex || currentValue !== 12))
        sumT = sumT + current?.item12;
      if (current?.item13 && (index !== currentIndex || currentValue !== 13))
        sumT = sumT + current?.item13;
      if (current?.item14 && (index !== currentIndex || currentValue !== 14))
        sumT = sumT + current?.item14;
      if (current?.item15 && (index !== currentIndex || currentValue !== 15))
        sumT = sumT + current?.item15;
      if (current?.item16 && (index !== currentIndex || currentValue !== 16))
        sumT = sumT + current?.item16;
    });

    return sumT;
  };

  const handleSetItem = (
    element: any,
    e: any,
    value: string,
    index: number
  ) => {
    element[`item${value}`] = Number(e.target.value);
    setItem(element);
    let sum = sumItems(index, Number(value));
    sum =
      sum +
      (Number(element?.item1 && Number(value) === 1) ? element?.item1 : 0) +
      (Number(element?.item2 && Number(value) === 2) ? element?.item2 : 0) +
      (Number(element?.item3 && Number(value) === 3) ? element?.item3 : 0) +
      (Number(element?.item4 && Number(value) === 4) ? element?.item4 : 0) +
      (Number(element?.item5 && Number(value) === 5) ? element?.item5 : 0) +
      (Number(element?.item6 && Number(value) === 6) ? element?.item6 : 0) +
      (Number(element?.item7 && Number(value) === 7) ? element?.item7 : 0) +
      (Number(element?.item8 && Number(value) === 8) ? element?.item8 : 0) +
      (Number(element?.item9 && Number(value) === 9) ? element?.item9 : 0) +
      (Number(element?.item10 && Number(value) === 10) ? element?.item10 : 0) +
      (Number(element?.item11 && Number(value) === 11) ? element?.item11 : 0) +
      (Number(element?.item12 && Number(value) === 12) ? element?.item12 : 0) +
      (Number(element?.item13 && Number(value) === 13) ? element?.item13 : 0) +
      (Number(element?.item14 && Number(value) === 14) ? element?.item14 : 0) +
      (Number(element?.item15 && Number(value) === 15) ? element?.item15 : 0) +
      (Number(element?.item16 && Number(value) === 16) ? element?.item16 : 0);
    onBlur(sum, data?.length * lengthItems);
  };

  const lengthItems = useMemo(() => {
    let listItems = 0;
    if (values?.point1 !== 0 && values?.point1 != null) {
      listItems = 1;
    }
    if (values?.point2 !== 0 && values?.point2 != null) listItems = 2;
    if (values?.point3 !== 0 && values?.point3 != null) listItems = 3;
    if (values?.point4 !== 0 && values?.point4 != null) listItems = 4;
    if (values?.point5 !== 0 && values?.point5 != null) listItems = 5;
    if (values?.point6 !== 0 && values?.point6 != null) listItems = 6;
    if (values?.point7 !== 0 && values?.point7 != null) listItems = 7;
    if (values?.point8 !== 0 && values?.point8 != null) listItems = 8;
    if (values?.point9 !== 0 && values?.point9 != null) listItems = 9;
    if (values?.point10 !== 0 && values?.point10 != null) listItems = 10;
    if (values?.point11 !== 0 && values?.point11 != null) listItems = 11;
    if (values?.point12 !== 0 && values?.point12 != null) listItems = 12;
    if (values?.point13 !== 0 && values?.point13 != null) listItems = 13;
    if (values?.point14 !== 0 && values?.point14 != null) listItems = 14;
    if (values?.point15 !== 0 && values?.point15 != null) listItems = 15;
    if (values?.point16 !== 0 && values?.point16 != null) listItems = 16;
    return listItems;
  }, [values]);

  const changeMatrixXY = async () => {
    setGridLoading(true);
    try {
      await executeRefresh(values?.id, values);
      const response = await pitotTraverseActualTestService.getByPitotId(
        values?.id
      );
      await executeSave(response.data);
      setDataBase(values?.ductSizeBase ?? 0);
      setDataHeight(values?.ductSizeHeight ?? 0);
      setDataInsulation(values?.internalInsulation ?? 0);
      if (!ovalAction.ovalAction) setShowSteady(true);
      setChangeMatrixButton(false);
      setPitotTraverseGrid(false);
      setShowChangeXYModal(false);
      setGridLoading(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const cancelModalXY = async () => {
    const newValues = {
      ...values,
      ductSizeBase: dataBase,
      ductSizeHeight: dataHeight,
      internalInsulation: dataInsulation,
    };

    await executeRefresh(values?.id, newValues);
    setShowChangeXYModal(false);
    setPitotTraverseGrid(false);
    setChangeMatrixButton(false);
  };

  return (
    <>
      <Table>
        <TableBody>
          <SpreadSheetRow>
            <SpreadSheetCell key={`number`} width={"5%"}>
              <Typography
                mt={0.8}
                padding={1.8}
                fontWeight="bold"
                textAlign={"center"}
              >
                #
              </Typography>
            </SpreadSheetCell>
            <>
              {Array.from({ length: lengthItems }, (_, i) => (
                <SpreadSheetCell key={`${i + 1}`}>
                  <>
                    {values.ductTypeId === 3 && values.ductSizeHeight <= 10 && (
                      <>
                        {i === 3 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point3}
                          </Typography>
                        )}
                        {i === 4 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point2}
                          </Typography>
                        )}
                        {i === 5 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point1}
                          </Typography>
                        )}
                      </>
                    )}
                    {values.ductTypeId === 3 && values.ductSizeHeight > 10 && (
                      <>
                        {i === 5 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point5}
                          </Typography>
                        )}
                        {i === 6 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point4}
                          </Typography>
                        )}
                        {i === 7 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point3}
                          </Typography>
                        )}
                        {i === 8 && (
                          <Typography
                            mt={0.8}
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point4}
                          </Typography>
                        )}
                        {i === 9 && (
                          <Typography
                            pl={1.8}
                            color={"primary.main"}
                            fontWeight={"bold"}
                          >
                            {values.point5}
                          </Typography>
                        )}
                      </>
                    )}
                    <Typography mt={0.8} padding={1.8} fontWeight="bold">
                      {values[`point${i + 1}`].toFixed(2)}
                    </Typography>
                  </>
                </SpreadSheetCell>
              ))}
            </>
          </SpreadSheetRow>
          {data &&
            data?.length > 0 &&
            Object.values(data ?? []).map((item: any[], index: number) => (
              <SpreadSheetRow>
                <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                  <Typography
                    m={2}
                    mt={0.8}
                    paddingLeft={1.8}
                    fontWeight="bold"
                  >
                    {values[`total${index + 1}`].toFixed(2) > 0
                      ? values[`total${index + 1}`].toFixed(2)
                      : index + 1}
                  </Typography>
                </SpreadSheetCell>
                <>
                  {Array.from({ length: lengthItems }, (_, i) => (
                    <SpreadSheetCell key={``} minWidth={95}>
                      {showSkeleton ? (
                        <Skeleton height={50} />
                      ) : (
                        <>
                          <SpreadsheetNumericInput2
                            name={data[index][`item${i + 1}`]}
                            value={data[index][`item${i + 1}`]}
                            onBlur={(e: any) => {
                              handleSetItem(item, e, (i + 1).toString(), index);
                            }}
                            decimalScale={3}
                            maxValue={1000000}
                            disabled={disabled}
                            mode={disabled ? "read" : undefined}
                            placeholder={systemOfMeasurement.get("phfpm")}
                          ></SpreadsheetNumericInput2>
                        </>
                      )}
                    </SpreadSheetCell>
                  ))}
                </>
              </SpreadSheetRow>
            ))}
        </TableBody>
      </Table>
      <ChangeReadingsPopUp
        onConfirm={changeMatrixXY}
        text={`Are you certain you want to change the values of the dimensions?`}
        matchWord={"CHANGE"}
        isDialogOpen={showChangeXYModal}
        setIsDialogOpen={setShowChangeXYModal}
        onCancel={cancelModalXY}
        setShowSteady={setShowSteady}
        showSteady={showSteady}
      />
    </>
  );
};

export default PitotFanTraverseTTGrid;
